/**
 * @generated SignedSource<<9c2bae54754de500eec16794480e6050>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Day = "FRI" | "MON" | "SAT" | "SUN" | "THU" | "TUE" | "WED" | "%future added value";
export type JobTaskType = "BAKING" | "BEVERAGE_MAKING" | "CLEANING" | "CONVENIENCE_STORE" | "ETC" | "HOUSEWORK" | "KIDS_PICK_UP" | "KITCHEN_ASSISTANCE" | "KITCHEN_COOK" | "LEAFLET_DISTRIBUTION" | "LIGHT_WORK" | "NURSING" | "SERVING" | "SHOP_MANAGEMENT" | "TUTORING" | "%future added value";
export type WorkPeriod = "LESS_THAN_A_MONTH" | "MORE_THAN_A_MONTH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobPostFilterAlarmRegistration_jobPost$data = {
  readonly _id: string;
  readonly id: string;
  readonly jobTasks: ReadonlyArray<JobTaskType>;
  readonly workDays: ReadonlyArray<Day> | null | undefined;
  readonly workPeriod: WorkPeriod | null | undefined;
  readonly " $fragmentType": "JobPostFilterAlarmRegistration_jobPost";
};
export type JobPostFilterAlarmRegistration_jobPost$key = {
  readonly " $data"?: JobPostFilterAlarmRegistration_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostFilterAlarmRegistration_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostFilterAlarmRegistration_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTasks",
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "c145f7b988bc4f4ffb85e14f0d58f30d";

export default node;
