import React from 'react'

type DetectTouchMoveOption = {
  direction: 'vertical' | 'horizontal'
  disatnce?: number
}

export function useDetectTouchMove({ direction = 'vertical', disatnce = 30 }: DetectTouchMoveOption) {
  const clientStart = React.useRef({ x: 0, y: 0 })
  const touchMoveRef = React.useRef(false)
  const [touchMove, setTouchMove] = React.useState(false)

  const handleTouchStart = React.useCallback((e: TouchEvent) => {
    const { clientY, clientX } = e.touches[0]
    clientStart.current.x = clientX
    clientStart.current.y = clientY
  }, [])

  const handleTouchMove = React.useCallback(
    (e: TouchEvent) => {
      const { clientY, clientX } = e.touches[0]
      const moveX = Math.abs(clientX - clientStart.current.x)
      const moveY = Math.abs(clientY - clientStart.current.y)
      if (direction === 'vertical' && moveY > disatnce && moveX < disatnce) {
        touchMoveRef.current = true
      } else if (direction === 'horizontal' && moveX > disatnce && moveY < disatnce) {
        touchMoveRef.current = true
      }
    },
    [direction, disatnce]
  )

  const handleTouchEnd = React.useCallback(() => {
    setTouchMove(touchMoveRef.current)
    touchMoveRef.current = false
  }, [])

  React.useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleTouchMove)
    document.addEventListener('touchend', handleTouchEnd)
    return () => {
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [handleTouchStart, handleTouchMove, handleTouchEnd])

  return { direction, touchMove }
}
