import { useReferrerContext } from '@src/packages/logAnalyticsEvent/ReferrerContext'
import { MAIN_PATH } from '@src/router/routes'
import { generateURL } from '@src/utils/url'

import { useEnhancedRouter } from './useEnhancedRouter'

export const useRefreshJobs = () => {
  const { replace: bridgeReplace } = useEnhancedRouter()
  const { referrerInfo } = useReferrerContext()

  const refreshJobs = (paths: string | string[] = MAIN_PATH, params?: Record<any, any>) => {
    bridgeReplace({
      remote: generateURL({
        base: window.location.origin,
        paths,
        params: {
          referrer: referrerInfo?.referrer,
          referrer_context: referrerInfo?.referrer_context,
          ...params,
        },
      }),
    })
  }

  return { refreshJobs }
}
