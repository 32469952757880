/**
 * @generated SignedSource<<cb25692f598441fb95ef622d1db425c2>>
 * @relayHash c84b13de5b1d676ce1b4f124a078172b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c84b13de5b1d676ce1b4f124a078172b

import { ConcreteRequest, Query } from 'relay-runtime';
export type usePhoneMappingMutationByAuthorQuery$variables = {
  id: string;
};
export type usePhoneMappingMutationByAuthorQuery$data = {
  readonly application: {
    readonly appliedAt: Dateable;
    readonly applyLocation: {
      readonly haversineDistance: number | null | undefined;
    } | null | undefined;
    readonly jobPost: {
      readonly _id: string;
    } | null | undefined;
    readonly phoneMapping: {
      readonly safeNumber: string | null | undefined;
    } | null | undefined;
    readonly resume: {
      readonly _id: string;
    } | null | undefined;
  } | null | undefined;
};
export type usePhoneMappingMutationByAuthorQuery = {
  response: usePhoneMappingMutationByAuthorQuery$data;
  variables: usePhoneMappingMutationByAuthorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PhoneMapping",
  "kind": "LinkedField",
  "name": "phoneMapping",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "safeNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appliedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ApplyLocation",
  "kind": "LinkedField",
  "name": "applyLocation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "haversineDistance",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePhoneMappingMutationByAuthorQuery",
    "selections": [
      {
        "alias": "application",
        "args": (v1/*: any*/),
        "concreteType": "Application",
        "kind": "LinkedField",
        "name": "applicationById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Resume",
            "kind": "LinkedField",
            "name": "resume",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobPost",
            "kind": "LinkedField",
            "name": "jobPost",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePhoneMappingMutationByAuthorQuery",
    "selections": [
      {
        "alias": "application",
        "args": (v1/*: any*/),
        "concreteType": "Application",
        "kind": "LinkedField",
        "name": "applicationById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Resume",
            "kind": "LinkedField",
            "name": "resume",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobPost",
            "kind": "LinkedField",
            "name": "jobPost",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "c84b13de5b1d676ce1b4f124a078172b",
    "metadata": {},
    "name": "usePhoneMappingMutationByAuthorQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "423182e946c81e42c16d1de1baca2042";

export default node;
