import { CssComponent } from 'node_modules/@stitches/react/types/styled-component'
import React from 'react'

import Button from '@src/components/base/buttons/Button'
import { styled } from '@src/stitches/stitches.config'

interface ErrorPageProps {
  /**
   * title, messages 보다 우선순위 높음.
   */
  children?: React.ReactNode
  /**
   * 메시지 타이틀
   */
  title?: string
  /**
   * 메시지 리스트(줄바꿈 됌)
   */
  messages?: string[]
  /**
   * stitches css override
   */
  customStyle?: CssComponent
  /**
   * stitches css override
   */
  buttonStyle?: CssComponent
  /**
   * stitches css override
   */
  buttonMessage?: React.ReactNode
  /**
   * 액션을 취하기 위한 버튼을 보여주는 여부
   */
  showButton?: boolean
  onClickButton?: () => void
}

const ErrorPage: React.FCC<ErrorPageProps> = ({
  title,
  messages,
  children,
  customStyle,
  onClickButton,
  buttonMessage,
  showButton = true,
}) => {
  return (
    <Base className={customStyle ? customStyle() : ''}>
      <Contents>
        {children || (
          <>
            {title && <Title>{title}</Title>}
            {messages && (
              <Message>
                {messages.map((message, idx) => (
                  <React.Fragment key={message}>
                    {message}
                    {messages.length - 1 > idx && <br />}
                  </React.Fragment>
                ))}
              </Message>
            )}
          </>
        )}
        {showButton && <BackButton onClick={onClickButton}>{buttonMessage || '닫기'}</BackButton>}
      </Contents>
    </Base>
  )
}

const Base = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  zIndex: '$page1',
  background: '$paperDefault-semantic',
  padding: '0 0 19px',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',

  '& *': {
    boxSizing: 'border-box',
  },
})

const Contents = styled('div', {
  flex: 1,
  width: '100%',
  textAlign: 'center',
})

const Title = styled('h2', {
  margin: '0 0 4px',
  $text: 'subtitle1Regular',
})

const Message = styled('p', {
  color: '$gray600',
  $text: 'subtitle2Regular',
})

const BackButton = styled(Button, {
  margin: '38px auto 0',
  minWidth: '120px',

  defaultVariants: {
    stretch: false,
    size: 'medium',
    priority: 'secondary',
  },
})

export default ErrorPage
