type FieldTransforms<T, F> = {
  [K in keyof (T & F)]?: (value: K extends keyof T ? T[K] : never, origin: T) => any
}

/*
 * Type T: transform 의 arguments
 * Type F: transform 의 return Type
 **/
export function transformFields<T extends Record<string, any>, F extends Record<string, any> = Record<string, unknown>>(
  fieldTransforms: FieldTransforms<T, F>
) {
  type Result = T & (F extends Record<string, any> ? F : Record<string, unknown>)
  return (data: T): Result => {
    const newData = { ...data } as Result
    for (const [key, transform] of Object.entries(fieldTransforms)) {
      if (transform) {
        newData[key as keyof Result] = transform(newData[key], newData)
      }
    }
    return newData
  }
}
