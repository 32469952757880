import React from 'react'

import { styled } from '@src/stitches/stitches.config'

type Props = {
  isChecked?: boolean
  size?: 'large' | 'medium' | 'small'
} & Omit<JSX.IntrinsicElements['svg'], 'ref'>

const CheckboxSquare: React.FCC<Props> = ({ isChecked = false, size = 'large', ...props }) => {
  const side = { large: 24, medium: 20, small: 18 }[size]

  return (
    <Square
      isChecked={isChecked}
      width={side}
      height={side}
      viewBox="0 0 18 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect x="0.5" y="1" width="17" height="17" rx="3.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8536 6.14645C14.0488 6.34171 14.0488 6.65829 13.8536 6.85355L7.85355 12.8536C7.65829 13.0488 7.34171 13.0488 7.14645 12.8536L4.14645 9.85355C3.95118 9.65829 3.95118 9.34171 4.14645 9.14645C4.34171 8.95118 4.65829 8.95118 4.85355 9.14645L7.5 11.7929L13.1464 6.14645C13.3417 5.95118 13.6583 5.95118 13.8536 6.14645Z"
      />
    </Square>
  )
}

export default CheckboxSquare

const Square = styled('svg', {
  variants: {
    isChecked: {
      true: {
        fill: '$primary-semantic',
        '& > path': {
          fill: '$white-static',
        },
      },
      false: {
        fill: '$paperDefault-semantic',
        '& > rect': {
          stroke: '$gray300',
        },
        '& > path': {
          fill: '$paperDefault-semantic',
        },
      },
    },
  },
})
