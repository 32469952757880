import { configureStore } from '@reduxjs/toolkit'
import { Store } from 'redux'
import thunk from 'redux-thunk'

import rootReducer, { RootState } from '@src/ducks/rootReducer'
import logger from '@src/middlewares/logger'
import updateFilterStatusMiddleware from '@src/middlewares/updateFilterStatus'

import { sentryReduxEnhancer } from './sdks/sentry'

let store: Store<RootState> | null = null

const createMiddleware = () => {
  const middlewares: any = [thunk, updateFilterStatusMiddleware]

  if (process.env.NODE_ENV === 'development') {
    return [logger, ...middlewares]
  }

  return middlewares
}

const _configureStore = () => {
  const middlewares = createMiddleware()
  store = configureStore({ reducer: rootReducer(), middleware: middlewares, enhancers: [sentryReduxEnhancer] })

  return store
}

export default _configureStore()
