import React from 'react'

import { userSelectText } from '@src/stitches/action'
import { styled } from '@src/stitches/stitches.config'

interface Props {
  content?: string | null
  length?: number
}

const LoadMoreContent: React.FCC<Props> = ({ content, length = 140 }) => {
  const [isContentEllipsis, setIsContentEllipsis] = React.useState(false)
  const [isContentOpen, setIsContentOpen] = React.useState(false)
  const [ellipsisContent, setEllipsisContent] = React.useState('')
  const [isOverLineLength, setIsOverLineLength] = React.useState(false)
  const contentRef = React.useRef<HTMLParagraphElement>(null)

  const handleMoreClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setEllipsisContent('')
    setIsContentEllipsis(false)
    setIsContentOpen(true)
  }

  const truncateContent = React.useCallback(
    (originContent?: string | null) => {
      if (!originContent) return

      let overContentLength = false
      let overLineLength = false
      let copyContent = originContent

      if (copyContent.length > length) {
        overContentLength = true
        copyContent = copyContent.substr(0, length) + '... \u00A0\u00A0\u00A0'
      }

      if (copyContent.split(/\r?\n/).length > 4) overLineLength = true

      if (overLineLength || overContentLength) {
        if (overLineLength) {
          copyContent = copyContent.split(/\r?\n/).slice(0, 4).join('\n')
          copyContent += '...'
          setIsOverLineLength(true)
        }
        setEllipsisContent(copyContent)
        setIsContentEllipsis(true)
      }
    },
    [length]
  )

  React.useEffect(() => {
    const element = contentRef.current

    if (!element) return
    if (isContentOpen) return

    truncateContent(content)
  }, [content, truncateContent, isContentOpen, isContentEllipsis])

  if (!content) return null

  return (
    <ContentWrapper>
      <ContentText ref={contentRef}>
        {ellipsisContent ? ellipsisContent : content}
        {isContentEllipsis && (
          <LoadMore onClick={handleMoreClick} isOverLineLength={isOverLineLength}>
            더보기
          </LoadMore>
        )}
      </ContentText>
    </ContentWrapper>
  )
}

export default LoadMoreContent

const ContentWrapper = styled('div', {
  position: 'relative',
})

const ContentText = styled('p', userSelectText, {
  $text: 'bodyL1Regular',
  overflow: 'hidden',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
})

const LoadMore = styled('span', {
  $text: 'bodyL1Regular',
  display: 'inline-block',
  color: '$gray600',
  variants: {
    isOverLineLength: {
      true: {
        display: 'block',
        paddingTop: '8px',
      },
    },
  },
})
