/**
 * @generated SignedSource<<8332eacd3526acf40553138e96cce91d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { receivedApplicantCommentReviewCount as userReceivedApplicantCommentReviewCountResolverType } from "../graphql/resolvers/UserReceivedApplicantCommentReviewCount";
import { receivedApplicantPositiveReviewCount as userReceivedApplicantPositiveReviewCountResolverType } from "../graphql/resolvers/UserReceivedApplicantPositiveReviewCount";
import { receivedApplicantReviewCount as userReceivedApplicantReviewCountResolverType } from "../graphql/resolvers/UserReceivedApplicantReviewCount";
export type JobPostAuthorReviewTab_jobPost$data = {
  readonly author: {
    readonly hoianUser?: {
      readonly _id: number;
      readonly previewReviews: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"ApplicantReviewCard_jobReview">;
      }>;
      readonly receivedApplicantCommentReviewCount: number | null | undefined;
      readonly receivedApplicantPositiveReviewCount: number | null | undefined;
      readonly receivedApplicantReviewCount: number | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ApplicantReviewStats_user" | "SelectedReviewItemList_user">;
    };
    readonly id?: string;
  };
  readonly companyName: string | null | undefined;
  readonly " $fragmentType": "JobPostAuthorReviewTab_jobPost";
};
export type JobPostAuthorReviewTab_jobPost$key = {
  readonly " $data"?: JobPostAuthorReviewTab_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostAuthorReviewTab_jobPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostAuthorReviewTab_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "hoianUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserReceivedApplicantReviewCountResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "receivedApplicantReviewCount",
                  "resolverModule": require('./../graphql/resolvers/UserReceivedApplicantReviewCount').receivedApplicantReviewCount,
                  "path": "author.hoianUser.receivedApplicantReviewCount"
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserReceivedApplicantPositiveReviewCountResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "receivedApplicantPositiveReviewCount",
                  "resolverModule": require('./../graphql/resolvers/UserReceivedApplicantPositiveReviewCount').receivedApplicantPositiveReviewCount,
                  "path": "author.hoianUser.receivedApplicantPositiveReviewCount"
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserReceivedApplicantCommentReviewCountResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "receivedApplicantCommentReviewCount",
                  "resolverModule": require('./../graphql/resolvers/UserReceivedApplicantCommentReviewCount').receivedApplicantCommentReviewCount,
                  "path": "author.hoianUser.receivedApplicantCommentReviewCount"
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 3
                    }
                  ],
                  "concreteType": "JobReview",
                  "kind": "LinkedField",
                  "name": "previewReviews",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ApplicantReviewCard_jobReview"
                    }
                  ],
                  "storageKey": "previewReviews(first:3)"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ApplicantReviewStats_user"
                },
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "evaluator",
                      "value": "APPLICANT"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "SelectedReviewItemList_user"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};
})();

(node as any).hash = "04531e4f6ab5143db7e8319f7663ea50";

export default node;
