import { mapValues } from 'lodash/fp'
import React from 'react'

import { styled } from '@src/stitches/stitches.config'

type DraggableProps = {
  initialRight?: number
  initialBottom?: number
}

export default <Props,>(WrappedComponent: React.ComponentType<React.PropsWithChildren<Props & DraggableProps>>) => {
  const DraggableComponent: React.FCC<Props & DraggableProps> = ({ initialBottom, initialRight, ...props }) => {
    const [style, setStyle] = React.useState({ right: initialRight ?? 0, bottom: initialBottom ?? 0 })
    const [pos, setPos] = React.useState({ x: 0, y: 0 })

    const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
      setPos({ x: e.touches[0].clientX, y: e.touches[0].clientY })
    }

    const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
      const $el = document.querySelector(`.${Wrapped.className}`)

      if (!$el) return

      const dx = e.touches[0].clientX - pos.x
      const dy = e.touches[0].clientY - pos.y
      setPos({ x: e.touches[0].clientX, y: e.touches[0].clientY })
      setStyle((prev) => ({ right: Math.max(prev.right - dx, 0), bottom: Math.max(prev.bottom - dy, 0) }))
    }

    const css = mapValues((v) => `${v}px`)(style)

    return (
      <Container style={css}>
        <WrappedComponent
          className={`${Wrapped.className}`}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          {...(props as Props)}
        />
      </Container>
    )
  }

  return DraggableComponent
}

const Container = styled('div', {
  zIndex: '$global1',
  position: 'absolute',
})

const Wrapped = styled('div')
