import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { JobPostCardContainer_feedCard$key } from '@src/__generated__/JobPostCardContainer_feedCard.graphql'
import { advertisement as advertisementAPI } from '@src/api/api'
import JobPostCardImpressionChecker from '@src/components/pageMain/JobPostCardImpressionChecker'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'

import JobPostCard from './JobPostCard'

type ChildrenProps = {
  advertisement: React.ComponentProps<typeof JobPostCard>['advertisement']
  logger: () => void
}

interface Props {
  feedCardRef: JobPostCardContainer_feedCard$key
  advertisement: React.ComponentProps<typeof JobPostCard>['advertisement']
  cardPage?: number
  cardIndex: number
  searchKeyword?: string
  children: (arg0: ChildrenProps) => React.ReactNode
}

const JobPostCardContainer: React.FC<Props> = ({
  feedCardRef,
  advertisement,
  cardPage,
  cardIndex,
  searchKeyword,
  children,
}) => {
  const { logClickEvent } = useLogAnalyticsEvent()
  const card = useFragment(
    graphql`
      fragment JobPostCardContainer_feedCard on FeedCard {
        advertisement {
          exposureType
          callback {
            hide
            impression
            click
          }
        }
        jobPost {
          _id
          bringUpCount
          author {
            ... on HoianUserAuthor {
              badge {
                items {
                  ... on FastResponseBadge {
                    __typename
                  }
                }
              }
            }
          }
        }
      }
    `,
    feedCardRef
  )

  const logEvent = () => {
    const badges = card.jobPost.author.badge?.items

    logClickEvent({
      name: 'job_post_card',
      params: {
        job_post_id: card.jobPost._id,
        bring_up_count: card.jobPost.bringUpCount,
        badges: badges?.map((a) => a.__typename).join(',') || undefined,
        advertisement:
          // intersectionObserver가 지원되지 않는 브라우저에서는 광고 impression을 보내지 않기 때문에, 클릭도 동일하게 맞춤
          typeof IntersectionObserver === 'undefined' ? false : isAdvertisement,
        page: cardPage ?? 0,
        index: cardIndex,
        keyword: searchKeyword,
      },
    })
  }

  const isAdvertisement = !!card.advertisement?.exposureType
  const { click, impression } = card.advertisement?.callback ?? { click: null, impression: null }

  return (
    <>
      {children({
        advertisement: { ...advertisement, isAdvertisement, hideUrl: card.advertisement?.callback?.hide },
        logger: () => {
          advertisement?.isAdvertisement && advertisementAPI.sendClickEvent({ click, impression })
          logEvent()
        },
      })}
      <JobPostCardImpressionChecker
        _id={card.jobPost._id}
        cardPage={cardPage}
        cardIndex={cardIndex}
        isAdvertisement={isAdvertisement}
        advertisementImpressionUrl={card.advertisement?.callback?.impression}
      />
    </>
  )
}

export default JobPostCardContainer
