/**
 * @generated SignedSource<<4421a7242b0c87ff60ae5f97f64dff9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthorSection_jobPost$data = {
  readonly companyName: string | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AuthorStats_jobPost" | "Author_jobPost" | "JobPostAuthorReputationTabs_jobPost">;
  readonly " $fragmentType": "AuthorSection_jobPost";
};
export type AuthorSection_jobPost$key = {
  readonly " $data"?: AuthorSection_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthorSection_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthorSection_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Author_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthorStats_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobPostAuthorReputationTabs_jobPost"
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "ecf3c86a107a62f4c6b7c4bd48c210fb";

export default node;
