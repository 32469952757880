import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry'
import { createAxiosInterceptors } from 'plantae/axios'
import { v4 as uuid } from 'uuid'

import storage from '@src/api/storage'
import { getPlantaeConfig } from '@src/sdks/plantae'

export default class Request {
  private axios: AxiosInstance

  constructor(baseURL?: string) {
    this.axios = axios.create({ baseURL })
  }

  public setTokenHeader() {
    this.axios.interceptors.request.use(
      (config) => {
        const authToken = storage.getToken()

        if (authToken) {
          config.headers['x-auth-token'] = authToken
        }

        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  public setUserAgentHeader() {
    this.axios.interceptors.request.use(
      (config) => {
        const userAgent = storage.getUserAgent()

        if (userAgent) {
          config.headers['x-user-agent'] = userAgent
        }

        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  public setBizTokenHeader() {
    this.axios.interceptors.request.use(
      (config) => {
        const bizAccessToken = storage.getBizToken()

        if (bizAccessToken) {
          config.headers['x-biz-access-token'] = bizAccessToken
        }

        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  public setRequestIdHeader() {
    this.axios.interceptors.request.use(
      (config) => {
        const requestId = uuid()

        if (requestId) {
          config.headers['x-request-id'] = requestId
        }

        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  public setPlantaeInterceptor() {
    const { request, response } = createAxiosInterceptors(getPlantaeConfig(this.axios))

    this.axios.interceptors.request.use(request.onFulfilled, request.onRejected)
    this.axios.interceptors.response.use(response.onFulfilled, response.onRejected)
  }

  public setRetry(options?: IAxiosRetryConfig) {
    axiosRetry(this.axios, options)
  }

  public get(config: Omit<AxiosRequestConfig, 'method'>) {
    return this.axios({ method: 'get', ...config })
  }

  public delete(config: Omit<AxiosRequestConfig, 'method'>) {
    return this.axios({ method: 'delete', ...config })
  }

  public post(config: Omit<AxiosRequestConfig, 'method'>) {
    return this.axios({ method: 'post', ...config })
  }

  public put(config: Omit<AxiosRequestConfig, 'method'>) {
    return this.axios({ method: 'put', ...config })
  }
}
