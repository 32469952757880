import React from 'react'
import { useSelector } from 'react-redux'
import { graphql, useLazyLoadQuery } from 'react-relay'

import { JobPostPageQuery } from '@src/__generated__/JobPostPageQuery.graphql'
import storage from '@src/api/storage'
import NonBlockingGlobalLoader from '@src/components/base/loader/NonBlockingGlobalLoader'
import JobPostPageScreen from '@src/components/pageJobPost/JobPostAppScreen'
import JobPostFooter from '@src/components/pageJobPost/JobPostFooter'
import JobPostJobPost from '@src/components/pageJobPost/JobPostJobPost'
import JobPostOtherPosts from '@src/components/pageJobPost/JobPostOtherPosts'
import JobPostPersonalPosts from '@src/components/pageJobPost/JobPostPersonalPosts'
import { PageCodeInfoProvider } from '@src/contexts/PageCodeInfoContext'
import use202307CampaignMarketing from '@src/hooks/use202307CampaignMarketing'
import { useCalcWalkingDistance } from '@src/hooks/useCalcWalkingDistance'
import { useJobPostStatus } from '@src/hooks/useJobPostStatus'
import { useJobsActivityParams } from '@src/hooks/useJobsActivityParams'
import useNumberOfEventsInTime from '@src/hooks/useNumberOfEventsInTime'
import { useJobPostReportMutation } from '@src/mutations/jobPost/useJobPostReportMutation'
import { useJobPostShareMutation } from '@src/mutations/jobPost/useJobPostShareMutation'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { JobsActivity } from '@src/router/activityNames'
import Redirect from '@src/router/Redirect'
import RedirectNotFoundPage from '@src/router/RedirectNotFoundPage'
import { bridge } from '@src/sdks/bridge'
import { currentRegionIdSelector } from '@src/selectors/region'
import { currentPositionSelector, isLoginSelector } from '@src/selectors/user'
import { styled } from '@src/stitches/stitches.config'
import { JobTaskType } from '@src/types/jobPost'
import { calcShortWorkPeriod } from '@src/utils/jobPost'

export const JobPostPageQueryNode = graphql`
  query JobPostPageQuery($id: ID!, $regionId: Int!) {
    jobPost: jobPostById(id: $id) {
      id
      _id
      title
      author {
        ... on HoianUserAuthor {
          hoianUser {
            _id
            receivedApplicantReviewCount
            receivedApplicantCommentReviewCount
            lastActivatedAt
          }
          badge {
            items {
              ... on FastResponseBadge {
                __typename
              }
            }
          }
        }
      }
      status
      hidden
      deleted
      eventGroup {
        type
      }
      # eslint-disable-next-line relay/unused-fields
      workplaceLocation {
        lat
        lng
      }
      workplaceRegion {
        name3Region {
          _id
        }
      }
      bringUpCount
      companyName
      # eslint-disable-next-line relay/unused-fields
      images {
        url
      }
      workPeriod
      jobTasks
      ...useJobPostShareMutation_jobPost
      ...useJobPostReportMutation_jobPost
      ...JobPostFooter_jobPost
      ...JobPostJobPost_jobPost
      ...JobPostOtherPosts_jobPost
    }
    me {
      _id
      ...JobPostFooter_user
      ...JobPostJobPost_user
    }
    ...JobPostPersonalPosts_query @arguments(id: $id)
    statistic {
      liveJobPost(region: $regionId)
    }
  }
`

const JobPostPage: React.FCC = () => {
  const currentRegionId = useSelector(currentRegionIdSelector)
  const { jobPostId, referrer } = useJobsActivityParams<typeof JobsActivity.JOB_POST>()
  const query = useLazyLoadQuery<JobPostPageQuery>(
    JobPostPageQueryNode,
    { id: jobPostId, regionId: currentRegionId ?? 0 },
    { fetchPolicy: 'store-or-network' }
  )
  const { mutateWithCallback: handlePostShareClick, isLoading: isPostShareLoading } = useJobPostShareMutation({
    jobPostRef: query.jobPost,
  })
  const { mutateWithCallback: handlePostReportClick } = useJobPostReportMutation({
    jobPostRef: query.jobPost,
  })
  const currentPosition = useSelector(currentPositionSelector)
  const { logShowEvent } = useLogAnalyticsEvent()
  const isLogin = useSelector(isLoginSelector)
  const inTimeEventCount = useNumberOfEventsInTime('JOB_POST_DETAIL', { clearCountInterval: 'day' })
  const is202307Campaign = use202307CampaignMarketing()
  const badges = query.jobPost?.author.badge?.items
  const { isLive: isPostLive } = useJobPostStatus(query.jobPost?.status)
  const isUserAuthor = query.jobPost?.author?.hoianUser?._id === query.me?._id
  const isUserAdmin = storage.getIsAdmin()
  const isPostDeleted = !!(!query.jobPost || query.jobPost.deleted || query.jobPost.hidden)
  const isPostDaangnEvent = query.jobPost?.eventGroup?.type === 'DAANGN_EVENT'
  const isPostVisible =
    query.jobPost && !isPostDeleted && (isPostLive || isUserAuthor || (isPostDaangnEvent && isUserAdmin))
  const { isWalkingDistance } = useCalcWalkingDistance({
    positions: [currentPosition?.position, query.jobPost?.workplaceLocation],
  })

  React.useEffect(() => {
    switch (true) {
      case inTimeEventCount % 2 === 0: {
        logShowEvent({
          name: 'job_post_detail_2_within_24',
          component_type: 'page',
        })
        break
      }
      case inTimeEventCount % 3 === 0:
        logShowEvent({
          name: 'job_post_detail_3_within_24',
          component_type: 'page',
        })
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!query.jobPost?.id) return

    logShowEvent({
      // 이벤트 이름 변경되면 안됨
      name: 'job_post_detail',
      component_type: 'page',
      params: {
        job_post_id: query.jobPost?._id,
        job_post_title: query.jobPost?.title,
        badges: badges?.map((a) => a.__typename).join(',') || undefined,
        bring_up_count: query.jobPost.bringUpCount,
        region3_id: query.jobPost.workplaceRegion?.name3Region?._id,
        selected_review_count: String(query.jobPost.author.hoianUser?.receivedApplicantReviewCount ?? 0),
        comment_review_count: String(query.jobPost.author.hoianUser?.receivedApplicantCommentReviewCount ?? 0),
        is_walking_distance: isWalkingDistance,
        is_personal: !query.jobPost.companyName,
        job_post_type: is202307Campaign ? 'EVENT' : 'NORMAL',
        last_activated_at: query.jobPost.author.hoianUser?.lastActivatedAt
          ? String(query.jobPost.author.hoianUser?.lastActivatedAt)
          : undefined,
        is_short_work_period: calcShortWorkPeriod(query.jobPost?.workPeriod),
        job_tasks: query.jobPost.jobTasks as JobTaskType[],
      },
      log_condition: referrer === 'feed',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isPostVisible) {
    return <RedirectNotFoundPage resource="알바공고" />
  }

  if (isUserAuthor) {
    return <Redirect to={JobsActivity.BIZ_JOB_POST_MANAGE} params={{ jobPostId: query.jobPost.id }} />
  }

  if (!query.me) {
    return <Redirect to={JobsActivity.NEED_LOGIN} params={{}} />
  }

  return (
    <JobPostPageScreen
      environment={bridge.environment}
      onShareClick={handlePostShareClick}
      onReportClick={handlePostReportClick}
      isLogin={isLogin}
      isEmpty={!isPostVisible}
      blockReport={isPostDaangnEvent}>
      <NonBlockingGlobalLoader isOpen={isPostShareLoading} />
      <ScrollHandler>
        <JobPostJobPost jobPostRef={query.jobPost} userRef={query.me} />

        <PageCodeInfoProvider pageSectionCode="personal_posts">
          <JobPostPersonalPosts queryRef={query} />
        </PageCodeInfoProvider>

        <PageCodeInfoProvider pageSectionCode="other_posts">
          <JobPostOtherPosts jobPostRef={query.jobPost} liveJobPostCount={query.statistic?.liveJobPost ?? 0} />
        </PageCodeInfoProvider>
      </ScrollHandler>
      <JobPostFooter jobPostRef={query.jobPost} userRef={query.me ?? null} />
    </JobPostPageScreen>
  )
}

export default JobPostPage

const ScrollHandler = styled('div', {
  $scrollable: 'vertical',
})
