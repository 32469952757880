import { datadogRum } from '@datadog/browser-rum-slim'

import { APP_ENV, DATADOG_APP_ID, DATADOG_CLIENT_TOKEN, IS_LOG_ENABLED, VERSION } from '@src/config'

const initDatadog = () => {
  if (!IS_LOG_ENABLED) return

  datadogRum.init({
    applicationId: DATADOG_APP_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'jobs-webview',
    env: APP_ENV,
    version: VERSION,
    sessionSampleRate: 0.2,
    sessionReplaySampleRate: 0, //datadog-rum-slim에서는 지원하지 않는 옵션
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    enableExperimentalFeatures: ['feature_flags'],
  })

  datadogRum.startSessionReplayRecording()
}

initDatadog()
