// @src confluence 업로드시 에러
/* eslint-disable no-restricted-imports */
import { PartialOnUndefinedDeep } from 'type-fest'

import {
  AnalyticsEvents,
  AnalyticsCategory as Category,
  AnalyticsLogOption as Log,
  AnalyticsParamGenerator as Param,
  Empty,
} from './type'
import { MARKETING_INVITE_202404, MARKETING_INVITE_REGULAR } from '../../constants/referrer'
import { ResumeFormType } from '../../types/resume'

export const analyticsShowEvents = {
  visited: {
    category: Category.ETC,
    description: '알바 방문',
    logs: [Log.JOBS_LOG_ONLY],
  },
  need_login: {
    category: Category.ACCOUNT,
    sample: true,
    description: '로그인 요구 페이지',
  },
  advertisement_terms: {
    category: Category.AD,
    sample: true,
    logs: [],
    description: '광고 약관창',
  },
  beginner_job_post: {
    category: Category.AD,
    sample: false,
    params: {
      advertisement_index: Param.Number.Required,
    },
    description: '초보알바 임프레션',
  },
  walking_distance_job_post: {
    category: Category.AD,
    sample: false,
    params: {
      advertisement_index: Param.Number.Required,
    },
    description: '걸어서10분 알바 임프레션',
  },
  teenager_job_post: {
    category: Category.AD,
    sample: false,
    params: {
      advertisement_index: Param.Number.Required,
    },
    description: '10대알바 임프레션',
  },
  senior_job_post: {
    category: Category.AD,
    sample: false,
    params: {
      advertisement_index: Param.Number.Required,
    },
    description: '시니어알바 임프레션',
  },
  top_ad_job_posts: {
    category: Category.AD,
    sample: false,
    description: '상단광고 영역 임프레션',
  },
  interest_posts: {
    category: Category.AD,
    sample: false,
    description: '상세 매력도 알바 임프레션',
    params: {
      type: Param.Enum('NEARBY', 'WEEKDAY').Required,
      filled_ad_count: Param.Number.Required,
      region_id: Param.Number.Required,
    },
  },
  popular_author_reviews: {
    category: Category.AD,
    sample: false,
    params: {
      advertisement_index: Param.Number.Required,
    },
    description: '좋은 사장님 소개 섹션 임프레션',
  },
  job_post_card: {
    category: Category.AD,
    sample: false,
    params: {
      advertisement: Param.Boolean.Required,
      page: Param.Number.Required,
      index: Param.Number.Required,
      job_post_id: Param.String.Required,
      viwed_to_applicant: Param.Boolean.Required,
    },
    logs: [],
    description: '공고 카드 임프레션',
  },
  advertisement_create: {
    category: Category.AD,
    sample: true,
    params: {
      job_post_id: Param.String.Optional,
      business_type: Param.Enum(
        'LARGE_SCALE_BUSINESS',
        'SMALL_BUSINESS',
        'FRANCHISE',
        'INDIVIDUAL',
        '%future added value'
      ).Required,
      bring_up_count: Param.Number.Optional,
      minutes_from_published_at: Param.Number.Optional,
      application_count: Param.Number.Optional,
      is_short_work_period: Param.Boolean.Optional,
      jobPostCount: Param.Number.Optional,
      hasAdvertiserAccount: Param.Boolean.Optional,
      jobsAdvertisementCount: Param.Number.Optional,
      is_advertise_unavailable: Param.Boolean.Optional,
      // Braze 공통발송 삭제되면 안됨
      jobsAdvertisementJobPostCount: Param.Number.Optional,
    },
    logs: [Log.MIXPANEL, Log.BRAZE],
    description: '광고 생성 페이지',
  },
  advertisement_update: {
    category: Category.AD,
    sample: true,
    params: {
      job_post_id: Param.String.Optional,
      business_type: Param.Enum(
        'LARGE_SCALE_BUSINESS',
        'SMALL_BUSINESS',
        'FRANCHISE',
        'INDIVIDUAL',
        '%future added value'
      ).Required,
    },
    logs: [Log.MIXPANEL],
    description: '광고 수정 페이지',
  },
  advertisement_reserve: {
    category: Category.AD,
    sample: true,
    params: {
      job_post_id: Param.String.Required,
      business_type: Param.Enum(
        'LARGE_SCALE_BUSINESS',
        'SMALL_BUSINESS',
        'FRANCHISE',
        'INDIVIDUAL',
        '%future added value'
      ).Required,
      duration: Param.Number.Required,
      daily_budget: Param.Number.Required,
    },
    logs: [],
    description: '광고 예약 페이지',
  },
  advertisement_reserve_exception: {
    category: Category.AD,
    sample: true,
    params: {
      region_count: Param.Number.Required,
      timing: Param.String.Required,
    },
    logs: [Log.MIXPANEL],
    description: '광고 예약 예외 케이스 추적',
  },
  introduce_jobs: {
    category: Category.AD,
    sample: true,
    description: '당근알바 소개 페이지',
  },
  large_scale_business_noti: {
    category: Category.AD,
    sample: true,
    logs: [],
    params: {
      job_post_id: Param.String.Required,
    },
    description: '대규모/상시채용 미노출 사유 노티',
  },
  large_scale_business_guide: {
    category: Category.AD,
    sample: true,
    logs: [],
    params: {
      job_post_id: Param.String.Required,
    },
    description: '대규모/상시채용 미노출 광고 유도 페이지',
  },
  new_job_post_filter_subscription: {
    category: Category.ALARM,
    sample: true,
    description: '새글 필터알림 설정 페이지',
  },
  confirm_hired: {
    category: Category.APPLICATION,
    sample: true,
    description: '채용 확정 컨펌 페이지',
  },
  applications: {
    category: Category.APPLICATION,
    sample: true,
    params: {
      minutes_from_published_at: Param.Number.Optional,
      application_count: Param.Number.Optional,
      bring_up_count: Param.Number.Optional,
      waiting_application_count: Param.Number.Required,
      open_right_bm_treatment: Param.Enum('A').Optional,
      engagement_score: Param.Number.Nullable,
    },
    logs: [Log.MIXPANEL],
    description: '지원자 목록 페이지',
  },
  application_open_confirm_dialog: {
    category: Category.APPLICATION,
    sample: true,
    params: {
      application_id: Param.String.Required,
    },
    description: '지원서 열람 다이얼로그',
    logs: [Log.MIXPANEL],
  },
  application_open_right_select: {
    category: Category.APPLICATION,
    sample: true,
    params: {
      job_post_id: Param.String.Required,
      waiting_application_count: Param.Number.Required,
    },
    description: '지원서 열람권 옵션 선택',
    logs: [Log.MIXPANEL],
  },
  application_open_right_purchase: {
    category: Category.APPLICATION,
    sample: true,
    params: {
      job_post_id: Param.String.Required,
      selected_count: Param.Number.Required,
      waiting_application_count: Param.Number.Required,
      payment_price: Param.Number.Required,
    },
    description: '지원서 열람권 구매',
    logs: [Log.MIXPANEL],
  },
  application_viewer: {
    category: Category.APPLICATION,
    sample: true,
    logs: [],
    description: '지원서 상세 페이지(legacy)',
  },
  application_detail: {
    category: Category.APPLICATION,
    params: {
      extra_feature_count: Param.Number.Required,
      is_first_view_by_author: Param.Boolean.Required,
      status: Param.String.Optional,
      open_right_bm_treatment: Param.Enum('A').Optional,
      engagement_score: Param.Number.Nullable,
    },
    sample: true,
    logs: [Log.MIXPANEL],
    description: '지원서 상세 페이지',
  },
  select_applicant: {
    category: Category.APPLICATION,
    sample: true,
    logs: [],
    description: '지원자 채용확정 페이지',
  },
  apply_job_post: {
    category: Category.APPLY,
    params: {
      status: Param.Enum(
        'resume_not_exist',
        'terms_not_agreed',
        'direct_apply',
        'name_invalid',
        'name_not_exist',
        'content_not_enough',
        'content_not_exist'
      ).Required,
      job_post_id: Param.String.Required,
    },
    logs: [],
    sample: true,
    description: '지원하기 페이지',
  },
  activity_badge: {
    category: Category.BADGE,
    sample: true,
    params: {
      role: Param.Role.Required,
    },
    logs: [],
    description: '활동배지 탭',
  },
  certificate_ask: {
    category: Category.CERTIFICATION,
    sample: true,
    description: '사업자인증 문의하기 페이지',
  },
  certificate_image_result: {
    category: Category.CERTIFICATION,
    sample: true,
    params: {
      success: Param.Boolean.Required,
    },
    logs: [Log.MIXPANEL],
    description: '사업자인증 OCR 결과',
  },
  biz_business_certificate: {
    category: Category.CERTIFICATION,
    sample: true,
    logs: [Log.MIXPANEL],
    description: '사업자등록번호 인증 페이지',
  },
  biz_identification_certificate: {
    category: Category.CERTIFICATION,
    sample: true,
    logs: [Log.MIXPANEL],
    description: '사업자 본인인증 페이지',
  },
  invite_event_invited: {
    category: Category.EVENT,
    sample: false,
    params: {
      event_kind: Param.String.Required,
      path: Param.String.Required,
    },
    logs: [],
    description: '초대 수락 페이지',
  },
  invited_success_halfview: {
    category: Category.EVENT,
    sample: true,
    params: {
      group: Param.Enum(MARKETING_INVITE_REGULAR, MARKETING_INVITE_202404).Required,
    },
    description: '초대 성공 하프뷰',
  },
  invite_event_prize: {
    category: Category.EVENT,
    sample: true,
    params: {
      group: Param.Enum(MARKETING_INVITE_REGULAR, MARKETING_INVITE_202404).Required,
    },
    description: '초대 이벤트 당첨금 확인 페이지',
  },
  pay_account_signup_guide: {
    category: Category.EVENT,
    sample: true,
    params: {
      group: Param.Enum(MARKETING_INVITE_REGULAR, MARKETING_INVITE_202404).Required,
    },
    description: '초대 이벤트 당첨금 확인시 페이 미가입 안내',
  },
  event_group_feed: {
    category: Category.EVENT,
    sample: true,
    description: '이벤트 그룹 피드',
    params: {
      group: Param.String.Required,
    },
    logs: [],
  },
  curation_feed: {
    category: Category.JOB_POST,
    sample: true,
    description: '큐레이션 피드',
    params: {},
    logs: [],
  },
  introduce_biz_invite: {
    category: Category.EVENT,
    sample: true,
    description: '사장님 초대 이벤트 소개 페이지',
  },
  campaign: {
    category: Category.EVENT,
    sample: true,
    params: {
      group: Param.Enum(
        'LGU_REWARD_202405',
        'JOBS_TOP_PREMIUM_202305',
        'MARKETING_PAID_CREATE_JOB_POST_202306',
        'MARKETING_PAY_ACCOUNT_SIGNUP_202309',
        'MARKETING_CREATE_JOB_POST_202309',
        'JOBS_NO_SHOW_COMPENSATION_JOB_POST_202309',
        'JOBS_NO_SHOW_COMPENSATION_ADVERTISEMENT_202309',
        'MARKETING_CREATE_JOB_POST_202310',
        'MARKETING_PAY_ACCOUNT_SIGNUP',
        'MARKETING_TOTALCARE_REGULAR',
        'FLY_202310',
        'MARKETING_TOTALCARE_202403',
        'MARKETING_NEW_SEMESTER_202403_APPLICANT',
        'MARKETING_APPLICANT_WARRANTY_202406'
      ).Required,
      is_new_user: Param.Boolean.Optional,
    },
    logs: [Log.MIXPANEL, Log.JOBS_LOG],
    description: '이벤트 페이지 (범용)',
  },
  event_banner: {
    category: Category.EVENT,
    params: {
      event_kind: Param.String.Required,
      image_type: Param.Number.Optional,
    },
    description: '이벤트 배너 확인',
  },
  main: {
    category: Category.HOME,
    component_type: 'page',
    sample: false,
    params: {
      // Braze 공통발송 삭제되면 안됨
      initial_referrer: Param.String.Nullable,
    },
    description: '메인 페이지',
    logs: [Log.BRAZE],
  },
  main_1_within_day: {
    category: Category.HOME,
    sample: false,
    description: '메인 페이지 당일 첫 조회',
  },
  me: {
    category: Category.PROFILE,
    sample: true,
    params: {
      applications_count: Param.Number.Required,
    },
    logs: [],
    description: '지원자 프로필 페이지',
  },
  me_tab: {
    category: Category.PROFILE,
    sample: true,
    params: {
      tab: Param.Enum('applications', 'favorites').Required,
    },
    description: '지원자 프로필 페이지 내 탭',
  },
  preview_resume: {
    category: Category.RESUME,
    params: {
      percent: Param.Number.Required,
    },
    sample: true,
    description: '이력서 미리보기 페이지',
  },
  manage_resume: {
    category: Category.RESUME,
    sample: true,
    description: '이력서 관리 페이지',
  },
  create_resume: {
    category: Category.RESUME,
    sample: true,
    params: {
      type: Param.Enum(...Object.values(ResumeFormType)).Required,
    },
    logs: [],
    description: '이력서 생성하기 페이지',
  },
  update_resume: {
    category: Category.RESUME,
    sample: true,
    params: {
      type: Param.Enum(...Object.values(ResumeFormType)).Required,
    },
    description: '이력서 수정하기 페이지',
  },
  add_experience: {
    category: Category.RESUME,
    sample: true,
    description: '경력 추가 페이지(이력서 생성 후)',
  },
  add_resume_experience: {
    category: Category.RESUME,
    sample: true,
    description: '경력 추가 페이지(이력서 생성 전)',
    logs: [],
  },
  update_experience: {
    category: Category.RESUME,
    sample: true,
    description: '경력 수정 페이지',
  },
  written_review: {
    category: Category.REVIEW,
    sample: true,
    params: {
      actor: Param.Role.Required,
    },
    description: '작성한 리뷰 상세 페이지',
  },
  received_review: {
    category: Category.REVIEW,
    sample: true,
    params: {
      actor: Param.Role.Required,
    },
    description: '받은 후기 모아보기 페이지',
  },
  applicant_selected_reviews: {
    category: Category.REVIEW,
    sample: true,
    logs: [],
    description: '구직자 객관후기 페이지',
  },
  review_list_to_send: {
    category: Category.REVIEW,
    sample: true,
    description: '후기 작성 가능한 구인글 페이지',
  },
  review_write_page: {
    category: Category.REVIEW,
    sample: true,
    params: {
      actor: Param.Role.Required,
    },
    description: '후기 작성 페이지',
  },
  author_comment_reviews: {
    category: Category.REVIEW,
    sample: true,
    description: '구인자 주관후기 페이지',
  },
  author_selected_reviews: {
    category: Category.REVIEW,
    sample: true,
    description: '구인자 객관후기 페이지',
  },
  search: {
    category: Category.SEARCH,
    sample: true,
    description: '검색 페이지',
  },
  search_job_post_result: {
    category: Category.SEARCH,
    sample: true,
    params: {
      keyword: Param.String.Required,
    },
    description: '검색 결과 페이지',
  },
  shared_service: {
    category: Category.SHARE,
    sample: true,
    logs: [],
    description: '서비스 공유 링크를 통한 페이지',
  },
  job_post_slot_overflow: {
    category: Category.SLOT,
    sample: true,
    logs: [],
    description: '슬롯 초과 페이지',
  },
  empty_job_posts: {
    category: Category.JOB_POST,
    sample: true,
    params: {
      is_filter_set: Param.Boolean.Required,
      region_full_name: Param.String.Required,
    },
    description: '구인글 없는 메인 피드 조회',
  },
  famous_job_posts: {
    category: Category.JOB_POST,
    sample: true,
    description: '인기알바 영역',
    params: {
      advertisement_index: Param.Number.Required,
    },
  },
  job_post_detail: {
    category: Category.JOB_POST,
    sample: true,
    params: {
      badges: Param.String.Optional,
      bring_up_count: Param.Number.Required,
      region3_id: Param.Number.Optional,
      selected_review_count: Param.String.Required,
      comment_review_count: Param.String.Required,
      is_personal: Param.Boolean.Required,
      is_walking_distance: Param.Boolean.Required,
      job_post_type: Param.Enum('EVENT', 'NORMAL').Required,
      last_activated_at: Param.String.Optional,
      // Braze 공통발송 삭제되면 안됨
      job_post_id: Param.String.Optional,
      job_post_title: Param.String.Nullable,
      is_short_work_period: Param.Boolean.Required,
      job_tasks: Param.JobTasks.Optional,
    },
    logs: [Log.JOBS_LOG_OPTIONAL, Log.BRAZE],
    description: '공고 상세 페이지',
  },
  job_post_detail_2_within_24: {
    category: Category.JOB_POST,
    description: '(Appsflyer용) 24시간 내 구인글 조회 2회',
  },
  job_post_detail_3_within_24: {
    category: Category.JOB_POST,
    description: '(Appsflyer용) 24시간 내 구인글 조회 3회',
  },
  copy_job_post: {
    category: Category.JOB_POST,
    sample: true,
    logs: [],
    description: '불러오기 페이지',
  },
  job_posts_by_author: {
    category: Category.JOB_POST,
    sample: true,
    description: '구인자 글 모아보기 페이지',
  },
  checkin_region_at_creation: {
    category: Category.JOB_POST_CREATE,
    sample: true,
    logs: [],
    params: {
      uid: Param.String.Required,
      title: Param.String.Required,
    },
    description: '공고 작성 중 동네인증 요청',
  },
  migrate_job_post: {
    category: Category.JOB_POST_CREATE,
    sample: true,
    params: {
      is_today: Param.Boolean.Required,
      is_daily: Param.Boolean.Required,
    },
    description: '공고 이전 페이지',
    logs: [Log.MIXPANEL],
  },
  create_job_post: {
    category: Category.JOB_POST_CREATE,
    sample: true,
    params: {
      is_continue: Param.Boolean.Required,
      is_writing: Param.Boolean.Required,
      written_experience: Param.Boolean.Required,
    },
    description: '공고 작성 페이지',
    logs: [Log.MIXPANEL, Log.BRAZE],
  },
  job_post_create_purchase: {
    category: Category.JOB_POST_CREATE,
    sample: true,
    params: {
      job_post_id: Param.String.Required,
      passed_minutes_from_created_at: Param.Number.Required,
      job_tasks: Param.Array([Param.String.Required]).Required,
      job_post_count: Param.Number.Required,
      advertised_job_post_count: Param.Number.Required,
      workplace_region_id: Param.Number.Optional,
    },
    description: '공고 작성 후 등록비 결제 페이지',
    logs: [Log.MIXPANEL, Log.AMPLITUDE],
  },
  create_job_post_nudge_banner: {
    category: Category.JOB_POST_CREATE,
    description: '공고 작성 유도 배너',
    params: {
      statistics_count: Param.Number.Optional,
    },
    logs: [],
  },
  search_address: {
    category: Category.JOB_POST_CREATE,
    sample: true,
    description: '주소검색 페이지',
  },
  update_extend_job_post: {
    category: Category.JOB_POST_UPDATE,
    sample: true,
    params: {
      job_post_id: Param.String.Required,
    },
    description: '공고 추가정보 입력 페이지',
    logs: [Log.AMPLITUDE],
  },
  manage_job_post: {
    category: Category.JOB_POST_MANAGE,
    sample: true,
    params: {
      job_post_id: Param.String.Required,
      is_personal: Param.Boolean.Required,
      minutes_from_published_at: Param.Number.Required,
      images_count: Param.Number.Required,
      view_count: Param.Number.Optional,
      application_count: Param.Number.Optional,
      jobPostCount: Param.Number.Optional,
    },
    logs: [],
    description: '작성한 공고 페이지',
  },
  preview_job_post: {
    category: Category.JOB_POST_MANAGE,
    sample: true,
    params: {
      job_post_id: Param.String.Required,
    },
    description: '공고 미리보기 페이지 (Control 기능 제거)',
  },
  abuser_dialog: {
    category: Category.JOB_POST_MANAGE,
    sample: true,
    logs: [],
    params: {
      job_post_id: Param.String.Required,
      open: Param.Boolean.Required,
    },
    description: '어뷰저 경고 모달',
  },
  bring_up_guide: {
    category: Category.JOB_POST_MANAGE,
    sample: true,
    logs: [],
    params: {
      job_post_id: Param.String.Required,
      bring_up_count: Param.Number.Required,
      action: Param.Enum('BRING_UP', 'ADVERTISEMENT').Required,
    },
    description: '끌올 가이드 하프뷰',
  },
  recruit_main: {
    category: Category.JOB_POST_MANAGE,
    sample: true,
    params: {
      has_not_job_post: Param.Boolean.Required,
      has_live_job_post: Param.Boolean.Required,
    },
    description: '비즈 메인 페이지',
  },
  bring_up_job_post: {
    category: Category.JOB_POST_MANAGE,
    sample: true,
    params: {
      job_post_id: Param.String.Required,
    },
    logs: [],
    description: '끌올하기 페이지',
  },
  update_job_post: {
    category: Category.JOB_POST_MANAGE,
    sample: true,
    description: '공고 수정 페이지',
  },
  nearby_onboarding: {
    category: Category.ETC,
    sample: true,
    description: '내 근처 온보딩 페이지',
  },
  floating_alarm_registration_nudge: {
    category: Category.ETC,
    sample: true,
    description: '알림 등록 유도 UI (임시)',
  },
  deprecated_page: {
    category: Category.ETC,
    logs: [],
    params: {
      page_code: Param.String.Required,
    },
    description: '삭제된 페이지 접근 트래킹',
  },
  campaign_page: {
    category: Category.EVENT,
    description: '내/외부 캠페인 페이지 내 View 트래킹',
    params: {
      page_view: Param.Enum('alba_campaign_author_202307', 'alba_campaign_applicant_202307').Required,
    },
  },
  create_confirm_for_campaign: {
    category: Category.EVENT,
    description: '캠페인 공고 작성 완료 페이지',
    params: {
      event_kind: Param.String.Required,
    },
  },
  show_all_posts: {
    category: Category.JOB_POST,
    description: '우리동네 알바 더보기 버튼 임프레션',
  },
  payment_product_select: {
    category: Category.BM,
    description: '상품 선택 페이지',
    params: {
      job_post_count: Param.Number.Required,
      is_author: Param.Boolean.Required,
      is_short_work_period: Param.Boolean.Required,
      job_tasks: Param.Array([Param.String.Required]).Required,
      company_name: Param.String.Optional,
      treatment_group: Param.String.Optional,
      viewed_hour: Param.Number.Optional,
    },
    logs: [Log.AMPLITUDE, Log.MIXPANEL],
  },
  free_writing_confirm_halfview: {
    category: Category.BM,
    description: '무료 공고 등록 확인 하프뷰',
    logs: [Log.AMPLITUDE, Log.MIXPANEL],
  },
  new_semester_success_halfview: {
    category: Category.EVENT,
    description: '신학기 성공 하프뷰',
    logs: [Log.MIXPANEL],
  },
  author_fast_response_nudge_section: {
    category: Category.ETC,
    description: '구인자 빠른응답 동기부여 섹션',
    params: {
      engagement_score: Param.Number.Nullable,
    },
    logs: [Log.MIXPANEL],
  },
  author_fast_response_nudge_halfview: {
    category: Category.ETC,
    description: '구인자 빠른응답 동기부여 하프뷰',
    params: {
      type: Param.Enum('success', 'explain').Required,
      engagement_score: Param.Number.Nullable,
    },
    logs: [Log.MIXPANEL],
  },
  author_fast_response_badge_card: {
    category: Category.ETC,
    description: '구인자 빠른응답 배지 공고카드',
    params: {
      engagement_score: Param.Number.Nullable,
    },
    logs: [Log.MIXPANEL],
  },
  author_fast_response_badge_top_premium: {
    category: Category.ETC,
    description: '구인자 빠른응답 배지 공고 상단광고',
    params: {
      engagement_score: Param.Number.Nullable,
    },
    logs: [Log.MIXPANEL],
  },
  author_fast_response_badge_applications: {
    category: Category.ETC,
    description: '구인자 빠른응답 배지 지원자목록',
    params: {
      engagement_score: Param.Number.Nullable,
    },
    logs: [Log.MIXPANEL],
  },
} as const

export const _showEventsForOnlyTypeCheck: AnalyticsEvents = analyticsShowEvents

export type AnalyticsShowEventKey = keyof typeof analyticsShowEvents

export type InferShowEventParams<T extends AnalyticsShowEventKey> = (typeof analyticsShowEvents)[T] extends {
  params: any
}
  ? { params: PartialOnUndefinedDeep<(typeof analyticsShowEvents)[T]['params']> }
  : Empty
