import { KotisaariContextProvider } from '@daangn/export-kotisaari-ui'
import { SnackbarProvider } from '@daangn/sprout-components-snackbar'
import {
  Dialog as TransferIdentificationDialog,
  TransferIdentificationProvider,
} from '@daangn/transfer-identification-sdk'
import React from 'react'
import { useDispatch } from 'react-redux'
import { RelayEnvironmentProvider } from 'react-relay'
import { timer } from 'rxjs'
import timekeeper from 'timekeeper'
import useDarkMode from 'use-dark-mode'

import storage from '@src/api/storage'
import { useAsyncError } from '@src/hooks/useAsyncError'
import { bridge, theme } from '@src/sdks/bridge'
import { RemoveSplash } from '@src/Splash'

import { discovery, setStorageData } from './api/api'
import GlobalLoader from './components/base/loader/GlobalLoader'
import { IS_ALPHA, IS_LOG_ENABLED, IS_NATIVE, IS_PROD, TEST_DATE } from './config'
import { EventContextProvider } from './contexts/EventContext'
import { initApplication } from './ducks/common'
import { useEnhancedInfo } from './hooks/useEnhancedInfo'
import { useJobsRelayEnvironment } from './hooks/useJobsRelayEnvironment'
import { initAmplitude } from './sdks/amplitude'
import { installGtag } from './sdks/googleAnalytics'
import { initJobsLog } from './sdks/jobs'
import { initMixpanel } from './sdks/mixpanel'
import { captureException, captureMessage, configSentry } from './sdks/sentry'
import { initVConsole } from './sdks/vConsole'
import { Stack } from './stackflow/stackflow'
import { appInfoStorage } from './utils/appInfo'
import { isIOS } from './utils/userAgent'

const App: React.FCC = () => {
  const dispatch = useDispatch()
  const { getInfo } = useEnhancedInfo()
  const [isInit, setIsInit] = React.useState(false)
  const [environmentConfig, setEnvironmentConfig] =
    React.useState<Nullable<{ regionId?: number; userId?: string }>>(null)
  const { environment } = useJobsRelayEnvironment(environmentConfig)
  const throwAsyncError = useAsyncError()

  const { value: isDarkMode } = useDarkMode(
    undefined,
    IS_PROD
      ? {
          storageKey: null as any,
        }
      : {}
  )

  React.useEffect(() => {
    document.documentElement.dataset.seed = ''
    document.documentElement.dataset.seedScaleLetterSpacing = isIOS() ? 'ios' : 'android'
    document.documentElement.dataset.seedScaleColor = isDarkMode ? 'dark' : 'light'
  }, [isDarkMode])

  React.useEffect(() => {
    const timeout = timer(5000).subscribe(() => {
      captureMessage('App.tsx 브릿지 정보 가져오는데 시간이 너무 오래 걸렸어요.')
    })

    getInfo()
      .then(({ app, region, user, currentPosition }) => {
        if (!app) {
          const err = new Error(`브릿지 정보가 없어요.`)
          captureException(err, { extra: { app, region, user, currentPosition } })
          throw new Error(`브릿지 정보가 없어요.`)
        }

        const isUserExist = !!user?.id
        const regionId = Number((region as any)?.name3Id) || Number(region?.id)

        // set storages
        appInfoStorage.setAppInfo({ userAgent: app.userAgent })
        setStorageData(user?.authToken, app.userAgent)
        storage.setIsAdmin(user?.id)

        // Sentry setup
        configSentry({ userId: user?.id, nativeVersion: appInfoStorage.getAppInfo().appVersion })

        // preload data setup
        setEnvironmentConfig({ regionId, userId: user?.id })

        // Redux data setup
        dispatch(initApplication({ app, user, region, currentPosition }))

        if (IS_LOG_ENABLED) {
          installGtag({
            gaOptions: {
              clientId: app.deviceIdentity,
            },
          })

          isUserExist && initAmplitude(user.id)
          isUserExist && initMixpanel(user.id)
        }
        // JobsLog init
        IS_NATIVE && isUserExist && initJobsLog({ userId: user?.id })

        // Discovery visited (임시로 알파에만 적용되도록 함, 프로덕션 적용시 위 Logger setup으로 이동)
        IS_NATIVE && isUserExist && discovery.visited()

        // Admin VConsole setup
        ;(IS_ALPHA || storage.getIsAdmin()) && initVConsole()

        // Timekeeper setup for testing
        TEST_DATE && timekeeper.travel(new Date(TEST_DATE))

        setIsInit(true)
      })
      .catch((err) => {
        timeout.unsubscribe()
        console.error({ err })
        throwAsyncError(err)
      })
      .finally(() => {
        timeout.unsubscribe()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <GlobalLoader />

      {isInit
        ? environment && (
            <EventContextProvider>
              <SnackbarProvider offset={theme.environment === 'Cupertino' ? '80px' : '54px'}>
                <RelayEnvironmentProvider environment={environment}>
                  <TransferIdentificationProvider previous_screen_name="job">
                    <KotisaariContextProvider bridge={bridge} authToken={storage.getToken()} isProduction={IS_PROD}>
                      <Stack />
                      <TransferIdentificationDialog />
                      <RemoveSplash />
                    </KotisaariContextProvider>
                  </TransferIdentificationProvider>
                </RelayEnvironmentProvider>
              </SnackbarProvider>
            </EventContextProvider>
          )
        : null}
    </>
  )
}

export default App
