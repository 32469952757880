/**
 * @generated SignedSource<<8e4e08b1a45051250f8e1d4253a54f9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventGroupType = "BUDDHA_BIRTHDAY" | "CHILDREN_DAY" | "CHRISTMAS" | "Chuseok" | "DAANGN_EVENT" | "LunarNewYear" | "MEMORIAL_DAY" | "PEPERO_DAY" | "WHITE_DAY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Author_jobPost$data = {
  readonly _id: string;
  readonly author: {
    readonly bizAccount?: {
      readonly _id: number;
      readonly region: {
        readonly name3: string | null | undefined;
      } | null | undefined;
    };
    readonly hoianUser?: {
      readonly _id: number;
      readonly author: {
        readonly comment: string | null | undefined;
      } | null | undefined;
      readonly displayRegionCheckinsCount: number;
      readonly image: {
        readonly thumbnail: string;
      } | null | undefined;
      readonly isOfficial: boolean | null | undefined;
      readonly lastActivatedAt: Dateable | null | undefined;
      readonly nickname: string | null | undefined;
      readonly region: {
        readonly name3: string | null | undefined;
      } | null | undefined;
      readonly temperature: number;
    };
  };
  readonly companyName: string | null | undefined;
  readonly eventGroup: {
    readonly type: EventGroupType;
  } | null | undefined;
  readonly " $fragmentType": "Author_jobPost";
};
export type Author_jobPost$key = {
  readonly " $data"?: Author_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"Author_jobPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Region",
  "kind": "LinkedField",
  "name": "region",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name3",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Author_jobPost",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BizAccount",
              "kind": "LinkedField",
              "name": "bizAccount",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "BizAccountAuthor",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "hoianUser",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nickname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "image",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "thumbnail",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "temperature",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayRegionCheckinsCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isOfficial",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Author",
                  "kind": "LinkedField",
                  "name": "author",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "comment",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastActivatedAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventGroup",
      "kind": "LinkedField",
      "name": "eventGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};
})();

(node as any).hash = "dae6228040480ec7963af188c0939712";

export default node;
