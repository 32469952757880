/**
 * @generated SignedSource<<c16816d9efb65c3cece7dad42c877c2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CountStatus_jobPost$data = {
  readonly viewCount: number;
  readonly watchCount: number;
  readonly " $fragmentType": "CountStatus_jobPost";
};
export type CountStatus_jobPost$key = {
  readonly " $data"?: CountStatus_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"CountStatus_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CountStatus_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "watchCount",
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "ae45bbb046bf6429bff64951a736114d";

export default node;
