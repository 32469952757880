import React from 'react'

type Props = {
  width?: number
  height?: number
  color?: string
} & JSX.IntrinsicElements['svg']

const TownSettingWalkFill: React.FCC<Props> = ({
  width = 20,
  height = 20,
  color = 'var(--colors-green500)',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      {...props}>
      <path
        d="M9.07601 4.23332C9.07601 3.17477 9.93413 2.31665 10.9927 2.31665C12.0512 2.31665 12.9093 3.17477 12.9093 4.23332C12.9093 5.29186 12.0512 6.14998 10.9927 6.14998C9.93413 6.14998 9.07601 5.29186 9.07601 4.23332Z"
        fill="currentColor"
      />
      <path
        d="M8.30585 6.15832C8.40863 6.08124 8.53905 6.05083 8.66532 6.0745L12.477 6.7892C12.6608 6.82367 12.8053 6.96638 12.842 7.14979L13.2745 9.31229L14.2893 9.81971C14.8991 10.1246 15.1317 10.8764 14.8006 11.4724C14.4918 12.0282 13.8025 12.2457 13.2306 11.968L12.1876 11.4613L11.5227 11.1289L11.4482 11.6125L12.7406 13.1203C12.8118 13.2034 12.8509 13.3092 12.8509 13.4186V16.5156C12.8509 17.1634 12.3258 17.6886 11.6779 17.6886C11.0301 17.6886 10.5049 17.1634 10.5049 16.5156V14.0617L8.27854 11.4154L8.277 11.4135C8.25952 11.3925 8.24388 11.3698 8.23033 11.3458C8.19464 11.2826 8.17537 11.2132 8.172 11.1434V9.20478L7.6098 9.61627V10.3367C7.6098 10.9845 7.08462 11.5097 6.43678 11.5097C5.78893 11.5097 5.26375 10.9845 5.26375 10.3367V8.66906C5.26375 8.5248 5.33168 8.38895 5.44709 8.3024L8.30585 6.15832Z"
        fill="currentColor"
      />
      <path
        d="M9.87508 13.8376L8.33341 12.0876L7.09838 13.5829L5.30096 15.1459C4.94583 15.4547 4.8114 15.9469 4.96022 16.3933C5.21965 17.1716 6.18087 17.4451 6.81112 16.9199L8.87459 15.2003C8.91152 15.1695 8.94337 15.1332 8.96899 15.0925L9.87508 13.8376Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default TownSettingWalkFill
