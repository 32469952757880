import React from 'react'

import GlobalLoaderOpen from '@src/components/base/loader/GlobalLoaderOpen'

export default <Props,>(Comp: React.FCC<Props>, FallbackComponent?: React.FCC<unknown> | null) => {
  const WrappedComponent: React.FCC<Props> = (props) => {
    const [isInitialized, setInitialized] = React.useState(false)

    React.useEffect(() => {
      // Set initialized to true after the component is mounted.
      setInitialized(true)
    }, [])

    if (!isInitialized) {
      // Render nothing or a basic placeholder on the initial render.
      return <>{FallbackComponent}</>
    }

    return (
      <React.Suspense fallback={FallbackComponent ? <FallbackComponent /> : <GlobalLoaderOpen />}>
        <Comp {...props} />
      </React.Suspense>
    )
  }

  return WrappedComponent
}
