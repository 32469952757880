import { useMutation, graphql } from 'react-relay'

import { useWatchMutationCreateWatchMutation } from '@src/__generated__/useWatchMutationCreateWatchMutation.graphql'
import { useWatchMutationDeleteMutation } from '@src/__generated__/useWatchMutationDeleteMutation.graphql'
import { handleRelayError } from '@src/utils/error'

const WATCH_OPTIMISTIC_GLOBAL_ID = 'Watch:temp'
const WATCH_OPTIMISTIC_ID = 'temp'

export function useWatchMutation() {
  const [createWatchMutate, isCreateWatchMutateLoading] = useMutation<useWatchMutationCreateWatchMutation>(graphql`
    mutation useWatchMutationCreateWatchMutation($data: CreateWatchDataInput!) {
      createWatch(data: $data) {
        watchEdge {
          node {
            _id
            deleted
            kind
            target {
              ... on JobPost {
                _id
                deleted
                myWatch {
                  id
                  _id
                }
              }
            }
          }
        }
      }
    }
  `)

  const [deleteWatchMutate, isDeleteWatchMutateLoading] = useMutation<useWatchMutationDeleteMutation>(graphql`
    mutation useWatchMutationDeleteMutation($where: DeleteWatchWhereInput!) {
      deleteWatch(where: $where) {
        watch {
          id
          deleted
          target {
            ... on JobPost {
              myWatch {
                id
                _id
              }
            }
          }
        }
      }
    }
  `)

  const isLoading = isCreateWatchMutateLoading || isDeleteWatchMutateLoading

  const createWatch = ({
    jobPostDBId,
    jobPostGlobalId,
    userNodeId,
    onCompleted,
    useSyncStore = true,
  }: {
    jobPostDBId: string
    jobPostGlobalId: string
    userNodeId: string
    onCompleted?: () => void
    useSyncStore?: boolean
  }) => {
    createWatchMutate({
      variables: { data: { kind: 'JOB_POST', target: jobPostDBId } },
      configs: useSyncStore
        ? [
            {
              type: 'RANGE_ADD',
              parentID: userNodeId,
              connectionInfo: [
                {
                  key: 'MePostsFavoritesList_watches',
                  rangeBehavior: 'prepend',
                  filters: { where: { kind: 'JOB_POST' } },
                },
              ],
              edgeName: 'watchEdge',
            },
          ]
        : [],
      optimisticUpdater: (store) => {
        const jobPost = store.get(jobPostGlobalId)
        const myWatch = store.create(WATCH_OPTIMISTIC_GLOBAL_ID, 'Watch')
        myWatch.setValue(WATCH_OPTIMISTIC_ID, '_id')
        myWatch.setValue(WATCH_OPTIMISTIC_GLOBAL_ID, 'id')
        jobPost?.setLinkedRecord(myWatch, 'myWatch')
      },
      onCompleted,
      onError: (err) => {
        handleRelayError(err)
      },
    })
  }

  const deleteWatch = ({
    watchDBId,
    watchGlobalId,
    userNodeId,
    useSyncStore = true,
  }: {
    watchDBId: string
    watchGlobalId: string
    userNodeId: string
    useSyncStore?: boolean
  }) => {
    if (watchDBId === WATCH_OPTIMISTIC_ID) {
      return
    }

    deleteWatchMutate({
      variables: {
        where: { _id: watchDBId },
      },
      configs: useSyncStore
        ? [
            {
              type: 'RANGE_DELETE',
              parentID: userNodeId,
              connectionKeys: [
                {
                  key: 'MePostsFavoritesList_watches',
                },
              ],
              pathToConnection: ['me', 'watches'],
              deletedIDFieldName: ['watch'],
            },
          ]
        : [],
      optimisticUpdater: (store) => {
        const watch = store.get(watchGlobalId)
        watch?.setValue(true, 'deleted')
      },
      onError: (err) => {
        handleRelayError(err)
      },
    })
  }

  return { isLoading, createWatch, deleteWatch }
}
