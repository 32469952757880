/**
 * @generated SignedSource<<703d44f13c052f2d5bb8289b2ec864dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { stringifiedSalary as jobPostStringifiedSalaryResolverType } from "../graphql/resolvers/JobPostStringifiedSalaryResolver";
export type JobPostCardRecommend_jobPost$data = {
  readonly author: {
    readonly badge?: {
      readonly items: ReadonlyArray<{
        readonly __typename: "FastResponseBadge";
        readonly name: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      }>;
    } | null | undefined;
    readonly hoianUser?: {
      readonly receivedHasCommentJobReviewsCount: number;
    };
  };
  readonly closed: boolean;
  readonly companyName: string | null | undefined;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly thumbnail: string;
  }> | null | undefined;
  readonly stringifiedSalary: string | null | undefined;
  readonly title: string | null | undefined;
  readonly workplaceLocation: {
    readonly lat: number;
    readonly lng: number;
  } | null | undefined;
  readonly workplaceRegion: {
    readonly name3: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "JobPostCardRecommend_jobPost";
};
export type JobPostCardRecommend_jobPost$key = {
  readonly " $data"?: JobPostCardRecommend_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostCardRecommend_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostCardRecommend_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "workplaceRegion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name3",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JobPostStringifiedSalaryResolver"
      },
      "kind": "RelayResolver",
      "name": "stringifiedSalary",
      "resolverModule": require('./../graphql/resolvers/JobPostStringifiedSalaryResolver').stringifiedSalary,
      "path": "stringifiedSalary"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Point",
      "kind": "LinkedField",
      "name": "workplaceLocation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lng",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AuthorBadge",
              "kind": "LinkedField",
              "name": "badge",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "items",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "type": "FastResponseBadge",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "hoianUser",
              "plural": false,
              "selections": [
                {
                  "alias": "receivedHasCommentJobReviewsCount",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "filter",
                      "value": {
                        "evaluator": "APPLICANT",
                        "hasComment": true
                      }
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "receivedJobReviewsCount",
                  "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"hasComment\":true})"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "d6b6b5def64dd81d88ff28049c701260";

export default node;
