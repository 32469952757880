import React from 'react'

import { styled } from '@src/stitches/stitches.config'

type Props = Pick<React.ComponentProps<typeof Container>, 'type' | 'size' | 'bold'> & {
  Icon?: React.ForwardRefExoticComponent<
    Omit<
      React.SVGProps<SVGSVGElement> & {
        size?: string | number | undefined
      },
      'ref'
    > &
      React.RefAttributes<SVGSVGElement>
  >
} & { text: string }

const Badge: React.FCC<Props> = ({ Icon, text, ...props }) => {
  return (
    <Container {...props}>
      {Icon ? (
        <BadgeIconWrapper type={props.type}>
          <Icon width={12} height={12} />
        </BadgeIconWrapper>
      ) : null}
      {text}
    </Container>
  )
}

export default Badge

const Container = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  padding: '3px 6px',
  background: '$gray100',
  borderRadius: '4px',
  color: '$gray700',

  '&:first-child': {
    margin: 0,
  },

  variants: {
    type: {
      normal: {
        background: '$gray100',
        color: '$gray700',
      },
      success: {
        background: '$successLow-semantic',
        color: '$green700',
      },
      primary: {
        background: '$carrotAlpha100',
        color: '$carrot500',
      },
      accent: {
        background: '$blue50',
        color: '$blue600',
      },
    },
    size: {
      small: {
        $text: 'label5Regular',
      },
      medium: {
        $text: 'caption1Regular',
      },
    },
    bold: {
      true: {
        fontWeight: 'bold',
      },
    },
  },
})

const BadgeIconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: 12,
  height: 12,
  margin: '0 2px 0 -2px',
  color: '$gray700',

  variants: {
    type: {
      normal: {
        color: '$gray700',
      },
      success: {
        color: '$green700',
      },
      primary: {
        color: '$carrot500',
      },
      accent: {
        color: '$blue600',
      },
    },
  },
  defaultVariants: {
    type: 'normal',
  },
})
