import React from 'react'

import Manner01 from '@src/components/icons/Manner01'
import Manner02 from '@src/components/icons/Manner02'
import Manner03 from '@src/components/icons/Manner03'
import Manner04 from '@src/components/icons/Manner04'
import Manner05 from '@src/components/icons/Manner05'
import Manner06 from '@src/components/icons/Manner06'

const MAX_TEMPERATURE = 99

export const useMannerTemperatureItems = (temperature: number) => {
  return React.useMemo(() => {
    let Icon
    let textColor: string | null = null
    let gaugeColor: string | null = null
    const ratio = Math.round((temperature / MAX_TEMPERATURE) * 100 * 100) / 100

    switch (true) {
      case temperature >= 52:
        Icon = Manner06
        textColor = gaugeColor = '#FF6F0F'
        break
      case temperature >= 42:
        Icon = Manner05
        textColor = gaugeColor = '#FFB938'
        break
      case temperature >= 37.6:
        Icon = Manner04
        textColor = gaugeColor = '#66D06B'
        break
      case temperature >= 36.3:
        Icon = Manner03
        textColor = gaugeColor = '#58B9ED'
        break
      case temperature >= 30:
        Icon = Manner02
        gaugeColor = '#3C77B0'
        textColor = '#4D8FCE'
        break
      default:
        Icon = Manner01
        textColor = gaugeColor = '#777F87'
        break
    }

    return { Icon, textColor, gaugeColor, ratio }
  }, [temperature])
}
