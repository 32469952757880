import { useSnackbarAdapter } from '@daangn/sprout-components-snackbar'
import { IconCheckFill, IconNotificationFill } from '@seed-design/icon'
import { compact, isNil } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { graphql, useFragment } from 'react-relay'

import { JobPostFilterAlarmRegistration_jobPost$key } from '@src/__generated__/JobPostFilterAlarmRegistration_jobPost.graphql'
import { JobPostFilterAlarmRegistration_user$key } from '@src/__generated__/JobPostFilterAlarmRegistration_user.graphql'
import Button, { withLoading } from '@src/components/base/buttons/Button'
import { jobTasksMap } from '@src/constants/form'
import { useNavigator } from '@src/hooks/useNavigator'
import { useNewJobPostFilterSubscriptionMutation } from '@src/mutations/useNewJobPostFilterSubscriptionMutation'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { JobsActivity } from '@src/router/activityNames'
import { captureException } from '@src/sdks/sentry'
import { currentRegionSelector, otherRegionSelector } from '@src/selectors/region'
import { styled } from '@src/stitches/stitches.config'
import { JobTaskType } from '@src/types/jobPost'

interface Props {
  jobPostRef: JobPostFilterAlarmRegistration_jobPost$key
  userRef: JobPostFilterAlarmRegistration_user$key
  onSubscriptionTrigger: () => void
}

const JobPostFilterAlarmRegistration: React.FCC<Props> = ({ jobPostRef, userRef, onSubscriptionTrigger }) => {
  const user = useFragment(
    graphql`
      fragment JobPostFilterAlarmRegistration_user on User {
        newJobPostFilterSubscription {
          id
          isHolding
          deleted
        }
      }
    `,
    userRef
  )

  const jobPost = useFragment(
    graphql`
      fragment JobPostFilterAlarmRegistration_jobPost on JobPost {
        id
        _id
        workDays
        workPeriod
        jobTasks
      }
    `,
    jobPostRef
  )

  const { push } = useNavigator()
  const { logClickEvent } = useLogAnalyticsEvent()
  const snackbarAdapter = useSnackbarAdapter()
  const { mutateSubscribe, mutateUnsubscribe, isLoading } = useNewJobPostFilterSubscriptionMutation()
  const currentRegion = useSelector(currentRegionSelector)
  const otherRegion = useSelector(otherRegionSelector)

  const { id: subscriptionId, isHolding, deleted } = user?.newJobPostFilterSubscription ?? {}
  const { _id, workDays, workPeriod, jobTasks } = jobPost
  const regions = compact([currentRegion?.id, otherRegion?.id])
  const workPeriods = compact([workPeriod])
  const jobTasksKR = jobTasks?.map((jobTask) => jobTasksMap[jobTask as JobTaskType]).join(', ')
  const isNewJobPostFilterSubscription = !!(subscriptionId && !deleted && !isHolding)

  React.useEffect(() => {
    if (isNil(jobTasks) || isNil(workDays) || isNil(workPeriods)) {
      captureException(new Error('jobTasks or workDays or workPeriods is empty'), {
        extra: {
          data: { jobTasks, workDays, workPeriods },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubscribe = () => {
    if (isLoading) return

    mutateSubscribe({
      variables: {
        where: {
          regions,
        },
        data: {
          jobTasks: jobTasks ?? ['ETC'],
          workDays: workDays ?? ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
          workPeriods: workPeriods ?? ['LESS_THAN_A_MONTH'],
        },
      },
      onCompleted: () => {
        snackbarAdapter.dismiss()
        snackbarAdapter.create({
          message: '알바 알림이 등록되었어요',
          type: 'default',
          timeout: 3000,
          actionLabel: '보러가기',
          onAction: () => {
            push(JobsActivity.NEW_JOB_POST_FILTER_SUBSCRIPTION, {})
          },
        })
        logClickEvent({
          name: 'job_post_filter_subscription',
          params: {
            job_post_id: _id,
          },
        })
      },
    })

    onSubscriptionTrigger()
  }

  const handleUnsubscribe = () => {
    if (isLoading) return

    mutateUnsubscribe({
      variables: { option: {} },
      onCompleted: () => {
        snackbarAdapter.dismiss()
        snackbarAdapter.create({
          message: '등록한 알바 알림이 삭제되었어요',
          type: 'default',
          timeout: 3000,
        })
      },
    })
  }

  const handleClick = () => {
    if (isNewJobPostFilterSubscription) {
      handleUnsubscribe()
    } else {
      handleSubscribe()
    }
  }

  const Icon = isNewJobPostFilterSubscription ? StyledCheck : StyledNotification
  const text = isNewJobPostFilterSubscription ? '알림 받는 중' : '알바 알림 받기'

  return (
    <Container>
      <Description>
        {currentRegion?.name2}에서 <strong>{jobTasksKR}</strong> 알바가 올라오면 바로 알려드릴까요?
      </Description>
      <StyledButton onClick={handleClick} isLoading={isLoading}>
        <Icon />
        <Text>{text}</Text>
      </StyledButton>
    </Container>
  )
}

export default JobPostFilterAlarmRegistration

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const Description = styled('div', {
  flex: 1,
  $text: 'bodyL2Regular',
  color: '$gray900',
  wordBreak: 'keep-all',
})

const StyledButton = withLoading(
  styled(Button, {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 16px',

    defaultVariants: {
      size: 'small',
      stretch: false,
    },
  })
)

const Text = styled('div')

const StyledNotification = styled(IconNotificationFill, {
  width: '16px',
  height: '16px',
  margin: '0 4px 0 0',
})

const StyledCheck = styled(IconCheckFill, {
  width: '16px',
  height: '16px',
  margin: '0 4px 0 0',
})
