import { get, mapValues } from 'lodash/fp'
import React from 'react'

import { CustomAppScreenState } from '@src/components/base/stackflow/CustomAppScreenContext'
import { ActivityName, JobsActivity as Activity } from '@src/router/activityNames'

const AdvertisementReserveRequestDone = React.lazy(
  () => import('@src/components/pageAdvertisementReserve/AdvertisementReserveRequestDone')
)
const JobPostPageContainer = React.lazy(() => import(/* webpackPreload: true */ '@src/pages/applicant/JobPostPage'))
const JobPostBusinessCertificatePage = React.lazy(() => import('@src/pages/applicant/JobPostBusinessCertificatePage'))
const AuthorJobPostsPage = React.lazy(() => import('@src/pages/applicant/AuthorJobPostsPage'))
const AuthorReviewsCommentPage = React.lazy(() => import('@src/pages/applicant/AuthorReviewsCommentPage'))
const CollaborationRecruitmentPageContainer = React.lazy(
  () => import('@src/pages/applicant/CollaborationRecruitmentPage')
)
const ConfirmHiredPage = React.lazy(() => import('@src/pages/applicant/ConfirmHiredPage'))
const ExperienceAddPage = React.lazy(() => import('@src/pages/applicant/ExperienceAddPage'))
const ExperienceUpdatePage = React.lazy(() => import('@src/pages/applicant/ExperienceUpdatePage'))
const JobPostApplyConfirmPage = React.lazy(() => import('@src/pages/applicant/JobPostApplyConfirmPage'))
const JobPostApplyPage = React.lazy(() => import('@src/pages/applicant/JobPostApplyPage'))
const JobPostExternalPage = React.lazy(() => import('@src/pages/applicant/JobPostExternalPage'))
const MePageContainer = React.lazy(() => import(/* webpackPrefetch: true */ '@src/pages/applicant/MePage'))
const NewJobPostFilterSubscriptionPage = React.lazy(
  () => import('@src/pages/applicant/NewJobPostFilterSubscriptionPage')
)
const ResumeCreateAndApplyPage = React.lazy(() => import('@src/pages/applicant/ResumeCreateAndApplyPage'))
const ResumeExperienceAddPage = React.lazy(() => import('@src/pages/applicant/ResumeExperienceAddPage'))
const ResumeManagePage = React.lazy(() => import('@src/pages/applicant/ResumeManagePage'))
const ResumePreviewPage = React.lazy(() => import('@src/pages/applicant/ResumePreviewPage'))
const ResumeUpdatePage = React.lazy(() => import('@src/pages/applicant/ResumeUpdatePage'))
const ReviewListToWritePage = React.lazy(() => import('@src/pages/applicant/ReviewListToWritePage'))
const ReviewsSelectedPage = React.lazy(() => import('@src/pages/applicant/ReviewsSelectedPage'))
const AdvertisementAccountCreatePage = React.lazy(() => import('@src/pages/common/AdvertisementAccountCreatePage'))
const CampaignPage = React.lazy(() => import('@src/pages/common/CampaignPage'))
const FeedEventGroupPage = React.lazy(() => import('@src/pages/common/FeedEventGroupPage'))
const GwangsanguCitizenSupportPage = React.lazy(() => import('@src/pages/common/GwangsanguCitizenSupportPage'))
const IntroduceJobsPage = React.lazy(() => import('@src/pages/common/IntroduceJobsPage'))
const InviteEventInvitedPage = React.lazy(() => import('@src/pages/common/InviteEventInvitedPage'))
const InviteEventSharePage = React.lazy(() => import('@src/pages/common/InviteEventSharePage'))
const InviteEventPrizeCheckPage = React.lazy(() => import('@src/pages/common/InviteEventPrizeCheckPage'))
const MainExternalPage = React.lazy(() => import('@src/pages/common/MainExternalPage'))
const MainPage = React.lazy(() => import(/* webpackPreload: true */ '@src/pages/common/MainPage'))
const NearbyOnboardingPage = React.lazy(() => import('@src/pages/common/NearbyOnboardingPage'))
const RegionInfoPage = React.lazy(() => import('@src/pages/common/RegionInfoPage'))
const RestrictionGuidePage = React.lazy(() => import('@src/pages/common/RestrictionGuidePage'))
const ReviewReceivedPage = React.lazy(() => import('@src/pages/common/ReviewReceivedPage'))
const ReviewsReceivedGreatPage = React.lazy(() => import('@src/pages/common/ReviewsReceivedGreatPage'))
const ReviewWritePage = React.lazy(() => import('@src/pages/common/ReviewWritePage'))
const ReviewWrittenPage = React.lazy(() => import('@src/pages/common/ReviewWrittenPage'))
const SearchPage = React.lazy(() => import('@src/pages/common/SearchPage'))
const SearchResultPage = React.lazy(() => import('@src/pages/common/SearchResultPage'))
const SharedServicePage = React.lazy(() => import('@src/pages/common/SharedServicePage'))
const TermsKarrotPrivacyPage = React.lazy(() => import('@src/pages/common/TermsKarrotPrivacyPage'))
const AdvertisementCreatePage = React.lazy(() => import('@src/pages/employer/AdvertisementCreatePage'))
const JobPostAdvertisementReservePage = React.lazy(() => import('@src/pages/employer/AdvertisementReservePage'))
const JobPostAdvertisementUpdatePage = React.lazy(() => import('@src/pages/employer/AdvertisementUpdatePage'))

const ApplicationDetailPage = React.lazy(() => import('@src/pages/employer/ApplicationDetailPage'))
const ApplicationRejectPage = React.lazy(() => import('@src/pages/employer/ApplicationRejectPage'))
const ApplicationsRejectPage = React.lazy(() => import('@src/pages/employer/ApplicationsRejectPage'))
const ApplicationsPageContainer = React.lazy(() => import('@src/pages/employer/ApplicationsPage'))
const ApplicationsSelectConfirmPage = React.lazy(() => import('@src/pages/employer/ApplicationsSelectConfirmPage'))
const ApplicationsSelectPage = React.lazy(() => import('@src/pages/employer/ApplicationsSelectPage'))
const ApplicationViewPage = React.lazy(() => import('@src/pages/employer/ApplicationViewPage'))
const ApplicationOpenRightPurchasePage = React.lazy(
  () => import('@src/pages/employer/ApplicationOpenRightPurchasePage')
)

const BizBusinessCertificateAskPage = React.lazy(() => import('@src/pages/employer/BizBusinessCertificateAskPage'))
const BizBusinessCertificateExternalPage = React.lazy(
  () => import('@src/pages/employer/BizBusinessCertificateExternalPage')
)
const BizBusinessCertificatePage = React.lazy(() => import('@src/pages/employer/BizBusinessCertificatePage'))
const BizBusinessCertificateAddCompanyNamePage = React.lazy(
  () => import('@src/pages/employer/BizBusinessCertificateAddCompanyNamePage')
)
const BizIdentificationCertificatePage = React.lazy(
  () => import('@src/pages/employer/BizIdentificationCertificatePage')
)
const BizMainPageContainer = React.lazy(() => import(/* webpackPrefetch: true */ '@src/pages/employer/BizMainPage'))
const DeprecatedJobPostAdvertisementCreatePage = React.lazy(
  () => import('@src/pages/employer/DeprecatedJobPostAdvertisementCreatePage')
)
const DeprecatedJobPostAdvertisementPageContainer = React.lazy(
  () => import('@src/pages/employer/DeprecatedJobPostAdvertisementPage')
)
const JobPostAdvertisementTypeDetailPage = React.lazy(
  () => import('@src/pages/employer/JobPostAdvertisementTypeDetailPage')
)
const JobPostBringUpPage = React.lazy(() => import('@src/pages/employer/JobPostBringUpPage'))
const JobPostCopyPage = React.lazy(() => import('@src/pages/employer/JobPostCopyPage'))
const JobPostCreateConfirmCampaignPage = React.lazy(
  () => import('@src/pages/employer/JobPostCreateConfirmCampaignPage')
)
const JobPostCreatePage = React.lazy(() => import('@src/pages/employer/JobPostCreatePage'))
const JobPostManagePageContainer = React.lazy(() => import('@src/pages/employer/JobPostManagePage'))
const JobPostMigratePage = React.lazy(() => import('@src/pages/employer/JobPostMigratePage'))
const JobPostPreviewPage = React.lazy(() => import('@src/pages/employer/JobPostPreviewPage'))
const JobPostSearchAddressPage = React.lazy(() => import('@src/pages/employer/JobPostSearchAddressPage'))
const JobPostSlotOverflowPage = React.lazy(() => import('@src/pages/employer/JobPostSlotOverflowPage'))
const JobPostStatusChangePage = React.lazy(() => import('@src/pages/employer/JobPostStatusChangePage'))
const JobPostUpdateExtendPage = React.lazy(() => import('@src/pages/employer/JobPostUpdateExtendPage'))
const JobPostUpdatePage = React.lazy(() => import('@src/pages/employer/JobPostUpdatePage'))
const LargeScaleBusinessGuidePage = React.lazy(() => import('@src/pages/employer/LargeScaleBusinessGuidePage'))
const ReviewSelectedByAuthorPage = React.lazy(() => import('@src/pages/employer/ReviewSelectedByAuthorPage'))
const BizCertificateManagePage = React.lazy(() => import('@src/pages/employer/BizCertificateManagePage'))
const ErrorTestPage = React.lazy(() => import('@src/pages/service/ErrorTestPage'))
const EventTestPage = React.lazy(() => import('@src/pages/service/EventTestPage'))
const NeedLoginPage = React.lazy(() => import('@src/pages/service/NeedLoginPage'))
const NotFoundPage = React.lazy(() => import('@src/pages/service/NotFoundPage'))
const JobPostCreateConfirmRegisterFeePage = React.lazy(
  () => import('@src/pages/employer/JobPostCreateConfirmRegisterFeePage')
)
const JobPostCreatePurchasePage = React.lazy(() => import('@src/pages/employer/JobPostCreatePurchasePage'))
const BizAuthorUpdatePage = React.lazy(() => import('@src/pages/employer/BizAuthorUpdatePage'))
const BizAuthorIntroductionExamplePage = React.lazy(
  () => import('@src/pages/employer/BizAuthorIntroductionExamplePage')
)
const BizAuthorBadgePage = React.lazy(() => import('@src/pages/employer/BizAuthorBadgePage'))
const BizJobPostsPage = React.lazy(() => import('@src/pages/employer/BizJobPostsPage'))
const BizAuthorReviewsPage = React.lazy(() => import('@src/pages/employer/BizAuthorReviewsPage'))

import Redirect from './Redirect'
import { MAIN_PATH, SLOT_OVERFLOW_PATH } from './routes'

const CurationFeedPage = React.lazy(() => import('@src/pages/common/CurationFeedPage'))
const CurationFeedExternalPage = React.lazy(() => import('@src/pages/common/CurationFeedExternalPage'))

export type RouteInfo = {
  path: string
  component: React.FCC | (() => JSX.Element)
  fallbackComponent?: React.FCC | (() => JSX.Element)
  withReplaceToMain?: boolean
  withoutAuth?: boolean
  withoutAppScreen?: boolean
  appScreenOptions?: CustomAppScreenState
}

export const routes: Record<ActivityName, RouteInfo> = {
  // ------------------------------- Applicant -----------------------------------
  // 알바공고 상세
  [Activity.JOB_POST]: {
    path: `/job-post/:jobPostId`,
    component: JobPostPageContainer,
  },
  // 알바공고 광고 유입 상세
  [Activity.JOB_POST_AD]: {
    path: `/job-post/:jobPostId/ad`,
    component: JobPostPageContainer,
  },
  // 알바공고 상세(external)
  [Activity.JOB_POST_EXTERNAL]: {
    path: `/job-post/:jobPostId/external/:referrer`,
    component: JobPostExternalPage,
  },
  // 지원 하기
  [Activity.JOB_POST_APPLY]: {
    path: `/job-post/:jobPostId/apply`,
    component: JobPostApplyPage,
    withoutAppScreen: true,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 지원 하기
  [Activity.JOB_POST_APPLY_CONFIRM]: {
    path: `/job-post/:jobPostId/apply-confirm`,
    component: JobPostApplyConfirmPage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 구인자 사업자 정보
  [Activity.JOB_POST_BUSINESS_CERTIFICATE]: {
    path: '/job-post/:jobPostId/business-certificate',
    component: JobPostBusinessCertificatePage,
  },
  // 작성자별 알바공고 보기
  [Activity.AUTHOR_JOB_POSTS]: {
    path: `/author/:authorId/job-posts`,
    component: AuthorJobPostsPage,
  },
  // 구인자가 받은 주관식 후기 모아보기
  [Activity.AUTHOR_REVIEWS_COMMENT]: {
    path: `/author/:authorId/comment-reviews`,
    component: AuthorReviewsCommentPage,
  },
  // 나의 당근알바
  [Activity.ME]: {
    path: '/me',
    component: MePageContainer,
    withReplaceToMain: true,
  },
  // 프로필 관리
  [Activity.RESUME_MANAGE]: {
    path: `/user/resume`,
    component: ResumeManagePage,
  },
  // 프로필 미리보기
  [Activity.RESUME_PREVIEW]: {
    path: '/user/resume/preview',
    component: ResumePreviewPage,
  },
  // 프로필 업데이트
  [Activity.RESUME_UPDATE]: {
    path: `/user/resume/update`,
    component: ResumeUpdatePage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 프로필 생성 후 ApplyPage로 Redirect
  [Activity.RESUME_CREATE_AND_APPLY]: {
    path: `/user/resume/create-and-apply`,
    component: ResumeCreateAndApplyPage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 프로필 생성 시 경력 추가
  [Activity.RESUME_EXPERIENCE_ADD]: {
    path: `/user/resume/:method/add-experience`,
    component: ResumeExperienceAddPage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 구인자에 대한 후기 작성 넛지(내근처 넛지용)
  [Activity.REVIEW_LIST_TO_WRITE]: {
    path: '/user/review/list-to-send',
    component: ReviewListToWritePage,
  },
  // 채용 확정 맞는지 확인
  [Activity.APPLICATION_CONFIRM_HIRED]: {
    path: `/user/application/:applicationId/confirm`,
    component: ConfirmHiredPage,
  },
  // 구직자가 받은 객관식 추천 리스트
  [Activity.REVIEWS_SELECTED]: {
    path: `/user/selected-reviews`,
    component: ReviewsSelectedPage,
  },
  // 경력 추가
  [Activity.EXPERIENCE_ADD]: {
    path: `/user/resume/experience/add`,
    component: ExperienceAddPage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 경력 수정
  [Activity.EXPERIENCE_UPDATE]: {
    path: `/user/resume/experience/update`,
    component: ExperienceUpdatePage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 새 공고 필터 알림 설정
  [Activity.NEW_JOB_POST_FILTER_SUBSCRIPTION]: {
    path: '/user/subscription/new-job-post-filter',
    component: NewJobPostFilterSubscriptionPage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // ------------------------------- Common --------------------------------------
  // 홈 리다이렉트
  [Activity.ROOT]: {
    path: '/',
    component: () => <Redirect to={Activity.MAIN} params={{}} />,
  },
  // 이용제한 상세
  [Activity.RESTRICTION_DETAIL]: {
    path: '/restriction',
    component: RestrictionGuidePage,
  },
  // 홈
  [Activity.MAIN]: {
    path: MAIN_PATH,
    component: MainPage,
  },
  // 검색
  [Activity.SEARCH]: {
    path: '/main/search',
    component: SearchPage,
  },
  // 검색 결과
  [Activity.SEARCH_RESULT]: {
    path: '/main/search/result',
    component: SearchResultPage,
  },
  // 홈 외부 Referrer
  [Activity.MAIN_EXTERNAL]: {
    path: '/main/external/:referrer',
    component: MainExternalPage,
  },
  // 내근처 온보딩
  [Activity.NEARBY_ONBOARDING]: {
    path: '/nearby-onboarding',
    component: NearbyOnboardingPage,
  },
  // 리뷰 작성 하기
  [Activity.REVIEW_WRITE]: {
    path: '/application/:applicationId/write-review',
    component: ReviewWritePage,
  },
  // 구직자 지원서로 받은 후기
  [Activity.REVIEW_RECEIVED]: {
    path: '/application/:applicationId/received-review',
    component: ReviewReceivedPage,
  },
  // 작성한 리뷰 보기
  [Activity.REVIEW_WRITTEN]: {
    path: '/application/:applicationId/written-review',
    component: ReviewWrittenPage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 유저가 받은 추천 후기 보기
  [Activity.REVIEWS_RECEIVED_GREAT]: {
    path: '/user/review/received-great',
    component: ReviewsReceivedGreatPage,
  },
  // 동네명 확인
  [Activity.REGION_INFO]: {
    path: '/user/region',
    component: RegionInfoPage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 서비스 공유하기
  [Activity.SHARED_SERVICE]: {
    path: '/shared-service',
    component: SharedServicePage,
  },
  // 외부협업 채용 소개
  [Activity.COLLABORATION_RECRUITMENT]: {
    path: '/collaboration/:organizationName',
    component: CollaborationRecruitmentPageContainer,
  },
  // 초대하기 링크 공유
  [Activity.INVITE_EVENT_SHARE]: {
    path: '/invite-event/:inviteEventKind/share',
    component: InviteEventSharePage,
  },
  // 초대하기 초대 코드 등록
  [Activity.INVITE_EVENT_INVITED]: {
    path: '/invite-event/:inviteEventCode/:inviteEventKind',
    component: InviteEventInvitedPage,
  },
  // 초대하기 링크 공유
  [Activity.INVITE_EVENT_PRIZE_CHECK]: {
    path: '/invite-event/:inviteEventKind/prize/:campaignEntryId',
    component: InviteEventPrizeCheckPage,
  },
  // 알바 서비스 소개
  [Activity.INTRODUCE_JOBS]: {
    path: '/introduce/jobs',
    component: IntroduceJobsPage,
  },
  // 광고 계정 생성
  [Activity.ADVERTISEMENT_ACCOUNT_CREATE]: {
    path: '/advertisement/account/create',
    component: AdvertisementAccountCreatePage,
  },
  // 당근마켓 약관
  [Activity.TERMS_KARROT_PRIVACY]: {
    path: '/terms/karrot_privacy',
    component: TermsKarrotPrivacyPage,
  },
  // 피드 이벤트 그룹
  [Activity.FEED_EVENT_GROUP]: {
    path: '/feed/event-group/:eventGroup',
    component: FeedEventGroupPage,
  },
  // 큐레이션 피드
  [Activity.CURATION_FEED]: {
    path: '/feed/curation',
    component: CurationFeedPage,
    withReplaceToMain: true,
  },
  // 큐레이션 피드
  [Activity.CURATION_FEED_EXTERNAL]: {
    path: '/feed/curation/external/:referrer',
    component: CurationFeedExternalPage,
  },
  // 이벤트 페이지 (범용)
  [Activity.CAMPAIGN]: {
    path: '/campaign/:campaignKind',
    component: CampaignPage,
  },
  // 광산구시민수당 지원사업 공고모아보기
  [Activity.GWANGSANGU_CITIZEN_SUPPORT]: {
    path: '/feed/custom/gwangsangu-citizen-support',
    component: GwangsanguCitizenSupportPage,
  },
  // ------------------------------- Employer ------------------------------------
  // 비즈 홈
  [Activity.BIZ_MAIN]: {
    path: '/biz/main',
    component: BizMainPageContainer,
    withReplaceToMain: true,
  },
  // 알바공고 관리 상세
  [Activity.BIZ_JOB_POST_MANAGE]: {
    path: `/biz/job-post/:jobPostId/view`,
    component: JobPostManagePageContainer,
  },
  // 알바공고 미리 보기 (Control 기능 제거)
  [Activity.BIZ_JOB_POST_PREVIEW]: {
    path: `/biz/job-post/:jobPostId/preview`,
    component: JobPostPreviewPage,
  },
  // 알바공고 광고
  [Activity.BIZ_JOB_POST_ADVERTISEMENT]: {
    path: `/biz/job-post/:jobPostId/advertisement`,
    component: DeprecatedJobPostAdvertisementPageContainer,
  },
  // 알바공고 광고 타입 상세
  [Activity.BIZ_JOB_POST_ADVERTISEMENT_TYPE_DETAIL]: {
    path: '/biz/job-post/advertisement/detail',
    component: JobPostAdvertisementTypeDetailPage,
  },
  // 알바공고 광고 만들기
  [Activity.BIZ_JOB_POST_ADVERTISEMENT_CREATE]: {
    path: `/biz/job-post/:jobPostId/advertisement/create`,
    component: DeprecatedJobPostAdvertisementCreatePage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  [Activity.BIZ_JOB_POST_ADVERTISEMENT_UPDATE]: {
    path: `/biz/job-post/:jobPostId/advertisement/:advertisementId/update`,
    component: JobPostAdvertisementUpdatePage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 알바공고 광고 만들기
  [Activity.BIZ_JOB_POST_ADVERTISEMENT_RESERVE]: {
    path: `/biz/job-post/:jobPostId/advertisement/reserve`,
    component: JobPostAdvertisementReservePage,
    withoutAppScreen: true,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 알바공고 광고 만들기 완료
  [Activity.BIZ_JOB_POST_ADVERTISEMENT_RESERVE_REQUEST_DONE]: {
    path: `/biz/job-post/:jobPostId/advertisement/reserve/done`,
    component: AdvertisementReserveRequestDone,
  },
  // 알바공고 유료 카테고리 채용 제한 안내 페이지
  [Activity.BIZ_JOB_POST_LARGE_SCALE_BUSINESS_GUIDE]: {
    path: '/biz/job-post/:jobPostId/large-scale-business-guide',
    component: LargeScaleBusinessGuidePage,
  },
  // 알바공고 생성
  [Activity.BIZ_JOB_POST_CREATE]: {
    path: `/biz/job-post/create`,
    component: JobPostCreatePage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 알바공고 등록비 결제
  [Activity.BIZ_JOB_POST_CREATE_PURCHASE]: {
    path: `/biz/job-post/:jobPostId/register-fee-purchase`,
    component: JobPostCreatePurchasePage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 하는 일에 따른 유료 알바공고 생성 확인
  [Activity.BIZ_JOB_POST_CREATE_CONFIRM_REGISTER_FEE]: {
    path: `/biz/job-post/create/confirm/register-fee`,
    component: JobPostCreateConfirmRegisterFeePage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 알바공고 생성 확인 (202307 캠페인 대응)
  [Activity.BIZ_JOB_POST_CREATE_CONFIRM_CAMPAIGN]: {
    path: `/biz/job-post/create/confirm/campaign/:campaignKind`,
    component: JobPostCreateConfirmCampaignPage,
  },
  // 알바공고 마이그레이션
  [Activity.BIZ_JOB_POST_MIGRATE]: {
    path: `/biz/job-post/migrate`,
    component: JobPostMigratePage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 알바공고 업데이트
  [Activity.BIZ_JOB_POST_UPDATE]: {
    path: `/biz/job-post/update`,
    component: JobPostUpdatePage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 알바공고 업데이트 (추가정보 입력하기)
  [Activity.BIZ_JOB_POST_UPDATE_EXTEND]: {
    path: `/biz/job-post/update-extend`,
    component: JobPostUpdateExtendPage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 알바공고 끌어올리기
  [Activity.BIZ_JOB_POST_BRING_UP]: {
    path: `/biz/job-post/:jobPostId/bring-up`,
    component: JobPostBringUpPage,
  },
  // 알바공고 Slot 초과
  [Activity.BIZ_JOB_POST_SLOT_OVERFLOW]: {
    path: SLOT_OVERFLOW_PATH,
    component: JobPostSlotOverflowPage,
    withReplaceToMain: true,
  },
  // 알바공고 복사
  [Activity.BIZ_JOB_POST_COPY]: {
    path: `/biz/job-post/method/copy`,
    component: JobPostCopyPage,
  },
  // 알바공고 생성 시 주소 검색
  [Activity.BIZ_JOB_POST_SEARCH_ADDRESS]: {
    path: '/biz/job-post/:method/search-address',
    component: JobPostSearchAddressPage,
  },
  // 지원자 목록
  [Activity.BIZ_APPLICATIONS]: {
    path: `/biz/job-post/:jobPostId/applications`,
    component: ApplicationsPageContainer,
    withReplaceToMain: true,
  },
  // 지원자 상세
  [Activity.BIZ_APPLICATION_DETAIL]: {
    path: `/biz/application/:applicationId`,
    component: ApplicationDetailPage,
    withReplaceToMain: true,
  },
  [Activity.BIZ_APPLICATION_PURCHASE]: {
    path: `/biz/application/purchase`,
    component: ApplicationOpenRightPurchasePage,
  },
  // 지원자가 받은 추천 리스트
  [Activity.BIZ_APPLICATION_REVIEWS_SELECTED]: {
    path: `/biz/application/:applicationId/selected-reviews`,
    component: ReviewSelectedByAuthorPage,
  },
  // 지원자 거절 메시지 발송
  [Activity.BIZ_APPLICATION_REJECT]: {
    path: `/biz/application/:applicationId/reject`,
    component: ApplicationRejectPage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 지원자 거절 메시지 일괄 발송
  [Activity.BIZ_APPLICATIONS_REJECT]: {
    path: `/biz/job-post/:jobPostId/applications/reject`,
    component: ApplicationsRejectPage,
  },
  // 채용 선택
  [Activity.BIZ_APPLICATIONS_SELECT]: {
    path: `/biz/job-post/:jobPostId/applications/select`,
    component: ApplicationsSelectPage,
  },
  // 채용 확정 컨펌
  [Activity.BIZ_APPLICATIONS_SELECT_CONFIRM]: {
    path: `/biz/job-post/:jobPostId/applications/select/confirm`,
    component: ApplicationsSelectConfirmPage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 사업자 인증
  [Activity.BIZ_BUSINESS_CERTIFICATE]: {
    path: '/biz/certificate/business-registration',
    component: BizBusinessCertificatePage,
  },
  // 사업자 인증
  [Activity.BIZ_BUSINESS_CERTIFICATE_EXTERNAL]: {
    path: '/biz/certificate/business-registration/external/:referrer',
    component: BizBusinessCertificateExternalPage,
  },
  // 사업자 인증
  [Activity.BIZ_BUSINESS_CERTIFICATE_ASK]: {
    path: '/biz/certificate/business-ask',
    component: BizBusinessCertificateAskPage,
  },
  // 사업자 인증 상호명 추가
  [Activity.BIZ_BUSINESS_CERTIFICATE_ADD_COMPANY_NAME]: {
    path: '/biz/certificate/business-registration/add-company-name',
    component: BizBusinessCertificateAddCompanyNamePage,
  },
  // 본인 인증
  [Activity.BIZ_IDENTIFICATION_CERTIFICATE]: {
    path: '/biz/certificate/identification',
    component: BizIdentificationCertificatePage,
  },
  // 사업자 인증 관리
  [Activity.BIZ_BUSINESS_CERTIFICATE_MANAGE]: {
    path: '/biz/certificate/manage',
    component: BizCertificateManagePage,
  },
  // 광고하기 (공고 선택 없이)
  [Activity.BIZ_ADVERTISEMENT_CREATE]: {
    path: `/biz/advertisement/create`,
    component: AdvertisementCreatePage,
    withReplaceToMain: true,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 구인자 정보 업데이트
  [Activity.BIZ_AUTHOR_UPDATE]: {
    path: '/biz/author/update',
    component: BizAuthorUpdatePage,
    appScreenOptions: {
      CUPERTINO_ONLY_modalPresentationStyle: 'fullScreen',
    },
  },
  // 구인자 소개글 예시
  [Activity.BIZ_AUTHOR_INTRODUCTION_EXAMPLE]: {
    path: '/biz/author/introduction-example',
    component: BizAuthorIntroductionExamplePage,
  },
  // 구인자 뱃지
  [Activity.BIZ_AUTHOR_BADGE]: {
    path: '/biz/author/badge',
    component: BizAuthorBadgePage,
  },
  // 구인글 리스트
  [Activity.BIZ_JOB_POSTS]: {
    path: `/biz/job-posts`,
    component: BizJobPostsPage,
  },
  // 구인자 받은 알바 후기
  [Activity.BIZ_AUTHOR_REVIEWS]: {
    path: '/biz/author/reviews',
    component: BizAuthorReviewsPage,
  },
  // ------------------------------- External Halfview ------------------------------------
  // 알바공고 상태 변경 for halfview
  [Activity.BIZ_JOB_POST_STATUS_CHANGE]: {
    path: `/biz/job-post/:jobPostId/status`,
    component: JobPostStatusChangePage,
  },

  // ------------------------------- Service -------------------------------------
  // 로그인 필요
  [Activity.NEED_LOGIN]: {
    path: '/need-login',
    component: NeedLoginPage,
    withoutAuth: true,
  },
  // 404
  [Activity.NOT_FOUND]: {
    path: '/404',
    component: NotFoundPage,
  },
  // ------------------------------- Legacy Handling -----------------------------
  // 관심 관리
  [Activity.FAVORITES_MANAGE]: {
    path: `/user/favorites`,
    component: () => <Redirect to={Activity.FAVORITES_MANAGE} params={{ tab: 'favorites' }} />,
  },
  // 지원 관리
  [Activity.APPLICATIONS_MANAGE]: {
    path: `/user/applications`,
    component: () => <Redirect to={Activity.APPLICATIONS_MANAGE} params={{ tab: 'applications' }} />,
  },
  // 지원 보기 (예전 채팅에서 구인자가 구직자 프로필 확인하는 fallback, 신규 채팅에서는 biz/application으로 변경)
  [Activity.APPLICATION_VIEW]: {
    path: `/user/application/:applicationId`,
    component: ApplicationViewPage,
  },
  // 이벤트 피드
  [Activity.FEED_EVENT]: {
    path: '/feed/event',
    component: () => <Redirect to={Activity.MAIN} params={{}} />,
  },
  // 다이제스트
  [Activity.JOB_POSTS_DIGEST]: {
    path: '/digest-job-post',
    component: () => <Redirect to={Activity.MAIN} params={{}} />,
  },
  // ------------------------------- Test ----------------------------------------
  // error test
  [Activity.ERROR_TEST]: {
    path: '/test-error-query',
    component: ErrorTestPage,
  },
  [Activity.EVENT_TEST]: {
    path: '/event-test',
    component: EventTestPage,
  },
}

export const activities = mapValues(get('component'))(routes)

export type TypeActivities = typeof activities
