/* eslint-disable import/no-duplicates */
import { format, getDate, isToday as calcIsToday, startOfDay } from 'date-fns'
import ko from 'date-fns/locale/ko'
import { chunk, range } from 'lodash'
import React from 'react'

import { styled } from '@src/stitches/stitches.config'
import {
  areDateEqual,
  datesBetweenDates,
  inDates,
  isBetweenDates,
  isHolidayDate,
  MS,
  sortDates,
  uniqueDates,
  withoutDates,
} from '@src/utils/date'

interface Props {
  value: Date[]
  enableStartDate: Date
  enableEndDate: Date
  displayStartDate: Date
  displayEndDate: Date
  onChange?: (dates: Date[]) => void
  visibleHeader?: boolean
  fixedValue?: Date[]
  description?: React.ReactNode
}

interface TouchEventState {
  touchStartClientY?: number
  start?: Date
  cursor?: Date
  mode?: 'on' | 'off'
}

enum ActiveState {
  START = 'start',
  MIDDLE = 'middle',
  END = 'end',
  INDEPENDENCE = 'independence',
  NONE = 'none',
}

const WEEK_DAYS_COUNT = 7

const Calendar: React.FCC<Props> = ({
  value: activeDates,
  displayStartDate,
  displayEndDate,
  enableStartDate,
  enableEndDate,
  fixedValue = [],
  onChange,
  visibleHeader,
  description,
}) => {
  const dates = React.useMemo(() => {
    return chunk(
      datesBetweenDates(displayStartDate, displayEndDate).map((date) => ({
        value: date,
        enable:
          startOfDay(new Date()).getTime() <= date.getTime() && isBetweenDates(date, enableStartDate, enableEndDate),
      })),
      WEEK_DAYS_COUNT
    )
  }, [displayStartDate, displayEndDate, enableStartDate, enableEndDate])

  const touchEventStateRef = React.useRef<TouchEventState>({})

  const handleChange = (date: Date) => {
    const isDateInFixedExposureDates = fixedValue.some((d) => areDateEqual(d, date))
    if (!onChange || isDateInFixedExposureDates) return

    const isActive = inDates(date, activeDates)
    const newActiveDates = isActive ? withoutDates(activeDates, date) : activeDates.concat([date])
    onChange(sortDates(newActiveDates))
  }

  const getDateFromTouchEvent = (event: React.TouchEvent<HTMLElement>) => {
    const element = document.elementFromPoint(
      event.changedTouches[0].clientX,
      event.changedTouches[0].clientY
    ) as HTMLElement | null
    const time = element?.dataset?.time
    if (!time) return null

    return new Date(Number(time))
  }

  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    const date = getDateFromTouchEvent(e)
    const fixedDates = fixedValue.map((v) => new Date(v))
    if (!date || inDates(date, fixedDates)) return

    touchEventStateRef.current = {
      touchStartClientY: e.touches[0].clientY,
      start: date,
      cursor: undefined,
      mode: inDates(date, activeDates) ? 'off' : 'on',
    }
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    const date = getDateFromTouchEvent(e)
    const time = date?.getTime()
    const fixedDates = fixedValue.map((v) => new Date(v))
    const eventState = touchEventStateRef.current

    const timeRowIndex = dates.findIndex((row) => row.some((d) => d.value.getTime() === time))
    const startTimeRowIndex = dates.findIndex((row) =>
      row.some((d) => d.value.getTime() === eventState.start?.getTime())
    )
    const willScroll = Math.abs(e.touches[0].clientY - (eventState.touchStartClientY ?? 0)) >= 5

    if (
      !onChange ||
      !time ||
      !eventState.start ||
      !eventState.mode ||
      time === eventState.start.getTime() ||
      time === eventState.cursor?.getTime() ||
      timeRowIndex !== startTimeRowIndex ||
      !isBetweenDates(time, enableStartDate, enableEndDate) ||
      willScroll ||
      (date && inDates(date, fixedDates))
    )
      return

    const targetDates = datesBetweenDates(time, eventState.start)

    const newActiveDates = sortDates(
      eventState.mode === 'on'
        ? uniqueDates([...activeDates, ...targetDates])
        : withoutDates(activeDates, ...targetDates)
    )

    onChange(newActiveDates)
    eventState.cursor = new Date(time)
  }

  const handleClick = (event: React.MouseEvent<HTMLTableElement>) => {
    if (!(event.target instanceof HTMLElement)) return
    const date = event.target.dataset.time
    if (!date) return

    handleChange(new Date(Number(date)))
  }

  const eventListeners = onChange
    ? { onTouchStart: handleTouchStart, onTouchMove: handleTouchMove, onClick: handleClick }
    : {}

  return (
    <CalendarContainer>
      {visibleHeader && (
        <CurrentMonth isDescription={!!description}>{`${format(enableStartDate, 'yyyy.M.d')} ~ ${format(
          enableEndDate,
          'yyyy.M.d'
        )}`}</CurrentMonth>
      )}
      {description}
      <Table {...eventListeners}>
        <THead>
          <tr>
            {range(WEEK_DAYS_COUNT).map((diff) => {
              return <Th key={diff}>{format(displayStartDate.getTime() + diff * MS.day, 'E', { locale: ko })}</Th>
            })}
          </tr>
        </THead>
        <tbody>
          {dates.map((week, i) => {
            return (
              <tr key={i}>
                {week.map((date) => {
                  const isActive = inDates(date.value, activeDates)
                  const isHoliday = isHolidayDate(date.value)
                  const isToday = calcIsToday(date.value)
                  const activeState = convertActiveState(date, activeDates)

                  return (
                    <Td key={date.value.getTime()}>
                      <Text
                        data-time={date.enable ? date.value.getTime() : undefined}
                        isActive={isActive}
                        activeState={activeState}
                        isDisabled={!date.enable}
                        isHoliday={isHoliday}>
                        {isToday ? '오늘' : getDate(date.value)}
                      </Text>
                    </Td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </CalendarContainer>
  )
}

export default Calendar

const convertActiveState = (date: { value: Date; enable: boolean }, activeDates: Date[]) => {
  const beforeDateActive = activeDates.some((d) => areDateEqual(d, date.value.getTime() - MS.day))
  const afterDateActive = activeDates.some((d) => areDateEqual(d, date.value.getTime() + MS.day))
  const isActive = inDates(date.value, activeDates)

  switch (true) {
    case !isActive:
      return ActiveState.NONE
    case beforeDateActive && afterDateActive:
      return ActiveState.MIDDLE
    case !beforeDateActive && !afterDateActive:
      return ActiveState.INDEPENDENCE
    case beforeDateActive:
      return ActiveState.END
    case afterDateActive:
    default:
      return ActiveState.START
  }
}

const CalendarContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const CurrentMonth = styled('div', {
  $text: 'title3Bold',
  variants: {
    isDescription: {
      true: {
        margin: '0 0 8px',
      },
      false: {
        margin: '0 0 16px',
      },
    },
  },
})

const Table = styled('table', {
  borderCollapse: 'collapse',
  width: '100%',
})

const THead = styled('thead', {
  margin: '0 0 8px',
})

const Th = styled('th', {
  $text: 'caption1Regular',
  padding: 0,
  color: '$gray600',
})

const Td = styled('td', {
  position: 'relative',
  width: 'calc(100% / 7)',

  '&:after': {
    content: '',
    display: 'block',
    marginTop: '100%',
  },
})

const Text = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  $text: 'title3Regular',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  $$side: 'calc(100% / 7)',
  $$gap: '4px',
  margin: '$$gap 0',

  variants: {
    isDisabled: {
      true: {
        color: '$gray400',
      },
    },
    isActive: {
      true: {},
      false: {
        margin: '$$gap $$gap',
      },
    },
    activeState: {
      start: {
        borderRadius: '50% 0 0 50%',
        padding: '0 $$gap 0 0',
        margin: '$$gap 0 $$gap $$gap',

        [`${Td}:last-of-type &`]: {
          margin: '$$gap $$gap $$gap 0',
        },
      },
      middle: {
        [`${Td}:first-child &`]: {
          margin: '$$gap 0 $$gap $$gap',
        },
        [`${Td}:last-of-type &`]: {
          margin: '$$gap $$gap $$gap 0',
        },
      },
      end: {
        borderRadius: '0 50% 50% 0',
        padding: '0 0 0 $$gap',
        margin: '$$gap $$gap $$gap 0',

        [`${Td}:first-child &`]: {
          margin: '$$gap 0 $$gap $$gap',
        },
      },
      independence: {
        borderRadius: '50%',
        margin: '$$gap $$gap',
      },
      none: {},
    },
    isHoliday: { true: {} },
  },
  compoundVariants: [
    {
      isDisabled: false,
      isActive: true,
      css: {
        background: '$gray800',
        color: '$gray00',
      },
    },
    {
      isDisabled: true,
      isActive: true,
      css: {
        background: '$gray100',
        color: '$gray400',
      },
    },
    {
      isDisabled: false,
      isActive: false,
      isHoliday: true,
      css: {
        color: '$carrot600',
      },
    },
  ],
})
