/**
 * @generated SignedSource<<44848ceb1586d1de58c1d34db4ff751d>>
 * @relayHash 905c5c8a914de381e09661416803ef27
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 905c5c8a914de381e09661416803ef27

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApplicationStatus = "HIRED" | "IN_PROGRESS" | "REJECTED" | "WAITING" | "%future added value";
export type ExperiencePeriod = "LESS_THAN_A_MONTH" | "LESS_THAN_SIX_MONTH" | "LESS_THAN_THREE_MONTH" | "MORE_THAN_ONE_YEAR_LESS_THAN_TWO_YEAR" | "MORE_THAN_SIX_MONTH_LESS_THAN_ONE_YEAR" | "MORE_THAN_THREE_YEAR" | "MORE_THAN_TWO_YEAR_LESS_THAN_THREE_YEAR" | "%future added value";
export type OfficalAuthorType = "DAANGN_MARKET" | "GWANGJU_GWANGSAN" | "%future added value";
export type CancelApplicationWhereInput = {
  _id: string;
};
export type useApplicationApplyCancelMutationMutation$variables = {
  deleteConnections: ReadonlyArray<string>;
  where: CancelApplicationWhereInput;
};
export type useApplicationApplyCancelMutationMutation$data = {
  readonly cancelApplication: {
    readonly application: {
      readonly canceled: boolean;
      readonly id: string;
      readonly resume: {
        readonly experiences: ReadonlyArray<{
          readonly _id: string;
          readonly autoAddedBy: OfficalAuthorType | null | undefined;
          readonly content: string | null | undefined;
          readonly isAutoAdded: boolean;
          readonly period: ExperiencePeriod | null | undefined;
          readonly workplaceName: string;
          readonly year: number | null | undefined;
        }>;
      } | null | undefined;
      readonly status: ApplicationStatus;
      readonly user: {
        readonly activityBadge: {
          readonly items: ReadonlyArray<{
            readonly count: number;
            readonly name: string;
          }>;
        };
        readonly applicationCountInfo: {
          readonly countByStatus: ReadonlyArray<{
            readonly count: number;
            readonly status: ApplicationStatus;
          }>;
          readonly totalCount: number;
        };
      };
      readonly viewedAt: Dateable | null | undefined;
    };
  };
};
export type useApplicationApplyCancelMutationMutation = {
  response: useApplicationApplyCancelMutationMutation$data;
  variables: useApplicationApplyCancelMutationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteConnections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v2 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canceled",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ResumeExperience",
  "kind": "LinkedField",
  "name": "experiences",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workplaceName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "year",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "period",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAutoAdded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoAddedBy",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ApplicantApplicationCountInfo",
  "kind": "LinkedField",
  "name": "applicationCountInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ApplicationCountByStatus",
      "kind": "LinkedField",
      "name": "countByStatus",
      "plural": true,
      "selections": [
        (v8/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useApplicationApplyCancelMutationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CancelApplicationResult",
        "kind": "LinkedField",
        "name": "cancelApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Resume",
                "kind": "LinkedField",
                "name": "resume",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserActivityBadge",
                    "kind": "LinkedField",
                    "name": "activityBadge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useApplicationApplyCancelMutationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CancelApplicationResult",
        "kind": "LinkedField",
        "name": "cancelApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "deleteConnections"
                  }
                ]
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Resume",
                "kind": "LinkedField",
                "name": "resume",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserActivityBadge",
                    "kind": "LinkedField",
                    "name": "activityBadge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v10/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "905c5c8a914de381e09661416803ef27",
    "metadata": {},
    "name": "useApplicationApplyCancelMutationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d11bdbc6de5a075290ff4dd5fa310994";

export default node;
