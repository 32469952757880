import React from 'react'
import { graphql, useFragment } from 'react-relay'

import { ApplicantReviewStats_user$key } from '@src/__generated__/ApplicantReviewStats_user.graphql'
import Spacer from '@src/components/base/Spacer'
import { styled } from '@src/stitches/stitches.config'

type Category = 'GREAT' | 'GOOD' | 'BAD'

interface Counts {
  GREAT: number
  GOOD: number
  BAD: number
}

interface Ratio {
  GREAT: number
  GOOD: number
  BAD: number
}

type Props = {
  userRef: ApplicantReviewStats_user$key
}

const ApplicantReviewStats: React.FCC<Props> = ({ userRef }) => {
  const user = useFragment(
    graphql`
      fragment ApplicantReviewStats_user on User {
        receivedApplicantGreatReviewCount
        receivedApplicantGoodReviewCount
        receivedApplicantBadReviewCount
      }
    `,
    userRef
  )

  const { counts, ratio, isGreatHighest, isGoodHighest, isBadHighest } = React.useMemo(() => {
    const greatCount = user.receivedApplicantGreatReviewCount ?? 0
    const goodCount = user.receivedApplicantGoodReviewCount ?? 0
    const badCount = user.receivedApplicantBadReviewCount ?? 0
    const totalCount = greatCount + goodCount + badCount

    const counts: Counts = {
      GREAT: greatCount,
      GOOD: goodCount,
      BAD: badCount,
    }
    const ratio: Ratio = totalCount
      ? {
          GREAT: (greatCount / totalCount) * 100,
          GOOD: (goodCount / totalCount) * 100,
          BAD: (badCount / totalCount) * 100,
        }
      : {
          GREAT: 0,
          GOOD: 0,
          BAD: 0,
        }

    const highestCategory = totalCount
      ? ((counts: Counts): Category => {
          let highestCategory: Category = 'BAD'
          let highestCount = counts.BAD

          if (counts.GOOD >= highestCount) {
            highestCategory = 'GOOD'
            highestCount = counts.GOOD
          }

          if (counts.GREAT >= highestCount) {
            highestCategory = 'GREAT'
          }

          return highestCategory
        })(counts)
      : 'NONE'

    return {
      counts,
      ratio,
      isGreatHighest: highestCategory === 'GREAT',
      isGoodHighest: highestCategory === 'GOOD',
      isBadHighest: highestCategory === 'BAD',
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderReviewStats = () => {
    return (
      [
        { title: '추천해요', count: counts.GREAT, ratio: ratio.GREAT, variant: isGreatHighest ? 'primary' : undefined },
        { title: '좋아요', count: counts.GOOD, ratio: ratio.GOOD, variant: isGoodHighest ? 'primary' : undefined },
        { title: '아쉬워요', count: counts.BAD, ratio: ratio.BAD, variant: isBadHighest ? 'secondary' : undefined },
      ] as const
    ).map((item, i) => {
      return (
        <React.Fragment key={item.title}>
          {i !== 0 && <Spacer h={6} />}
          <Row>
            <NonShrinkTd>{item.title}</NonShrinkTd>
            <StretchTd>
              <ProgressBarContainer>
                <ProgressBar variant={item.variant} css={{ width: `${item.ratio}%` }} />
              </ProgressBarContainer>
            </StretchTd>
            <NonShrinkTdNum align="right" variant={item.variant}>
              {item.count}명
            </NonShrinkTdNum>
          </Row>
        </React.Fragment>
      )
    })
  }

  return <Container>{renderReviewStats()}</Container>
}

export default ApplicantReviewStats

const Container = styled('table', {
  width: '100%',
  // tableLayout: 'fixed',

  '& td': {
    padding: 0,
  },
})

const Row = styled('tr', {})

const NonShrinkTdNum = styled('td', {
  whiteSpace: 'nowrap',
  $text: 'caption1Regular',
  color: '$gray800',

  variants: {
    align: {
      right: {
        textAlign: 'right',
      },
    },
    variant: {
      primary: {
        color: '$carrot500',
        fontWeight: 'bold',
      },
      secondary: {
        color: '$gray900',
        fontWeight: 'bold',
      },
    },
  },
})

const NonShrinkTd = styled('td', {
  whiteSpace: 'nowrap',
  $text: 'caption1Regular',
  color: '$gray700',
})

const StretchTd = styled('td', {
  width: '100%',
})

const ProgressBarContainer = styled('div', {
  position: 'relative',
  margin: '0 16px 0 12px',
  height: '8px',
  borderRadius: '30px',
  background: '$gray100',
  overflow: 'hidden',
})

const ProgressBar = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  background: '$gray400',
  borderRadius: '30px',

  variants: {
    variant: {
      primary: {
        background: '$carrot500',
      },
      secondary: {
        background: '$gray700',
      },
    },
  },
})
