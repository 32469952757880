import React from 'react'

import storage from '@src/api/storage'
import Button from '@src/components/base/buttons/Button'
import CheckCircle from '@src/components/base/form/CheckCircle'
import Image from '@src/components/base/image/Image'
import PaginationRefHandler from '@src/components/base/paginationScroll/PaginationRefHandler'
import PaginationScroll from '@src/components/base/paginationScroll/PaginationScroll'
import { MASKING_GUIDE_SHOWN } from '@src/constants/api'
import { IMAGE_MASKING_GUIDE_EMPLOY, IMAGE_MASKING_GUIDE_PHONE_NUMBER } from '@src/constants/images'
import useBoolean from '@src/hooks/useBoolean'
import Halfview from '@src/packages/halfview/Halfview'
import { styled } from '@src/stitches/stitches.config'

interface Props {
  onCloseClick: () => void
  isOpen: boolean
}

const guides = [
  {
    source: IMAGE_MASKING_GUIDE_PHONE_NUMBER,
    title: '개인정보를 안전하게 보호해요',
    description: (
      <>
        당근알바는 개인정보 유출∙오용∙남용으로부터 안전하게 보호하기 위해 <strong>연락처를 별표(*)</strong>로 가리고
        있어요. 당근전화나 채팅을 통해 이웃과 더욱 안전하고 편한 연락을 만들어 가세요!
      </>
    ),
  },
  {
    source: IMAGE_MASKING_GUIDE_EMPLOY,
    title: '공정하고 평등한 일자리를 만들어요',
    description: (
      <>
        <strong>연령, 성별 등</strong> 고용 차별로 오인할 수 있는 단어는 사용을 제한하고 있어요. 알바공고를 작성할 때
        수정을 안내하고, 별표(*)로 가리고 있어요.
      </>
    ),
  },
]

const JobPostMaskingHalfview: React.FCC<Props> = ({ onCloseClick, isOpen }) => {
  const [page, setPage] = React.useState(1)
  const [isChecked, setCheckedTrue, setCheckedFalse] = useBoolean(true)

  const handleToggleChecked = () => {
    if (isChecked) {
      setCheckedFalse()
    } else {
      setCheckedTrue()
    }
  }

  const handleConfirm = () => {
    storage.setItemAtLocal(MASKING_GUIDE_SHOWN, isChecked)
    onCloseClick()
  }

  return (
    <Halfview onCloseClick={onCloseClick} open={isOpen}>
      <PaginationScroll>
        <MaskingGuideCard>
          {guides.map(({ source, title, description }) => (
            <Contents key={title}>
              <GuideImageWrapper>
                <GuideImage source={source} alt="masking guide" />
              </GuideImageWrapper>
              <TitleContainer>
                <Title>{title}</Title>
                <Description>{description}</Description>
              </TitleContainer>
            </Contents>
          ))}
        </MaskingGuideCard>
        <PaginationRefHandler onPageChange={setPage} />
      </PaginationScroll>
      <SlotWrapper>
        <Slot full={page === 1} />
        <Slot full={page === 2} />
      </SlotWrapper>
      <CheckCircleContainer onClick={handleToggleChecked}>
        <CheckCircle isSelected={isChecked} />
        <CheckCircleLabel>다시 보지 않기</CheckCircleLabel>
      </CheckCircleContainer>
      <ButtonWrapper>
        <Button onClick={handleConfirm} priority="primary" size="xlarge">
          확인
        </Button>
      </ButtonWrapper>
    </Halfview>
  )
}

export default JobPostMaskingHalfview

const MaskingGuideCard = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
})

const Contents = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  flexShrink: 0,
})

const GuideImageWrapper = styled('div', {
  padding: '16px 24px',
  width: '100%',
})

const GuideImage = styled(Image, {
  width: '100%',
})

const TitleContainer = styled('div', {
  padding: '16px',
  width: '100%',
  height: '100%',
  whiteSpace: 'pre-wrap',
})

const Title = styled('div', {
  $text: 'title2Bold',
  color: '$gray900',
  margin: '0 0 6px 0',
})

const Description = styled('div', {
  $text: 'bodyL1Regular',
  color: '$gray700',
})

const SlotWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0',
})

const Slot = styled('div', {
  width: '6px',
  height: '6px',
  borderRadius: '50%',

  '&:last-of-type': {
    margin: '0 0 0 8px',
  },

  variants: {
    full: {
      true: {
        background: '$primary-semantic',
      },
      false: {
        background: '$grayAlpha500',
        opacity: 0.2,
      },
    },
  },
  defaultVariants: {
    full: false,
  },
})

const CheckCircleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '4px 16px',
})

const CheckCircleLabel = styled('div', {
  $text: 'caption1Regular',
  color: '$gray900',
  margin: '0 0 0 8px',
})

const ButtonWrapper = styled('div', {
  padding: '12px 16px 8px 16px',
})
