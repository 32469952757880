import { jobTasksMap } from '@src/constants/form'
import { JobTaskType } from '@src/types/jobPost'

const searchTermDictionary: Record<JobTaskType, string[]> = {
  SERVING: ['서빙', '써빙', '홀서빙', '홀써빙', '홀직원', '홀알바'],
  KITCHEN_ASSISTANCE: ['주방', '주방보조', '조리보조', '주방직원', '설거지', '설겆이', '세정', '세척', '조리실장'],
  KITCHEN_COOK: ['주방', '조리', '주방장', '조리사', '조리원', '급식조리사'],
  SHOP_MANAGEMENT: ['고객응대', '매장관리', '매장직원', '매장알바', '판매', '카운터'],
  BEVERAGE_MAKING: ['카페', '음료제조', '커피', '까페', '바리스타'],
  BAKING: ['베이커리', '베이킹', '파티쉐', '제빵', '파리바게트', '파리바게뜨', '디저트', '제과'],
  CONVENIENCE_STORE: [
    '편의점',
    'CU',
    '씨유',
    'GS25',
    '지에스25',
    '세븐일레븐',
    'emart24',
    '이마트24',
    '미니스탑',
    '미니스톱',
  ],
  TUTORING: [
    '과외',
    '학원',
    '강사',
    '선생님',
    '논술',
    '회화',
    '내신',
    '레슨',
    '교사',
    '보조교사',
    '튜터',
    '학습지',
    '구몬',
    '빨간펜',
    '수업',
  ],
  LEAFLET_DISTRIBUTION: ['전단', '부착', '포스터'],
  CLEANING: ['청소', '미화', '미화 여사님', '청소 여사님'],
  HOUSEWORK: ['가사', '가정집', '집안일', '청소', '집청소', '집정리'],
  NURSING: [
    '돌봄',
    '돌봐',
    '돌보',
    '강아지',
    '고양이',
    '시터',
    '식사도우미',
    '목욕동행',
    '아이돌보미',
    '베이비시터',
    '펫시팅',
    '펫시터',
  ],
  KIDS_PICK_UP: ['등하원', '등원', '하원', '유치원', '차량도우미', '동승', '승하차'],
  LIGHT_WORK: ['심부름', '소일거리', '무슨 일이든', '무슨일이든', '무엇이든', '말벗', '벌레', '부업'],
  ETC: [],
}

const searchTermDictionaryEntries = Object.entries(searchTermDictionary)

export const searchSuggestedJobTasks = (
  searchTerm: string,
  defaultSortFn?: (a: [string, string[]], b: [string, string[]]) => number
) => {
  const trimmedSearchTerm = searchTerm.replace(/\s/g, '')
  const suggested = (defaultSortFn ? searchTermDictionaryEntries.sort(defaultSortFn) : searchTermDictionaryEntries)
    .map(([k, v]) => {
      if (!v.length) return [k, 0] as [JobTaskType, number]

      const regex = new RegExp(`(${v.join('|')})`, 'gi')
      return [k, (trimmedSearchTerm.match(regex) || []).length] as [JobTaskType, number]
    })
    .sort((a, b) => b[1] - a[1])
    .map(([k]) => ({ label: jobTasksMap[k], value: k }))

  return suggested
}

const relativeJobTasksDictionary: Record<JobTaskType, JobTaskType[]> = {
  SERVING: ['SHOP_MANAGEMENT', 'KITCHEN_ASSISTANCE', 'ETC', 'BEVERAGE_MAKING', 'CONVENIENCE_STORE'],
  KITCHEN_ASSISTANCE: ['SERVING', 'ETC', 'SHOP_MANAGEMENT', 'LIGHT_WORK', 'CLEANING'],
  KITCHEN_COOK: ['KITCHEN_ASSISTANCE', 'SERVING', 'SHOP_MANAGEMENT', 'ETC', 'BEVERAGE_MAKING'],
  CONVENIENCE_STORE: ['SHOP_MANAGEMENT', 'SERVING', 'ETC', 'KITCHEN_ASSISTANCE', 'BEVERAGE_MAKING'],
  SHOP_MANAGEMENT: ['SERVING', 'ETC', 'BEVERAGE_MAKING', 'KITCHEN_ASSISTANCE', 'LIGHT_WORK'],
  BEVERAGE_MAKING: ['SHOP_MANAGEMENT', 'SERVING', 'ETC', 'CONVENIENCE_STORE', 'BAKING'],
  BAKING: ['BEVERAGE_MAKING', 'SHOP_MANAGEMENT', 'SERVING', 'ETC', 'CONVENIENCE_STORE'],
  TUTORING: ['ETC', 'SHOP_MANAGEMENT', 'LIGHT_WORK', 'KIDS_PICK_UP', 'NURSING'],
  LEAFLET_DISTRIBUTION: ['LIGHT_WORK', 'ETC', 'CLEANING', 'SHOP_MANAGEMENT', 'SERVING'],
  CLEANING: ['ETC', 'LIGHT_WORK', 'KITCHEN_ASSISTANCE', 'HOUSEWORK', 'SHOP_MANAGEMENT'],
  HOUSEWORK: ['LIGHT_WORK', 'ETC', 'CLEANING', 'KIDS_PICK_UP', 'NURSING'],
  NURSING: ['KIDS_PICK_UP', 'LIGHT_WORK', 'ETC', 'SHOP_MANAGEMENT', 'HOUSEWORK'],
  KIDS_PICK_UP: ['LIGHT_WORK', 'NURSING', 'ETC', 'SHOP_MANAGEMENT', 'HOUSEWORK'],
  LIGHT_WORK: ['ETC', 'SHOP_MANAGEMENT', 'CLEANING', 'KIDS_PICK_UP', 'KITCHEN_ASSISTANCE'],
  ETC: ['LIGHT_WORK', 'SHOP_MANAGEMENT', 'SERVING', 'KITCHEN_ASSISTANCE', 'CLEANING'],
}

export const searchRelativeJobTaks = ({
  jobTasks,
  count,
  includeTaskSelf,
}: {
  jobTasks: JobTaskType[]
  count: number
  includeTaskSelf?: boolean
}): JobTaskType[] => {
  const frequency = jobTasks
    .map((task) => relativeJobTasksDictionary[task])
    .concat(includeTaskSelf ? jobTasks : [])
    .flat()
    .reduce(
      (acc, item) => {
        acc[item] = (acc[item] || 0) + 1
        return acc
      },
      {} as Record<JobTaskType, number>
    )

  const ans = (Object.keys(frequency) as Array<JobTaskType>).sort(
    (a, b) => frequency[b] - frequency[a] || a.localeCompare(b)
  )

  return ans.slice(0, count)
}
