/**
 * @generated SignedSource<<59ad492cb38f64f266a480518e267bd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ConsideredQualification = "ADULT_ALL_AGES" | "BEGINNER" | "FOREIGNER" | "MINOR" | "%future added value";
export type EventGroupType = "BUDDHA_BIRTHDAY" | "CHILDREN_DAY" | "CHRISTMAS" | "Chuseok" | "DAANGN_EVENT" | "LunarNewYear" | "MEMORIAL_DAY" | "PEPERO_DAY" | "WHITE_DAY" | "%future added value";
export type JobTaskType = "BAKING" | "BEVERAGE_MAKING" | "CLEANING" | "CONVENIENCE_STORE" | "ETC" | "HOUSEWORK" | "KIDS_PICK_UP" | "KITCHEN_ASSISTANCE" | "KITCHEN_COOK" | "LEAFLET_DISTRIBUTION" | "LIGHT_WORK" | "NURSING" | "SERVING" | "SHOP_MANAGEMENT" | "TUTORING" | "%future added value";
export type Welfare = "ANNUAL_LEAVE" | "HOLIDAY_WORKING_ALLOWANCE" | "INSURANCE" | "MEAL_PROVIDED" | "PAID_VACATION" | "REST_TIME_GUARANTEE" | "RETIREMENT_BENEFIT" | "%future added value";
export type WorkPeriod = "LESS_THAN_A_MONTH" | "MORE_THAN_A_MONTH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
import { stringifiedBringUpPassedDate as jobPostStringifiedBringUpPassedDateResolverType } from "../graphql/resolvers/JobPostStringifiedBringUpPassedDateResolver";
import { stringifiedSalary as jobPostStringifiedSalaryResolverType } from "../graphql/resolvers/JobPostStringifiedSalaryResolver";
import { stringifiedWorkDateAndDays as jobPostStringifiedWorkDateAndDaysResolverType } from "../graphql/resolvers/JobPostStringifiedWorkDatesAndDaysResolver";
import { stringifiedWorkTime as jobPostStringifiedWorkTimeResolverType } from "../graphql/resolvers/JobPostStringifiedWorkTimeResolver";
export type Info_jobPost$data = {
  readonly author: {
    readonly hoianUser?: {
      readonly _id: number;
    };
  };
  readonly closed: boolean;
  readonly companyName: string | null | undefined;
  readonly consideredQualifications: ReadonlyArray<ConsideredQualification> | null | undefined;
  readonly eventGroup: {
    readonly type: EventGroupType;
    readonly view: {
      readonly logo: {
        readonly fullImageUrl: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly jobTasks: ReadonlyArray<JobTaskType>;
  readonly lastBringUpDate: Dateable | null | undefined;
  readonly maskingTitle: string | null | undefined;
  readonly publishedAt: Dateable | null | undefined;
  readonly stringifiedBringUpPassedDate: string | null | undefined;
  readonly stringifiedSalary: string | null | undefined;
  readonly stringifiedWorkDateAndDays: string | null | undefined;
  readonly stringifiedWorkTime: string | null | undefined;
  readonly unMaskingTitle?: string | null | undefined;
  readonly welfare: ReadonlyArray<Welfare> | null | undefined;
  readonly workPeriod: WorkPeriod | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"InfoWorkDatesCalendar_jobPost">;
  readonly " $fragmentType": "Info_jobPost";
};
export type Info_jobPost$key = {
  readonly " $data"?: Info_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"Info_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isMasking"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Info_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": "maskingTitle",
      "args": [
        {
          "kind": "Literal",
          "name": "masking",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(masking:true)"
    },
    {
      "condition": "isMasking",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "unMaskingTitle",
          "args": [
            {
              "kind": "Literal",
              "name": "masking",
              "value": false
            }
          ],
          "kind": "ScalarField",
          "name": "title",
          "storageKey": "title(masking:false)"
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastBringUpDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JobPostStringifiedSalaryResolver"
      },
      "kind": "RelayResolver",
      "name": "stringifiedSalary",
      "resolverModule": require('./../graphql/resolvers/JobPostStringifiedSalaryResolver').stringifiedSalary,
      "path": "stringifiedSalary"
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JobPostStringifiedBringUpPassedDateResolver"
      },
      "kind": "RelayResolver",
      "name": "stringifiedBringUpPassedDate",
      "resolverModule": require('./../graphql/resolvers/JobPostStringifiedBringUpPassedDateResolver').stringifiedBringUpPassedDate,
      "path": "stringifiedBringUpPassedDate"
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JobPostStringifiedWorkDatesAndDaysResolver"
      },
      "kind": "RelayResolver",
      "name": "stringifiedWorkDateAndDays",
      "resolverModule": require('./../graphql/resolvers/JobPostStringifiedWorkDatesAndDaysResolver').stringifiedWorkDateAndDays,
      "path": "stringifiedWorkDateAndDays"
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JobPostStringifiedWorkTimeResolver"
      },
      "kind": "RelayResolver",
      "name": "stringifiedWorkTime",
      "resolverModule": require('./../graphql/resolvers/JobPostStringifiedWorkTimeResolver').stringifiedWorkTime,
      "path": "stringifiedWorkTime"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consideredQualifications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "welfare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventGroup",
      "kind": "LinkedField",
      "name": "eventGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EventGroupView",
          "kind": "LinkedField",
          "name": "view",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EventGroupLogo",
              "kind": "LinkedField",
              "name": "logo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullImageUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "hoianUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InfoWorkDatesCalendar_jobPost"
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "168af77665597e06bbb97ff405151016";

export default node;
