/**
 * @generated SignedSource<<b9922f564938f6d6345ee0ae781d1f90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Day = "FRI" | "MON" | "SAT" | "SUN" | "THU" | "TUE" | "WED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobPostStringifiedWorkDatesAndDaysResolver$data = {
  readonly workDates: ReadonlyArray<Dateable> | null | undefined;
  readonly workDays: ReadonlyArray<Day> | null | undefined;
  readonly " $fragmentType": "JobPostStringifiedWorkDatesAndDaysResolver";
};
export type JobPostStringifiedWorkDatesAndDaysResolver$key = {
  readonly " $data"?: JobPostStringifiedWorkDatesAndDaysResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostStringifiedWorkDatesAndDaysResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostStringifiedWorkDatesAndDaysResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workDates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workDays",
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "ed26fbdc93340cf27acc13c448e69b92";

export default node;
