import React from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'
import { useFragment, graphql } from 'react-relay'

import { JobPostJobPost_jobPost$key } from '@src/__generated__/JobPostJobPost_jobPost.graphql'
import { JobPostJobPost_user$key } from '@src/__generated__/JobPostJobPost_user.graphql'
import storage from '@src/api/storage'
import CalloutAction from '@src/components/base/callout/CalloutAction'
import Divider from '@src/components/base/divider/Divider'
import ErrorBoundary from '@src/components/base/error/ErrorBoundary'
import ImageContainer from '@src/components/base/slider/ImageContainer'
import Spacer from '@src/components/base/Spacer'
import AddedContentByType from '@src/components/common/jobPost/AddedContentByType'
import ApplicantStatus from '@src/components/common/jobPost/ApplicantStatus'
import AuthorSection from '@src/components/common/jobPost/AuthorSection'
import Content from '@src/components/common/jobPost/Content'
import CountStatus from '@src/components/common/jobPost/CountStatus'
import Info from '@src/components/common/jobPost/Info'
import KakaoMap from '@src/components/common/jobPost/KakaoMap'
import KarrotMap from '@src/components/common/jobPost/KarrotMap'
import ReplaceToMain from '@src/components/ReplaceToMain'
import { MASKING_GUIDE_SHOWN } from '@src/constants/api'
import { PageCodeInfoProvider } from '@src/contexts/PageCodeInfoContext'
import use202307CampaignMarketing from '@src/hooks/use202307CampaignMarketing'
import { useCalcWalkingDistance } from '@src/hooks/useCalcWalkingDistance'
import { useGwangsanguUser } from '@src/hooks/useGwangsanguUser'
import useModalState from '@src/hooks/useModalState'
import { useAppNavigation } from '@src/hooks/useNavigator'
import { currentPositionSelector } from '@src/selectors/user'
import { styled } from '@src/stitches/stitches.config'

import JobPostFilterAlarmRegistration from './JobPostFilterAlarmRegistration'
import JobPostMaskingHalfview from './JobPostMaskingHalfview'

interface Props {
  jobPostRef: JobPostJobPost_jobPost$key
  userRef: JobPostJobPost_user$key
}

const JobPostJobPost: React.FCC<Props> = ({ jobPostRef, userRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment JobPostJobPost_jobPost on JobPost {
        id
        images {
          url
        }
        _author: author {
          ... on HoianUserAuthor {
            id
            hoianUser {
              _id
              id
            }
          }
        }
        eventGroup {
          type
        }
        maskingContent: content(masking: true)
        unMaskingContent: content(masking: false)
        maskingTitle: title(masking: true)
        unMaskingTitle: title(masking: false)
        # eslint-disable-next-line relay/unused-fields
        workplaceLocation {
          lat
          lng
        }
        ...AuthorSection_jobPost
        ...Info_jobPost
        ...Content_jobPost
        ...KakaoMap_jobPost
        ...KarrotMap_jobPost
        ...CountStatus_jobPost
        ...JobPostFilterAlarmRegistration_jobPost
        ...ApplicantStatus_jobPost
      }
    `,
    jobPostRef
  )

  const user = useFragment(
    graphql`
      fragment JobPostJobPost_user on User {
        newJobPostFilterSubscription {
          id
          isHolding
          deleted
        }
        ...JobPostFilterAlarmRegistration_user
      }
    `,
    userRef
  )
  const currentPosition = useSelector(currentPositionSelector)
  const { isWalkingDistance: walkingDistanceWithinTenMinutes } = useCalcWalkingDistance({
    positions: [currentPosition?.position, jobPost.workplaceLocation],
  })
  const [isMaskingHalfviewOpen, setMaskingHalfviewOpen, setMaskingHalfviewClose] = useModalState()
  const { isJobsMainShortcutVisibleDepth } = useAppNavigation()
  const { id, deleted, isHolding } = user?.newJobPostFilterSubscription ?? {}

  const [isCurrentSubscriptionTrigger, setIsCurrentSubscriptionTrigger] = React.useState(false)
  const isNewJobPostFilterSubscription = !!(id && !deleted && !isHolding)
  const isShowJobPostFilterAlarmRegistration = !isNewJobPostFilterSubscription || isCurrentSubscriptionTrigger

  const { isGwangsanguUser } = useGwangsanguUser(jobPost._author.hoianUser?._id)

  const isDaangnEvent = jobPost.eventGroup?.type === 'DAANGN_EVENT'
  const images = React.useMemo(() => {
    return jobPost.images?.map((image) => image.url) ?? []
  }, [jobPost.images])
  const isImageExist = images.length > 0
  const isMasking =
    jobPost.maskingContent !== jobPost.unMaskingContent || jobPost.maskingTitle !== jobPost.unMaskingTitle
  const isMaskingGuideShown = React.useMemo(
    () => storage.getItemFromLocal(MASKING_GUIDE_SHOWN),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMaskingHalfviewOpen]
  )
  const is202307Campaign = use202307CampaignMarketing()

  const handleCurrentSubscriptionTrigger = () => {
    setIsCurrentSubscriptionTrigger(true)
  }

  return (
    <PageCodeInfoProvider pageSectionCode="job_post">
      {isImageExist && <ImageContainer images={images} />}
      {isJobsMainShortcutVisibleDepth && <ReplaceToMain isTopOnDocument={false} />}
      <Section>
        <Info jobPostRef={jobPost} />
        <Spacer h={24} />
        <Content jobPostRef={jobPost} />
        <Spacer h={20} />
        {isGwangsanguUser && (
          <>
            <Divider />
            <Spacer h={20} />
            <AddedContentByType contentType="GWANGSANGU" />
            <Spacer h={32} />
          </>
        )}
        <CountStatus jobPostRef={jobPost} />
        {isMasking && !isMaskingGuideShown && (
          <>
            <Spacer h={20} />
            <CalloutAction
              onClick={setMaskingHalfviewOpen}
              content={
                <>
                  차별 없고 안전한 동네 일자리를 위해 고용차별 키워드 혹은 개인 연락처가 <strong>별표(*)</strong>로
                  가려졌어요.
                </>
              }
              type="link"
            />
          </>
        )}
        {!is202307Campaign && !isDaangnEvent && (
          <>
            <Spacer h={20} />
            <Divider />
            <Spacer h={20} />
            <ApplicantStatus jobPostRef={jobPost} />
          </>
        )}

        {!isDaangnEvent && (
          <>
            <Spacer h={20} />
            <Divider />
            <Spacer h={20} />
            <LazyLoadComponent>
              {walkingDistanceWithinTenMinutes ? (
                <ErrorBoundary fallback={<KarrotMap jobPostRef={jobPost} />}>
                  <KakaoMap jobPostRef={jobPost} />
                </ErrorBoundary>
              ) : (
                <ErrorBoundary fallback={<KakaoMap jobPostRef={jobPost} />}>
                  <KarrotMap jobPostRef={jobPost} />
                </ErrorBoundary>
              )}
            </LazyLoadComponent>
          </>
        )}
        {isShowJobPostFilterAlarmRegistration && (
          <>
            <Spacer h={20} />
            <Divider />
            <Spacer h={24} />
            <JobPostFilterAlarmRegistration
              jobPostRef={jobPost}
              userRef={user}
              onSubscriptionTrigger={handleCurrentSubscriptionTrigger}
            />
          </>
        )}
      </Section>

      <Section>
        <AuthorSection jobPostRef={jobPost} />
      </Section>

      <JobPostMaskingHalfview isOpen={isMaskingHalfviewOpen} onCloseClick={setMaskingHalfviewClose} />
    </PageCodeInfoProvider>
  )
}

export default JobPostJobPost

const Section = styled('div', {
  padding: '20px 16px',
  borderBottom: '8px solid $gray100',

  '&:last-of-type': {
    border: 'none',
  },
})
