import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { JobPostCardPersonal_jobPost$key } from '@src/__generated__/JobPostCardPersonal_jobPost.graphql'
import Image from '@src/components/base/image/Image'
import TagGroup from '@src/components/base/tag/TagGroup'
import { IMAGE_JOB_POST_IMAGE_CARD_DEFAULT } from '@src/constants/images'
import { useJobPostExpired } from '@src/hooks/useJobPostExpired'
import { useNavigator } from '@src/hooks/useNavigator'
import { JobsActivity } from '@src/router/activityNames'
import { styled } from '@src/stitches/stitches.config'
import { textEllipsis } from '@src/stitches/text'

interface Props {
  logger?: () => void
  jobPostRef: JobPostCardPersonal_jobPost$key
}

const JobPostCardPersonal: React.FCC<Props> = ({ jobPostRef, logger }) => {
  const jobPost = useFragment(
    graphql`
      fragment JobPostCardPersonal_jobPost on JobPost {
        id
        companyName
        title
        stringifiedSalary
        images {
          thumbnail
        }
        # eslint-disable-next-line relay/unused-fields
        closed
        workplaceRegion {
          name3
        }
      }
    `,
    jobPostRef
  )
  const { push } = useNavigator()
  const isExpired = useJobPostExpired(jobPost)
  const thumbnail = jobPost.images?.[0]?.thumbnail

  const handleClick = () => {
    logger?.()
    push(JobsActivity.JOB_POST_AD, { jobPostId: jobPost.id })
  }

  return (
    <Container onClick={handleClick}>
      <ImageWrapper>
        <Image
          border={true}
          lazyLoad={true}
          width={72}
          height={72}
          radius={5}
          source={thumbnail ?? IMAGE_JOB_POST_IMAGE_CARD_DEFAULT}
        />
      </ImageWrapper>
      <ContentContainer>
        <Title>{jobPost.title}</Title>
        <HeadlineInfo>
          {isExpired ? <Expired>마감</Expired> : null}
          <TagGroup size="small" items={[jobPost.workplaceRegion?.name3, jobPost.companyName]} />
        </HeadlineInfo>
        <SalaryText>{jobPost.stringifiedSalary}</SalaryText>
      </ContentContainer>
    </Container>
  )
}

export default JobPostCardPersonal

const Container = styled('article', {
  display: 'flex',
  overflow: 'hidden',
  padding: '16px',
  border: '1px solid $divider1-semantic',
  borderRadius: '6px',
  flexShrink: 0,
  fontSize: 0,
  margin: '0 0 0 12px',

  '&:first-of-type': {
    margin: 0,
  },
})

const ImageWrapper = styled('div', {
  margin: '0 16px 0 0',
})

const ContentContainer = styled('div', {
  overflow: 'hidden',
})

const HeadlineInfo = styled('div', {
  display: 'flex',
  alignItems: 'center',
  margin: '4px 0 0',
})

const Expired = styled('span', {
  flex: 'none',
  display: 'inline-block',
  margin: '0 4px 0 0',
  padding: '2px 4px',
  borderRadius: '2px',
  background: '$gray700',
  color: '$gray00',
  fontSize: '10px',
  fontWeight: 'bold',
  lineHeight: '120%',
  letterSpacing: '-0.02em',
})

const Title = styled('div', textEllipsis, {
  $text: 'subtitle1Bold',
  color: '$gray900',
})

const SalaryText = styled('div', {
  margin: '4px 0 0',
  $text: 'label3Bold',
  color: '$gray700',
  fontWeight: 'bold',
})
