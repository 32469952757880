import React from 'react'

import { useJobsActivityParams } from '@src/hooks/useJobsActivityParams'
import { useNavigator } from '@src/hooks/useNavigator'
import { captureMessage } from '@src/sdks/sentry'

import { JobsActivity } from './activityNames'

interface Props {
  resource?: string
}

const RedirectNotFoundPage: React.FCC<Props> = ({ resource }) => {
  const { replace } = useNavigator()
  const querystring = useJobsActivityParams<typeof JobsActivity.NOT_FOUND>()

  React.useEffect(() => {
    captureMessage("Redirected to 'Not Found' page", {
      extra: {
        resource,
        pathname: location.pathname,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    replace(JobsActivity.NOT_FOUND, { resource: resource ?? '', ...querystring })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default RedirectNotFoundPage
