import { useActivityParams } from '@stackflow/react'

import { MARKETING_CAMPAIGN_APPLICANT_202307_JOB_POST_IDS } from '@src/constants/campaign'

export default () => {
  const { jobPostId } = useActivityParams<{ jobPostId: string }>()
  const is202307Campaign = MARKETING_CAMPAIGN_APPLICANT_202307_JOB_POST_IDS.includes(jobPostId)

  return is202307Campaign
}
