import { AlertDialog, DialogContainer, SeedAlertDialogProps } from '@daangn/sprout-components-dialog'
import React from 'react'

type DialogPrimaryAction = {
  label: string
  onClick: () => void
}

type DialogSecondaryAction = {
  intent?: SeedAlertDialogProps['secondaryActionIntent']
  label: string
  onClick: () => void
}

type Props = {
  open: boolean
  buttons: [DialogSecondaryAction, DialogPrimaryAction] | [DialogPrimaryAction]
  title?: SeedAlertDialogProps['title']
  description?: SeedAlertDialogProps['description']
  onClose?: () => void
  onClosed?: () => void
}

const Dialog: React.FCC<Props> = ({ open, title, description = '', buttons, onClose, onClosed }) => {
  const dialogActionProps = React.useMemo(() => {
    const primaryButton = buttons.length === 2 ? buttons[1] : buttons[0]
    const secondaryButton = buttons.length === 2 ? buttons[0] : ({} as DialogSecondaryAction)
    const { label: primaryActionLabel, onClick: onPrimaryAction } = primaryButton
    const {
      label: secondaryActionLabel,
      onClick: onSecondaryAction,
      intent: secondaryActionIntent = 'neutral',
    } = secondaryButton

    return { primaryActionLabel, onPrimaryAction, secondaryActionLabel, onSecondaryAction, secondaryActionIntent }
  }, [buttons])

  if (!open) return null

  return (
    <DialogContainer
      onOutsideClick={onClose}
      onOpenChange={(isOpen) => {
        !isOpen && onClosed?.()
      }}>
      <AlertDialog title={title} description={description} {...dialogActionProps} />
    </DialogContainer>
  )
}

export default Dialog
