import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@src/ducks/rootReducer'

export const devToolSelector = createSelector(
  (state: RootState) => state.devTool,
  (d) => d
)

export const segmentsSelector = createSelector(devToolSelector, (d) => d.segments)

export const sampleGroupsSelector = createSelector(devToolSelector, (d) => d.sampleGroups)
