/**
 * @generated SignedSource<<852dde8c3590a23efe65e6ce4aac1a62>>
 * @relayHash 9a88c6c21b33793f3429465ea7c9c9a7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9a88c6c21b33793f3429465ea7c9c9a7

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportJobReviewWhereInput = {
  jobReview: string;
};
export type useReportJobReviewMutationMutation$variables = {
  where: ReportJobReviewWhereInput;
};
export type useReportJobReviewMutationMutation$data = {
  readonly reportJobReview: {
    readonly jobReview: {
      readonly id: string;
    } | null | undefined;
  };
};
export type useReportJobReviewMutationMutation = {
  response: useReportJobReviewMutationMutation$data;
  variables: useReportJobReviewMutationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "ReportJobReviewResult",
    "kind": "LinkedField",
    "name": "reportJobReview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JobReview",
        "kind": "LinkedField",
        "name": "jobReview",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useReportJobReviewMutationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useReportJobReviewMutationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "9a88c6c21b33793f3429465ea7c9c9a7",
    "metadata": {},
    "name": "useReportJobReviewMutationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "590f2a9ea2910ba3201903a686a6ae69";

export default node;
