import authPlugin from '@daangn/plantae-plugin-auth'
import type { AxiosInstance } from 'axios'

import { AUTH_TOKEN, IS_DEV_NATIVE, IS_NATIVE } from '@src/config'

import { karrotBridge } from './bridge'

export const getPlantaeConfig = <T extends typeof fetch | AxiosInstance | undefined>(client: T) => {
  return {
    client,
    plugins: IS_DEV_NATIVE
      ? []
      : [
          authPlugin({
            bridge: karrotBridge,
            options: {
              fallbackAuthToken: IS_NATIVE || {
                value: AUTH_TOKEN,
              },
            },
          }),
        ],
  }
}
