import React from 'react'

import JobsAppScreen from '@src/components/base/stackflow/JobsAppScreen'
import { useNavigator } from '@src/hooks/useNavigator'
import { styled } from '@src/stitches/stitches.config'

import ErrorPage from './ErrorPage'

interface Props {
  title?: string
  messages?: string[]
  buttonMessage?: string
  onButtonClick?: () => void
  children?: React.ReactNode
}

const ScreenErrorPage: React.FCC<Props> = ({
  title,
  messages,
  buttonMessage = '뒤로가기',
  children,
  onButtonClick,
}) => {
  const { pop } = useNavigator()

  const handleButtonClick = React.useCallback(() => {
    ;(onButtonClick || pop)()
  }, [pop, onButtonClick])

  return (
    <JobsAppScreen appBar={{ title: '당근알바' }}>
      <Base>
        <ErrorPage
          showButton
          onClickButton={handleButtonClick}
          buttonMessage={buttonMessage}
          title={title}
          messages={messages}>
          {children}
        </ErrorPage>
      </Base>
    </JobsAppScreen>
  )
}

const Base = styled('div', {
  height: '100%',
  position: 'relative',
  background: '$paperDefault-semantic',
})

export default ScreenErrorPage
