import { ValueOf } from 'type-fest'

export const INVITE_EVENT_KIND = {
  MARKETING_INVITE_REGULAR: 'MARKETING_INVITE_REGULAR',
  MARKETING_TOTALCARE_REGULAR: 'MARKETING_TOTALCARE_REGULAR',
  GWANGSANGU_CITIZEN_SUPPORT: 'GWANGSANGU_CITIZEN_SUPPORT',
  MARKETING_CHUSEOK_202309_AUTHOR: 'MARKETING_CHUSEOK_202309_AUTHOR',
  MARKETING_CHUSEOK_202309_APPLICANT: 'MARKETING_CHUSEOK_202309_APPLICANT',
  MARKETING_CREATE_JOB_POST_202310: 'MARKETING_CREATE_JOB_POST_202310',
  FLY_202310: 'FLY_202310',
  MARKETING_NEW_SEMESTER_202403_AUTHOR: 'MARKETING_NEW_SEMESTER_202403_AUTHOR',
  MARKETING_NEW_SEMESTER_202403_APPLICANT: 'MARKETING_NEW_SEMESTER_202403_APPLICANT',
  MARKETING_TOTALCARE_202403: 'MARKETING_TOTALCARE_202403',
  MARKETING_BALANCE_GAME_202404a: 'MARKETING_BALANCE_GAME_202404a',
  MARKETING_BALANCE_GAME_202404b: 'MARKETING_BALANCE_GAME_202404b',
  MARKETING_BALANCE_GAME_202405: 'MARKETING_BALANCE_GAME_202405',
  MARKETING_INVITE_202404: 'MARKETING_INVITE_202404',
  MARKETING_APPLICANT_WARRANTY_202406: 'MARKETING_APPLICANT_WARRANTY_202406',
  MARKETING_PROMOTION_202405: 'MARKETING_PROMOTION_202405',
} as const

export type INVITE_EVENT_KIND_VALUES = ValueOf<typeof INVITE_EVENT_KIND>
