import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setIsGlobalLoaderOpen } from '@src/ducks/app'
import { isGlobalLoaderOpenSelector } from '@src/selectors/app'

export const useGlobalLoader = () => {
  const isGlobalLoaderOpen = useSelector(isGlobalLoaderOpenSelector)
  const dispatch = useDispatch()

  const setGlobalLoaderOpen = React.useCallback(() => {
    dispatch(setIsGlobalLoaderOpen(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const setGlobalLoaderClose = React.useCallback(() => {
    dispatch(setIsGlobalLoaderOpen(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [isGlobalLoaderOpen, setGlobalLoaderOpen, setGlobalLoaderClose] as const
}
