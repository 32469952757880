/**
 * @generated SignedSource<<bb5aaa99224d445ec736a30b647ae06c>>
 * @relayHash 93f5faa4ea3f388678942267df699d83
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 93f5faa4ea3f388678942267df699d83

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteWatchWhereInput = {
  _id: string;
};
export type useWatchMutationDeleteMutation$variables = {
  where: DeleteWatchWhereInput;
};
export type useWatchMutationDeleteMutation$data = {
  readonly deleteWatch: {
    readonly watch: {
      readonly deleted: boolean;
      readonly id: string;
      readonly target: {
        readonly myWatch?: {
          readonly _id: string;
          readonly id: string;
        } | null | undefined;
      } | null | undefined;
    };
  };
};
export type useWatchMutationDeleteMutation = {
  response: useWatchMutationDeleteMutation$data;
  variables: useWatchMutationDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Watch",
      "kind": "LinkedField",
      "name": "myWatch",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useWatchMutationDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteWatchResult",
        "kind": "LinkedField",
        "name": "deleteWatch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Watch",
            "kind": "LinkedField",
            "name": "watch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "target",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useWatchMutationDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteWatchResult",
        "kind": "LinkedField",
        "name": "deleteWatch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Watch",
            "kind": "LinkedField",
            "name": "watch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "target",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "93f5faa4ea3f388678942267df699d83",
    "metadata": {},
    "name": "useWatchMutationDeleteMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "fe3ebf0ec3a374fb5bb649cea0ad524b";

export default node;
