import React from 'react'
import { useSelector } from 'react-redux'
import { graphql, useFragment } from 'react-relay'

import { JobPostFooter_jobPost$key } from '@src/__generated__/JobPostFooter_jobPost.graphql'
import { JobPostFooter_user$key } from '@src/__generated__/JobPostFooter_user.graphql'
import Button from '@src/components/base/buttons/Button'
import Footer from '@src/components/base/footer/Footer'
import { usePhoneMappingMutation } from '@src/mutations/usePhoneMappingMutation'
import { toast } from '@src/sdks/bridge'
import { isLoginSelector } from '@src/selectors/user'
import { styled } from '@src/stitches/stitches.config'
import { calcJobPostExpired } from '@src/utils/jobPost'

import JobPostFooterApplyButton from './JobPostFooterApplyButton'
import JobPostFooterLikeButton from './JobPostFooterLikeButton'

interface Props {
  jobPostRef: JobPostFooter_jobPost$key
  userRef: Nullable<JobPostFooter_user$key>
}

const JobPostFooter: React.FCC<Props> = ({ jobPostRef, userRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment JobPostFooter_jobPost on JobPost {
        _id
        # eslint-disable-next-line relay/unused-fields
        closed
        phone
        isPhoneContactable
        myApplication {
          canceled
          deleted
        }
        ...JobPostFooterLikeButton_jobPost
        ...JobPostFooterApplyButton_jobPost
      }
    `,
    jobPostRef
  )
  const user = useFragment(
    graphql`
      fragment JobPostFooter_user on User {
        ...JobPostFooterLikeButton_user
        ...JobPostFooterApplyButton_user
      }
    `,
    userRef
  )
  const { mutateWithCallbackByApplicant: handlePhoneMapping } = usePhoneMappingMutation()
  const isLogin = useSelector(isLoginSelector)
  const isApplied = !!jobPost.myApplication && !jobPost.myApplication.canceled && !jobPost.myApplication.deleted
  const isExpired = calcJobPostExpired(jobPost)
  const isPhoneApproved = !!(jobPost.phone && jobPost.isPhoneContactable)
  const showLoginMessage = () => {
    toast('해당기능을 사용하시려면 로그인이 필요해요')
  }

  const handlePhoneClick = () => {
    if (!jobPost) return

    if (!isLogin) {
      showLoginMessage()
      return
    }

    handlePhoneMapping({
      jobPostId: jobPost._id,
    })
  }

  return (
    <FooterContainer noPadding noBorder>
      <BottomArea>
        <JobPostFooterLikeButton jobPostRef={jobPost} userRef={user ?? null} />

        {isExpired ? (
          isApplied ? (
            <JobPostFooterApplyButton jobPostRef={jobPost} userRef={user ?? null} />
          ) : (
            <ApplyExpiredButton />
          )
        ) : (
          <ButtonsContainer>
            <CallButton isPhoneApproved={isPhoneApproved} onPhoneClick={handlePhoneClick} />
            <JobPostFooterApplyButton jobPostRef={jobPost} userRef={user ?? null} />
          </ButtonsContainer>
        )}
      </BottomArea>
    </FooterContainer>
  )
}

export default JobPostFooter

const CallButton = ({ isPhoneApproved, onPhoneClick }: { isPhoneApproved: boolean; onPhoneClick: () => void }) => {
  return isPhoneApproved ? (
    <StyledButton priority="primaryLow" size="xlarge" onClick={onPhoneClick}>
      전화문의
    </StyledButton>
  ) : null
}

const ApplyExpiredButton = () => {
  return (
    <Button priority="primary" size="xlarge" disabled={true}>
      구인마감
    </Button>
  )
}

const FooterContainer = styled(Footer, {})

const BottomArea = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '12px 16px 8px',
})

const ButtonsContainer = styled('div', {
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
})

const StyledButton = styled(Button, {
  paddingLeft: 0,
  paddingRight: 0,

  [`&:nth-of-type(n+2)`]: {
    margin: '0 0 0 8px',
  },
})
