import React from 'react'

import InlineStyleLoader from '@src/components/base/loader/InlineStyleLoader'
import { StitchesCSS, styled } from '@src/stitches/stitches.config'
import { textEllipsis } from '@src/stitches/text'

export const withLoading = <P extends React.ComponentProps<typeof Button>>(
  Component: React.FCC<Omit<P, 'isLoading' | 'children'>>
) => {
  return React.forwardRef(({ isLoading, children, ...props }: P, ref) => {
    const [isLoadingState, setIsLoadingState] = React.useState(false)

    React.useEffect(() => {
      if (isLoading) {
        const timerId = setTimeout(() => {
          setIsLoadingState(true)
        }, 300)
        return () => {
          timerId && clearTimeout(timerId)
        }
      }
      setIsLoadingState(false)
    }, [isLoading])

    return (
      <Component {...props} ref={ref} isLoading={isLoadingState}>
        {children}
        {isLoadingState && <Loader isOpen={true} size={14} />}
      </Component>
    )
  })
}

const Loader = styled(InlineStyleLoader, {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const ButtonElement: React.FCC<JSX.IntrinsicElements['button']> = ({ children, type = 'button', ...props }) => (
  <button type={type} {...props}>
    {children}
  </button>
)

const Button = styled(ButtonElement, textEllipsis, {
  position: 'relative',
  display: 'block',
  width: '100%',
  flex: 1,
  borderRadius: '6px',
  color: '$gray900',
  transition: 'background 0.2s ease-out',

  '& > *': {
    verticalAlign: 'middle',
  },

  variants: {
    stretch: {
      false: {
        width: 'initial',
        flex: 'initial',
      },
    },

    size: {
      xlarge: {
        $text: 'label1Bold',
        padding: '0.8625rem 1.25rem',
      },
      large: {
        $text: 'label3Bold',
        padding: '0.875rem 1.25rem',
      },
      medium: {
        $text: 'label3Bold',
        padding: '0.5625rem 1rem',
      },
      small: {
        $text: 'label3Bold',
        padding: '0.5rem 0.875rem',
      },
      xsmall: {
        $text: 'label4Bold',
        padding: '0.25rem 0.625rem',
      },
    },

    priority: {
      primary: {
        background: '$primary-semantic',
        ...activeBackground('$primaryPressed-semantic'),
        color: '$onPrimary-semantic',
      },
      primaryLow: {
        background: '$primaryLow-semantic',
        ...activeBackground('$primaryLowPressed-semantic'),
        color: '$primary-semantic',
      },
      secondary: {
        background: '$gray100',
        ...activeBackground('$gray300'),
        color: '$gray900',
      },
      text: {
        color: '$gray700',
        ...activeBackground('$grayPressed-semantic'),
      },
    },

    disabled: {
      true: {
        pointerEvents: 'none',
      },
      false: {},
    },

    isLoading: {
      true: {
        position: 'relative',
        '& > svg': {
          opacity: 0,
        },
        '& > div': {
          opacity: 0,
        },
      },
    },
  },

  compoundVariants: generateCompoundVariants(),

  defaultVariants: {
    size: 'medium',
    priority: 'secondary',
  },
})

function generateCompoundVariants() {
  const priorities = ['primary', 'primaryLow', 'secondary', 'text'] as const

  return priorities.map((priority) => ({
    priority,
    disabled: true,
    css: {
      background: '$gray300',
      color: '$gray500',
    },
  }))
}

function activeBackground(backgroundColor: StitchesCSS['background']) {
  return {
    '&:active': {
      background: backgroundColor,
    },
  }
}

export default Button
