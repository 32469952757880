/**
 * @generated SignedSource<<9b53f4474d395e396c21bf57f1d593a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectedReviewItemList_user$data = {
  readonly receivedJobReviewsExtend: {
    readonly countBySelectedItemCode: ReadonlyArray<{
      readonly code: string;
      readonly count: number;
    }>;
  };
  readonly " $fragmentType": "SelectedReviewItemList_user";
};
export type SelectedReviewItemList_user$key = {
  readonly " $data"?: SelectedReviewItemList_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectedReviewItemList_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "evaluator"
    },
    {
      "defaultValue": [
        "GREAT",
        "GOOD"
      ],
      "kind": "LocalArgument",
      "name": "rating"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectedReviewItemList_user",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "evaluator",
              "variableName": "evaluator"
            },
            {
              "kind": "Variable",
              "name": "rating",
              "variableName": "rating"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "ReceivedJobReviewsExtend",
      "kind": "LinkedField",
      "name": "receivedJobReviewsExtend",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CountBySelectedItemCode",
          "kind": "LinkedField",
          "name": "countBySelectedItemCode",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "db0f74c1103ce3e9f1893968e4f16e88";

export default node;
