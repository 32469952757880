import React from 'react'

import Image from '@src/components/base/image/Image'
import { IMAGE_JOBS_COLORED } from '@src/constants/images'
import { styled } from '@src/stitches/stitches.config'

const Splash: React.FCC = () => {
  return (
    <SplashContainer>
      <SplashImage source={IMAGE_JOBS_COLORED} />
    </SplashContainer>
  )
}

export default Splash

const SplashContainer = styled('div', {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const SplashImage = styled(Image, {
  width: '40px',
  height: '40px',
})

export const RemoveSplash: React.FCC = () => {
  React.useLayoutEffect(() => {
    // Remove Dom object that id is 'splash'
    const splash = document.getElementById('splash')
    if (splash) {
      splash.remove()
    }
  }, [])

  return null
}
