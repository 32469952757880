import React from 'react'

import { styled } from '@src/stitches/stitches.config'

type Props = Omit<JSX.IntrinsicElements['div'], 'ref'> &
  React.ComponentProps<typeof Compo> & {
    h?: number
  }

const Divider: React.FCC<Props> = ({ h, ...props }) => {
  return <Compo css={h ? { borderWidth: `${h}px` } : undefined} {...props} />
}

export default Divider

const Compo = styled('div', {
  borderBottom: '1px solid $divider1-semantic',

  variants: {
    line: {
      3: {
        borderColor: '$divider3-semantic',
      },
    },
  },
})
