import { INVITE_EVENT_KIND, INVITE_EVENT_KIND_VALUES } from './invite'

export const MARKETING_INVITE_REGULAR = INVITE_EVENT_KIND.MARKETING_INVITE_REGULAR.toLowerCase()
export const MARKETING_TOTALCARE_REGULAR = INVITE_EVENT_KIND.MARKETING_TOTALCARE_REGULAR.toLowerCase()
export const GWANGSANGU_CITIZEN_SUPPORT = INVITE_EVENT_KIND.GWANGSANGU_CITIZEN_SUPPORT
export const MARKETING_CHUSEOK_202309_AUTHOR = INVITE_EVENT_KIND.MARKETING_CHUSEOK_202309_AUTHOR.toLowerCase()
export const MARKETING_CHUSEOK_202309_APPLICANT = INVITE_EVENT_KIND.MARKETING_CHUSEOK_202309_APPLICANT.toLowerCase()
export const MARKETING_CREATE_JOB_POST_202310 = INVITE_EVENT_KIND.MARKETING_CREATE_JOB_POST_202310.toLowerCase()
export const FLY_202310 = INVITE_EVENT_KIND.FLY_202310.toLowerCase()
export const MARKETING_NEW_SEMESTER_202403_AUTHOR = INVITE_EVENT_KIND.MARKETING_NEW_SEMESTER_202403_AUTHOR.toLowerCase()
export const MARKETING_NEW_SEMESTER_202403_APPLICANT =
  INVITE_EVENT_KIND.MARKETING_NEW_SEMESTER_202403_APPLICANT.toLowerCase()
export const MARKETING_TOTALCARE_202403 = INVITE_EVENT_KIND.MARKETING_TOTALCARE_202403.toLowerCase()
export const MARKETING_BALANCE_GAME_202404a = INVITE_EVENT_KIND.MARKETING_BALANCE_GAME_202404a.toLowerCase()
export const MARKETING_BALANCE_GAME_202404b = INVITE_EVENT_KIND.MARKETING_BALANCE_GAME_202404b.toLowerCase()
export const MARKETING_BALANCE_GAME_202405 = INVITE_EVENT_KIND.MARKETING_BALANCE_GAME_202405.toLowerCase()
export const MARKETING_INVITE_202404 = INVITE_EVENT_KIND.MARKETING_INVITE_202404.toLowerCase()
export const MARKETING_APPLICANT_WARRANTY_202406 = INVITE_EVENT_KIND.MARKETING_APPLICANT_WARRANTY_202406.toLowerCase()
export const MARKETING_PROMOTION_202405 = INVITE_EVENT_KIND.MARKETING_PROMOTION_202405.toLowerCase()

export const INVITE_EVENT_KIND_REFERRER_MAP: { [key in INVITE_EVENT_KIND_VALUES]: string } = {
  MARKETING_INVITE_REGULAR,
  MARKETING_TOTALCARE_REGULAR,
  GWANGSANGU_CITIZEN_SUPPORT,
  MARKETING_CHUSEOK_202309_AUTHOR,
  MARKETING_CHUSEOK_202309_APPLICANT,
  MARKETING_CREATE_JOB_POST_202310,
  FLY_202310,
  MARKETING_NEW_SEMESTER_202403_AUTHOR,
  MARKETING_NEW_SEMESTER_202403_APPLICANT,
  MARKETING_TOTALCARE_202403,
  MARKETING_BALANCE_GAME_202405,
  MARKETING_BALANCE_GAME_202404a,
  MARKETING_BALANCE_GAME_202404b,
  MARKETING_INVITE_202404,
  MARKETING_APPLICANT_WARRANTY_202406,
  MARKETING_PROMOTION_202405,
}

export const KOTISAARI = 'kotisaari'
