import { IconCheckFill, IconMoneyWonFill, IconOrderFill } from '@seed-design/icon'
import React from 'react'

import { styled, theme } from '@src/stitches/stitches.config'

type Props = {
  contentType: 'GWANGSANGU'
}

const AddedContentByType: React.FCC<Props> = ({ contentType }) => {
  if (contentType === 'GWANGSANGU') {
    return (
      <Container>
        <Section>
          <TitleContainer>
            <TitleIconWrapper>
              <IconCheckFill width={18} height={18} color={theme.colors.gray600.computedValue} />
            </TitleIconWrapper>
            <Title>지원 안내</Title>
          </TitleContainer>
          <List>
            <ListItem>채용은 선착순으로 이루어져요.</ListItem>
            <ListItem>주민등록상 거주지가 광산구인 주민만 지원할 수 있어요.</ListItem>
            <ListItem>채용확정 후 채용기관 방문시 필수 서류를 지참해주세요.</ListItem>
            <ListItem>광산구참여자 모집 운영은 평일 9시부터 18시까지에요.</ListItem>
            <ListItem>
              필요에 따라 각 기관에서 범죄경력을 조회할 수 있어요.
              <SubDescription>
                「아동·청소년의 성 보호에 관한 법률」 제56조 및 「노인복지법」 제39조 근거, 노인 및 아동 관련시설 근로시
                시설 범죄경력회보서 조회가 반드시 필요해요
              </SubDescription>
              <SubDescription>따라서, 각 공공시설에서 범죄경력을 조회할 수 있어요.</SubDescription>
            </ListItem>
          </List>
        </Section>

        <Section>
          <TitleContainer>
            <TitleIconWrapper>
              <IconOrderFill width={18} height={18} color={theme.colors.gray600.computedValue} />
            </TitleIconWrapper>
            <Title>필수 지참 서류</Title>
          </TitleContainer>
          <List>
            <ListItem>신분증</ListItem>
            <ListItem>주민등록 등본 or 초본(6개월 이내 발급)</ListItem>
            <ListItem>통장사본 (본인명의)</ListItem>
          </List>
        </Section>

        <Section>
          <TitleContainer>
            <TitleIconWrapper>
              <IconMoneyWonFill width={18} height={18} color={theme.colors.gray600.computedValue} />
            </TitleIconWrapper>
            <Title>지급요건 및 시기</Title>
          </TitleContainer>
          <List>
            <ListItem>2024년 생활임금 12,760원(시급)을 적용해요.</ListItem>
            <ListItem>
              보건복지부 고시 1인가구 생계급여 한도에 따라 월 상한액 713,102원 / 월 55시간 이내로 참여 가능해요.
            </ListItem>
            <ListItem>참여자 1인당 사업기간 내 최대 100시간까지 가능해요.</ListItem>
            <ListItem>참여 후 익월 10일이내 본인명의 통장으로 지급되어요.</ListItem>
          </List>
        </Section>
      </Container>
    )
  }

  return null
}

export default AddedContentByType

const Container = styled('div', {})

const Section = styled('div', {
  '& + &': {
    margin: '32px 0 0',
  },
})

const TitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const TitleIconWrapper = styled('div', {
  width: '18px',
  height: '18px',
  color: '$gray900',
})

const Title = styled('div', {
  $text: 'subtitle1Bold',
  margin: '0 0 0 8px',
})

const List = styled('ul', {
  listStyleType: 'disc',
  margin: '10px 0 0',
  padding: '0 0 0 20px',
})

const ListItem = styled('li', {
  $text: 'bodyL2Regular',

  '& + &': {
    margin: '8px 0 0',
  },
})

const SubDescription = styled('div', {
  margin: '10px 0 0',
  color: '$gray600',
  $text: 'caption1Regular',
})
