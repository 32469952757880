/**
 * @generated SignedSource<<bd614f0c016d9bd9fdce46620c08caa6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type JobTaskType = "BAKING" | "BEVERAGE_MAKING" | "CLEANING" | "CONVENIENCE_STORE" | "ETC" | "HOUSEWORK" | "KIDS_PICK_UP" | "KITCHEN_ASSISTANCE" | "KITCHEN_COOK" | "LEAFLET_DISTRIBUTION" | "LIGHT_WORK" | "NURSING" | "SERVING" | "SHOP_MANAGEMENT" | "TUTORING" | "%future added value";
export type WorkPeriod = "LESS_THAN_A_MONTH" | "MORE_THAN_A_MONTH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobPostPersonalPosts_query$data = {
  readonly me: {
    readonly resume: {
      readonly birthYear: number | null | undefined;
      readonly name: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly standardJobPost: {
    readonly jobTasks: ReadonlyArray<JobTaskType>;
    readonly workPeriod: WorkPeriod | null | undefined;
    readonly workplaceRegion: {
      readonly _id: number;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"JobPostCardPersonal_jobPost">;
  } | null | undefined;
  readonly " $fragmentType": "JobPostPersonalPosts_query";
};
export type JobPostPersonalPosts_query$key = {
  readonly " $data"?: JobPostPersonalPosts_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostPersonalPosts_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostPersonalPosts_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Resume",
          "kind": "LinkedField",
          "name": "resume",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "birthYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "standardJobPost",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": "JobPost",
      "kind": "LinkedField",
      "name": "jobPostById",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "workplaceRegion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workPeriod",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTasks",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "JobPostCardPersonal_jobPost"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8a2d2a2da3a2655ca595a78a78332166";

export default node;
