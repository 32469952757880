import { Portal } from '@daangn/karrot-clothes'
import React from 'react'

import storage from '@src/api/storage'
import { IS_PROD } from '@src/config'
import withDraggable from '@src/hocs/withDraggable'
import Falcon from '@src/packages/falcon-react'
import { styled } from '@src/stitches/stitches.config'

import { DevToolLibraryProvider } from './DevToolLibraryManager'
import DevToolsHalfView from './DevToolsHalfView'

const DevTools: React.FCC = () => {
  const isAdmin = storage.getIsAdmin()
  const [isDevToolsOpen, setIsDevToolsOpen] = React.useState(false)

  const isDesignAdmin = storage.getIsDesignAdmin()

  const handleClick = () => {
    setIsDevToolsOpen((prev) => !prev)
  }

  const handleCloseClick = () => {
    setIsDevToolsOpen(false)
  }

  if (IS_PROD && !isAdmin) return null

  return (
    <DevToolLibraryProvider>
      <Portal>
        <>
          <DevMenu initialBottom={200} onClick={handleClick}>
            DEV
          </DevMenu>
          {isDesignAdmin && <Falcon />}
        </>
      </Portal>

      <DevToolsHalfView isOpen={isDevToolsOpen} closeHandler={handleCloseClick} />
    </DevToolLibraryProvider>
  )
}

export default DevTools

const DevMenu = withDraggable(
  styled('button', {
    position: 'absolute',
    zIndex: '$global1',
    bottom: 0,
    right: 0,
    width: '60px',
    height: '40px',
    background: '$grayAlpha200',
    color: '$gray00',
    fontWeight: 'bold',
  })
)
