import React from 'react'

import storage from '@src/api/storage'
import { COUNT_SOMETHING } from '@src/constants/api'
import { areDateEqual, MS } from '@src/utils/date'

type Event = {
  date: Dateable
}

export default (
  eventName: string,
  {
    inTimeMilliseconds = MS.hour * 24,
    clearCountInterval = undefined,
  }:
    | {
        /**
         * @property The maximum number of milliseconds to count events.
         */
        inTimeMilliseconds?: number
        /**
         * @property The interval time unit to clear event count.
         */
        clearCountInterval?: 'day'
      }
    | undefined = {
    inTimeMilliseconds: MS.hour * 24,
    clearCountInterval: undefined,
  }
) => {
  const recordEventName = `${COUNT_SOMETHING}${eventName}`
  const inTimeEvents = React.useMemo(() => {
    const events = storage.getItemFromLocal<Event[]>(recordEventName) ?? []
    return events.filter((event) => {
      const eventDate = new Date(event.date)
      const now = new Date()

      if (clearCountInterval === 'day' && !areDateEqual(eventDate, now)) {
        return false
      }

      return now.getTime() - eventDate.getTime() < inTimeMilliseconds
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const updatedEvents = [...inTimeEvents, { date: new Date() }]
    storage.setItemAtLocal(recordEventName, updatedEvents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return inTimeEvents.length + 1
}
