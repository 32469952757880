/* eslint-disable @typescript-eslint/ban-types */

import { Middleware } from '@src/types/relay'

type Props = {
  url: Promise<string>
  headers?: { [name: string]: string }
}

// github.com/relay-tools/react-relay-network-modern/blob/master/src/middlewares/url.js

export const urlMiddleware = (opts?: Props): Middleware => {
  // eslint-disable-next-line no-console

  return (next) => async (req) => {
    req.url = opts?.url ? await opts.url : 'graphql'
    req.headers = opts?.headers ? { ...req.headers, ...opts.headers } : req.headers

    return next(req)
  }
}
