import {
  IconCalendarFill,
  IconConfirmationProfileFill,
  IconInfoRegular,
  IconLeafFill,
  IconMarketFill,
} from '@seed-design/icon'
import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { AuthorStats_jobPost$key } from '@src/__generated__/AuthorStats_jobPost.graphql'
import Image from '@src/components/base/image/Image'
import Tooltip, { TooltipContent } from '@src/components/base/tooltip/Tooltip'
import { IMAGE_NEW_AUTHOR_BANNER_ICON } from '@src/constants/images'
import { styled, theme } from '@src/stitches/stitches.config'
import { getCeilMonthDiff, isPastDateAddDaysLimit } from '@src/utils/date'

const NEW_AUTHOR_RANGE = 1

interface Props {
  jobPostRef: AuthorStats_jobPost$key
}

const AuthorStats: React.FCC<Props> = ({ jobPostRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment AuthorStats_jobPost on JobPost {
        businessLicense {
          __typename
        }
        identification {
          __typename
        }
        author {
          ... on HoianUserAuthor {
            hoianUser {
              promiseMinimumWage {
                promise
                lastReportedAt
              }
              author {
                applicationCount {
                  hire
                }
                reviewCount {
                  total
                }
                applicationResponse {
                  rate
                }
                roleAssignedAt
              }
            }
          }
        }
      }
    `,
    jobPostRef
  )

  const isIdentificationVerified = !!jobPost.identification
  const isBusinessRegistration = !!jobPost.businessLicense
  const responseRate = jobPost.author.hoianUser?.author?.applicationResponse?.rate
  const hireCount = jobPost.author.hoianUser?.author?.applicationCount?.hire
  const receivedReviewCount = jobPost.author.hoianUser?.author?.reviewCount?.total
  const roleAssignedAt = new Date(jobPost.author.hoianUser?.author?.roleAssignedAt ?? Date.now())
  const promise = jobPost.author.hoianUser?.promiseMinimumWage?.promise
  const lastReportedAt = jobPost.author.hoianUser?.promiseMinimumWage?.lastReportedAt
  const promiseMinimumWage =
    (promise &&
      (!lastReportedAt ||
        isPastDateAddDaysLimit({
          days: 365,
          targetDate: lastReportedAt,
        }))) ??
    false

  const monthDiff = getCeilMonthDiff(new Date(), roleAssignedAt)
  const isNewAuthor = monthDiff <= NEW_AUTHOR_RANGE

  return (
    <>
      {isNewAuthor && (
        <NewAuthorBanner>
          <NewAuthorBannerContent>
            <NewAuthorBannerPrimaryText>당근알바에서 첫 구인 중이에요</NewAuthorBannerPrimaryText>
            <NewAuthorBannerSecondaryText>이용이 다소 서툴더라도 너그럽게 이해해 주세요.</NewAuthorBannerSecondaryText>
          </NewAuthorBannerContent>
          <NewAuthorIconContainer>
            <NewAuthorIcon source={IMAGE_NEW_AUTHOR_BANNER_ICON} />
          </NewAuthorIconContainer>
        </NewAuthorBanner>
      )}

      {(!!hireCount || !!responseRate || !!receivedReviewCount) && (
        <StatResponseBanner>
          <StatResponseBannerContent>
            <StateResponseBannerPrimaryText>{hireCount}회</StateResponseBannerPrimaryText>
            <StatResponseBannerSecondaryText>채용 확정</StatResponseBannerSecondaryText>
          </StatResponseBannerContent>
          <StatResponseBannerContent>
            <StateResponseBannerPrimaryText>
              {responseRate ? (responseRate * 100).toFixed(0) : '- '}%
            </StateResponseBannerPrimaryText>
            <StatResponseBannerSecondaryText>알바 응답률</StatResponseBannerSecondaryText>
          </StatResponseBannerContent>
          <StatResponseBannerContent>
            <StateResponseBannerPrimaryText>{receivedReviewCount}개</StateResponseBannerPrimaryText>
            <StatResponseBannerSecondaryText>알바 후기</StatResponseBannerSecondaryText>
          </StatResponseBannerContent>
        </StatResponseBanner>
      )}

      {isIdentificationVerified && (
        <StatContainer>
          <IconConfirmationProfileFill width={16} height={16} color={theme.colors['green500'].computedValue} />
          <StatText>본인인증 완료</StatText>
          <Tooltip
            placement="bottom-start"
            arrowOffset={{ left: 5 }}
            offset={[-10, 10]}
            duration={3000}
            content={<TooltipContent>휴대폰 본인인증 절차를 통과한 구인자예요.</TooltipContent>}
            preventOverflow>
            {({ ref, onOpen, referenceProps }) => (
              <InfoIconWrapper ref={ref} onClick={onOpen} {...referenceProps}>
                <IconInfoRegular width={14} height={14} color={theme.colors.gray600.computedValue} />
              </InfoIconWrapper>
            )}
          </Tooltip>
        </StatContainer>
      )}

      {isBusinessRegistration && (
        <StatContainer>
          <IconMarketFill width={16} height={16} color={theme.colors['green500'].computedValue} />
          <StatText>사업자인증 완료</StatText>
          <Tooltip
            placement="bottom-start"
            arrowOffset={{ left: 5 }}
            offset={[-10, 10]}
            duration={3000}
            content={<TooltipContent>사업자 인증을 통과한 구인자예요.</TooltipContent>}
            preventOverflow>
            {({ ref, onOpen, referenceProps }) => (
              <InfoIconWrapper ref={ref} onClick={onOpen} {...referenceProps}>
                <IconInfoRegular width={14} height={14} color={theme.colors.gray600.computedValue} />
              </InfoIconWrapper>
            )}
          </Tooltip>
        </StatContainer>
      )}

      {promiseMinimumWage && (
        <StatContainer>
          <IconLeafFill width={16} height={16} color={theme.colors['gray600'].computedValue} />
          <StatText>최저임금 준수 약속</StatText>
          <Tooltip
            placement="bottom-start"
            arrowOffset={{ left: 5 }}
            offset={[-10, 10]}
            duration={3000}
            content={
              <TooltipContent>
                최저임금을 준수하기로 약속하고, 최근 1년 내 최저임금 위반 신고를 받지 않은 구인자예요.
              </TooltipContent>
            }
            preventOverflow>
            {({ ref, onOpen, referenceProps }) => (
              <InfoIconWrapper ref={ref} onClick={onOpen} {...referenceProps}>
                <IconInfoRegular width={14} height={14} color={theme.colors.gray600.computedValue} />
              </InfoIconWrapper>
            )}
          </Tooltip>
        </StatContainer>
      )}

      <StatContainer>
        <IconCalendarFill width={16} height={16} color={theme.colors.gray600.computedValue} />
        <StatText>
          당근알바와 함께한지{' '}
          {monthDiff <= 12 ? `${monthDiff}개월` : `${Math.floor(monthDiff / 12)}년 ${monthDiff % 12}개월`}
        </StatText>
      </StatContainer>
    </>
  )
}

export default AuthorStats

const NewAuthorBanner = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  padding: '10px 16px',
  margin: '0 0 16px',
  borderRadius: '10px',
  background: '$paperContents-semantic',
})

const NewAuthorBannerContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '2px 0',
  gap: '2px',
})

const NewAuthorBannerPrimaryText = styled('p', {
  $text: 'caption1Bold',
  color: '$gray900',
})

const NewAuthorBannerSecondaryText = styled('p', {
  $text: 'caption2Regular',
  color: '$gray700',
})

const NewAuthorIconContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '42px',
  height: '42px',
  borderRadius: '30px',
  background: '$paperFloating-semantic',
})

const NewAuthorIcon = styled(Image, {
  width: '28px',
  height: '28px',
})

const StatResponseBanner = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  padding: '17px 4px',
  background: '$paperContents-semantic',
  borderRadius: '10px',
  margin: '0 0 16px',
})

const StatResponseBannerContent = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '& + &': {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '1px',
      backgroundColor: '$divider2-semantic',
    },
  },
})

const StateResponseBannerPrimaryText = styled('div', {
  $text: 'title3Bold',
})

const StatResponseBannerSecondaryText = styled('div', {
  margin: '4px 0 0',
  $text: 'caption2Regular',
  color: '$gray600',
})

const StatContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',

  '& + &': {
    margin: '8px 0 0',
  },
})

const StatText = styled('div', {
  display: 'flex',
  alignItems: 'center',
  $text: 'subtitle2Regular',
  margin: '0 0 0 12px',

  variants: {
    acceent: {
      true: {
        color: '$green500',
        $text: 'label3Bold',
      },
    },
  },
})

const InfoIconWrapper = styled('div', {
  margin: '0 0 0 4px',
})
