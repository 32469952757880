import React from 'react'
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay'

import { Subway_jobPost$key } from '@src/__generated__/Subway_jobPost.graphql'
import { SubwayLine, SubwayQuery } from '@src/__generated__/SubwayQuery.graphql'
import TagGroup from '@src/components/base/tag/TagGroup'
import Empty from '@src/components/Empty'
import withSuspense from '@src/hocs/withSuspense'
import { styled } from '@src/stitches/stitches.config'

const SUBWAY_LINE_MAP = {
  // 수도권
  SEOUL_METRO_LINE_1: { name: '1', color: '#2D57C2' },
  SEOUL_METRO_LINE_2: { name: '2', color: '#61B258' },
  SEOUL_METRO_LINE_3: { name: '3', color: '#E3945A' },
  SEOUL_METRO_LINE_4: { name: '4', color: '#52B3E5' },
  SEOUL_METRO_LINE_5: { name: '5', color: '#894FD6' },
  SEOUL_METRO_LINE_6: { name: '6', color: '#AA632A' },
  SEOUL_METRO_LINE_7: { name: '7', color: '#73772C' },
  SEOUL_METRO_LINE_8: { name: '8', color: '#DD5895' },
  SEOUL_METRO_LINE_9: { name: '9', color: '#C8A64E' },
  GYEONGCHUN_LINE: { name: '경춘', color: '#5EC57E' },
  GYEONGUI_JUNGANG_LINE: {
    name: '경의중앙',
    color: '#4AA29D',
  },
  INCHEON_METRO_LINE_1: { name: '인천 1', color: '#8FB4DE' },
  INCHEON_METRO_LINE_2: { name: '인천 2', color: '#F2A755' },
  AIRPORT_RAILROAD: { name: '공항', color: '#61A7E2' },
  SINBUNDANG_LINE: { name: '신분당', color: '#BE4049' },
  UIJEONGBU_LIGHT_RAIL_TRANSIT: { name: '의정부', color: '#F4BA75' },
  YONGIN_EVERLINE: { name: '에버라인', color: '#99C872' },
  GYEONGGANG_LINE: {
    name: '경강',
    color: '#3B7AE1',
  },
  SEOUL_LRT_UI_SINSEOL_LINE: { name: '우이신설', color: '#D1CA4D' },
  SEOHAE_LINE: { name: '서해', color: '#92BE4E' },
  GIMPO_GOLDLINE: { name: '김포골드', color: '#95782B' },
  SUIN_BUNDANG_LINE: { name: '수인분당', color: '#F8D055' },
  SEOUL_LRT_SILLIM_LINE: { name: '신림', color: '#6E88C5' },
  // 부산
  BUSAN_METRO_LINE_1: { name: '1', color: '#E3945A' },
  BUSAN_METRO_LINE_2: { name: '2', color: '#61B258' },
  BUSAN_METRO_LINE_3: { name: '3', color: '#C8A64E' },
  BUSAN_METRO_LINE_4: { name: '4', color: '#3B7AE1' },
  BUSAN_GIMHAE_LRT: { name: '경전철', color: '#894FD6' },
  DONGHAE_LINE: { name: '동해선', color: '#2D57C2' },
  // 대구
  DAEGU_METRO_LINE_1: { name: '1', color: '#DB343B' },
  DAEGU_METRO_LINE_2: { name: '2', color: '#61B258' },
  DAEGU_METRO_LINE_3: { name: '3', color: '#EAC153' },
  // 광주
  GWANGJU_METRO_LINE_1: { name: '1', color: '#61B258' },
  // 대전
  DAEJEON_METRO_LINE_1: { name: '1', color: '#61B258' },
}

const SUBWAY_LINE_SORT_INDEX = {
  SEOUL_METRO_LINE_1: 1,
  SEOUL_METRO_LINE_2: 2,
  SEOUL_METRO_LINE_3: 3,
  SEOUL_METRO_LINE_4: 4,
  SEOUL_METRO_LINE_5: 5,
  SEOUL_METRO_LINE_6: 6,
  SEOUL_METRO_LINE_7: 7,
  SEOUL_METRO_LINE_8: 8,
  SEOUL_METRO_LINE_9: 9,
  GYEONGCHUN_LINE: 10,
  GYEONGUI_JUNGANG_LINE: 11,
  INCHEON_METRO_LINE_1: 12,
  INCHEON_METRO_LINE_2: 13,
  AIRPORT_RAILROAD: 14,
  SINBUNDANG_LINE: 15,
  UIJEONGBU_LIGHT_RAIL_TRANSIT: 16,
  YONGIN_EVERLINE: 17,
  GYEONGGANG_LINE: 18,
  SEOUL_LRT_UI_SINSEOL_LINE: 19,
  SEOHAE_LINE: 20,
  GIMPO_GOLDLINE: 21,
  SUIN_BUNDANG_LINE: 22,
  SEOUL_LRT_SILLIM_LINE: 23,
  BUSAN_METRO_LINE_1: 24,
  BUSAN_METRO_LINE_2: 25,
  BUSAN_METRO_LINE_3: 26,
  BUSAN_METRO_LINE_4: 27,
  BUSAN_GIMHAE_LRT: 28,
  DONGHAE_LINE: 29,
  DAEGU_METRO_LINE_1: 30,
  DAEGU_METRO_LINE_2: 31,
  DAEGU_METRO_LINE_3: 32,
  GWANGJU_METRO_LINE_1: 33,
  DAEJEON_METRO_LINE_1: 34,
}

type SubwayLineColorMapKeys = keyof typeof SUBWAY_LINE_MAP

const subwayLineFormatter = (line: SubwayLine) => {
  const mappedSubwayLine = SUBWAY_LINE_MAP[line as SubwayLineColorMapKeys]

  if (!mappedSubwayLine) {
    return null
  }

  return mappedSubwayLine
}
const subwayLineSortIndexFormatter = (line: SubwayLine) => {
  const mappedSubwayLine = SUBWAY_LINE_SORT_INDEX[line as SubwayLineColorMapKeys]

  if (!mappedSubwayLine) {
    return 100
  }

  return mappedSubwayLine
}

// 100% duration을 사용하는 것으로 우선 변경함
const TRUNCATE_RATIO = 1
const MAX_STATION_COUNT = 3

type Props = {
  jobPostRef: Subway_jobPost$key
}

const Subway: React.FCC<Props> = ({ jobPostRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment Subway_jobPost on JobPost {
        workplaceLocation {
          lat
          lng
        }
      }
    `,
    jobPostRef
  )

  const query = useLazyLoadQuery<SubwayQuery>(
    graphql`
      query SubwayQuery($filter: SearchSubwaysFilterInput!, $skip: Boolean!) {
        searchSubways(filter: $filter) @skip(if: $skip) {
          lines
          name
          duration
        }
      }
    `,
    {
      filter: {
        point: {
          lat: Number(jobPost?.workplaceLocation?.lat),
          lng: Number(jobPost?.workplaceLocation?.lng),
        },
        maxDistance: 1000,
      },
      skip: !jobPost?.workplaceLocation?.lat || !jobPost?.workplaceLocation?.lng,
    }
  )

  const filteredItems = query.searchSubways

    ?.map((item) => ({
      ...item,
      duration: Math.floor((item.duration / 60) * TRUNCATE_RATIO),
      lines: Array.from(item.lines).sort((a, b) => {
        const aIndex = subwayLineSortIndexFormatter(a)
        const bIndex = subwayLineSortIndexFormatter(b)
        return aIndex - bIndex
      }),
    }))
    .filter((item) => Number(item?.duration) <= 15)
    .slice(0, MAX_STATION_COUNT)

  if (!filteredItems || filteredItems.length === 0) {
    return null
  }

  return (
    <Container>
      {filteredItems.map((item) => {
        return (
          <Station key={item.name}>
            <LineContainer>
              {item.lines.map((line) => {
                const mappedSubwayLine = subwayLineFormatter(line)
                if (!mappedSubwayLine) return null
                return (
                  <Line key={line} css={{ backgroundColor: mappedSubwayLine.color }}>
                    {mappedSubwayLine.name}
                  </Line>
                )
              })}
            </LineContainer>
            <TagGroup
              size="medium"
              items={[
                {
                  value: <StationName>{item.name}</StationName>,
                  visible: true,
                },
                `걸어서 ${item.duration}분`,
              ]}
            />
          </Station>
        )
      })}
    </Container>
  )
}

export default withSuspense(Subway, Empty)

const Container = styled('div', {})

const Station = styled('div', {
  display: 'flex',
  alignItems: 'center',

  '& + &': {
    margin: '8px 0 0',
  },
})

const LineContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  margin: '0 8px 0 0',
})

const Line = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  $text: 'label5Bold',
  padding: '2px 6px',
  color: '$white-static',
  borderRadius: '1000px',

  '& + &': {
    margin: '0 0 0 4px',
  },
})

const StationName = styled('strong', {
  color: '$gray900',
})
