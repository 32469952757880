import React from 'react'
import useDarkMode from 'use-dark-mode'

import Button from '@src/components/base/buttons/Button'
import Image from '@src/components/base/image/Image'
import { ADVERTISEMENT_DISPLAY_POLICY_DARK, ADVERTISEMENT_DISPLAY_POLICY_LIGHT } from '@src/constants/images'
import Halfview from '@src/packages/halfview/Halfview'
import { styled } from '@src/stitches/stitches.config'

interface Props {
  onCloseClick: () => void
}

const AdvertisementDisplayPolicyHalfview: React.FCC<Props> = ({ onCloseClick }) => {
  const { value: isDarkMode } = useDarkMode()
  return (
    <Halfview open={true} onCloseClick={onCloseClick}>
      <Base>
        <PolicyImageContainer>
          <PolicyImage source={isDarkMode ? ADVERTISEMENT_DISPLAY_POLICY_DARK : ADVERTISEMENT_DISPLAY_POLICY_LIGHT} />
        </PolicyImageContainer>
        <GuideContainer>
          <GuideTitle>광고 노출 기준</GuideTitle>
          <GuideText>앱 내 최근 활동 이력을 분석하여 이용자의 관심사와 관련성이 높은 게시글을 노출해요.</GuideText>
        </GuideContainer>
        <ButtonWrapper>
          <Button size="xlarge" priority="primary" onClick={onCloseClick}>
            확인
          </Button>
        </ButtonWrapper>
      </Base>
    </Halfview>
  )
}

export default AdvertisementDisplayPolicyHalfview

const Base = styled('div', {})

const PolicyImageContainer = styled('div', {
  padding: '24px 0 0',
  display: 'flex',
  justifyContent: 'center',
  background: '$grayAlpha50',
})

const PolicyImage = styled(Image, {
  width: '227px',
  height: '146px',
})

const GuideContainer = styled('div', {
  padding: '24px 16px 16px',
})

const GuideTitle = styled('div', {
  $text: 'title2Bold',
  color: '$gray900',
})

const GuideText = styled('div', {
  margin: '6px 0 0',
  $text: 'subtitle1Regular',
  color: '$gray700',
})

const ButtonWrapper = styled('div', {
  padding: '12px 16px 8px',
})
