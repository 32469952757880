import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './shim'

import './sdks/datadog'
import './sdks/sentry'

import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import '@karrotmarket/design-token/colors/light.css'
import '@karrotmarket/design-token/colors/dark.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import '@seed-design/stylesheet/global.css'
import '@daangn/karrot-clothes/styles.css'
import '@daangn/karrot-clothes/pullToRefresh.css'
import '@stackflow/plugin-basic-ui/index.css'
import '@daangn/sprout-components-snackbar/index.css'
import '@daangn/sprout-components-spinner/index.css'
import '@daangn/sprout-components-dialog/index.css'
import '@daangn/sprout-components-button/index.css'
import '@daangn/sprout-components-chips/index.css'
import '@daangn/sprout-components-avatar/index.css'
import '@daangn/sprout-components-callout/index.css'
import '@daangn/transfer-identification-sdk/index.css'
import './index.css'

import App from './App'
import ErrorBoundary from './components/base/error/ErrorBoundary'
import RootErrorPage from './components/base/error/RootErrorPage'
import GlobalStyle from './GlobalStyle'
import store from './store'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <GlobalStyle />
    <ErrorBoundary fallback={RootErrorPage}>
      <App />
    </ErrorBoundary>
  </Provider>
)
