import React from 'react'

import { Swiper, SwiperSlide } from '@src/sdks/swiper'
import { css, styled } from '@src/stitches/stitches.config'
import { FullSizeSwiperWrapper } from '@src/stitches/swiper'

type Props = {
  images: string[]
  onDetailClick: (index: number) => void
}

const ImageSlider: React.FCC<Props> = ({ images, onDetailClick }) => {
  return (
    <FullSizeSwiperWrapper>
      <Swiper
        pagination={{
          bulletClass: bullet().className,
          bulletActiveClass: bulletActive().className,
          clickable: true,
        }}>
        {images.map((src: any, i: any) => {
          return (
            <SwiperSlide key={i} onClick={() => onDetailClick(i)}>
              <ImageWrapper>
                <Image css={{ backgroundImage: `url(${src})` }} />
              </ImageWrapper>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </FullSizeSwiperWrapper>
  )
}

export default ImageSlider

const ImageWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  height: 0,
  paddingBottom: '66.67%',
})

const Image = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
})

const bullet = css({
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  margin: '0 4px',
  background: '$whiteAlpha200-static',
})

const bulletActive = css({
  background: '$white-static',
})
