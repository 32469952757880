import { combineReducers } from 'redux'

import app from './app'
import devTool from './devTool'
import event from './event'
import filter from './filter'
import jobPost from './jobPost'
import region from './region'
import user from './user'

const rootReducer = () =>
  combineReducers({
    app,
    devTool,
    jobPost,
    region,
    user,
    event,
    filter,
  })

export type RootState = ReturnType<ReturnType<typeof rootReducer>>

export default rootReducer
