import React from 'react'

type Props = {
  width?: number
  height?: number
} & JSX.IntrinsicElements['svg']

const Manner04: React.FCC<Props> = ({ width = 24, height = 24, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FADD65"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41176 14.9677C7.61018 14.7047 7.92055 14.55 8.25003 14.55H15.75C16.0795 14.55 16.3899 14.7047 16.5883 14.9677C16.7867 15.2308 16.8501 15.5717 16.7596 15.8885L16.7588 15.8913C16.7432 15.9453 16.7262 15.9959 16.6954 16.0742C16.6593 16.166 16.6067 16.2858 16.5326 16.424C16.3847 16.7 16.1488 17.0548 15.7856 17.4054C15.0372 18.128 13.8345 18.75 12 18.75C10.1656 18.75 8.96287 18.128 8.21446 17.4054C7.85131 17.0548 7.61532 16.7 7.46745 16.424C7.3934 16.2858 7.34073 16.166 7.30467 16.0742C7.28662 16.0282 7.27263 15.989 7.26213 15.9578C7.14973 15.623 7.19907 15.2497 7.41176 14.9677Z"
        fill="#895F00"
      />
      <path
        d="M7.80019 12.75C8.54578 12.75 9.15019 11.8098 9.15019 10.65C9.15019 9.49022 8.54578 8.55002 7.80019 8.55002C7.05461 8.55002 6.4502 9.49022 6.4502 10.65C6.4502 11.8098 7.05461 12.75 7.80019 12.75Z"
        fill="#895F00"
      />
      <path
        d="M16.2001 12.75C16.9457 12.75 17.5501 11.8098 17.5501 10.65C17.5501 9.49022 16.9457 8.55002 16.2001 8.55002C15.4545 8.55002 14.8501 9.49022 14.8501 10.65C14.8501 11.8098 15.4545 12.75 16.2001 12.75Z"
        fill="#895F00"
      />
      <path
        opacity="0.85"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88511 5.5681L5.88346 5.57135C5.88453 5.5694 5.88746 5.56417 5.89174 5.55715C5.90035 5.54304 5.91526 5.52008 5.93683 5.49187C5.9804 5.43489 6.04828 5.36021 6.14285 5.29266C6.32036 5.16587 6.62069 5.04175 7.10894 5.16381C7.30986 5.21404 7.51346 5.09188 7.56369 4.89096C7.61392 4.69004 7.49176 4.48644 7.29084 4.43621C6.57909 4.25827 6.05441 4.43416 5.70692 4.68236C5.53899 4.80231 5.41937 4.93388 5.34107 5.03628C5.2775 5.1194 5.24447 5.17335 5.21535 5.23056L5.21489 5.23148C5.21489 5.23148 5.21535 5.23056 5.21448 5.2323C5.12186 5.41755 5.19694 5.6428 5.38218 5.73542C5.5662 5.82743 5.78972 5.75393 5.88346 5.57135L5.88402 5.57024L5.88458 5.56913L5.88511 5.5681Z"
        fill="#D49D3A"
      />
      <path
        opacity="0.85"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6352 5.2319C18.7278 5.41714 18.6529 5.6428 18.4677 5.73542C18.2837 5.82743 18.0602 5.75394 17.9664 5.57136M17.9664 5.57136C17.913 5.49187 17.9454 5.53412 17.913 5.49187C17.8695 5.43489 17.8016 5.36021 17.707 5.29266C17.5295 5.16587 17.2292 5.04175 16.7409 5.16381C16.54 5.21404 16.3364 5.09188 16.2862 4.89096C16.236 4.69004 16.3581 4.48644 16.559 4.43621C17.2708 4.25827 17.7955 4.43416 18.143 4.68236C18.3109 4.80231 18.4305 4.93388 18.5088 5.03628C18.556 5.09794 18.6002 5.16246 18.6352 5.2319"
        fill="#D49D3A"
      />
      <path
        d="M9.2999 15.45C9.24586 15.45 9.196 15.4791 9.16937 15.5261C9.14275 15.5731 9.14347 15.6308 9.17128 15.6772C9.17128 15.6772 9.13784 15.6427 9.20842 15.7279C9.23038 15.7544 9.26277 15.7893 9.30847 15.8296C9.4 15.9104 9.54396 16.012 9.76283 16.1115C10.2001 16.3103 10.9351 16.5 12.1499 16.5C13.3647 16.5 14.0997 16.3103 14.537 16.1115C14.7558 16.012 14.8998 15.9104 14.9913 15.8296C15.037 15.7893 15.0694 15.7544 15.0914 15.7279C15.1048 15.7118 15.1176 15.6951 15.1285 15.6772C15.1563 15.6308 15.1571 15.5731 15.1304 15.5261C15.1038 15.4791 15.0539 15.45 14.9999 15.45H9.2999Z"
        fill="white"
      />
    </svg>
  )
}

export default Manner04
