import React from 'react'
import { useDispatch } from 'react-redux'
import { graphql } from 'react-relay'
import { Environment, fetchQuery, commitMutation } from 'relay-runtime'

import {
  ExperimentSegmentGroup,
  useJobsRelayEnvironmentInitExperimentMutation,
} from '@src/__generated__/useJobsRelayEnvironmentInitExperimentMutation.graphql'
import { useJobsRelayEnvironmentQuery } from '@src/__generated__/useJobsRelayEnvironmentQuery.graphql'
import { TreatmentSymbol } from '@src/constants/experiment'
import { initEventSampleGroup, initExperiments } from '@src/ducks/devTool'
import { setEvents } from '@src/ducks/event'
import { setIsJobPostWritable, setUnadvertisedJobPostId } from '@src/ducks/jobPost'
import { setUserByMe } from '@src/ducks/user'
import { createEnvironment } from '@src/graphql/graphql'
import { useEnhancedInfo } from '@src/hooks/useEnhancedInfo'
import { RegionRange } from '@src/types/jobPost'
import { hasAcquisition } from '@src/utils/acquisition'
import {
  initExperimentSegments,
  makeControlSegment,
  makeExcludeSegment,
  makeTreatmentSegment,
  parseSegment,
  treatmentSymbolToServerGroup,
} from '@src/utils/experiment'
import { calcUserRole } from '@src/utils/user'

import { useAsyncError } from './useAsyncError'
import { usePreloadJobPostQuery } from './usePreloadJobPostQuery'

type Props = {
  userId?: string
  regionId?: number
}

export const useJobsRelayEnvironment = (props: Nullable<Props>) => {
  const { getInfo } = useEnhancedInfo()
  const [environment, setEnvironment] = React.useState<Environment | undefined>()
  const throwAsyncError = useAsyncError()
  const { isLoaded: isPreloadDataLoaded, fetch: preloadJobPost } = usePreloadJobPostQuery()
  const [isInitialDataLoaded, setIsInitialDataLoaded] = React.useState(false)
  const [isExperimentSegmentSet, setIsExperimentSegmentSet] = React.useState(false)
  const dispatch = useDispatch()

  const regionId = props?.regionId ?? 0

  React.useEffect(() => {
    const environment = createEnvironment()
    setEnvironment(environment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (props === null || !environment) return

    preloadJobPost({ variables: { regionId } }, environment)

    fetchQuery<useJobsRelayEnvironmentQuery>(
      environment,
      graphql`
        query useJobsRelayEnvironmentQuery($eventGroupsWhere: EventGroupsWhereInput!, $regionExist: Boolean!) {
          me {
            id
            role
            resume {
              birthYear
            }
            jobPosts(first: 2, filter: { status: [ACCEPTED, PENDING] }) @connection(key: "MainWritingsList_jobPosts") {
              edges {
                node {
                  id
                  advertisements {
                    __typename
                  }
                }
              }
            }
            jobPostsExtend {
              availableSlotCount
            }
            resettableDeviceId
            initialReferrer
          }
          eventGroups(where: $eventGroupsWhere) @include(if: $regionExist) {
            type
            name
            hasJobPost
          }
        }
      `,
      {
        eventGroupsWhere: {
          region: regionId ?? 0,
          regionRange: RegionRange.RANGE_3,
        },
        regionExist: !!regionId,
      }
    )
      .toPromise()
      .then((res) => {
        if (!res) return

        const unadvertisedJobPost = res.me?.jobPosts.edges.find((edge) => !edge.node.advertisements?.length)?.node
        dispatch(initEventSampleGroup({ role: res.me?.role ?? 'NONE' }))
        dispatch(setUnadvertisedJobPostId(unadvertisedJobPost?.id ?? null))
        dispatch(setIsJobPostWritable(!!res.me?.jobPostsExtend.availableSlotCount))
        dispatch(
          setUserByMe({
            nodeId: res.me?.id ?? '',
            role: res.me?.role ?? 'NONE',
            resettableDeviceId: res.me?.resettableDeviceId ?? undefined,
            paidAcquiredAuthor: calcUserRole(res.me).isAuthor && hasAcquisition(),
            initialReferrer: res.me?.initialReferrer ?? undefined,
            birthYear: res.me?.resume?.birthYear ?? undefined,
          })
        )

        const events =
          res.eventGroups?.map((group) => ({
            type: group.type,
            name: group.name ?? '',
            hasJobPost: group.hasJobPost ?? false,
          })) ?? []

        dispatch(setEvents(events))

        setIsInitialDataLoaded(true)
      })
      .catch((err) => {
        throwAsyncError(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, environment])

  React.useEffect(() => {
    if (props === null || !environment) return

    getInfo()
      .then(({ region }) => {
        if (!region) {
          setIsExperimentSegmentSet(true)
          return
        }

        const [enableSegments, , deletedSegments] = initExperimentSegments({ region })

        if (!enableSegments.length && !deletedSegments.length) {
          setIsExperimentSegmentSet(true)
          return
        }

        const mutation = graphql`
          mutation useJobsRelayEnvironmentInitExperimentMutation(
            $registerData: [RegisterExperimentSegmentDataInput!]!
            $endData: [EndExperimentSegmentDataInput!]!
          ) {
            registerExperimentSegments(data: $registerData) {
              experimentSegments {
                name
                version
                group
                endAt
                endReason
              }
            }
            endExperimentSegment(data: $endData) {
              success
            }
          }
        `
        commitMutation<useJobsRelayEnvironmentInitExperimentMutation>(environment, {
          mutation,
          variables: {
            registerData: enableSegments.map((rawSegment) => {
              const segment = parseSegment(rawSegment)
              const group = segment.isExclude
                ? 'EXCLUDE'
                : segment.isControl
                  ? 'CONTROL'
                  : treatmentSymbolToServerGroup(segment.treatmentType)

              return {
                name: segment.name,
                version: segment.version,
                group: group as ExperimentSegmentGroup,
              }
            }),
            endData: deletedSegments.map((rawSegment) => {
              const segment = parseSegment(rawSegment)
              return {
                name: segment.name,
                version: segment.version,
                endReason: 'OUTDATED_ACCESS',
              }
            }),
          },
          onCompleted: (response) => {
            const res = response.registerExperimentSegments.experimentSegments
            const segments = res.map((segment) => {
              switch (true) {
                case !!segment.endAt:
                  return makeExcludeSegment(segment.name, segment.version)
                case segment.group == 'CONTROL':
                  return makeControlSegment(segment.name, segment.version)
                case segment.group.startsWith('TREATMENT'):
                  return makeTreatmentSegment(
                    segment.name,
                    segment.version,
                    segment.group[segment.group.length - 1] as TreatmentSymbol
                  )
                case segment.group == 'EXCLUDE':
                default:
                  return makeExcludeSegment(segment.name, segment.version)
              }
            })
            dispatch(initExperiments(segments))
            setIsExperimentSegmentSet(true)
          },
          onError: () => {
            setIsExperimentSegmentSet(true)
          },
        })
      })
      .catch((err) => {
        console.error({ err })
        throwAsyncError(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, environment])

  return { environment: isInitialDataLoaded && isPreloadDataLoaded && isExperimentSegmentSet ? environment : undefined }
}
