import type { StitchesCSS } from '@src/stitches/stitches.config'

export const getSafeAreaStyle = ({
  direction,
  property,
  converter,
}: {
  direction: 'top' | 'bottom' | 'left' | 'right'
  property: string
  converter: (safeAreaValue: string) => string
}): StitchesCSS => {
  const safeAreaValue = `safe-area-inset-${direction}`

  return {
    [property]: `calc(${converter(`constant(${safeAreaValue})`)})`,
    '&': { [property]: `calc(${converter(`env(${safeAreaValue})`)})` },
  }
}

export const getNavigatorThemeStyle = ({
  direction,
  property,
}: {
  direction: 'top' | 'bottom'
  property: string
}): {
  themeType: Record<'Android' | 'Cupertino', StitchesCSS>
} => {
  return {
    themeType: {
      Android: {
        [property]: '3.5rem',
      },
      Cupertino: {
        [property]: `calc(2.75rem + constant(safe-area-inset-${direction}))`,
        '&': {
          [property]: `calc(2.75rem + env(safe-area-inset-${direction}))`,
        },
      },
    },
  }
}
