export const IMAGE_JOBS_COLORED = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/ffe880d9be73ee10044475c05d266519faf398c6da3b4f71387d40da9505b040.webp',
    '4x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/21d66be367dfa7c39204171db9f46f9d02c2425887e5998a396a69d2d17459f3.webp',
  },
}

export const IMAGE_JOB_POST_IMAGE_CARD_DEFAULT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/3cb49b63d17483346ab83367b10277fc7f726f5debbf84a34d4fc59fefbe519e.webp',
  },
}

export const IMAGE_JOBS_GREY = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/54858d9462611cbc41e0d2de41d9b9a62138fbb30b8712d5848f13ecbfb6eaba.webp',
  },
}

export const IMAGE_RESUME_DEFAULT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/492c4ecf3a8e564e06db0852d3775eb10b8bc8f1a4003052b660317d22ec7743.webp',
  },
}

export const IMAGE_SLOT_CARD = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/c5c3e530fbeb916b3615372380a111d1ed90d1db24f9d7fcb7ef919b6ca16e41.webp',
  },
}

export const IMAGE_JOB_POST_CARD_MANAGE_DEFAULT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/c276cb79f2e99b4ab6d88dcdd0ab32b0ff8745318183d67790fbee6101ab1254.webp',
  },
}

export const IMAGE_JOB_POST_DETAIL_DEFAULT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/46b36dcda9a67f0fa3d478ac5f1a55364bab365f01958200ebecbd45885b4196.webp',
  },
}

export const IMAGE_POI_PIN = {
  START:
    'https://dnvefa72aowie.cloudfront.net/origin/etc/202208/5450bd9d0c640e170ea5506ad3dcb532c128a1e1f477a258b6bf61ee75a901c3.png',
  END: 'https://dnvefa72aowie.cloudfront.net/origin/etc/202208/97a88c82ed7e2463207448d2499c162c3fc17b141a3bb1ebdf1c667921666268.png',
  GPS: 'https://dnvefa72aowie.cloudfront.net/origin/etc/202208/459b05fd9e3dc05dbf76e0059a9577364727c7ed2c081298119097c5cc653f25.png',
}

// 숏컷 온보딩
export const IMAGE_SHORTCUT_ONBOARDING = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/034b37d835309b63a3263cf164fc06978e0ed06f1c0efe657b9d96c1c768a2e5.webp',
  },
}

/**
 * 로고 + 이름 형태
 */
// 알바 로고
export const IMAGE_LOGO_ALBA = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/26c8657b8d98e528e0ce3728c79517394f2d1c3cc775b24a6d98ea160793c26d.webp',
  },
}
export const IMAGE_LOGO_ALBA_WITH_TEXT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/c6c9e16915153d28baa9535074c33a4318091f8571c5e10ba0151cadb5d7608e.webp',
  },
}

// 광산구 로고
export const IMAGE_LOGO_GWANGSANGU = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/fc9f9ee9f0de23954b25789613a6c182cb5e408bb96c64912ec63a310f4f579b.webp',
  },
}

export const IMAGE_ACTIVITY_BADGE = {
  LOCK_GOLD: {
    WEBP: {
      '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/9350befd784fd6540abe23e7edb4e813c4f2862560f6fd4ab85db2a357fdc024.webp',
    },
  },
  LOCK_GRAY: {
    WEBP: {
      '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/bf04f3d8dcf1e170b61297f9e6152282e20278279bffd9b75c7cc3ebf9c76039.webp',
    },
  },
  BADGE_GOLD: {
    WEBP: {
      '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/bb435792891c12601b01bf32ebedfbd55136fd02c98e3fcd7b6ffee600c046c8.webp',
    },
  },
  BADGE_BLUE: {
    WEBP: {
      '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/c5305da84d9689148fa394895ba465cf96a7e45f07aa0101056640809c3154f2.webp',
    },
  },
  BADGE_GREEN: {
    WEBP: {
      '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/58059e12cdef76b4f0b946563f2579ab424d58b7516fa4a001c5331131b40f16.webp',
    },
  },
  GUIDE_VISIT: IMAGE_SHORTCUT_ONBOARDING,
}

// 알바 소개 페이지
export const IMAGE_JOBS_SERVICE_1 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/dc4b2c97bed6229e660d20b2b9b53eefa504beb11b16c90799183477e76c82dc.webp',
  },
}
export const IMAGE_JOBS_SERVICE_2 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/b1dd6852b2fff34108ae959d5be5df7966b3cd111d041d94589cdf149f0a08f7.webp',
  },
}
export const IMAGE_JOBS_SERVICE_3 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/97c96503d4bcaf29a19f89ea3a9461f05f6fd61bc44266a06b7e9fcd8e870a49.webp',
  },
}
export const IMAGE_JOBS_SERVICE_4 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/ff97998973546aa64092e36fd092fb21fe9bcc142391177492b17f16154e7abe.webp',
  },
}
export const IMAGE_WORKING_10_MIN = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/3600894f9ce2ee8d1c7000f9e50343c8306601145699b454608f4f57b81649f8.webp',
  },
}
export const IMAGE_CLOCK = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/d1d907fc4658f94784d1f49e2b32fcd396aa021a08ac66ceb71bf1253d3d2da8.webp',
  },
}
export const IMAGE_USERS_3000K = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/cf3ec09da4c0e1f8f66f7e5b81002d61fb886c93ad5934deac0cdcf6fa477557.webp',
  },
}
export const IMAGE_JOBS_HOME_FEED = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/ed6e64b1e1b61a3dae57d5fad813723707e441dd2c2266fa436124a74cb54491.webp',
  },
}
export const IMAGE_JOBS_CHAT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/30fa757be78d7b900ceeaa5a79f1c49ad4651023472399dd2ce072a0a7239a1c.webp',
  },
}

// 이벤트 그룹 피드 이미지
export const IMAGE_EVENT_GROUP_FEED = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/285af2355224dfec958a50cb177f4d710da7b35bfa2f3274caa34ac316db3235.webp',
  },
}

// 이벤트 그룹 키워드 검색 유도 아이콘
export const IMAGE_EVENT_KEYWORD_SUGGEST = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/e19d7916a546e1a995ae03a0b7bb362623444445ffe66440d7cab9d52c0371ad.webp',
  },
}

// 사업자인증 OCR 가이드
export const IMAGE_BIZ_CERTIFICATE_OCR_GUIDE = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/8213e3023d6b5dbae872e7f04709b3b3b1b42996727814d9e44093b7cc73b733.webp',
  },
}

// 광고 타입 선택하기 내 프리미엄 광고 이미지
export const IMAGE_AD_TOP_PREMIUM_ICON = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/b7d65d129282572e3cf2c87fee77c16a6ccfc88c405509db476346bffe8aeb55.webp',
  },
}

export const IMAGE_AD_TOP_PREMIUM_DETAIL_1 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/da89c76ce66917e1946c6d0eff5c4b0d864451bb5a296efa71ea821e2c7cfafe.webp',
  },
}
export const IMAGE_AD_TOP_PREMIUM_DETAIL_2 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/5216c321f518a24c79f2ea6a4f0b74264400f4e10dd7d0cf7f33928dc955527b.webp',
  },
}
export const IMAGE_AD_TOP_PREMIUM_DETAIL_3 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/38e89dba783986250019e5322e3e4fca478735b2a109bd6d1388bde69b4d90d8.webp',
  },
}

export const IMAGE_AD_STANDARD_DETAIL_1 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/186671863b665bd7fea31d091f75c193bf857e7b29df51c2475fef3b9cd6881c.webp',
  },
}
export const IMAGE_AD_STANDARD_DETAIL_2 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/9ea7a4e12697b4b0a082ba45d9d40f5bba52459edf29b048a6a11a16e89722e2.webp',
  },
}

// 202309 캠페인 구인자 글쓰기 배너 (긴구지)
export const IMAGE_CREATE_JOB_POST_CAMPAIGN_BANNER = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/f948692c0ae2ef0100b9881955123eb2a0ad13fec911bac703f513d8e4b78d28.webp',
  },
}

// 202309 캠페인 구인글 작성완료 페이지 (긴구지))
export const IMAGE_CAMPAIGN_CREATE_COMPLETE = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/47f28d18b2c9bf5124918cec9ee4e0f1bf49703e21ee9530c7f0198650f5f4a8.webp',
  },
}

// 광산구 배너
export const IMAGE_GWANGSANGU_BANNER = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/ccd36091ec3cb4235ec8e02a6a686322b410827db0197ffe55f96b9e748034c5.webp',
  },
}

// 광산구 메인 타이틀 이미지
export const IMAGE_GWANGSANGU_MAIN_NOTICE = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/135a303686c2e824d9319451dc2caa22a32a68b5e994bc077b0f14465021dc5d.webp',
  },
}

// 정기 초대하기 이벤트 배너
export const IMAGE_INVITE_EVENT_REGULAR_BANNER1 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/069c4f167b33d8a1f9ed406da1aa185bb0d7a92ae0854c761d53d7cd2f2ed578.webp',
  },
}
export const IMAGE_INVITE_EVENT_REGULAR_BANNER2 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/c871feeafc5dd8360ab983671953e35d6514f546e2d534627bd00a6dd612a0d9.webp',
  },
}

// 토탈 케어 정규
export const IMAGE_TOTAL_CARE_BANNER1 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/2e8e7f3437e16c6d8d00d822e7285dae5ef463792c1bbf3ab6d163dd34ab0762.webp',
  },
}
export const IMAGE_TOTAL_CARE_BANNER2 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/551ab78ced47e677c9543be3a656c2a3449bd436da73ccd75ca495424c567be5.webp',
  },
}

export const IMAGE_TOTAL_CARE_HALFVIEW = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/dd758e553bd44f55ca5cc1edfdd4eb1cf5ad6345104f8669de6947c2f77681f8.webp',
  },
}

// 202403 토탈케어
export const IMAGE_TOTAL_CARE_202403_COMPLETE = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/5df6317ed2ae9ecac3c67b76a715e8a553388fcccdf73fd5c697d0a5bd4f09e4.webp',
  },
}

// 202309 노쇼 보상 캠페인
export const IMAGE_NO_SHOW_202309_COUPON = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/dfd9e3040987c36d960621d57135e90e1c3473df9cef57c0ed88e9d52faca2fd.webp',
  },
}

// 구인글쓰기 퍼널 개선 배너
export const IMAGE_JOB_POST_CREATE_BANNER = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/b0640798a5dac7c868e51390611d33ca5748fd47f5bb619515f36d523fa2841c.webp',
  },
}

// 신규 사장님 전단지
export const IMAGE_NEW_BOSS_FLY_COUPON = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/80a3fab933523f1d677e0820cd15070bc8d02e4277438b9245707c3eb965a158.webp',
  },
}
export const IMAGE_NEW_BOSS_DESC_1 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/a5d5e777a6de995f1b4a8ad2263f66362865389f41234027a51635556a6db072.webp',
  },
}
export const IMAGE_NEW_BOSS_DESC_2 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/361c1ae60c10992703871e90126ea68b1f5e9c5964d6e5139ac508816aa5241a.webp',
  },
}

export const ADVERTISEMENT_DISPLAY_POLICY_LIGHT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/d636171a269311d23aeea59424a71d41f6049c6eed62cd0e717df5ae12130ee4.webp',
  },
}
export const ADVERTISEMENT_DISPLAY_POLICY_DARK = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/dea42c3a57ac731923675924d205ac5e94bf7d76cded377bcab371781b2e1647.webp',
  },
}

// 202403 신학기 이벤트
export const IMAGE_NEW_SEMESTER_202403_BANNER_AUTHOR = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/9ff47071aaed71683d88dc63e2c99e3c0371cacc9f2269e5593f7a1d2f321477.webp',
  },
}
export const IMAGE_NEW_SEMESTER_202403_BANNER_APPLICANT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/01e4d09c7170e4bcc72f1cafcdfa11786890e325af7ce3f1b0633de1f8467ba9.webp',
  },
}

// 구인자 소개
export const IMAGE_AUTHOR_INTRODUCTION_EXAMPLE_LIGHT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/57bd262c200201cc39e0855ed14e87c81ac1cd7d7085a5d487835262d4d751ad.webp',
  },
}
export const IMAGE_AUTHOR_INTRODUCTION_EXAMPLE_DARK = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/99c29af6d7fcd9c97d548aed14eb292cf7cae8c82870eab91fe0de4dc898b1a8.webp',
  },
}

// 구인글 마스킹 안내
export const IMAGE_MASKING_GUIDE_PHONE_NUMBER = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/54ed03e4dee08cabdb0a86fd8e1a5e05d12f81d8e1aa255c95635a0fbcdff09f.webp',
  },
}
export const IMAGE_MASKING_GUIDE_EMPLOY = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/b8f63bfbdb574df9b74743f0ec705acea20ae8c422f71273a2abf227ca7c5c87.webp',
  },
}

export const IMAGE_BALANCE_GAME = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/2358f1788b875e1f04d152e2f7ab316ed80b1137dcb8dc0d1d6ce5a3ff967198.webp',
  },
}

export const IMAGE_REGULAR_INVITE_202404 = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/34ff28d56f190ee3c8a01d0cb7742d9bed1dfd8d6e9508919dc348f636ee5c1e.webp',
  },
}
export const IMAGE_REGULAR_INVITE_202404_PRIZE = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/f95161d172748346fd0332d192877a842ede2df4d68e793d68cd73e286416623.webp',
  },
}

// 202406 프로모션 이벤트
export const IMAGE_PROMOTION_202406_BANNER_APPLICANT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/01c437f840de3f6b1dfbfc73bf26331ab6667620e4d8dd5456cddaec442e8672.webp',
  },
}
export const IMAGE_PROMOTION_202406_BANNER_AUTHOR = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/5705b03c4d8b6f71ccc8968d2a167ca70d5fc3d59a842419711ffae6bca395ae.webp',
  },
}
export const IMAGE_CAMPAIGN_BEWARE_ACCOUNT_HIJACKING = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/e6587c2f2133429fe09a5e8d61196d97eb57016e12a74902d14809c746e71d60.webp',
  },
}
export const IMAGE_PROMOTION_202406_CONFIRM = {
  WEBP: {
    '3x': 'https://assetstorage.krrt.io/1047075453641369739/b16d1ef7-3498-4633-8fad-677ea161bfe2/width=900,height=900.webp',
    '4x': 'https://assetstorage.krrt.io/1047075453641369739/b16d1ef7-3498-4633-8fad-677ea161bfe2/width=3600,height=3600.webp',
  },
}

export const IMAGE_BM_APPLICATION_MODEL_NUDGE = {
  light: {
    WEBP: {
      '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/038926a4781c8fa3dae328efe4b63e460e5b4ac01bb48fbfccfc843666ee6629.webp',
    },
  },
  dark: {
    WEBP: {
      '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/c003ed88d2bf5e20b5ffaa7d9ff61b1b268a7dc95eacbe4b6d2a631b3e0a0b20.webp',
    },
  },
}

// 구인자 동기부여 실험
export const FAST_RESPONSE_NUDGE_JOB_POST_CARD_LIGHT = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/06fbc4ba272dc42b41ed2917e72726b2a5cedc15cc80a01b6845c7cdf81b3e49.webp',
  },
}
export const FAST_RESPONSE_NUDGE_JOB_POST_CARD_DARK = {
  WEBP: {
    '3x': 'https://dnvefa72aowie.cloudfront.net/origin/article/202406/77a7908be219715fab8f905101ea097dbe16a80bc6c855cf48934ec0893e8de5.webp',
  },
}

export const CURATION_FEED_BANNER_DEFAULT_IMAGE = {
  WEBP: {
    '3x': 'https://assetstorage.krrt.io/1047075453641369739/b164b3bb-37f8-4356-b17b-851c16f5f852/width=270,height=270.webp',
  },
}

export const IMAGE_NEW_AUTHOR_BANNER_ICON = {
  WEBP: {
    '3x': 'https://assetstorage.krrt.io/1377829835093180555/6b599693-29e3-4a4c-80c5-0112fdf18a38/width=84,height=84.webp',
    '4x': 'https://assetstorage.krrt.io/1377829835093180555/13b092ce-f780-4813-867f-0a59bd1b9c5d/width=112,height=112.webp',
  },
}
