import React from 'react'

export type JsonViewerOptions = {
  value: any
  indentWidth: number
  style: React.CSSProperties
  defaultInspectDepth: number
  theme: 'light' | 'dark'
}

declare class JsonViewer {
  constructor(options: JsonViewerOptions)

  render(id: string): void
}

export type JsonViewerType = typeof JsonViewer

export async function initJsonViewer(): Promise<JsonViewerType> {
  const result = new Promise<JsonViewerType>((res) => {
    const script = document.createElement('script')
    script.src = 'https://cdn.jsdelivr.net/npm/@textea/json-viewer@2.16.2/dist/browser.min.js'
    script.onload = () => {
      res(JsonViewer)
    }

    document.body.appendChild(script)
  })

  const jsonViewer = await result
  return jsonViewer
}
