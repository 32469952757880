import { init, type Mixpanel, type identify, type track } from 'mixpanel-browser'

import { MIXPANEL_API_KEY, IS_LOG_ENABLED, VERSION, APP_BUILD_NUMBER } from '@src/config'

type TypeIdentifyParams = Parameters<typeof identify>
type TypeTrackPrams = Parameters<typeof track>

let initialized = false
let isUserIdentifiable = false

let mixpanel: Mixpanel

export const initMixpanel = async (userId: TypeIdentifyParams['0']) => {
  // init을 실행하는 시점에 바로 initialized를 갱신해 주어야 함
  initialized = true
  mixpanel = await new Promise((res) => {
    init(MIXPANEL_API_KEY, {
      loaded: (mixpanel) => {
        res(mixpanel)
      },
    })
  })

  if (userId) {
    isUserIdentifiable = true
    mixpanel.identify(userId)
  }
}

export const logMixpanelEvent = (name: TypeTrackPrams[0], params?: TypeTrackPrams[1], callback?: TypeTrackPrams[2]) => {
  if (!IS_LOG_ENABLED) {
    // eslint-disable-next-line no-console
    console.log(
      `mixpanel event called: ${JSON.stringify([
        name,
        {
          ...params,
          app_version_number: APP_BUILD_NUMBER,
          $app_version_string: VERSION,
          $app_build_number: APP_BUILD_NUMBER,
        },
        callback,
      ])}`
    )
    return
  }
  if (!initialized) {
    // eslint-disable-next-line no-console
    console.log('mixpanel is not initialized')
    return
  }

  mixpanel.track(
    name,
    {
      ...params,
      app_version_number: APP_BUILD_NUMBER,
      $app_version_string: VERSION,
      $app_build_number: APP_BUILD_NUMBER,
    },
    callback
  )
}

export const addMixpanelSegments = (segments: string[]) => {
  if (!IS_LOG_ENABLED) {
    // eslint-disable-next-line no-console
    console.log(`mixpanel segments added: ${segments}`)
    return
  }
  if (!initialized) {
    // eslint-disable-next-line no-console
    console.log('mixpanel is not initialized')
    return
  }
  if (!isUserIdentifiable) {
    // eslint-disable-next-line no-console
    console.log('User is not identifiable')
    return
  }

  mixpanel.people.union({
    segments,
  })
}
