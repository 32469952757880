import React from 'react'

import ModalBase from '@src/packages/modal/modalBase/ModalBase'
import { css, zIndices } from '@src/stitches/stitches.config'

type Props = Omit<React.ComponentProps<typeof ModalBase>, 'bodyClassName'>

const FullSizeModal: React.FCC<Props> = ({ children, zIndex = zIndices.global1, ...props }) => {
  return (
    <ModalBase bodyClassName={bodyStyle().className} zIndex={zIndex} {...props}>
      {children}
    </ModalBase>
  )
}

export default FullSizeModal

const bodyStyle = css({
  display: 'block',
  height: '100%',
  width: '100%',
  background: '$paperDialog-semantic',
})
