/**
 * @generated SignedSource<<f3e8daee3ed88ed8fa1a7c331f1862e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { receivedApplicantBadReviewCount as userReceivedApplicantBadReviewCountResolverType } from "../graphql/resolvers/UserReceivedApplicantBadReviewCount";
import { receivedApplicantGoodReviewCount as userReceivedApplicantGoodReviewCountResolverType } from "../graphql/resolvers/UserReceivedApplicantGoodReviewCount";
import { receivedApplicantGreatReviewCount as userReceivedApplicantGreatReviewCountResolverType } from "../graphql/resolvers/UserReceivedApplicantGreatReviewCount";
export type ApplicantReviewStats_user$data = {
  readonly receivedApplicantBadReviewCount: number | null | undefined;
  readonly receivedApplicantGoodReviewCount: number | null | undefined;
  readonly receivedApplicantGreatReviewCount: number | null | undefined;
  readonly " $fragmentType": "ApplicantReviewStats_user";
};
export type ApplicantReviewStats_user$key = {
  readonly " $data"?: ApplicantReviewStats_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicantReviewStats_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplicantReviewStats_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserReceivedApplicantGreatReviewCountResolver"
      },
      "kind": "RelayResolver",
      "name": "receivedApplicantGreatReviewCount",
      "resolverModule": require('./../graphql/resolvers/UserReceivedApplicantGreatReviewCount').receivedApplicantGreatReviewCount,
      "path": "receivedApplicantGreatReviewCount"
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserReceivedApplicantGoodReviewCountResolver"
      },
      "kind": "RelayResolver",
      "name": "receivedApplicantGoodReviewCount",
      "resolverModule": require('./../graphql/resolvers/UserReceivedApplicantGoodReviewCount').receivedApplicantGoodReviewCount,
      "path": "receivedApplicantGoodReviewCount"
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserReceivedApplicantBadReviewCountResolver"
      },
      "kind": "RelayResolver",
      "name": "receivedApplicantBadReviewCount",
      "resolverModule": require('./../graphql/resolvers/UserReceivedApplicantBadReviewCount').receivedApplicantBadReviewCount,
      "path": "receivedApplicantBadReviewCount"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "864ff302285c08115b75b904d9fbb866";

export default node;
