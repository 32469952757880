import { throttle } from 'lodash'
import React from 'react'

import { useNavigator } from '@src/hooks/useNavigator'

import ScreenErrorPage from './ScreenErrorPage'

const NetworkErrorPage: React.FCC = () => {
  const { refresh } = useNavigator()

  const refreshWithThrottle = React.useMemo(() => {
    return throttle(refresh, 5000, { leading: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ScreenErrorPage
      title="인터넷 연결이 불안정해요."
      messages={['Wi-Fi나 셀룰러 데이터 연결 상태를', '확인하고 다시 시도해주세요.']}
      buttonMessage="재시도"
      onButtonClick={refreshWithThrottle}
    />
  )
}

export default NetworkErrorPage
