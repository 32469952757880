import { Event } from './types'

export type EventStorage<T extends Event> = {
  get: (activityName: string) => T[] | undefined
  pop: (activityName: string) => T[] | undefined
  add: (activityName: string, event: T) => void
  clear: () => void
}

function createEventStorage<T extends Event>({ persistKey }: { persistKey?: string }): EventStorage<T> {
  const eventStorageFromLocal = persistKey && localStorage.getItem(persistKey)
  const eventStorageFromLocalParsed = eventStorageFromLocal && JSON.parse(eventStorageFromLocal)
  const eventStorage = new Map<string, T[]>(eventStorageFromLocalParsed)

  const get = (activityName: string) => {
    return eventStorage.get(activityName)
  }

  const pop = (activityName: string) => {
    const events = eventStorage.get(activityName)
    eventStorage.delete(activityName)
    if (persistKey) {
      localStorage.setItem(persistKey, JSON.stringify([...eventStorage]))
    }
    return events
  }

  const add = (activityName: string, event: T) => {
    const _event = {
      ...event,
      createdAt: new Date(),
    }
    const events = eventStorage.get(activityName)
    if (events) {
      events.push(_event)
    } else {
      eventStorage.set(activityName, [_event])
    }
    if (persistKey) {
      localStorage.setItem(persistKey, JSON.stringify([...eventStorage]))
    }
  }

  const clear = () => {
    eventStorage.clear()
    if (persistKey) {
      localStorage.removeItem(persistKey)
    }
  }

  return {
    get,
    pop,
    add,
    clear,
  }
}

export default createEventStorage
