/**
 * @generated SignedSource<<87e22e4bd85c7ab75553eff06925be05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostAuthorOtherPostTab_jobPost$data = {
  readonly author: {
    readonly id?: string;
    readonly jobPosts?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly closed: boolean;
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"JobPostCardRecommend_jobPost">;
        };
      }>;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    };
  };
  readonly id: string;
  readonly " $fragmentType": "JobPostAuthorOtherPostTab_jobPost";
};
export type JobPostAuthorOtherPostTab_jobPost$key = {
  readonly " $data"?: JobPostAuthorOtherPostTab_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostAuthorOtherPostTab_jobPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostAuthorOtherPostTab_jobPost",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "status": [
                      "ACCEPTED",
                      "CLOSED"
                    ]
                  }
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 3
                }
              ],
              "concreteType": "JobPostConnection",
              "kind": "LinkedField",
              "name": "jobPosts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "JobPostEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "JobPost",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "closed",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "JobPostCardRecommend_jobPost"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "jobPosts(filter:{\"status\":[\"ACCEPTED\",\"CLOSED\"]},first:3)"
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};
})();

(node as any).hash = "9cf9c2bcc90499c75c7acc4f239acfc1";

export default node;
