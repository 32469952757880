import storage from '@src/api/storage'
import { API_URLS } from '@src/config'
import { captureMessage } from '@src/sdks/sentry'

import Request from './Request'

export default class Lottery extends Request {
  constructor() {
    super()

    this.setTokenHeader()
    this.setUserAgentHeader()
  }

  public async checkPrizeClaimStatus(variables: { campaignEntryOriginalId: string }) {
    try {
      const { data } = await this.post({
        url: API_URLS['lottery'],
        data: {
          query: `
        query LotteryCheckPrizeQuery($campaignEntryOriginalId: String!) {
          viewer {
            isDaangnPayUserExists
          }
          getCampaignEntryByOriginalId(campaignEntryOriginalId: $campaignEntryOriginalId) {
            id
            rewardStatus
            campaign {
              isCampaignEnded
            }
          }
        }
      `,
          variables,
        },
      })

      const isDaangnPayAccountExists = !!data.data.viewer?.isDaangnPayUserExists
      const rewardStatus = data.data.getCampaignEntryByOriginalId.rewardStatus

      return {
        rewardStatus,
        isDaangnPayAccountExists,
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      captureMessage('Lottery checkPrizeClaimStatus error', {
        extra: {
          err,
          isTokenExist: !!storage.getToken(),
        },
      })
    }
  }

  public async resendFailedCampaignEntryReward(variables: { originalCampaignEntryId: string }) {
    try {
      const { data } = await this.post({
        url: API_URLS['lottery'],
        data: {
          query: `
            mutation resendFailedCampaignEntryReward($input: ResendFailedCampaignEntryRewardInput!) {
              resendFailedCampaignEntryReward(input: $input) {
                __typename
              }
            }
          `,
          variables: {
            input: variables,
          },
        },
      })

      const isError = data.data.resendFailedCampaignEntryReward.__typename === 'ToastError'
      return !isError
    } catch (err) {
      // eslint-disable-next-line no-console
      captureMessage('Lottery resendFailedCampaignEntryReward error', {
        extra: {
          err,
          isTokenExist: !!storage.getToken(),
        },
      })
    }
  }
}
