import { CircularProgress, Portal } from '@daangn/karrot-clothes'
import React from 'react'

import { styled, keyframes, zIndices } from '@src/stitches/stitches.config'

interface Props {
  isOpen: boolean
  zIndex?: number
  animationDurationMs?: number
  isCenterMode?: boolean
}

const NonBlockingGlobalLoader: React.FCC<Props> = ({
  isOpen,
  zIndex = zIndices.global1,
  animationDurationMs = 300,
  isCenterMode = true,
}) => {
  const [isOpenState, setIsOpenState] = React.useState(isOpen)

  React.useEffect(() => {
    if (!isOpen) {
      const timerId = setTimeout(() => {
        setIsOpenState(false)
      }, animationDurationMs)
      return () => {
        timerId && clearTimeout(timerId)
      }
    }
    setIsOpenState(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Portal>
      {isOpenState ? (
        <Base
          isOpen={isOpen}
          css={{ animationDuration: `${animationDurationMs}ms`, zIndex }}
          isCenterMode={isCenterMode}>
          <CircularProgress />
        </Base>
      ) : null}
    </Portal>
  )
}

const easeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
})

const easeOut = keyframes({
  '0%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
})

const Base = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  animationFillMode: 'forwards',
  animationTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
  variants: {
    isOpen: {
      true: {
        animationName: `${easeIn}`,
      },
      false: {
        animationName: `${easeOut}`,
      },
    },
    isCenterMode: {
      true: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
})

export default NonBlockingGlobalLoader
