import { graphql, useFragment } from 'react-relay'

import { useJobPostReportMutation_jobPost$key } from '@src/__generated__/useJobPostReportMutation_jobPost.graphql'
import { APP_URLS } from '@src/config'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'

type ActionParams = {
  jobPostRef?: Nullable<useJobPostReportMutation_jobPost$key>
}

export function useJobPostReportMutation({ jobPostRef }: ActionParams) {
  const { logClickEvent } = useLogAnalyticsEvent()
  const jobPost = useFragment(
    graphql`
      fragment useJobPostReportMutation_jobPost on JobPost {
        id
        myApplication {
          status
        }
      }
    `,
    jobPostRef
  )

  const handleReportClick = () => {
    if (!jobPost) return

    logClickEvent({
      name: 'report_job_post',
      params: {
        application_status: jobPost.myApplication?.status,
      },
    })

    // TODO: generateScheme 등으로 개선 필요 ( + `/` 포함해서 encode하는 문제)
    const scheme = APP_URLS.report
    const params = encodeURIComponent(`/?kind=jobs&job_post_id=${jobPost.id}`)
    const url = `${scheme}${params}`
    window.location.href = url
  }

  return { mutateWithCallback: handleReportClick }
}
