/**
 * @generated SignedSource<<f9e24eea0c352dd5287172a416245895>>
 * @relayHash 5eebf87f37dbdbc743fc028ba2ccc2b3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5eebf87f37dbdbc743fc028ba2ccc2b3

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvertisementIndexType = "FIXED" | "FULL" | "%future added value";
export type AdvertisementPlacementType = "BMJS" | "DETAIL_BOTTOM" | "FILTER" | "MAIN" | "MJS" | "SEARCH" | "TOP_MAIN" | "%future added value";
export type ConsideredQualification = "ADULT_ALL_AGES" | "BEGINNER" | "FOREIGNER" | "MINOR" | "%future added value";
export type Day = "FRI" | "MON" | "SAT" | "SUN" | "THU" | "TUE" | "WED" | "%future added value";
export type JobTaskType = "BAKING" | "BEVERAGE_MAKING" | "CLEANING" | "CONVENIENCE_STORE" | "ETC" | "HOUSEWORK" | "KIDS_PICK_UP" | "KITCHEN_ASSISTANCE" | "KITCHEN_COOK" | "LEAFLET_DISTRIBUTION" | "LIGHT_WORK" | "NURSING" | "SERVING" | "SHOP_MANAGEMENT" | "TUTORING" | "%future added value";
export type RegionRange = "ADJACENT" | "MY" | "NO_PUBLISH" | "RANGE_2" | "RANGE_3" | "RANGE_4" | "%future added value";
export type Welfare = "ANNUAL_LEAVE" | "HOLIDAY_WORKING_ALLOWANCE" | "INSURANCE" | "MEAL_PROVIDED" | "PAID_VACATION" | "REST_TIME_GUARANTEE" | "RETIREMENT_BENEFIT" | "%future added value";
export type WorkPeriod = "LESS_THAN_A_MONTH" | "MORE_THAN_A_MONTH" | "%future added value";
export type FeedWhereInput = {
  region: number;
  regionRange?: RegionRange;
};
export type FilteredFeedFilterInput = {
  consideredQualifications?: ReadonlyArray<ConsideredQualification> | null | undefined;
  jobTasks?: ReadonlyArray<JobTaskType> | null | undefined;
  welfare?: ReadonlyArray<Welfare> | null | undefined;
  workDays?: ReadonlyArray<Day> | null | undefined;
  workPeriod?: WorkPeriod | null | undefined;
  workTime?: SearchWorkTimeInput | null | undefined;
};
export type SearchWorkTimeInput = {
  end?: string | null | undefined;
  start?: string | null | undefined;
};
export type FilteredFeedOptionInput = {
  advertisement?: FeedOptionAdvertisement | null | undefined;
  onlyImageExist?: boolean | null | undefined;
  radius?: FeedOptionRadius | null | undefined;
  removeDuplicatedOnSameReq?: boolean | null | undefined;
};
export type FeedOptionAdvertisement = {
  allowThrottling?: boolean | null | undefined;
  placement: AdvertisementPlacementType;
  size: AdvertisementIndexType;
};
export type FeedOptionRadius = {
  maxDistance: number;
  point: PointInput;
};
export type PointInput = {
  lat: number;
  lng: number;
};
export type JobPostPersonalPostsQuery$variables = {
  filter: FilteredFeedFilterInput;
  first: number;
  option?: FilteredFeedOptionInput | null | undefined;
  where: FeedWhereInput;
};
export type JobPostPersonalPostsQuery$data = {
  readonly filteredFeed: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly jobPost: {
          readonly consideredQualifications: ReadonlyArray<ConsideredQualification> | null | undefined;
          readonly jobTasks: ReadonlyArray<JobTaskType>;
          readonly welfare: ReadonlyArray<Welfare> | null | undefined;
          readonly workPeriod: WorkPeriod | null | undefined;
          readonly workplaceLocation: {
            readonly lat: number;
            readonly lng: number;
          } | null | undefined;
        };
        readonly " $fragmentSpreads": FragmentRefs<"JobPostFeedCardPersonal_feedCard">;
      };
    }>;
  };
};
export type JobPostPersonalPostsQuery = {
  response: JobPostPersonalPostsQuery$data;
  variables: JobPostPersonalPostsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "option"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "option",
    "variableName": "option"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Point",
  "kind": "LinkedField",
  "name": "workplaceLocation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lng",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTasks",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workPeriod",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consideredQualifications",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "welfare",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JobPostPersonalPostsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "FeedConnection",
        "kind": "LinkedField",
        "name": "filteredFeed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeedCard",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "jobPost",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "JobPostFeedCardPersonal_feedCard"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "JobPostPersonalPostsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "FeedConnection",
        "kind": "LinkedField",
        "name": "filteredFeed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeedCard",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "jobPost",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnail",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "closed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Region",
                        "kind": "LinkedField",
                        "name": "workplaceRegion",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name3",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "_id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bringUpCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AuthorBadge",
                                "kind": "LinkedField",
                                "name": "badge",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "items",
                                    "plural": true,
                                    "selections": [
                                      (v11/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "HoianUserAuthor",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v10/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "name": "stringifiedSalary",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "salary",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "salaryType",
                              "storageKey": null
                            }
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeedAdvertisementInfo",
                    "kind": "LinkedField",
                    "name": "advertisement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "exposureType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdvertisementCallback",
                        "kind": "LinkedField",
                        "name": "callback",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hide",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "impression",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "click",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "5eebf87f37dbdbc743fc028ba2ccc2b3",
    "metadata": {},
    "name": "JobPostPersonalPostsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ff9c2a2c1b3de8bc654b83712488fb54";

export default node;
