import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { UserReceivedApplicantGoodReviewCountResolver$key } from '@src/__generated__/UserReceivedApplicantGoodReviewCountResolver.graphql'

/**
 *
 * @RelayResolver User.receivedApplicantGoodReviewCount: Int
 * @rootFragment UserReceivedApplicantGoodReviewCountResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function receivedApplicantGoodReviewCount(userRef: UserReceivedApplicantGoodReviewCountResolver$key): number {
  const user = readFragment(
    graphql`
      fragment UserReceivedApplicantGoodReviewCountResolver on User {
        receivedJobReviewsGoodCountByApplicant: receivedJobReviewsCount(
          filter: { evaluator: APPLICANT, rating: [GOOD] }
        )
      }
    `,
    userRef
  )

  return user.receivedJobReviewsGoodCountByApplicant ?? 0
}
