import { useSelector } from 'react-redux'
import { ConnectionHandler } from 'relay-runtime'

import { meNodeIdSelector } from '@src/selectors/user'

export const useRelayConnectionHandler = () => {
  const meNodeId = useSelector(meNodeIdSelector)

  return {
    MainWritingsList_jobPosts: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'MainWritingsList_jobPosts', {
        filter: { status: ['ACCEPTED', 'PENDING'] },
      }),
    BizMainManageActiveJobPosts_activeJobPosts: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'BizMainManageActiveJobPosts_activeJobPosts', {
        filter: { status: ['PENDING', 'ACCEPTED'] },
      }),
    BizMainManageClosedJobPosts_closedJobPosts: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'BizMainManageClosedJobPosts_closedJobPosts', {
        filter: { status: ['RETURNED', 'REJECTED', 'CLOSED'] },
      }),
    MePostsApplicationsList_total_applications: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'MePostsApplicationsList_applications', {
        filter: {},
      }),
    MePostsApplicationsList_total_applications_opened: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'ApplicationsList_applications', {
        filter: { jobPostStatus: 'ACCEPTED' },
      }),
    MePostsApplicationsList_waiting_applications: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'MePostsApplicationsList_applications', {
        filter: { applicationStatus: 'WAITING' },
      }),
    MePostsApplicationsList_waiting_applications_opened: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'ApplicationsList_applications', {
        filter: { applicationStatus: 'WAITING', jobPostStatus: 'ACCEPTED' },
      }),
    MePostsApplicationsList_in_progress_applications: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'MePostsApplicationsList_applications', {
        filter: { applicationStatus: 'IN_PROGRESS' },
      }),
    MePostsApplicationsList_in_progress_applications_opened: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'ApplicationsList_applications', {
        filter: { applicationStatus: 'IN_PROGRESS', jobPostStatus: 'ACCEPTED' },
      }),
    MePostsApplicationsList_hired_applications: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'MePostsApplicationsList_applications', {
        filter: { applicationStatus: 'HIRED' },
      }),
    MePostsApplicationsList_hired_applications_opened: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'ApplicationsList_applications', {
        filter: { applicationStatus: 'HIRED', jobPostStatus: 'ACCEPTED' },
      }),
    MePostsApplicationsList_rejected_applications: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'MePostsApplicationsList_applications', {
        filter: { applicationStatus: 'REJECTED' },
      }),
    MePostsApplicationsList_rejected_applications_opened: () =>
      ConnectionHandler.getConnectionID(meNodeId, 'ApplicationsList_applications', {
        filter: { applicationStatus: 'REJECTED', jobPostStatus: 'ACCEPTED' },
      }),
    ApplicationsList_total_applications: (jobPostId: string) =>
      ConnectionHandler.getConnectionID(jobPostId, 'ApplicationsList_applications', {
        filter: {},
      }),
    ApplicationsList_waiting_applications: (jobPostId: string) =>
      ConnectionHandler.getConnectionID(jobPostId, 'ApplicationsList_applications', {
        filter: { status: 'WAITING' },
      }),
    ApplicationsList_in_progress_applications: (jobPostId: string) =>
      ConnectionHandler.getConnectionID(jobPostId, 'ApplicationsList_applications', {
        filter: { status: 'IN_PROGRESS' },
      }),
    ApplicationsList_hired_applications: (jobPostId: string) =>
      ConnectionHandler.getConnectionID(jobPostId, 'ApplicationsList_applications', {
        filter: { status: 'HIRED' },
      }),
    ApplicationsList_rejected_applications: (jobPostId: string) =>
      ConnectionHandler.getConnectionID(jobPostId, 'ApplicationsList_applications', {
        filter: { status: 'REJECTED' },
      }),
    ApplicationsReject_applications: (jobPostId: string) =>
      ConnectionHandler.getConnectionID(jobPostId, 'ApplicationsList_applications', {
        filter: { statusList: ['WAITING', 'IN_PROGRESS'], canceled: false },
      }),
  }
}
