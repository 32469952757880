/**
 * @generated SignedSource<<11add4a51bb9f1ad26887dba130a4402>>
 * @relayHash 7332725bb8562d606f7455704179dfe2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7332725bb8562d606f7455704179dfe2

import { ConcreteRequest, Query } from 'relay-runtime';
export type SubwayLine = "AIRPORT_RAILROAD" | "BUSAN_GIMHAE_LRT" | "BUSAN_METRO_LINE_1" | "BUSAN_METRO_LINE_2" | "BUSAN_METRO_LINE_3" | "BUSAN_METRO_LINE_4" | "DAEGU_METRO_LINE_1" | "DAEGU_METRO_LINE_2" | "DAEGU_METRO_LINE_3" | "DAEJEON_METRO_LINE_1" | "DONGHAE_LINE" | "GIMPO_GOLDLINE" | "GWANGJU_METRO_LINE_1" | "GYEONGCHUN_LINE" | "GYEONGGANG_LINE" | "GYEONGUI_JUNGANG_LINE" | "GYEONGUI_LINE" | "INCHEON_AIRPORT_MAGLEV_LINE" | "INCHEON_METRO_LINE_1" | "INCHEON_METRO_LINE_2" | "SEOHAE_LINE" | "SEOUL_LRT_SILLIM_LINE" | "SEOUL_LRT_UI_SINSEOL_LINE" | "SEOUL_METRO_LINE_1" | "SEOUL_METRO_LINE_2" | "SEOUL_METRO_LINE_3" | "SEOUL_METRO_LINE_4" | "SEOUL_METRO_LINE_5" | "SEOUL_METRO_LINE_6" | "SEOUL_METRO_LINE_7" | "SEOUL_METRO_LINE_8" | "SEOUL_METRO_LINE_9" | "SINBUNDANG_LINE" | "SUIN_BUNDANG_LINE" | "UIJEONGBU_LIGHT_RAIL_TRANSIT" | "YONGIN_EVERLINE" | "%future added value";
export type SearchSubwaysFilterInput = {
  maxDistance: number;
  point: PointInput;
};
export type PointInput = {
  lat: number;
  lng: number;
};
export type SubwayQuery$variables = {
  filter: SearchSubwaysFilterInput;
  skip: boolean;
};
export type SubwayQuery$data = {
  readonly searchSubways?: ReadonlyArray<{
    readonly duration: number;
    readonly lines: ReadonlyArray<SubwayLine>;
    readonly name: string;
  }> | null | undefined;
};
export type SubwayQuery = {
  response: SubwayQuery$data;
  variables: SubwayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filter",
            "variableName": "filter"
          }
        ],
        "concreteType": "Subway",
        "kind": "LinkedField",
        "name": "searchSubways",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lines",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubwayQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubwayQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7332725bb8562d606f7455704179dfe2",
    "metadata": {},
    "name": "SubwayQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "60eeed6bc5e30224ff1a47093793aa97";

export default node;
