import React from 'react'

import { useGlobalLoader } from '@src/hooks/useGlobalLoader'

const GlobalLoaderOpen: React.FCC = () => {
  const [, setOpen, setClose] = useGlobalLoader()

  React.useEffect(() => {
    setOpen()

    return () => {
      setClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default GlobalLoaderOpen
