/**
 * @generated SignedSource<<e20fcaa4ce2d53b46f4dc1d7eab9dc29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostFooter_jobPost$data = {
  readonly _id: string;
  readonly closed: boolean;
  readonly isPhoneContactable: boolean | null | undefined;
  readonly myApplication: {
    readonly canceled: boolean;
    readonly deleted: boolean;
  } | null | undefined;
  readonly phone: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostFooterApplyButton_jobPost" | "JobPostFooterLikeButton_jobPost">;
  readonly " $fragmentType": "JobPostFooter_jobPost";
};
export type JobPostFooter_jobPost$key = {
  readonly " $data"?: JobPostFooter_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostFooter_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostFooter_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPhoneContactable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Application",
      "kind": "LinkedField",
      "name": "myApplication",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canceled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deleted",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobPostFooterLikeButton_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobPostFooterApplyButton_jobPost"
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "3faa029d3ee67e196545d1d0cf44837f";

export default node;
