import React from 'react'
import { FetchPolicy, useLazyLoadQuery } from 'react-relay'

type QueryOptions = Parameters<typeof useLazyLoadQuery>[2] | undefined

export const useRefetchableQuery = (initialQueryOptions?: QueryOptions) => {
  const [queryOptions, setQueryOptions] = React.useState<QueryOptions>(initialQueryOptions)

  const refetch = React.useCallback((fetchPolicy: FetchPolicy = 'store-and-network') => {
    setQueryOptions((prev) => ({
      fetchKey: ((prev?.fetchKey as number) || 0) + 1,
      fetchPolicy,
    }))
  }, [])

  return React.useMemo(() => ({ refetch, queryOptions }), [queryOptions, refetch])
}
