import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { addEventSampleGroups } from '@src/ducks/devTool'
import { updateInitialReferrer, updatePaidAcquiredAuthor } from '@src/ducks/user'
import { addAmplitudeAcquisitions } from '@src/sdks/amplitude'
import { userInitialReferrerSelector } from '@src/selectors/user'
import { addAcquisitionToLocal, getAcquisitionsFromLocal } from '@src/utils/acquisition'

import { useUserRole } from './useUserRole'

const PAID_MARKETING_PREFIX = 'marketing_paid'
const NEW_USER_ACQUIRED_SUFFIX = 'ua'
const RETARGET_USER_ACQUIRED_SUFFIX = 're'

export const useRegisterPaidMarketingAcquisition = () => {
  const dispatch = useDispatch()
  const { isAuthor } = useUserRole()
  const serviceInitialReferrer = useSelector(userInitialReferrerSelector)
  const isNewUser = !serviceInitialReferrer

  const initialReferrer = React.useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    return params.get('referrer') ?? ''
  }, [])

  React.useEffect(() => {
    if (!serviceInitialReferrer && initialReferrer) {
      dispatch(updateInitialReferrer(initialReferrer))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const isPaidAcquisition = initialReferrer.startsWith(PAID_MARKETING_PREFIX)
    if (!isPaidAcquisition) return

    const newAcquisitionPrefix = initialReferrer
    const acquisitions = getAcquisitionsFromLocal()
    const acquisitionAlreadyRegestered = acquisitions.some((acquisition) =>
      acquisition.startsWith(newAcquisitionPrefix)
    )
    if (acquisitionAlreadyRegestered) return

    const acquisition = [
      newAcquisitionPrefix,
      isNewUser ? NEW_USER_ACQUIRED_SUFFIX : RETARGET_USER_ACQUIRED_SUFFIX,
    ].join('_')

    addAcquisitionToLocal(acquisition)
    addAmplitudeAcquisitions([acquisition])
    dispatch(addEventSampleGroups({ groups: ['PAID_USER'] }))

    if (isAuthor) {
      dispatch(updatePaidAcquiredAuthor(true))
      dispatch(addEventSampleGroups({ groups: ['PAID_JOB_POST_AUTHOR'] }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
