import { compact, intersection } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFragment, useLazyLoadQuery, graphql } from 'react-relay'

import { JobPostPersonalPosts_query$key } from '@src/__generated__/JobPostPersonalPosts_query.graphql'
import { JobPostPersonalPostsQuery } from '@src/__generated__/JobPostPersonalPostsQuery.graphql'
import BadgeSquare from '@src/components/base/badge/BadgeSquare'
import AdvertisementTag from '@src/components/common/advertisement/AdvertisementTag'
import JobPostFeedCardPersonal from '@src/components/common/jobPostCard/JobPostFeedCardPersonal'
import { jobTasksMap, welfareMap } from '@src/constants/form'
import withDeferredSuspense from '@src/hocs/withDeferredSuspense'
import { useCalcWalkingDistance } from '@src/hooks/useCalcWalkingDistance'
import { currentRegionSelector } from '@src/selectors/region'
import { currentPositionSelector, nicknameSelector } from '@src/selectors/user'
import { styled } from '@src/stitches/stitches.config'
import { JobTaskType } from '@src/types/jobPost'
import { calcShortWorkPeriod } from '@src/utils/jobPost'
import { searchRelativeJobTaks } from '@src/utils/search'
import { isKoreanName } from '@src/utils/user'

interface Props {
  queryRef: JobPostPersonalPosts_query$key
}

const JobPostPersonalPosts: React.FCC<Props> = ({ queryRef }) => {
  const jobPostPersonalPosts_query = useFragment(
    graphql`
      fragment JobPostPersonalPosts_query on Query @argumentDefinitions(id: { type: "ID!" }) {
        me {
          resume {
            birthYear
            name
          }
        }
        standardJobPost: jobPostById(id: $id) {
          workplaceRegion {
            _id
          }
          workPeriod
          jobTasks
          ...JobPostCardPersonal_jobPost
        }
      }
    `,
    queryRef
  )

  const currentRegion = useSelector(currentRegionSelector)
  const currentPosition = useSelector(currentPositionSelector)
  const relativeJobTasks = searchRelativeJobTaks({
    jobTasks: [...((jobPostPersonalPosts_query.standardJobPost?.jobTasks ?? []) as JobTaskType[])],
    count: 5,
    includeTaskSelf: true,
  })
  const isStandardShortWorkPeriod = calcShortWorkPeriod(jobPostPersonalPosts_query.standardJobPost?.workPeriod)
  const query = useLazyLoadQuery<JobPostPersonalPostsQuery>(
    graphql`
      query JobPostPersonalPostsQuery(
        $where: FeedWhereInput!
        $filter: FilteredFeedFilterInput!
        $option: FilteredFeedOptionInput
        $first: Int!
      ) {
        filteredFeed(where: $where, filter: $filter, option: $option, first: $first) {
          edges {
            node {
              jobPost {
                # eslint-disable-next-line relay/unused-fields
                workplaceLocation {
                  lat
                  lng
                }
                jobTasks
                workPeriod
                consideredQualifications
                welfare
              }
              ...JobPostFeedCardPersonal_feedCard
            }
          }
        }
      }
    `,
    {
      where: {
        region: currentRegion?.id ?? jobPostPersonalPosts_query.standardJobPost?.workplaceRegion?._id ?? 0,
        regionRange: 'ADJACENT',
      },
      filter: {
        jobTasks: relativeJobTasks,
        ...(isStandardShortWorkPeriod ? { workPeriod: 'LESS_THAN_A_MONTH' } : {}),
      },
      option: {
        advertisement: {
          placement: 'MJS',
          size: 'FULL',
        },
      },
      first: 1,
    }
  )

  const nickname = useSelector(nicknameSelector)
  const personalPostCard = query.filteredFeed.edges[0]?.node
  const personalPost = personalPostCard?.jobPost
  const isPersonalShortWorkPeriod = calcShortWorkPeriod(personalPost?.workPeriod)
  const { isWalkingDistance, meter } = useCalcWalkingDistance({
    positions: [currentPosition?.position, personalPost?.workplaceLocation],
  })
  const distanceBadge = React.useMemo(() => {
    if (!personalPost || !isWalkingDistance || !meter) return false

    switch (true) {
      case isWalkingDistance:
        return { type: 'accent', label: '걸어서 10분' } as const
      case meter < 3000:
        return { type: 'accent', label: `${Math.floor(meter / 1000) + 1}km 이내` } as const
      default:
        return undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWalkingDistance, meter])

  const periodBadge = React.useMemo(() => {
    if (!personalPost) return undefined

    return isStandardShortWorkPeriod
      ? isPersonalShortWorkPeriod
        ? ({ type: 'basic', label: '단기' } as const)
        : undefined
      : ({ type: 'basic', label: isPersonalShortWorkPeriod ? '단기' : '장기' } as const)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPostPersonalPosts_query, query])

  const qualitificationBadge = React.useMemo(() => {
    if (!personalPost) return undefined

    const age = !jobPostPersonalPosts_query.me?.resume?.birthYear
      ? undefined
      : new Date().getFullYear() - jobPostPersonalPosts_query.me.resume.birthYear + 1

    const qualifications = personalPost.consideredQualifications
    return age && age < 20 && qualifications?.includes('MINOR')
      ? ({ type: 'basic', label: '미성년자 가능' } as const)
      : age && age >= 50 && qualifications?.includes('ADULT_ALL_AGES')
        ? ({ type: 'basic', label: '연령 무관' } as const)
        : !!jobPostPersonalPosts_query.me?.resume?.name &&
            !isKoreanName(jobPostPersonalPosts_query.me.resume.name) &&
            qualifications?.includes('FOREIGNER')
          ? ({ type: 'basic', label: '외국인 가능' } as const)
          : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPostPersonalPosts_query, query])

  const welfareBadge = React.useMemo(() => {
    if (!personalPost || !personalPost.welfare || personalPost.welfare.length === 0) return undefined

    const weflare = welfareMap[personalPost.welfare[0] as keyof typeof welfareMap]
    return {
      type: 'basic',
      label: weflare,
    } as const
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPostPersonalPosts_query, query])

  const workPeriodBadges = React.useMemo(() => {
    if (!personalPost) return []

    const jobTasks = intersection(
      jobPostPersonalPosts_query.standardJobPost?.jobTasks ?? [],
      personalPost.jobTasks ?? []
    )
    return jobTasks.map(
      (task) =>
        ({
          type: 'basic',
          label: jobTasksMap[task as keyof typeof jobTasksMap],
        }) as const
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPostPersonalPosts_query, query])

  if (!personalPost) return null

  return (
    <Section>
      <SectionTitleContainer>
        <SectionTitle>{nickname}님을 위한 추천 알바</SectionTitle>
        <AdvertisementTagWrapper>
          <AdvertisementTag />
        </AdvertisementTagWrapper>
      </SectionTitleContainer>
      <SectionChipArea>
        {compact([distanceBadge, periodBadge, qualitificationBadge, welfareBadge, ...workPeriodBadges]).map(
          (badge, i) => {
            return (
              <StyledBadgeSquare type={badge.type} key={i} size="large">
                {badge.label}
              </StyledBadgeSquare>
            )
          }
        )}
      </SectionChipArea>
      <CardWrapper>
        <JobPostFeedCardPersonal
          feedCardRef={personalPostCard}
          advertisement={{ isAdvertisement: true }}
          cardIndex={0}
        />
      </CardWrapper>
    </Section>
  )
}

export default withDeferredSuspense(JobPostPersonalPosts)

const Section = styled('div', {
  padding: '20px 0',
  borderBottom: '8px solid $gray100',

  '&:last-of-type': {
    border: 'none',
  },
})

const SectionTitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const SectionTitle = styled('div', {
  $text: 'title3Bold',
  padding: '0 16px',
})

const AdvertisementTagWrapper = styled('div', {
  margin: '0 16px 0 0',
})

const CardWrapper = styled('div', {
  padding: '12px 16px 0',
})

const SectionChipArea = styled('div', {
  margin: '8px 16px 0',
})

const StyledBadgeSquare = styled(BadgeSquare, {
  margin: '0 0 0 6px',

  '&:first-of-type': {
    margin: 0,
  },
})
