import React from 'react'
import zenscroll from 'zenscroll'

const SCROLL_DURATION = 400

export const useScrollToElement = (scrollRef: React.RefObject<any>) => {
  const scrollToElement = React.useCallback(
    ({ offset = 0, onDone }: { offset?: number; onDone?: () => void } = {}) => {
      const el = scrollRef.current
      const rect = el?.getBoundingClientRect()
      const containerEl = getScrollParent(el)
      const containerRect = containerEl?.getBoundingClientRect()

      if (!el || !containerEl) return

      const scroller = zenscroll.createScroller(containerEl)
      scroller.toY(
        containerEl.scrollTop + offset + (rect?.top ?? 0) - (containerRect?.top ?? 0),
        SCROLL_DURATION,
        onDone
      )
    },
    [scrollRef]
  )

  return scrollToElement
}

function getScrollParent(node: any): HTMLElement | null {
  if (!node) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentNode)
  }
}
