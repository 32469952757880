import Experimentation from '@src/api/Experimentation'
import Identification from '@src/api/Identification'
import Lottery from '@src/api/Lottery'
import { API_URLS } from '@src/config'
import { BIG_PICTURE_KEY, DISCOVERY_KEY, EXPERIMENTATION, IDENTIFICATION, JOBS_KEY } from '@src/constants/api'

import Advertisement from './Advertisement'
import Aws from './Aws'
import BigPicture from './BigPicture'
import Discovery from './Discovery'
import Jobs from './Jobs'
import storage from './storage'

const urls = API_URLS

export const jobsUrl = {
  url: urls[JOBS_KEY],
  get() {
    return this.url
  },
  set() {
    this.url = API_URLS[JOBS_KEY]
  },
}

export const bigPicture = new BigPicture(urls[BIG_PICTURE_KEY])
export const aws = new Aws()
export const advertisement = new Advertisement()
export const jobs = new Jobs(urls[JOBS_KEY])
export const discovery = new Discovery(urls[DISCOVERY_KEY])
export const lottery = new Lottery()
export const identification = new Identification(urls[IDENTIFICATION])
export const experimentation = new Experimentation(urls[EXPERIMENTATION])
export const setStorageData = (accessToken?: string, userAgent?: string) => {
  accessToken && storage.setToken(accessToken)
  userAgent && storage.setUserAgent(userAgent)
}
