import { IconInfoRegular } from '@seed-design/icon'
import React from 'react'

import Tag from '@src/components/base/tag/Tag'
import useModalState from '@src/hooks/useModalState'
import { styled, theme } from '@src/stitches/stitches.config'

import AdvertisementDisplayHalfview from './AdvertisementDisplayHalfview'
import AdvertisementDisplayPolicyHalfview from './AdvertisementDisplayPolicyHalfview'

type Props = {
  onIgnoreJobPostClick?: () => void
}

const AdvertisementTag: React.FCC<Props> = ({ onIgnoreJobPostClick }) => {
  const [isPolicyHalfviewOpen, openPolicyHalfview, closePolicyHalfview] = useModalState()
  const [isDisplayHalfviewOpen, openDisplayHalfview, closeDisplayHalfview] = useModalState()

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (!onIgnoreJobPostClick) {
      openPolicyHalfview()
      return
    }

    openDisplayHalfview()
  }

  const handlePolicyHalfviewCloseClick = () => {
    closePolicyHalfview()
  }

  const handleDisplayHalfviewCloseClick = (cb?: () => void) => {
    closeDisplayHalfview(cb)
  }
  const handleAdvertisementIgnore = () => {
    onIgnoreJobPostClick?.()
  }

  return (
    <>
      <Tag size="small" onClick={handleClick} noWrap={false}>
        <TagContent>
          <Text>광고</Text>
          <IconInfoRegular width={14} height={14} color={theme.colors.gray600.computedValue} />
        </TagContent>
      </Tag>
      {isPolicyHalfviewOpen && <AdvertisementDisplayPolicyHalfview onCloseClick={handlePolicyHalfviewCloseClick} />}
      <AdvertisementDisplayHalfview
        isOpen={isDisplayHalfviewOpen}
        onCloseClick={handleDisplayHalfviewCloseClick}
        onIgnoreJobPostClick={handleAdvertisementIgnore}
      />
    </>
  )
}

export default AdvertisementTag

const TagContent = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
})

const Text = styled('span', {
  margin: '0 2px 0 0',
})
