/**
 * @generated SignedSource<<a20759b343b83cfc556df442c9b63b24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useJobPostShareMutation_jobPost$data = {
  readonly _id: string;
  readonly author: {
    readonly badge?: {
      readonly items: ReadonlyArray<{
        readonly __typename: string;
      }>;
    } | null | undefined;
  };
  readonly bringUpCount: number;
  readonly permalink: {
    readonly publicId: string;
  };
  readonly title: string | null | undefined;
  readonly " $fragmentType": "useJobPostShareMutation_jobPost";
};
export type useJobPostShareMutation_jobPost$key = {
  readonly " $data"?: useJobPostShareMutation_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"useJobPostShareMutation_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useJobPostShareMutation_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Permalink",
      "kind": "LinkedField",
      "name": "permalink",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AuthorBadge",
              "kind": "LinkedField",
              "name": "badge",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "items",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bringUpCount",
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "dea5838e9f77522790f3190e4b16d3eb";

export default node;
