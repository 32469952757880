import React from 'react'

import Button from '@src/components/base/buttons/Button'
import Halfview from '@src/packages/halfview/Halfview'
import { styled } from '@src/stitches/stitches.config'

type Props = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const JobPostGwangsanguHalfview: React.FCC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const ref = React.useRef<() => void>()

  const handleConfirm = () => {
    ref.current = onConfirm
    onClose()
  }

  const handleClosed = () => {
    ref.current?.()
    ref.current = undefined
  }

  return (
    <Halfview open={isOpen} onCloseClick={onClose} onClosed={handleClosed}>
      <Container>
        <Title>
          주민등록등본상 거주지가
          <br />
          광산구가 맞나요?
        </Title>
        <Description>이 공고는 광산구 주민만 지원할 수 있어요.</Description>

        <ButtonContainer>
          <Button priority="primary" size="xlarge" onClick={handleConfirm}>
            네, 맞아요
          </Button>
        </ButtonContainer>
      </Container>
    </Halfview>
  )
}

export default JobPostGwangsanguHalfview

const Container = styled('div', {
  padding: '0 16px',
})

const Title = styled('div', {
  margin: '24px 0 0',
  $text: 'title2Bold',
})

const Description = styled('div', {
  margin: '8px 0 0',
  $text: 'bodyL1Regular',
  color: '$gray700',
})

const ButtonContainer = styled('div', {
  margin: '16px 0 0',
  padding: '12px 0 8px',
})
