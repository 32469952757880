import React from 'react'

import { theme } from '@src/stitches/stitches.config'
type Props = {
  isSelected?: boolean
  isDisabled?: boolean
} & IconProps

const CheckCircle: React.FCC<Props> = ({ isSelected = false, isDisabled = false, ...props }) => {
  switch (true) {
    case isSelected && isDisabled:
      return (
        <CheckCircleIcon
          stroke={theme.colors.gray300.computedValue}
          bgFill={theme.colors.gray300.computedValue}
          checkFill={theme.colors.gray500.computedValue}
          {...props}
        />
      )
    case isSelected && !isDisabled:
      return (
        <CheckCircleIcon
          stroke={theme.colors['primary-semantic'].computedValue}
          bgFill={theme.colors['primary-semantic'].computedValue}
          checkFill={theme.colors['onPrimary-semantic'].computedValue}
          {...props}
        />
      )
    case !isSelected && isDisabled:
      return (
        <CheckCircleIcon
          stroke={theme.colors.gray300.computedValue}
          bgFill={theme.colors.gray300.computedValue}
          checkFill="transparent"
          {...props}
        />
      )
    case !isSelected && !isDisabled:
      return (
        <CheckCircleIcon
          stroke={theme.colors.gray300.computedValue}
          bgFill="transparent"
          checkFill="transparent"
          {...props}
        />
      )
    default:
      return null
  }
}

export default CheckCircle

type IconProps = {
  width?: number
  height?: number
  stroke?: string
  bgFill?: string
  checkFill?: string
} & JSX.IntrinsicElements['svg']

const CheckCircleIcon: React.FCC<IconProps> = ({
  width = 24,
  height = 24,
  stroke = theme.colors.gray300.computedValue,
  bgFill = 'transparent',
  checkFill = 'transparent',
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" fill={bgFill} stroke={stroke} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.83 7.38984C18.2269 7.67556 18.3169 8.22889 18.0312 8.62574L11.9097 17.1279C11.4514 17.7643 10.5248 17.8206 9.99285 17.2444L6.03706 12.9589C5.70538 12.5996 5.72779 12.0395 6.08711 11.7078C6.44643 11.3761 7.00659 11.3985 7.33827 11.7578L10.8527 15.5652L16.5941 7.59103C16.8798 7.19419 17.4332 7.10411 17.83 7.38984Z"
        fill={checkFill}
      />
    </svg>
  )
}
