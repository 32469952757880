import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type Event = {
  type: string
  name: string
  hasJobPost: boolean
}

type EventState = {
  events: Event[]
}

const INITIAL_STATE: EventState = {
  events: [],
}

const eventSlice = createSlice({
  name: 'event',
  initialState: INITIAL_STATE,
  reducers: {
    setEvents: (state, { payload }: PayloadAction<EventState['events']>) => {
      state.events = payload
    },
  },
})

export const { setEvents } = eventSlice.actions

export default eventSlice.reducer
