import React from 'react'

type Props = {
  width?: number
  height?: number
} & JSX.IntrinsicElements['svg']

const Manner03: React.FCC<Props> = ({ width = 24, height = 24, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FADD65"
      />
      <path
        d="M9.33177 15.9175L9.33045 15.9164L9.32897 15.9152C9.08969 15.7231 8.72975 15.7547 8.53166 15.9899C8.33069 16.2286 8.36328 16.5833 8.60446 16.7821L8.60659 16.7839L8.60907 16.7859L8.6151 16.7907C8.63677 16.8076 8.65874 16.8241 8.68119 16.84C8.72211 16.8689 8.77857 16.9064 8.85117 16.9495C8.99646 17.0358 9.20596 17.1441 9.48434 17.25C10.0424 17.4624 10.8696 17.6625 12 17.6625C13.1305 17.6625 13.9576 17.4624 14.5157 17.25C14.7941 17.1441 15.0036 17.0358 15.1489 16.9495C15.2215 16.9064 15.2779 16.8689 15.3188 16.84C15.3434 16.8226 15.3675 16.8047 15.391 16.7859L15.3934 16.7839L15.3951 16.7825C15.6362 16.5837 15.6693 16.2286 15.4683 15.9899C15.2536 15.7349 14.9132 15.7442 14.658 15.9245C14.6409 15.9366 14.6098 15.9577 14.5639 15.9849C14.4724 16.0392 14.3219 16.1185 14.1076 16.2C13.6804 16.3626 12.9917 16.5375 12 16.5375C11.0084 16.5375 10.3196 16.3626 9.89244 16.2C9.67816 16.1185 9.52764 16.0392 9.43608 15.9849C9.43482 15.9841 9.33146 15.9172 9.33177 15.9175Z"
        fill="#895F00"
      />
      <path
        d="M7.80019 13.35C8.54578 13.35 9.15019 12.4098 9.15019 11.25C9.15019 10.0902 8.54578 9.14999 7.80019 9.14999C7.05461 9.14999 6.4502 10.0902 6.4502 11.25C6.4502 12.4098 7.05461 13.35 7.80019 13.35Z"
        fill="#895F00"
      />
      <path
        d="M16.2001 13.35C16.9457 13.35 17.5501 12.4098 17.5501 11.25C17.5501 10.0902 16.9457 9.14999 16.2001 9.14999C15.4545 9.14999 14.8501 10.0902 14.8501 11.25C14.8501 12.4098 15.4545 13.35 16.2001 13.35Z"
        fill="#895F00"
      />
      <path
        opacity="0.85"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88511 6.61809L5.88346 6.62134C5.88453 6.61939 5.88746 6.61416 5.89174 6.60714C5.90035 6.59302 5.91526 6.57007 5.93683 6.54185C5.9804 6.48488 6.04828 6.4102 6.14285 6.34265C6.32036 6.21585 6.62069 6.09174 7.10894 6.2138C7.30986 6.26403 7.51346 6.14187 7.56369 5.94095C7.61392 5.74003 7.49176 5.53643 7.29084 5.48619C6.57909 5.30826 6.05441 5.48414 5.70692 5.73235C5.53899 5.8523 5.41937 5.98387 5.34107 6.08627C5.30171 6.13774 5.27208 6.18275 5.25139 6.21668L5.21448 6.28229C5.12186 6.46754 5.19694 6.69279 5.38218 6.78541C5.5662 6.87742 5.78972 6.80392 5.88346 6.62134"
        fill="#D49D3A"
      />
      <path
        opacity="0.85"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4677 6.78541C18.2837 6.87742 18.0602 6.80393 17.9664 6.62134C17.9663 6.6212 17.9665 6.62155 17.9664 6.62134C17.9654 6.6194 17.9624 6.61416 17.9581 6.60714C17.9495 6.59302 17.9346 6.57007 17.913 6.54185C17.8695 6.48488 17.8016 6.4102 17.707 6.34265C17.5295 6.21585 17.2292 6.09174 16.7409 6.2138C16.54 6.26403 16.3364 6.14187 16.2862 5.94095C16.236 5.74003 16.3581 5.53643 16.559 5.48619C17.2708 5.30826 17.7955 5.48414 18.143 5.73235C18.3109 5.8523 18.4305 5.98387 18.5088 6.08627C18.5482 6.13774 18.5778 6.18275 18.5985 6.21669C18.6089 6.23369 18.617 6.24801 18.6231 6.2591L18.6345 6.28055C18.7267 6.46355 18.6512 6.69364 18.4677 6.78541Z"
        fill="#D49D3A"
      />
    </svg>
  )
}

export default Manner03
