/**
 * @generated SignedSource<<7e699daa695c730b6aba0498ba100f34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventGroupType = "BUDDHA_BIRTHDAY" | "CHILDREN_DAY" | "CHRISTMAS" | "Chuseok" | "DAANGN_EVENT" | "LunarNewYear" | "MEMORIAL_DAY" | "PEPERO_DAY" | "WHITE_DAY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobPostJobPost_jobPost$data = {
  readonly _author: {
    readonly hoianUser?: {
      readonly _id: number;
      readonly id: string;
    };
    readonly id?: string;
  };
  readonly eventGroup: {
    readonly type: EventGroupType;
  } | null | undefined;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly url: string;
  }> | null | undefined;
  readonly maskingContent: string | null | undefined;
  readonly maskingTitle: string | null | undefined;
  readonly unMaskingContent: string | null | undefined;
  readonly unMaskingTitle: string | null | undefined;
  readonly workplaceLocation: {
    readonly lat: number;
    readonly lng: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicantStatus_jobPost" | "AuthorSection_jobPost" | "Content_jobPost" | "CountStatus_jobPost" | "Info_jobPost" | "JobPostFilterAlarmRegistration_jobPost" | "KakaoMap_jobPost" | "KarrotMap_jobPost">;
  readonly " $fragmentType": "JobPostJobPost_jobPost";
};
export type JobPostJobPost_jobPost$key = {
  readonly " $data"?: JobPostJobPost_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostJobPost_jobPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "masking",
    "value": true
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "masking",
    "value": false
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostJobPost_jobPost",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "_author",
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "hoianUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "_id",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventGroup",
      "kind": "LinkedField",
      "name": "eventGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "maskingContent",
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "content",
      "storageKey": "content(masking:true)"
    },
    {
      "alias": "unMaskingContent",
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "content",
      "storageKey": "content(masking:false)"
    },
    {
      "alias": "maskingTitle",
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(masking:true)"
    },
    {
      "alias": "unMaskingTitle",
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(masking:false)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Point",
      "kind": "LinkedField",
      "name": "workplaceLocation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lng",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthorSection_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Info_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Content_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KakaoMap_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KarrotMap_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CountStatus_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobPostFilterAlarmRegistration_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicantStatus_jobPost"
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};
})();

(node as any).hash = "0cf792f206f1e026e3965348c09ae55f";

export default node;
