import { TextButton } from '@daangn/sprout-components-button'
import { IconChevronRightFill } from '@seed-design/icon'
import React from 'react'
import { graphql, useFragment } from 'react-relay'

import { AuthorSection_jobPost$key } from '@src/__generated__/AuthorSection_jobPost.graphql'
import Spacer from '@src/components/base/Spacer'
import JobPostAuthorReputationTabs from '@src/components/pageJobPost/JobPostAuthorReputationTabs'
import { SHOW_AUTHOR_SECTION } from '@src/constants/action'
import { useJobsActivityParams } from '@src/hooks/useJobsActivityParams'
import { useNavigator } from '@src/hooks/useNavigator'
import { useScrollToElement } from '@src/hooks/useScrollToElement'
import { JobsActivity } from '@src/router/activityNames'
import { styled } from '@src/stitches/stitches.config'

import Author from './Author'
import AuthorStats from './AuthorStats'

type Props = {
  jobPostRef: AuthorSection_jobPost$key
}

const SCROLL_DELAY = 400

const AuthorSection: React.FCC<Props> = ({ jobPostRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment AuthorSection_jobPost on JobPost {
        id
        companyName
        ...Author_jobPost
        ...AuthorStats_jobPost
        ...JobPostAuthorReputationTabs_jobPost
      }
    `,
    jobPostRef
  )

  const { actions } = useJobsActivityParams<typeof JobsActivity.JOB_POST>()
  const { push } = useNavigator()
  const isPersonal = !jobPost.companyName
  const authorSectionRef = React.useRef<HTMLDivElement>(null)
  const scrollToElement = useScrollToElement(authorSectionRef)

  const handleRouteToBusinessInfoClick = () => {
    push(JobsActivity.JOB_POST_BUSINESS_CERTIFICATE, { jobPostId: jobPost.id })
  }

  const conditionalInfo = isPersonal
    ? {
        title: '함께 일할 분이에요',
        buttonText: '구인자 정보',
        handler: handleRouteToBusinessInfoClick,
      }
    : {
        title: '함께 일할 사업자예요',
        buttonText: '사업자 정보',
        handler: handleRouteToBusinessInfoClick,
      }

  React.useEffect(() => {
    const clearIds: Array<NodeJS.Timeout> = []

    actions?.forEach((action) => {
      if (action === SHOW_AUTHOR_SECTION) {
        const clearId = setTimeout(() => {
          scrollToElement({ offset: 200 })
        }, SCROLL_DELAY)

        clearIds.push(clearId)
      }
    })

    return () => {
      clearIds.forEach((id) => clearTimeout(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TitleContainer ref={authorSectionRef}>
        <Title>{conditionalInfo.title}</Title>
        <RouteToBusinessInfo onClick={conditionalInfo.handler}>
          <TextButton variant="secondary" size="small">
            {conditionalInfo.buttonText}
          </TextButton>
          <IconChevronRight width={14} height={14} />
        </RouteToBusinessInfo>
      </TitleContainer>
      <Spacer h={16} />
      <Author jobPostRef={jobPost} />
      <Spacer h={16} />
      <AuthorStats jobPostRef={jobPost} />
      <JobPostAuthorReputationTabs jobPostRef={jobPost} />
    </>
  )
}

export default AuthorSection

const TitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
})

const Title = styled('div', {
  $text: 'title3Bold',
})

const RouteToBusinessInfo = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const IconChevronRight = styled(IconChevronRightFill, {
  margin: '0 0 0 2px',
  color: '$gray900',
})
