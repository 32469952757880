import {
  useFloating,
  offset as offsetFn,
  flip,
  shift,
  Placement,
  arrow,
  Padding,
  Strategy,
  useDismiss,
  useRole,
  useInteractions,
  FloatingArrow,
} from '@floating-ui/react'
import { IconCloseRegular } from '@seed-design/icon'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import { styled, theme } from '@src/stitches/stitches.config'

const TRANSITION_MS = 200

type ChildrenProps = {
  ref: (arg0: Element | null) => void | null
  onOpen?: React.MouseEventHandler<Element> | (() => void)
  referenceProps: Record<string, unknown>
}

type Props = {
  onClose?: () => void
  children: (arg0: ChildrenProps) => React.ReactNode
  content: string | React.ReactNode
  placement: Placement
  duration?: number
  visible?: boolean
  strategy?: Strategy
  /**
   * Implement explict close icon or tolerate until duration
   */
  closeTrigger?: 'icon' | 'duration'
  arrowOffset?: Padding
  offset?: [number | null | undefined, number | null | undefined]
  fallbackPlacements?: [Placement]
  type?: 'gray' | 'white'
  preventOverflow?: boolean
  closeOnInteractOutside?: boolean
}

const Tooltip: React.FC<Props> = ({
  onClose,
  children,
  content,
  placement,
  duration = 3000,
  visible = false,
  strategy = 'absolute',
  closeTrigger = 'duration',
  arrowOffset,
  offset,
  fallbackPlacements,
  preventOverflow = true,
  type = 'gray',
  closeOnInteractOutside = false,
}) => {
  const [isOpened, setIsOpened, openTooltip, closeTooltip] = useTooltipState({ onClose })
  const arrowRef = React.useRef(null)
  const transitionRef = React.useRef(null)

  const { refs, floatingStyles, context } = useFloating({
    strategy,
    placement,
    open: isOpened,
    onOpenChange: setIsOpened,
    middleware: [
      offsetFn({ crossAxis: offset?.[0] ?? 0, mainAxis: offset?.[1] ?? 0 }),
      flip({ fallbackPlacements, mainAxis: false, crossAxis: false }),
      shift({ mainAxis: preventOverflow, crossAxis: false, padding: 16 }),
      arrow({ element: arrowRef, padding: arrowOffset }),
    ],
  })

  const dismiss = useDismiss(context, { enabled: closeOnInteractOutside })
  const role = useRole(context, { role: 'tooltip' })

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, role])

  useForceUpdate({ status: visible, fn: setIsOpened })
  useTimeoutTransition({ possible: isOpened && closeTrigger === 'duration', duration, cb: closeTooltip })

  return (
    <>
      <PositionWrapper strategy={strategy}>
        {children({ ref: refs.setReference, onOpen: openTooltip, referenceProps: getReferenceProps() })}
      </PositionWrapper>

      <StyledCSSTransition nodeRef={transitionRef} in={isOpened} timeout={TRANSITION_MS} unmountOnExit>
        <TooltipItemWrapper ref={transitionRef}>
          <TooltipItem role="tooltip" ref={refs.setFloating} style={floatingStyles} type={type} {...getFloatingProps()}>
            {typeof content === 'string' ? <Text>{content}</Text> : content}
            {closeTrigger === 'icon' && (
              <CloseIconWrapper>
                <CloseIcon
                  width={16}
                  height={16}
                  color={
                    type === 'gray'
                      ? theme.colors['paperDefault-semantic'].computedValue
                      : theme.colors['black-static'].computedValue
                  }
                  onClick={closeTooltip}
                />
              </CloseIconWrapper>
            )}
            <Arrow ref={arrowRef} context={context} type={type} />
          </TooltipItem>
        </TooltipItemWrapper>
      </StyledCSSTransition>
    </>
  )
}

export default Tooltip

function useTooltipState({ onClose }: { onClose?: () => void }) {
  const onCloseRef = React.useRef<() => void>()
  const [isOpened, setIsOpened] = React.useState<boolean>()
  const openTooltip = React.useCallback((e?: React.MouseEvent<Element>) => {
    e?.stopPropagation()
    setIsOpened(true)
  }, [])
  const closeTooltip = React.useCallback((e?: React.MouseEvent<Element>) => {
    e?.stopPropagation()
    setIsOpened(false)
    onCloseRef.current?.()
  }, [])

  React.useEffect(() => {
    onCloseRef.current = onClose
  }, [onClose])

  return [isOpened, setIsOpened, openTooltip, closeTooltip] as const
}

function useForceUpdate<T>({ status, fn }: { status: T; fn: React.Dispatch<React.SetStateAction<T | undefined>> }) {
  React.useEffect(() => {
    fn(status)
  }, [status, fn])
}

function useTimeoutTransition({
  possible,
  duration,
  cb,
}: {
  possible?: boolean
  duration?: number
  cb: (e?: React.MouseEvent<Element, MouseEvent> | undefined) => void
}) {
  React.useEffect(() => {
    if (possible && duration !== undefined) {
      const clearId = setTimeout(cb, duration)
      return () => {
        cb()
        clearTimeout(clearId)
      }
    }
  }, [possible, duration, cb])
}

const PositionWrapper: React.FCC<React.PropsWithChildren<{ strategy: Strategy; children: React.ReactNode }>> = ({
  children,
  strategy,
}) => {
  if (strategy === 'fixed') {
    return <>{children}</> || null
  }
  return <RelativePositionWrapper>{children}</RelativePositionWrapper>
}

const StyledCSSTransition = styled(CSSTransition, {
  '&.enter': {
    opacity: 0,
  },
  '&.enter-active': {
    opacity: 1,
    transition: `opacity ${TRANSITION_MS}ms`,
  },
  '&.exit': {
    opacity: 1,
  },
  '&.exit-active': {
    opacity: 0,
    transition: `opacity ${TRANSITION_MS}ms`,
  },
})

const TooltipItemWrapper = styled('div', {
  zIndex: '$page1',
})

const TooltipItem = styled('div', {
  $text: 'caption1Bold',
  display: 'flex',
  alignItems: 'flex-start',
  width: 'max-content',
  // https://popper.js.org/docs/v2/faq/#my-popper-is-bigger-than-the-viewport-what-do-i-do
  maxWidth: 'calc(100vw - 32px)',
  padding: '8px 12px',
  borderRadius: '6px',

  variants: {
    type: {
      gray: {
        color: '$gray00',
        background: '$gray900',
      },
      white: {
        color: '$black-static',
        background: '$white-static',
      },
    },
  },
})

const Arrow = styled(FloatingArrow, {
  position: 'absolute',
  width: '8px',
  height: '8px',
  variants: {
    type: {
      gray: {
        fill: '$gray900',
      },
      white: {
        fill: '$black-static',
      },
    },
  },
})

const CloseIconWrapper = styled('span', {
  $text: 'caption1Bold',
  svg: {
    verticalAlign: 'text-top',
  },
})

const CloseIcon = styled(IconCloseRegular, {
  margin: '0 0 0 8px',
})

const Text = styled('div')

const RelativePositionWrapper = styled('div', {
  position: 'relative',
})

// External Usage

export const TooltipContent = styled('div', {
  wordBreak: 'keep-all',
  whiteSpace: 'pre-wrap',
})

export const TooltipTargetContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
})
