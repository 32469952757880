import { IS_DEV, SESSION_ID, VERSION } from '@src/config'
import { appInfoStorage } from '@src/utils/appInfo'

import { karrotBridge } from './bridge'

const handleBrazeAnalytics = (fn: () => void) => {
  if (
    appInfoStorage.satisfyAppVersion('Android', '23.38.0', '>=') ||
    appInfoStorage.satisfyAppVersion('iOS', '23.38.0', '>=')
  ) {
    fn()
    return
  }

  console.warn(
    `[logBrazeEvent] 현재 앱 버전은 ${
      appInfoStorage.getAppInfo().appVersion
    } 이에요. 23.38.0 이상의 버전에서만 지원돼요.`
  )
}

// Braze 연동 관련 문서 - https://www.notion.so/daangn/_20231016-3d1f1d4f4518431784cab560050ebceb
export const logBrazeEvent = (data: { name: string; params: { [key: string]: any } }): void => {
  const event = { ...data, params: JSON.stringify({ ...data.params, session_id: SESSION_ID, version: VERSION }) }

  if (IS_DEV) {
    // eslint-disable-next-line no-console
    console.log(`analytics event called: ${JSON.stringify(event)}`)
    return
  }

  handleBrazeAnalytics(() => {
    karrotBridge.logEvent({
      analytics: {
        target: 'BRAZE',
        ...event,
      },
    })
  })
}
