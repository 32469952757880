/**
 * @generated SignedSource<<25c77e3899b5abb8753ed2561df6312b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Subway_jobPost$data = {
  readonly workplaceLocation: {
    readonly lat: number;
    readonly lng: number;
  } | null | undefined;
  readonly " $fragmentType": "Subway_jobPost";
};
export type Subway_jobPost$key = {
  readonly " $data"?: Subway_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"Subway_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Subway_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Point",
      "kind": "LinkedField",
      "name": "workplaceLocation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lng",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "999b9810f22ae531f6cb4a61dd9cd88d";

export default node;
