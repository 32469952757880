import React from 'react'
type Props = {
  size: number
  color: string
}

const ColorBox: React.FC<Props> = ({ size, color }) => {
  return (
    <div
      style={{
        border: '1px solid $grayAlpha200',
        width: size,
        height: size,
        backgroundColor: color,
      }}
    />
  )
}

export default ColorBox
