/**
 * @generated SignedSource<<741c9ccabb51d3781585890ed33b4848>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostFeedCardPersonal_feedCard$data = {
  readonly jobPost: {
    readonly " $fragmentSpreads": FragmentRefs<"JobPostCardPersonal_jobPost">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"JobPostCardContainer_feedCard">;
  readonly " $fragmentType": "JobPostFeedCardPersonal_feedCard";
};
export type JobPostFeedCardPersonal_feedCard$key = {
  readonly " $data"?: JobPostFeedCardPersonal_feedCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostFeedCardPersonal_feedCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostFeedCardPersonal_feedCard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JobPost",
      "kind": "LinkedField",
      "name": "jobPost",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "JobPostCardPersonal_jobPost"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobPostCardContainer_feedCard"
    }
  ],
  "type": "FeedCard",
  "abstractKey": null
};

(node as any).hash = "b5209fd54388436314cef2d928cfaa90";

export default node;
