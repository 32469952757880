import React from 'react'
import { useRelayEnvironment, graphql } from 'react-relay'
import { fetchQuery } from 'relay-runtime'

import { usePhoneMappingMutationByApplicantQuery } from '@src/__generated__/usePhoneMappingMutationByApplicantQuery.graphql'
import { usePhoneMappingMutationByAuthorQuery } from '@src/__generated__/usePhoneMappingMutationByAuthorQuery.graphql'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { getPassedMinutes } from '@src/utils/date'
import { handleRelayError } from '@src/utils/error'
import { checkWalkingDistance } from '@src/utils/jobPost'

export function usePhoneMappingMutation() {
  const environment = useRelayEnvironment()
  const { logClickEvent } = useLogAnalyticsEvent()
  const [isLoading, setLoading] = React.useState(false)

  const mutateWithCallbackByApplicant = ({
    jobPostId,
    onSuccess,
    onFailure,
  }: {
    jobPostId: string
    onSuccess?: () => void
    onFailure?: () => void
  }) => {
    if (isLoading) return
    setLoading(true)

    return fetchQuery<usePhoneMappingMutationByApplicantQuery>(
      environment,
      graphql`
        query usePhoneMappingMutationByApplicantQuery($id: ID!) {
          jobPostById(id: $id) {
            _id
            phoneMapping {
              safeNumber
            }
            myApplication {
              __typename
            }
          }
        }
      `,
      {
        id: jobPostId,
      }
    )
      .toPromise()
      .then((res) => {
        const safeNumber = res?.jobPostById?.phoneMapping?.safeNumber
        if (!safeNumber) return
        window.location.href = `tel:${safeNumber}`
        logClickEvent({
          name: 'phone_call',
          params: {
            role: 'APPLICANT',
            job_post_id: res.jobPostById._id,
            is_applied: !!res.jobPostById.myApplication,
            type: 'SAFE_NUMBER',
          },
        })
        onSuccess?.()
      })
      .catch((err) => {
        handleRelayError(err)
        onFailure?.()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const mutateWithCallbackByAuthor = ({
    isFirstContact,
    applicationId,
    onSuccess,
    onFailure,
  }: {
    isFirstContact: boolean
    applicationId: string
    onSuccess: () => void
    onFailure: () => void
  }) => {
    if (isLoading) return
    setLoading(true)

    return fetchQuery<usePhoneMappingMutationByAuthorQuery>(
      environment,
      graphql`
        query usePhoneMappingMutationByAuthorQuery($id: ID!) {
          application: applicationById(id: $id) {
            phoneMapping {
              safeNumber
            }
            resume {
              _id
            }
            jobPost {
              _id
            }
            appliedAt
            applyLocation {
              haversineDistance
            }
          }
        }
      `,
      {
        id: applicationId,
      }
    )
      .toPromise()
      .then((res) => {
        const safeNumber = res?.application?.phoneMapping?.safeNumber
        if (!safeNumber) return
        const application = res?.application

        logClickEvent({
          name: 'phone_call',
          params: {
            role: 'JOB_POST_AUTHOR',
            job_post_id: res?.application.jobPost?._id,
            is_applied: true,
            resume_id: application.resume?._id ?? '',
            passed_minutes_from_apply: getPassedMinutes(application.appliedAt),
            is_first_contact: isFirstContact,
            is_walking_distance: checkWalkingDistance(application.applyLocation?.haversineDistance),
            type: 'SAFE_NUMBER',
          },
        })

        window.location.href = `tel:${safeNumber}`
        onSuccess()
      })
      .catch((err) => {
        handleRelayError(err)
        onFailure()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { isLoading, mutateWithCallbackByApplicant, mutateWithCallbackByAuthor }
}
