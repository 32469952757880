import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { JobPostStringifiedWorkTimeResolver$key } from '@src/__generated__/JobPostStringifiedWorkTimeResolver.graphql'
import { stringifyWorkTime } from '@src/utils/jobPost'

/**
 *
 * @RelayResolver JobPost.stringifiedWorkTime: String
 * @rootFragment JobPostStringifiedWorkTimeResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function stringifiedWorkTime(jobPostRef: JobPostStringifiedWorkTimeResolver$key): string {
  const { workTimeStart, workTimeEnd, isWorkTimeNegotiable } = readFragment(
    graphql`
      fragment JobPostStringifiedWorkTimeResolver on JobPost {
        workTimeStart
        workTimeEnd
        isWorkTimeNegotiable
      }
    `,
    jobPostRef
  )

  return stringifyWorkTime({ workTimeStart, workTimeEnd, isWorkTimeNegotiable })
}
