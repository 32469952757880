import { Middleware } from '@reduxjs/toolkit'

import storage from '@src/api/storage'
import { FEED_FILTER_STATE } from '@src/constants/api'
import { SliceName } from '@src/ducks/filter'

const updateFilterStatusMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  if (action.type?.startsWith(SliceName)) {
    next(action)
    storage.setItemAtLocal(FEED_FILTER_STATE, { state: storeAPI.getState().filter, date: new Date() })
    return
  }

  return next(action)
}

export default updateFilterStatusMiddleware
