import type { Activity } from '@stackflow/core'
import { StackflowReactPlugin } from '@stackflow/react'
import React from 'react'
import { useSelector } from 'react-redux'

import CaseDefaultErrorPage from '@src/components/base/error/CaseDefaultErrorPage'
import ErrorBoundary from '@src/components/base/error/ErrorBoundary'
import NetworkErrorPage from '@src/components/base/error/NetworkErrorPage'
import LayoutWithSafeAreaBottom from '@src/components/base/layout/LayoutWithSafeAreaBottom'
import GlobalLoaderOpen from '@src/components/base/loader/GlobalLoaderOpen'
import CustomAppScreen from '@src/components/base/stackflow/CustomAppScreen'
import DevTools from '@src/components/common/devTools/DevTools'
import { RouteInfoContext } from '@src/hocs/withRouteInfo'
import { routes } from '@src/router/activities'
import { ActivityName, JobsActivity } from '@src/router/activityNames'
import Redirect from '@src/router/Redirect'
import { isLoginSelector } from '@src/selectors/user'
import { isNetworkError } from '@src/utils/error'

const jobsLayoutPlugin = (): StackflowReactPlugin => {
  return () => ({
    key: 'jobsLayoutPlugin',
    wrapStack({ stack }) {
      return (
        <LayoutWithSafeAreaBottom>
          {stack.render()}
          <DevTools />
        </LayoutWithSafeAreaBottom>
      )
    },
    wrapActivity({ activity }) {
      return <ActivityLayoutWrapper activity={activity}>{activity.render()}</ActivityLayoutWrapper>
    },
  })
}

export default jobsLayoutPlugin

type Props = {
  activity: Activity & { render: () => React.ReactNode }
}

const ActivityLayoutWrapper: React.FCC<Props> = ({ activity, children }) => {
  const activityName = activity.name as ActivityName
  const _route = routes[activityName]
  const route = React.useMemo(() => ({ ..._route, pageCode: activityName }), [_route, activityName])

  if (!_route) {
    return <>{children}</>
  }

  const FallbackComponentWithAppScreen: React.FCC<{ error: Error }> = (props) => {
    const FallbackComponent =
      route.fallbackComponent ?? isNetworkError(props.error) ? NetworkErrorPage : CaseDefaultErrorPage

    return (
      <CustomAppScreen>
        <FallbackComponent {...props} />
      </CustomAppScreen>
    )
  }

  return (
    <CheckAuthorize withoutAuth={!!route.withoutAuth}>
      <ErrorBoundary fallback={FallbackComponentWithAppScreen}>
        <RouteInfoContext.Provider value={route}>
          {route.withoutAppScreen ? (
            <React.Suspense fallback={<GlobalLoaderOpen />}>{children}</React.Suspense>
          ) : (
            <CustomAppScreen {...route.appScreenOptions}>
              <React.Suspense fallback={<GlobalLoaderOpen />}>{children}</React.Suspense>
            </CustomAppScreen>
          )}
        </RouteInfoContext.Provider>
      </ErrorBoundary>
    </CheckAuthorize>
  )
}

const CheckAuthorize: React.FCC<{ withoutAuth: boolean }> = ({ withoutAuth = false, children, ...props }) => {
  const isLogin = useSelector(isLoginSelector)

  if (withoutAuth && !isLogin) {
    return <Redirect to={JobsActivity.NEED_LOGIN} params={{}} />
  }

  return <React.Fragment {...props}>{children}</React.Fragment>
}
