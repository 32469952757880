import { APP_URLS } from '@src/config'
import { ActivityName } from '@src/router/activityNames'
import { bridge } from '@src/sdks/bridge'

import { useNavigator } from './useNavigator'

interface RouterParameters {
  appKey?: string
  remote?: string
  path?: string
  navbar?: boolean
  navbarTitle?: string
  scrollable?: boolean
  present?: boolean
  params?: Record<string, any>
}

export const useEnhancedRouter = () => {
  const { push: navigatorPush, replace: navigatorReplace } = useNavigator()

  const push = ({
    appKey,
    remote,
    path = '/',
    navbar = false,
    navbarTitle,
    scrollable = false,
    present = false,
    params = {},
  }: RouterParameters) => {
    if (bridge.environment === 'Web') {
      remote ? (window.location.href = remote) : navigatorPush(path as ActivityName, params)
      return
    }

    if (remote) {
      bridge.router.push({
        remote,
        navbar,
        navbarTitle,
        scrollable,
        present,
      })
      return
    }

    if (appKey) {
      bridge.router.push({
        app: APP_URLS[appKey],
        path,
        navbar,
        navbarTitle,
        scrollable,
        present,
      })
    }
  }

  const close = () => {
    if (bridge.environment === 'Web') {
      alert('bridge close event trigger')
      return
    }

    bridge.router.close()
  }

  const replace = ({
    appKey,
    remote,
    path = '/',
    params = {},
    navbar = false,
    navbarTitle,
    scrollable = false,
  }: RouterParameters) => {
    if (bridge.environment === 'Web') {
      remote ? (window.location.href = remote) : navigatorReplace(path as ActivityName, params)
      return
    }

    if (remote) {
      bridge.router.replace({
        remote,
        navbar,
        navbarTitle,
        scrollable,
      })
      return
    }

    if (appKey) {
      bridge.router.replace({
        app: APP_URLS[appKey],
        path,
        navbar,
        navbarTitle,
        scrollable,
      })
    }
  }

  return { push, replace, close }
}
