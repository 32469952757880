import React from 'react'

import { styled } from '@src/stitches/stitches.config'

import SafeAreaBottom from './SafeAreaBottom'

const LayoutWithSafeAreaBottom: React.FCC = ({ children }) => {
  return (
    <Layout>
      <ContentArea>{children}</ContentArea>
      <SafeAreaBottom />
    </Layout>
  )
}

export default LayoutWithSafeAreaBottom

const Layout = styled('div', {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const ContentArea = styled('div', {
  position: 'relative',
  flex: 1,
  overflowY: 'scroll',
  WebkitOverflowScrolling: 'touch',
  scrollBehavior: 'smooth',
})
