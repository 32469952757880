import React from 'react'
import { useSelector } from 'react-redux'

import { useNavigator } from '@src/hooks/useNavigator'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { JobsActivity } from '@src/router/activityNames'
import { isJobPostWritableSelector, unadvertisedJobPostIdSelector } from '@src/selectors/jobPost'
import { styled } from '@src/stitches/stitches.config'
import { AdvertisementAction } from '@src/types/advertisement'

type Props = {
  inventory: 'top_feed' | 'job_post_card' | 'recommend_job_post_card'
}

const AdvertisementHereButton: React.FCC<Props> = ({ inventory }) => {
  const { push } = useNavigator()
  const isWritable = useSelector(isJobPostWritableSelector)
  const { logClickEvent } = useLogAnalyticsEvent()
  const unadvertisedJobPostId = useSelector(unadvertisedJobPostIdSelector)

  const handleStartAdClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    logClickEvent({
      name: 'ad_nudge_by_inventory',
      params: {
        inventory_type: inventory,
        has_job_post: !!unadvertisedJobPostId,
      },
    })
    if (!unadvertisedJobPostId) {
      push(JobsActivity.BIZ_JOB_POST_CREATE, {})
    } else {
      push(JobsActivity.BIZ_JOB_POST_MANAGE, {
        jobPostId: unadvertisedJobPostId,
        actions: [AdvertisementAction.START],
      })
    }
  }

  if (!unadvertisedJobPostId && !isWritable) return null

  return (
    <AdvertisementNudgeText onClick={handleStartAdClick}>
      여기에 내 알바를 광고하고 싶다면?<Highlight>{unadvertisedJobPostId ? '광고하기' : '글쓰고 광고하기'}</Highlight>
    </AdvertisementNudgeText>
  )
}

export default AdvertisementHereButton

const AdvertisementNudgeText = styled('div', {
  $text: 'bodyM2Regular',
  color: '$gray700',
})

const Highlight = styled('span', {
  margin: '0 0 0 8px',
  $text: 'subtitle2Bold',
  color: '$accent-semantic',
})
