import React from 'react'

import ExpressedMargin from '@src/packages/falcon-react/drawers/ExpreesedMargin'
import ExpressedBorder from '@src/packages/falcon-react/drawers/ExpressedBorder'
import ExpressedContent from '@src/packages/falcon-react/drawers/ExpressedContent'
import ExpressedPadding from '@src/packages/falcon-react/drawers/ExpressedPadding'
import { styled } from '@src/stitches/stitches.config'

type Props = {
  element: HTMLElement | null
  elementId: number | null
}

const Drawer: React.FCC<Props> = ({ element, elementId }) => {
  const [visibleId, setVisibleId] = React.useState(elementId || 0)

  const content = React.useMemo(() => {
    if (!element || !elementId) {
      setVisibleId(0)
      return null
    }

    const style = getComputedStyle(element)
    const isPageClick = parseInt(style.height, 10) >= 500
    if (isPageClick) {
      setVisibleId(0)
      return null
    }

    setVisibleId(elementId)

    return (
      <Base>
        <ExpressedMargin key={`${elementId}-margin`} element={element} />
        <ExpressedBorder key={`${elementId}-border`} element={element} />
        <ExpressedPadding key={`${elementId}-padding`} element={element} />
        <ExpressedContent key={`${elementId}-content`} element={element} />
      </Base>
    )
  }, [element, elementId])

  // React.useEffect(() => {
  //   if (visibleId === 0) return

  //   const timer = setTimeout(() => {
  //     setVisibleId(0)
  //   }, 3000)

  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [visibleId])

  return visibleId > 0 ? content : null
}

export default Drawer

const Base = styled('div', {
  zIndex: '$global1',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
})
