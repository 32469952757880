import { useActivity, useActivityParams } from '@stackflow/react'
import { pickBy } from 'lodash'
import React from 'react'

import { useStepFlow } from '@src/stackflow/stackflow'

// eslint-disable-next-line @typescript-eslint/ban-types

type PageCodeParams = 'entry' | 'entrySection' | 'entryContext'
type RefererParams = 'referrer' | 'referrer_context'

const removeUndefined = (object: Record<string, string | undefined | number>) =>
  pickBy(object, (value) => value !== undefined) as Record<string, string>

export function useStepNavigator() {
  const { name } = useActivity()
  const actions = useStepFlow(name)
  const { entry, entrySection, entryContext } = useEntryParams()
  const { referrer, referrer_context } = useReferrerParams()

  const stepPush = React.useCallback(
    (params = {}, options = {}) => {
      const querystrings = removeUndefined({
        entry,
        entrySection,
        entryContext,
        referrer,
        referrer_context,
      })

      return actions.stepPush({ ...params, ...querystrings }, options)
    },
    [actions, entry, entrySection, entryContext, referrer, referrer_context]
  )

  const stepReplace = React.useCallback(
    (params = {}, options = {}) => {
      const querystrings = removeUndefined({
        entry,
        entrySection,
        entryContext,
        referrer,
        referrer_context,
      })

      actions.stepReplace({ ...params, ...querystrings }, { animate: false, ...options })
    },
    [actions, entry, entrySection, entryContext, referrer, referrer_context]
  )

  const stepPop = actions.stepPop

  return { stepPush, stepReplace, stepPop } as ReturnType<typeof useStepFlow>
}

export const useEntryParams = () => {
  const { entry, entrySection, entryContext } = useActivityParams<{ [key in PageCodeParams]: string }>()

  return React.useMemo(
    () => ({
      entry,
      entrySection,
      entryContext,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export const useReferrerParams = () => {
  const { referrer, referrer_context } = useActivityParams<{ [key in RefererParams]: string }>()

  return React.useMemo(
    () => ({
      referrer,
      referrer_context,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}
