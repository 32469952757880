import { IconUserGroupFill } from '@seed-design/icon'
import React from 'react'
import { graphql, useFragment } from 'react-relay'

import { ApplicantStatus_jobPost$key } from '@src/__generated__/ApplicantStatus_jobPost.graphql'
import { styled, theme } from '@src/stitches/stitches.config'

type Props = {
  jobPostRef: ApplicantStatus_jobPost$key
}

const ApplicantStatus: React.FCC<Props> = ({ jobPostRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment ApplicantStatus_jobPost on JobPost {
        applicationCountInfo(option: { canceled: false }) {
          totalCount
          viewed
        }
      }
    `,
    jobPostRef
  )

  const totalCount = jobPost.applicationCountInfo.totalCount
  const viewedCount = jobPost.applicationCountInfo.viewed

  return (
    <Container>
      <ApplicantCount>
        <IconUserGroupFill width={16} height={16} color={theme.colors.gray900.computedValue} />
        <ApplicantCountText>지원자 {totalCount}명</ApplicantCountText>
      </ApplicantCount>
      {totalCount > 0 && (
        <ViewedApplicantCount>전체 지원자 중 {viewedCount}명의 지원자를 확인했어요.</ViewedApplicantCount>
      )}
    </Container>
  )
}

export default ApplicantStatus

const Container = styled('div', {})

const ApplicantCount = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const ApplicantCountText = styled('p', {
  margin: '0 0 0 8px',
  $text: 'subtitle2Bold',
})

const ViewedApplicantCount = styled('p', {
  margin: '4px 0 0',
  $text: 'bodyM2Regular',
  color: '$gray600',
})
