/* eslint-disable import/no-duplicates */
import {
  parse,
  differenceInCalendarDays,
  format,
  endOfDay,
  formatDistanceToNow,
  add,
  getDay,
  startOfDay,
} from 'date-fns'
import ko from 'date-fns/locale/ko'
import { format as tzFormat } from 'date-fns-tz'

import { getHolidays } from './holiday'

export const MS = {
  second: 1000,
  minute: 60000,
  hour: 3600000,
  day: 86400000,
  week: 604800000,
  month: 2592000000,
  year: 31536000000,
} as const

export const calcPassedDate = (targetDate: Dateable): string => {
  return formatDistanceToNow(toDate(targetDate), { locale: ko, includeSeconds: true }).replace(
    /(약\s*)|(\s*미만$)/g,
    ''
  )
}

export const calcDistanceWithDateAndHour = (targetDate: Dateable): string => {
  const dday = toDate(targetDate).getTime()
  const today = new Date().getTime()
  const gap = dday - today
  const day = Math.floor(gap / (1000 * 60 * 60 * 24))
  const hour = Math.ceil((gap % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const adjustedDay = hour === 24 ? day + 1 : day

  return `${adjustedDay > 0 ? `${adjustedDay}일` : ''}${
    hour > 0 && hour < 24 ? `${adjustedDay > 0 ? ' ' : ''}${hour}시간` : ''
  }`
}

export const isPastDate = (targetDate: Dateable): boolean => {
  return toDate(targetDate).getTime() < new Date().getTime()
}

export const areDateEqual = (dateA: Dateable, dateB: Dateable): boolean => {
  const [a, b] = [toDate(dateA), toDate(dateB)]
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate()
}

export const isPastDateAddDaysLimit = ({
  targetDate,
  days,
}: {
  targetDate?: Dateable | null
  days: number
}): boolean => {
  if (!targetDate) return false
  return add(toDate(targetDate), { days }).getTime() < new Date().getTime()
}

export const isWithinDays = ({ targetDate, days }: { targetDate?: Dateable | null; days: number }): boolean => {
  if (!targetDate) return false

  return differenceInCalendarDays(toDate(targetDate), new Date()) <= days
}

export const formatDate = (targetDate: Dateable, type = 'PPP', options = { locale: ko }) => {
  return format(toDate(targetDate), type, options)
}

export const formatDateDot = (targetDate: Dateable) => {
  return formatDate(targetDate, 'yyyy.MM.dd')
}

// Todo: localize 대응 필요
export const setEndOfDay = (targetDate: Dateable) => {
  return endOfDay(toDate(targetDate))
}

export const localeDateString = (targetDate: Dateable, type = 'PPP', options = { timeZone: 'KST', locale: ko }) => {
  return tzFormat(toDate(targetDate), type, options)
}

export const parseDate = (date: string, format: string) => {
  return parse(date, format, new Date())
}

export const toDate = (date: string | number | Date) => {
  return date instanceof Date ? date : new Date(date)
}

export const isHolidayDate = (date: Dateable) => {
  const holidays = getHolidays()
  const _date = toDate(date)

  // 2100년 이상에 대해, holidays === '2099년까지의 공휴일만 계산할 수 있어요.'
  if (!holidays || typeof holidays === 'string') {
    return false
  }

  return getDay(_date) === 0 || holidays.some((holiday) => holiday.date.getTime() === _date.getTime())
}

export const inDates = (date: Dateable, dates: Dateable[]) => {
  return dates.some((d) => areDateEqual(d, date))
}

export const withoutDates = (dates: Dateable[], ...withoutDates: Dateable[]) => {
  return dates.filter((d) => !inDates(d, withoutDates))
}

export const sortDates = (dates: Dateable[]) => {
  return [...dates.map(toDate)].sort((a, b) => a.getTime() - b.getTime()).map(toDate)
}

export const isContinualDates = (dates: Dateable[]) => {
  if (dates.length <= 1) return true

  const sortedDates = sortDates(dates)
  const differenceDays = Math.abs(differenceInCalendarDays(sortedDates[0], sortedDates[sortedDates.length - 1]))
  return differenceDays === dates.length - 1
}

export const uniqueDates = (dates: Dateable[]) => {
  return [...new Set(dates.map((d) => toDate(d).getTime()))].map(toDate)
}

export const isBetweenDates = (date: Dateable, startDateable: Dateable, endDateable: Dateable) => {
  const [startDate, endDate] = [toDate(startDateable), toDate(endDateable)].sort((a, b) => a.getTime() - b.getTime())
  return toDate(date).getTime() >= startDate.getTime() && toDate(date).getTime() <= endDate.getTime()
}

export const datesBetweenDates = (startDate: Dateable, endDate: Dateable) => {
  const dates = []
  const [start, end] = [startOfDay(toDate(startDate)), startOfDay(toDate(endDate))].sort(
    (a, b) => a.getTime() - b.getTime()
  )
  let current = start

  while (current <= end) {
    dates.push(current)
    current = new Date(current.getTime() + MS.day)
  }

  return dates
}

export const getDiffMinutes = (dateA: Dateable, dateB: Dateable) => {
  return Math.floor(Math.abs(toDate(dateA).getTime() - toDate(dateB).getTime()) / MS.minute)
}

export const getDiffHours = (dateA: Dateable, dateB: Dateable) => {
  return Math.floor(Math.abs(toDate(dateA).getTime() - toDate(dateB).getTime()) / MS.hour)
}

export const getDiffDates = (dateA: Dateable, dateB: Dateable) => {
  return Math.abs(differenceInCalendarDays(toDate(dateA), toDate(dateB)))
}

export const getPassedHours = (date: Dateable) => {
  return getDiffHours(Date.now(), date)
}

export const getPassedMinutes = (date: Dateable) => {
  return getDiffMinutes(Date.now(), date)
}

export const getYearMonthDay = (date: Dateable) => {
  const _date = toDate(date)
  return { year: _date.getFullYear(), month: _date.getMonth() + 1, day: _date.getDate() }
}

export const getDiffDayHourMinute = (dateA: Dateable, dateB: Dateable) => {
  const [a, b] = [toDate(dateA), toDate(dateB)]
  const gap = Math.abs(a.getTime() - b.getTime())
  const day = Math.floor(gap / MS.day)
  const hour = Math.floor((gap % MS.day) / MS.hour)
  const minute = Math.floor((gap % MS.hour) / MS.minute)

  return { day, hour, minute }
}

export const getCeilMonthDiff = (dateA: Dateable, dateB: Dateable) => {
  const diff = toDate(dateA).getTime() - toDate(dateB).getTime()
  const ceilMonthDiff = Math.abs(Math.ceil(diff / MS.month))

  return ceilMonthDiff
}
