import { vars } from '@seed-design/design-token'
import { createStitches } from '@stitches/react'
import * as Stitches from '@stitches/react'
import { capitalize } from 'lodash'

import { Color, colorThemeAdapter } from './colors'

type LineStyle = 'solid'

export const typography = {
  ...vars.$semantic.typography,
}

export const zIndices = {
  negative: -1,
  component1: 10,
  component2: 20,
  component3: 30,
  page1: 100,
  page2: 200,
  page3: 300,
  global1: 1000,
  global2: 2000,
  global3: 3000,
}

export const { styled, createTheme, config, css, keyframes, theme, globalCss } = createStitches({
  theme: {
    colors: {
      ...colorThemeAdapter(vars),
    },
    zIndices,
    fonts: {
      karrotSans: 'karrotSans, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif',
    },
  },
  utils: {
    safeAreaInset: (direction: 'top' | 'right' | 'bottom' | 'left') => ({
      [`padding${capitalize(direction)}`]: `constant(safe-area-inset-${direction})`,
      '&': { [`padding${capitalize(direction)}`]: `env(safe-area-inset-${direction})` },
    }),
    boxShadow: (value: string) => ({ boxShadow: value.includes('$') ? value.replace('$', '$colors$') : value }),
    $background: (value: string) => ({ background: value.includes('$') ? value.replace('$', '$colors$') : value }),
    $text: (key: keyof typeof typography) => {
      return typography[key]
    },
    $border: (value: `${number}${'px' | 'rem'} ${LineStyle} $${Color}`) =>
      value.includes('Alpha') || value.includes('rgba')
        ? {
            boxShadow: `0 0 0 ${value
              .split(' ')
              .filter((_, i) => i === 0 || i === 2)
              .join(' ')} inset`,
          }
        : {
            boxSizing: 'border-box',
            border: value,
          },
    $scrollable: (value: 'vertical' | 'horizontal') => ({
      WebkitOverflowScrolling: 'touch',
      scrollBehavior: 'smooth',
      ...(value === 'vertical'
        ? {
            height: '100%',
            overflowY: 'scroll',
          }
        : {
            width: '100%',
            overflowX: 'scroll',
          }),
    }),
  },
  media: {
    motion: '(prefers-reduced-motion: no-preference)',
  },
})

export type StitchesCSS = Stitches.CSS<typeof config>
