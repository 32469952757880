import { IconWalkFill } from '@seed-design/icon'

import Badge from '@src/components/common/badge/Badge'

interface Props {
  icon?: boolean
}

const WalkingDistanceBadge: React.FCC<Props> = ({ icon = true }) => {
  return <Badge Icon={icon ? IconWalkFill : undefined} type="accent" size={'small'} bold={true} text="걸어서 10분" />
}

export default WalkingDistanceBadge
