import Request from './Request'

export default class Aws extends Request {
  constructor() {
    super()
  }

  public async uploadFileToS3({ url, blob, file, imageId }: { url: string; blob: Blob; file: File; imageId: string }) {
    const { data } = await this.put({
      url,
      data: blob,
      headers: {
        'Content-Type': file.type,
        'x-amz-meta-id': imageId,
      },
    })

    return data
  }
}
