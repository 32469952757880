import { IconChevronRightRegular } from '@seed-design/icon'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFragment, graphql } from 'react-relay'

import { JobPostOtherPosts_jobPost$key } from '@src/__generated__/JobPostOtherPosts_jobPost.graphql'
import Button from '@src/components/base/buttons/Button'
import ImpressionChecker from '@src/components/common/impression/ImpressionChecker'
import RecommendedPosts from '@src/components/common/jobPost/RecommendedPosts'
import withDeferredSuspense from '@src/hocs/withDeferredSuspense'
import use202307CampaignMarketing from '@src/hooks/use202307CampaignMarketing'
import { usePopOrReplace } from '@src/hooks/usePopOrReplace'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { JobsActivity } from '@src/router/activityNames'
import { currentRegionIdSelector, currentRegionNameSelector } from '@src/selectors/region'
import { styled, theme } from '@src/stitches/stitches.config'

interface Props {
  jobPostRef: JobPostOtherPosts_jobPost$key
  liveJobPostCount: number
}

const JobPostOtherPosts: React.FCC<Props> = ({ jobPostRef, liveJobPostCount }) => {
  const jobPost = useFragment(
    graphql`
      fragment JobPostOtherPosts_jobPost on JobPost {
        _id
        workplaceRegion {
          _id
        }
        eventGroup {
          type
        }
      }
    `,
    jobPostRef
  )

  const currentRegionId = useSelector(currentRegionIdSelector)
  const currentRegionName = useSelector(currentRegionNameSelector)
  const { logClickEvent } = useLogAnalyticsEvent()
  const popOrReplace = usePopOrReplace({ useBridgeReplace: true })
  const is202307Campaign = use202307CampaignMarketing()
  const isDaangnEvent = jobPost.eventGroup?.type === 'DAANGN_EVENT'
  const regionId = isDaangnEvent || is202307Campaign ? currentRegionId : jobPost.workplaceRegion?._id

  const handleGoToFeedClick = () => {
    logClickEvent({
      name: 'show_all_posts',
      params: {
        job_post_id: jobPost._id,
      },
    })

    popOrReplace(JobsActivity.MAIN, {})
  }

  return (
    <Section>
      <SectionTitle>우리동네 알바</SectionTitle>

      {!!regionId && <RecommendedPosts regionId={regionId} excludeAdvertisement={isDaangnEvent} />}

      <ImpressionChecker
        logParams={{
          name: 'show_all_posts',
        }}
      />
      <GoToFeedButton size="large" onClick={handleGoToFeedClick}>
        <span>{`${currentRegionName} 알바${liveJobPostCount ? ` ${liveJobPostCount}개` : ''} 더보기`}</span>{' '}
        <IconChevronRightRegular width={16} height={16} color={theme.colors.gray900.computedValue} />{' '}
      </GoToFeedButton>
    </Section>
  )
}

export default withDeferredSuspense(JobPostOtherPosts)

const Section = styled('div', {
  padding: '20px 16px',
  borderBottom: '8px solid $gray100',

  '&:last-of-type': {
    border: 'none',
  },
})

const SectionTitle = styled('div', {
  $text: 'title3Bold',
})

const GoToFeedButton = styled(Button, {
  margin: '16px 0 0',

  '& > span': {
    margin: '0 4px 0 0',
  },
})
