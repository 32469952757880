import React from 'react'

import { styled, theme } from '@src/stitches/stitches.config'

type Props = {
  element: HTMLElement
}

const ExpressedPadding: React.FCC<Props> = ({ element }) => {
  const { left, top } = element.getBoundingClientRect()
  const style = getComputedStyle(element)

  const padding = {
    top: parseInt(style.paddingTop, 10),
    right: parseInt(style.paddingRight, 10),
    bottom: parseInt(style.paddingBottom, 10),
    left: parseInt(style.paddingLeft, 10),
  }

  const isNoPadding = Object.values(padding).every((value) => value === 0)
  if (isNoPadding) {
    return null
  }

  return (
    <PaddingBase
      style={{
        background: theme.colors['greenAlpha200'].computedValue,
        width: `${element.offsetWidth}px`,
        height: `${element.offsetHeight}px`,
        top: `${top}px`,
        left: `${left}px`,
      }}>
      {padding.top > 0 && <PaddingTop>{padding.top}</PaddingTop>}
      {padding.left > 0 && <PaddingLeft>{padding.left}</PaddingLeft>}
      {padding.right > 0 && <PaddingRight>{padding.right}</PaddingRight>}
      {padding.bottom > 0 && <PaddingBottom>{padding.bottom}</PaddingBottom>}
    </PaddingBase>
  )
}

export default ExpressedPadding

const PaddingBase = styled('div', {
  position: 'fixed',
})

const PaddingFlagStyle = {
  color: '$green700',
  fontSize: '10px',
  fontWeight: '700',
  position: 'absolute',
}

const PaddingTop = styled('div', PaddingFlagStyle, {
  left: '50%',
  top: '0px',
  transform: 'translateX(-50%)',
})

const PaddingBottom = styled('div', PaddingFlagStyle, {
  left: '50%',
  bottom: '0px',
  transform: 'translateX(-50%)',
})

const PaddingRight = styled('div', PaddingFlagStyle, {
  right: '0px',
  top: '50%',
  transform: 'translateY(-6px)',
})

const PaddingLeft = styled('div', PaddingFlagStyle, {
  left: '0px',
  top: '50%',
  transform: 'translateY(-6px)',
})
