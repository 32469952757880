import { pickBy } from 'lodash'
import React from 'react'

import { useGetPageCodeInfo } from '@src/contexts/PageCodeInfoContext'
import { useEntryParams } from '@src/hooks/useNavigator'
import { logAnalyticsEvent } from '@src/sdks/bridge'

import {
  AnalyticsClickEventKey,
  AnalyticsClickEventParams,
  AnalyticsShowEventKey,
  AnalyticsShowEventParams,
} from './eventSchema'

export type LogShowEventParams<T extends AnalyticsShowEventKey> = AnalyticsShowEventParams<T> & {
  component_type?: 'page' | 'element'
  entry?: string
  log_condition?: boolean
}

export type LogClickEventParams<T extends AnalyticsClickEventKey> = AnalyticsClickEventParams<T> & {
  entry?: string
  log_condition?: boolean
  action?: string
  label?: string
}

export type LogAnalyticsEventType = {
  logShowEvent: <T extends AnalyticsShowEventKey>(data: LogShowEventParams<T>) => void
  logClickEvent: <T extends AnalyticsClickEventKey>(data: LogClickEventParams<T>) => void
}

const defaultLogAnalyticsEvent: LogAnalyticsEventType = {
  logShowEvent: (data) => {
    logAnalyticsEvent({ name: 'show_jobs', params: (data as any).params ?? {} })
    logAnalyticsEvent({ name: `shown_${data.component_type ?? 'element'}_jobs`, params: (data as any).params ?? {} })
  },
  logClickEvent: (data) => {
    logAnalyticsEvent({ name: 'click_jobs', params: (data as any).params ?? {} })
    logAnalyticsEvent({ name: 'clicked_jobs', params: (data as any).params ?? {} })
  },
}

export const LogAnalyticsEventContext = React.createContext<LogAnalyticsEventType>(defaultLogAnalyticsEvent)

export const useLogAnalyticsEvent = () => {
  const defaultLogEvent = React.useContext(LogAnalyticsEventContext)
  const { entry, entrySection, entryContext } = useEntryParams()
  const { pageCode, pageSectionCode, pageContext } = useGetPageCodeInfo()
  const { logShowEvent, logClickEvent } = React.useMemo(
    () => {
      const entryLog = pickBy(
        {
          entry,
          entry_section: entrySection,
          entry_context: entryContext,
          current: pageCode,
          current_section: pageSectionCode,
          current_context: pageContext,
        },
        (v) => v !== undefined
      )
      return {
        logShowEvent: <T extends AnalyticsShowEventKey>(log: LogShowEventParams<T>) => {
          defaultLogEvent.logShowEvent({ ...entryLog, ...log })
        },
        logClickEvent: <T extends AnalyticsClickEventKey>(log: LogClickEventParams<T>) => {
          defaultLogEvent.logClickEvent({ ...entryLog, ...log })
        },
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultLogEvent, pageCode, pageSectionCode]
  )

  return {
    logShowEvent,
    logClickEvent,
  }
}
