import { IconCloseRegular } from '@seed-design/icon'
import React from 'react'

import Image from '@src/components/base/image/Image'
import FullSizeModal from '@src/components/base/modal/FullSizeModal'
import { useDetectTouchMove } from '@src/hooks/useDetectTouchMove'
import { Swiper, SwiperSlide } from '@src/sdks/swiper'
import { styled, theme, zIndices } from '@src/stitches/stitches.config'
import { FullSizeSwiperWrapper } from '@src/stitches/swiper'

interface Props {
  images: string[]
  initialIndex: number | null
  onClose: () => void
}

const ImageDetailSlider: React.FCC<Props> = ({ images, initialIndex, onClose }) => {
  const { touchMove } = useDetectTouchMove({ direction: 'vertical' })
  const isOpen = initialIndex !== null

  React.useEffect(() => {
    if (touchMove && isOpen) {
      onClose()
    }
  }, [touchMove, isOpen, onClose])

  return (
    <FullSizeModal open={isOpen}>
      <Base>
        {initialIndex !== null && (
          <Swiper pagination={{ clickable: true }} initialSlide={initialIndex} zoom={true}>
            {images.map((src, i) => {
              return (
                <SwiperSlide key={i}>
                  <ZoomContentWrapper className="swiper-zoom-container">
                    <StyledImage source={src} />
                  </ZoomContentWrapper>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
        <CloseButton onClick={onClose}>
          <IconCloseRegular width={24} height={24} color={theme.colors['white-static'].computedValue} />
        </CloseButton>
      </Base>
    </FullSizeModal>
  )
}

const StyledImage = styled(Image, {
  display: 'block',
  width: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
})

const Base = styled(FullSizeSwiperWrapper, {
  position: 'relative',
  height: '100%',
  width: '100%',
  background: '#000',
})

const ZoomContentWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
})

const CloseButton = styled('div', {
  position: 'absolute',
  top: 'calc(11px + constant(safe-area-inset-top))',
  right: '11px',
  zIndex: zIndices.page1,
  padding: '5px',

  '&': {
    top: 'calc(11px + env(safe-area-inset-top))',
  },
})

export default ImageDetailSlider
