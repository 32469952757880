import React from 'react'

import ListModal from '@src/components/base/modal/ListModal'
import useModalState from '@src/hooks/useModalState'

import AdvertisementDisplayPolicyHalfview from './AdvertisementDisplayPolicyHalfview'

type Props = {
  isOpen: boolean
  onIgnoreJobPostClick: () => void
  onCloseClick: (cb?: () => void) => void
}

const AdvertisementDisplayHalfview: React.FCC<Props> = ({ isOpen, onCloseClick, onIgnoreJobPostClick }) => {
  const [isPolicyHalfviewOpen, openPolicyHalfview, closePolicyHalfview] = useModalState()

  const handleIgnoreJobPostClick = () => {
    onCloseClick(() => {
      onIgnoreJobPostClick()
    })
  }

  const handleDisplayPolicyClick = () => {
    onCloseClick(() => {
      openPolicyHalfview()
    })
  }

  return (
    <>
      <ListModal
        showCloseButton
        open={isOpen}
        onClose={onCloseClick}
        items={[
          {
            label: '이 광고 그만보기',
            onClick: handleIgnoreJobPostClick,
            type: 'info',
          },
          {
            label: '이 광고가 표시되는 이유',
            onClick: handleDisplayPolicyClick,
            type: 'info',
          },
        ]}
      />
      {isPolicyHalfviewOpen && (
        <AdvertisementDisplayPolicyHalfview
          onCloseClick={() => {
            closePolicyHalfview()
          }}
        />
      )}
    </>
  )
}

export default AdvertisementDisplayHalfview
