/**
 * @generated SignedSource<<2425b73f6ad5c0c8ee3f0aa597f11697>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { stringifiedSalary as jobPostStringifiedSalaryResolverType } from "../graphql/resolvers/JobPostStringifiedSalaryResolver";
export type JobPostCardPersonal_jobPost$data = {
  readonly closed: boolean;
  readonly companyName: string | null | undefined;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly thumbnail: string;
  }> | null | undefined;
  readonly stringifiedSalary: string | null | undefined;
  readonly title: string | null | undefined;
  readonly workplaceRegion: {
    readonly name3: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "JobPostCardPersonal_jobPost";
};
export type JobPostCardPersonal_jobPost$key = {
  readonly " $data"?: JobPostCardPersonal_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostCardPersonal_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostCardPersonal_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JobPostStringifiedSalaryResolver"
      },
      "kind": "RelayResolver",
      "name": "stringifiedSalary",
      "resolverModule": require('./../graphql/resolvers/JobPostStringifiedSalaryResolver').stringifiedSalary,
      "path": "stringifiedSalary"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "workplaceRegion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name3",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "7f573f95ca99e796cb356df41e287bfc";

export default node;
