import { Environment } from 'react-relay'

import { JobPostPageQuery } from '@src/__generated__/JobPostPageQuery.graphql'
import { JobPostPageQueryNode } from '@src/pages/applicant/JobPostPage'

import { usePreloadQuery } from './usePreloadQuery'

type Variables = {
  regionId: number
}

export const usePreloadJobPostQuery = () => {
  const { isLoaded, fetch, ignoreFetch } = usePreloadQuery<JobPostPageQuery>(JobPostPageQueryNode)

  return {
    isLoaded,
    fetch: ({ variables }: { variables: Variables }, environment: Environment) => {
      const jobPostId = window.location.pathname.match(/\/job-post\/([^/]+)\/?$/)?.[1]
      const isExperimentGroup = !!jobPostId && !['create', 'update', 'update-extend', 'migrate'].includes(jobPostId)
      if (isExperimentGroup) {
        fetch({
          environment,
          variables: { id: jobPostId, regionId: variables.regionId },
          onSuccess: (res) => {
            res?.jobPost?.images?.forEach((image) => {
              const img = new Image()
              img.src = image?.url ?? ''
            })
          },
          onError: () => {},
          onFinally: () => {},
        })
      } else {
        ignoreFetch()
      }
    },
  }
}
