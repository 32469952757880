import { useSnackbarAdapter } from '@daangn/sprout-components-snackbar'
import { IconHeartFill, IconHeartRegular } from '@seed-design/icon'
import React from 'react'
import { useSelector } from 'react-redux'
import { graphql, useFragment } from 'react-relay'

import { JobPostFooterLikeButton_jobPost$key } from '@src/__generated__/JobPostFooterLikeButton_jobPost.graphql'
import { JobPostFooterLikeButton_user$key } from '@src/__generated__/JobPostFooterLikeButton_user.graphql'
import storage from '@src/api/storage'
import { useFloatingMessageContext } from '@src/components/base/snackbar/FloatingMessage'
import Tooltip, { TooltipContent } from '@src/components/base/tooltip/Tooltip'
import { APP_URLS } from '@src/config'
import { FAVORITE_KEY, FAVORITE_NUDGE_SHOWN, FAVORITE_SUBSCRIPTION_NUDGE_SHOWN } from '@src/constants/api'
import { useNavigator } from '@src/hooks/useNavigator'
import { useWatchMutation } from '@src/mutations/useWatchMutation'
import { useEventManagerContext } from '@src/packages/eventManager/context'
import { ActionEvent } from '@src/packages/eventManager/types'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { JobsActivity } from '@src/router/activityNames'
import { toast } from '@src/sdks/bridge'
import { isLoginSelector } from '@src/selectors/user'
import { bounceAnimationStyle } from '@src/stitches/animation'
import { styled, theme } from '@src/stitches/stitches.config'
import { JobTaskType } from '@src/types/jobPost'
import { calcShortWorkPeriod } from '@src/utils/jobPost'

interface Props {
  jobPostRef: JobPostFooterLikeButton_jobPost$key
  userRef: Nullable<JobPostFooterLikeButton_user$key>
}

const JobPostFooterLikeButton: React.FCC<Props> = ({ jobPostRef, userRef }) => {
  const isLogin = useSelector(isLoginSelector)
  const snackbarAdapter = useSnackbarAdapter()
  const { logShowEvent, logClickEvent } = useLogAnalyticsEvent()
  const { eventStorage } = useEventManagerContext<ActionEvent>()
  const [, setFloatingMessage] = useFloatingMessageContext()
  const { push } = useNavigator()
  const { createWatch, deleteWatch, isLoading } = useWatchMutation()
  const jobPost = useFragment(
    graphql`
      fragment JobPostFooterLikeButton_jobPost on JobPost {
        id
        _id
        title
        myWatch {
          id
          _id
        }
        author {
          ... on HoianUserAuthor {
            badge {
              items {
                __typename
              }
            }
          }
        }
        bringUpCount
        workPeriod
        jobTasks
      }
    `,
    jobPostRef
  )

  const user = useFragment(
    graphql`
      fragment JobPostFooterLikeButton_user on User {
        id
        newJobPostFilterSubscription {
          id
          deleted
          isHolding
        }
      }
    `,
    userRef
  )

  const isFavorite = !!jobPost.myWatch
  const isSubscribingNewJobPostFilter = !!(
    user?.newJobPostFilterSubscription?.id &&
    !user?.newJobPostFilterSubscription.deleted &&
    !user?.newJobPostFilterSubscription.isHolding
  )

  const [isTooltipVisible, setTooltipVisible] = React.useState(() => {
    const isNotShown = !!storage.getItemFromLocal<boolean>(FAVORITE_NUDGE_SHOWN)

    if (isFavorite) return false
    if (isNotShown) storage.setItemAtLocal(FAVORITE_NUDGE_SHOWN, true)

    return isNotShown
  })

  const showLoginMessage = () => {
    toast('해당기능을 사용하시려면 로그인이 필요해요')
  }

  const handleFavoriteClick = () => {
    setTooltipVisible(false)

    if (!jobPost || !user?.id || isLoading) return

    if (!isLogin) {
      showLoginMessage()
      return
    }

    if (jobPost.myWatch) {
      deleteWatch({ watchDBId: jobPost.myWatch._id, watchGlobalId: jobPost.myWatch.id, userNodeId: user.id })
    } else {
      createWatch({
        jobPostDBId: jobPost._id,
        jobPostGlobalId: jobPost.id,
        userNodeId: user.id,
        onCompleted: () => {
          snackbarAdapter.create({
            message: '관심목록에 추가했어요.',
            actionLabel: '관심목록 보기',
            onAction: () => {
              window.location.href = APP_URLS[FAVORITE_KEY]
            },
            type: 'default',
            timeout: 3000,
          })

          !storage.getItemFromLocal(FAVORITE_SUBSCRIPTION_NUDGE_SHOWN) &&
            !isSubscribingNewJobPostFilter &&
            eventStorage.add(JobsActivity.MAIN, {
              action: () => {
                // eslint-disable-next-line local-rules/disallow-log-show-event-outside-page-files
                logShowEvent({ name: 'floating_alarm_registration_nudge' })
                setFloatingMessage({
                  isOpen: true,
                  message: (
                    <>
                      관심있는 <strong>&ldquo;{jobPost?.title?.slice(0, 8)}...&ldquo;</strong> 와 비슷한 공고가 올라오면
                      알려드릴까요?
                    </>
                  ),
                  buttonText: '네, 좋아요',
                  onClick: () => {
                    logClickEvent({ name: 'floating_alarm_registration_nudge' })
                    push(JobsActivity.NEW_JOB_POST_FILTER_SUBSCRIPTION, { jobPostId: jobPost?.id })
                  },
                  onClose: () => {
                    storage.setItemAtLocal(FAVORITE_SUBSCRIPTION_NUDGE_SHOWN, true)
                  },
                })
              },
            })
        },
      })
    }

    logClickEvent({
      name: 'job_post_favorite',
      action: `click_job_post_favorite-${jobPost.myWatch ? 'delete' : 'add'}`,
      params: {
        job_post_id: jobPost._id,
        job_post_title: jobPost.title,
        badges: jobPost.author.badge?.items?.map((a) => a.__typename).join(',') || undefined,
        will_like: !jobPost.myWatch,
        bring_up_count: jobPost.bringUpCount,
        is_short_work_period: calcShortWorkPeriod(jobPost.workPeriod),
        job_tasks: jobPost.jobTasks as JobTaskType[],
      },
    })
  }

  return (
    <Tooltip
      placement="top-start"
      offset={[0, 15]}
      visible={isTooltipVisible}
      closeTrigger="icon"
      strategy="fixed"
      content={<TooltipContent>이 알바가 마음에 든다면?</TooltipContent>}>
      {({ ref, referenceProps }) => (
        <LikeBuittonTooltipWrapper ref={ref} onClick={handleFavoriteClick} {...referenceProps}>
          {isFavorite ? (
            <StyledHeart width={24} height={24} color={theme.colors.carrot500.computedValue} />
          ) : (
            <IconHeartRegular width={24} height={24} color={theme.colors.gray600.computedValue} />
          )}
        </LikeBuittonTooltipWrapper>
      )}
    </Tooltip>
  )
}

export default JobPostFooterLikeButton

const LikeBuittonTooltipWrapper = styled('div', {
  width: '24px',
  height: '24px',
  margin: '0 16px 0 0',
})

const StyledHeart = styled(IconHeartFill, bounceAnimationStyle)
