import classNames from 'classnames'
import React from 'react'

import ModalBase, { ModalProps } from '@src/packages/modal/modalBase/ModalBase'
import { css, styled, zIndices } from '@src/stitches/stitches.config'
import { getSafeAreaStyle } from '@src/stitches/util'

interface BottomSheetProps extends ModalProps {
  autoClose?: boolean
}

const BottomSheet: React.FCC<BottomSheetProps> = ({ children, autoClose, ...props }) => {
  const handleClickUl = () => {
    if (autoClose) {
      props.onClose?.()
    }
  }
  return (
    <ModalBase
      zIndex={zIndices.global1}
      {...props}
      bodyClassName={classNames('bottom-sheet', props.bodyClassName)}
      render={({ duration, visible }) => (
        <ul
          className={bottomSheetSelectionList({ outro: !visible })}
          role="list"
          onClick={handleClickUl}
          style={{
            transitionDuration: `${duration}ms`,
          }}>
          {React.Children.map(children, (child) => (
            <BottomSheetItemWrapper role="BottomSheetItemWrapperstitem" className={bottomSheetSelectionItem()}>
              {child}
            </BottomSheetItemWrapper>
          ))}
        </ul>
      )}
    />
  )
}

export default BottomSheet

const bottomSheetSelectionList = css(
  {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    width: '100%',
    backgroundColor: '$paperSheet-semantic',
    borderRadius: '20px 20px 0 0',
    listStyle: 'none',
    transition: 'transform 0ms cubic-bezier(0.4, 0, 0.2, 1)',

    variants: {
      outro: {
        true: {
          transform: 'translate3d(0, 1.5rem, 0)',
        },
        false: {
          transform: 'translate3d(0, 0, 0)',
        },
      },
    },
  },
  {
    ...getSafeAreaStyle({
      direction: 'bottom',
      property: 'paddingBottom',
      converter: (v) => v,
    }),
  }
)

const bottomSheetSelectionItem = css({
  listStyle: 'none',
  margin: 0,
  padding: 0,
})

const BottomSheetItemWrapper = styled('li', {
  '&:first-of-type': {
    margin: '10px 0 0',
  },
})
