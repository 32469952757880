import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DEFAULT_FILTER_SET, calcFilterStatus } from '@src/hooks/useFilterState'
import { JobTaskType, RegionRange, WorkDay, WorkPeriod, WorkTime } from '@src/types/jobPost'

export type FilterState = {
  regionRange: RegionRange
  workPeriod?: WorkPeriod
  jobTasks?: JobTaskType[]
  workDays?: WorkDay[]
  workTime?: {
    start: WorkTime
    end: WorkTime
  }
  filterStatus: {
    any: boolean
    region: boolean
    workPeriod: boolean
    jobTasks: boolean
    workDays: boolean
    workTime: boolean
  }
}

export const SliceName = 'filter'

const INITIAL_STATE: FilterState = {
  regionRange: RegionRange.RANGE_3,
  workPeriod: undefined,
  jobTasks: [],
  workDays: [],
  workTime: undefined,
  filterStatus: {
    any: false,
    region: false,
    workPeriod: false,
    jobTasks: false,
    workDays: false,
    workTime: false,
  },
}

const filterSlice = createSlice({
  name: SliceName,
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, { payload }: PayloadAction<Partial<FilterState>>) => {
      return { ...state, ...payload }
    },
    resetFilters: () => {
      return { ...DEFAULT_FILTER_SET }
    },
    setRegionRange: (state, { payload }: PayloadAction<RegionRange>) => {
      return {
        ...state,
        regionRange: payload,
      }
    },
    setWorkPeriod: (state, { payload }: PayloadAction<WorkPeriod | undefined>) => {
      return {
        ...state,
        workPeriod: payload,
      }
    },
    setWorkDays: (state, { payload }: PayloadAction<WorkDay[] | undefined>) => {
      return {
        ...state,
        workDays: payload,
      }
    },
    setJobTasks: (state, { payload }: PayloadAction<JobTaskType[] | undefined>) => {
      return {
        ...state,
        jobTasks: payload,
      }
    },
    setWorkTime: (state, { payload }: PayloadAction<{ start: WorkTime; end: WorkTime } | undefined>) => {
      const start = Number(payload?.start.split(':')[0])
      const end = Number(payload?.end.split(':')[0])
      const isWorkTimeAllDay = !payload || start === end - 24
      return {
        ...state,
        workTime: isWorkTimeAllDay ? DEFAULT_FILTER_SET.workTime : payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return action.type.startsWith(SliceName)
      },
      (state) => {
        return {
          ...state,
          filterStatus: calcFilterStatus(state),
        }
      }
    )
  },
})

export const { setFilters, resetFilters, setRegionRange, setWorkPeriod, setWorkDays, setWorkTime, setJobTasks } =
  filterSlice.actions

export default filterSlice.reducer
