import React from 'react'

import { advertisement } from '@src/api/api'
import { useImpression } from '@src/hooks/useObserveShowElement'
import { useUserRole } from '@src/hooks/useUserRole'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'

type Props = {
  _id: string
  cardPage?: number
  cardIndex: number
  isAdvertisement: boolean
  advertisementImpressionUrl?: string | null
}

const JobPostCardImpressionChecker: React.FCC<Props> = ({
  _id,
  cardPage,
  cardIndex,
  isAdvertisement,
  advertisementImpressionUrl,
}) => {
  const { isApplicant } = useUserRole()
  const targetRef = React.useRef<HTMLDivElement>(null)
  const { logShowEvent } = useLogAnalyticsEvent()

  useImpression({
    onImpression: () => {
      if (advertisementImpressionUrl) {
        advertisement.impression(advertisementImpressionUrl)
      }

      logShowEvent({
        name: 'job_post_card',
        params: {
          advertisement: isAdvertisement,
          page: cardPage ?? 0,
          index: cardIndex,
          job_post_id: _id,
          viwed_to_applicant: isApplicant,
        },
      })
    },
    ref: targetRef,
    threshold: 0,
  })

  return <div ref={targetRef} />
}

export default JobPostCardImpressionChecker
