import { useActivity } from '@stackflow/react'

export const useCurrentScreen = () => {
  const { isTop, isRoot, isActive } = useActivity()

  return {
    isTop,
    isRoot,
    isActive,
  }
}
