import urlJoin from 'url-join'

import { captureMessage } from '@src/sdks/sentry'
import type { PathComponents, URIComponent, URIObject, URLComponents } from '@src/types/url'

const parseURIValue = (value: URIComponent | URIComponent[]) => {
  const _value = typeof value === 'string' ? value : Array.isArray(value) ? value.join(',') : JSON.stringify(value)

  return _value
}

export const generatePath = ({ paths, params }: PathComponents) => {
  let _path = typeof paths === 'string' ? paths : urlJoin(paths)
  const unusedParams: Record<string, URIComponent | URIComponent[]> = {}

  if (params) {
    Object.entries(params).forEach(([key, _value]) => {
      const value = parseURIValue(_value)

      if (_path.includes(`:${key}`)) {
        if (!value) {
          captureMessage('값이 undefined | null 인 파라미터가 있어요.', {
            extra: { path: _path, key, value },
          })
        }
        _path = _path.replace(`:${key}`, value)
      } else {
        unusedParams[key] = value
      }
    })
  }

  const unmatchedVariable = _path.match(/:(\w+)/g)
  if (unmatchedVariable) {
    throw new Error(
      `정의되지 않은 path variable이 있어요: ${unmatchedVariable}.\n만약 해당 값이 포트 번호라면, generateURL 함수의 base 값에 넣어서 사용해요. `
    )
  }

  return { path: _path, unusedParams }
}

export const generateQueryString = (searchParams: URIObject = {}) => {
  const queryString = Object.entries(searchParams)
    .map(([key, _value]) => {
      const value = parseURIValue(_value)

      return encodeURIComponent(key) + '=' + encodeURIComponent(value)
    })
    .join('&')

  return queryString
}

export const resolveURL = (path: string, queryString: string, base = '') => {
  if (queryString) {
    const separator = path.includes('?') ? '&' : '?'
    return urlJoin(base, path + separator + queryString)
  } else {
    return urlJoin(base, path)
  }
}

/**
 * path resolver function
 * @param {string | string[]} paths URL의 path
 * @param {string} base URL의 프로토콜 + 도메인
 * @param {URIObject | undefined} params path parameter 와 search parameter 를 포함한 객체
 * @notes 도메인에 포트번호를 명시하는 경우 base 값으로 넣어주어야 해요. path 안에서는 `:{key}` 형태를 전부 path variable 로 간주해요.
 */
export const generateURL = ({ paths, base, params }: URLComponents) => {
  try {
    const { path, unusedParams: searchParams } = generatePath({ paths, params })
    const queryString = generateQueryString(searchParams)
    const url = resolveURL(path, queryString, base)
    return url
  } catch (err) {
    // 잘못된 URL 생성 시도에 대응하기 위한 로깅
    const errorMsg = 'URL 생성 오류'
    captureMessage(errorMsg, { extra: { err } })

    return errorMsg
  }
}
