import { IconChevronRightRegular } from '@seed-design/icon'
import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { JobPostAuthorOtherPostTab_jobPost$key } from '@src/__generated__/JobPostAuthorOtherPostTab_jobPost.graphql'
import Button from '@src/components/base/buttons/Button'
import Divider from '@src/components/base/divider/Divider'
import JobPostCardRecommend from '@src/components/common/jobPostCard/JobPostCardRecommend'
import { useNavigator } from '@src/hooks/useNavigator'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { JobsActivity } from '@src/router/activityNames'
import { styled, theme } from '@src/stitches/stitches.config'

interface Props {
  jobPostRef: JobPostAuthorOtherPostTab_jobPost$key
}

const JobPostAuthorOtherPostTab: React.FCC<Props> = ({ jobPostRef }) => {
  const { logClickEvent } = useLogAnalyticsEvent()
  const { push } = useNavigator()

  const jobPost = useFragment(
    graphql`
      fragment JobPostAuthorOtherPostTab_jobPost on JobPost {
        id
        author {
          ... on HoianUserAuthor {
            id
            jobPosts(first: 3, filter: { status: [ACCEPTED, CLOSED] }) {
              edges {
                node {
                  id
                  closed
                  ...JobPostCardRecommend_jobPost
                }
              }
              pageInfo {
                hasNextPage
              }
            }
          }
        }
      }
    `,
    jobPostRef
  )

  const handleAuthorPostMoreClick = () => {
    if (!jobPost.author?.id) return

    logClickEvent({
      name: 'more_author_posts_at_job_post',
    })

    push(JobsActivity.AUTHOR_JOB_POSTS, { authorId: jobPost.author.id })
  }

  const authorPosts = React.useMemo(() => {
    return jobPost.author.jobPosts?.edges.filter(({ node }) => node.id !== jobPost.id) ?? []
  }, [jobPost])

  return (
    <>
      {authorPosts.length > 0 ? (
        <div>
          <div>
            {authorPosts
              .sort((a, b) => Number(a.node.closed) - Number(b.node.closed))
              .map(({ node }, i) => {
                return (
                  <React.Fragment key={node.id}>
                    {i !== 0 && <Divider />}
                    <CardWrapper key={node.id}>
                      <JobPostCardRecommend jobPostRef={node} additionalInfoVisible={false} />
                    </CardWrapper>
                  </React.Fragment>
                )
              })}
          </div>
          {jobPost.author?.jobPosts?.pageInfo.hasNextPage && (
            <NavButton size="small" onClick={handleAuthorPostMoreClick}>
              <NavButtonText>알바 모두 보기</NavButtonText>
              <IconChevronRightRegular width={18} height={18} color={theme.colors.gray900.computedValue} />
            </NavButton>
          )}
        </div>
      ) : (
        <EmptyTab>이 구인자의 다른 알바가 없어요.</EmptyTab>
      )}
    </>
  )
}

export default JobPostAuthorOtherPostTab

const CardWrapper = styled('div', {
  padding: '16px 0 12px',
})

const NavButton = styled(Button, {
  margin: '16px 0 0',
})

const NavButtonText = styled('span', {
  margin: '0 4px 0 0',
})

const EmptyTab = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '94px',
  color: '$gray600',
  $text: 'bodyM1Regular',
})
