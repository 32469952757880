import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@src/ducks/rootReducer'

const appSelector = createSelector(
  (state: RootState) => state.app,
  (d) => d
)

export const modalSelector = createSelector(appSelector, (d) => d.modal)
export const isGlobalLoaderOpenSelector = createSelector(appSelector, (d) => d.isGlobalLoaderOpen)
export const isMapQuotaExceededSelector = createSelector(appSelector, (d) => d.isMapQuotaExceeded)
