import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { JobPostStringifiedBringUpPassedDateResolver$key } from '@src/__generated__/JobPostStringifiedBringUpPassedDateResolver.graphql'
import { stringifyBringUpPassedDate } from '@src/utils/jobPost'

/**
 *
 * @RelayResolver JobPost.stringifiedBringUpPassedDate: String
 * @rootFragment JobPostStringifiedBringUpPassedDateResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function stringifiedBringUpPassedDate(jobPostRef: JobPostStringifiedBringUpPassedDateResolver$key): string {
  const { lastBringUpDate, publishedAt } = readFragment(
    graphql`
      fragment JobPostStringifiedBringUpPassedDateResolver on JobPost {
        lastBringUpDate
        publishedAt
      }
    `,
    jobPostRef
  )

  return stringifyBringUpPassedDate({ lastBringUpDate, publishedAt })
}
