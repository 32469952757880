import { IconCloseRegular } from '@seed-design/icon'
import React from 'react'
import { Transition } from 'react-transition-group'

import { styled, zIndices } from '@src/stitches/stitches.config'

type State = {
  isOpen: boolean
  message: string | React.ReactNode
  buttonText: string
  onClick: () => void
  onClose?: () => void
}

const defaultState: State = {
  isOpen: false,
  message: '',
  buttonText: '',
  onClick: () => {},
  onClose: () => {},
}

const FloatingMessageContext = React.createContext<[State, React.Dispatch<React.SetStateAction<State>>]>([
  {
    isOpen: false,
    message: '',
    buttonText: '',
    onClick: () => {},
    onClose: () => {},
  },
  () => {},
])

export const useFloatingMessageContext = () => React.useContext(FloatingMessageContext)

export const FloatingMessageProvider: React.FCC = ({ children }) => {
  const contextValue = React.useState<{
    isOpen: boolean
    message: string | React.ReactNode
    buttonText: string
    onClick: () => void
    onClose?: () => void
  }>(defaultState)

  return <FloatingMessageContext.Provider value={contextValue}>{children}</FloatingMessageContext.Provider>
}

const FloatingMessage: React.FCC = () => {
  const nodeRef = React.useRef(null)
  const [{ isOpen, message, buttonText, onClick, onClose }, setState] = useFloatingMessageContext()

  const handleClick = () => {
    setState(defaultState)
    onClick?.()
  }

  const handleCloseClick = () => {
    setState(defaultState)
    onClose?.()
  }

  return (
    <Transition nodeRef={nodeRef} in={isOpen} timeout={1000} unmountOnExit>
      {(state) => {
        const isOutro = state !== 'entered'

        return (
          <Base ref={nodeRef} isOutro={isOutro}>
            <Container>
              <Message>{message}</Message>
              <Button onClick={handleClick}>{buttonText}</Button>
              <Close width={16} height={16} onClick={handleCloseClick} />
            </Container>
          </Base>
        )
      }}
    </Transition>
  )
}

export default FloatingMessage

const Base = styled('div', {
  position: 'absolute',
  left: 0,
  bottom: '8px',
  padding: '0 16px',
  width: '100%',
  background: '$paperDefault-semantic',
  borderRadius: '10px',
  overflow: 'hidden',
  zIndex: zIndices.page2,
  transition: 'all 0.2s',

  variants: {
    isOutro: {
      true: {
        opacity: 0,
        transform: 'translate3d(0, 100%, 0)',
      },
      false: {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
    },
  },
})

const layout = {
  margin: '0 0 0 10px',

  '&:first-child': {
    margin: 0,
  },
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '14px',
  background: '$carrotAlpha50',
  borderRadius: '10px',
})

const Message = styled('div', layout, {
  flex: 1,
  $text: 'bodyM2Regular',
  color: '$carrot950',
})

const Button = styled('div', layout, {
  flex: 'none',
  padding: '8px 10px',
  $text: 'label4Bold',
  color: '$onPrimary-semantic',
  background: '$primary-semantic',
  borderRadius: '4px',
})

const Close = styled(IconCloseRegular, layout, {
  flex: 'none',
  color: '$carrot950',
})
