// @src confluence 업로드시 에러
/* eslint-disable no-restricted-imports */
import { PartialOnUndefinedDeep } from 'type-fest'

import {
  AnalyticsEvents,
  AnalyticsCategory as Category,
  Empty,
  AnalyticsLogOption as Log,
  AnalyticsParamGenerator as Param,
} from './type'
import { ResumeFormType } from '../../types/resume'

export const analyticsClickEvents = {
  ad_nudge_by_inventory: {
    category: Category.AD,
    logs: [],
    params: {
      inventory_type: Param.Enum('top_feed', 'job_post_card', 'recommend_job_post_card').Required,
      has_job_post: Param.Boolean.Required,
    },
    description: '광고 인벤토리 영역을 통한 광고하기 넛징 클릭',
  },
  advertisement_create_input_change: {
    category: Category.AD,
    logs: [],
    params: {
      field_name: Param.String.Required,
      duration: Param.Number.Optional,
      budget: Param.Number.Optional,
      region_range: Param.RegionRange.Optional,
    },
    description: '광고 생성시 폼 요소 값 변경',
  },
  advertisement_terms_submit: {
    category: Category.AD,
    logs: [],
    description: '광고 약관 동의',
  },
  biz_top_banner: {
    category: Category.AD,
    params: {
      group: Param.Enum('biz_survey', 'business_profile_banner').Required,
    },
    description: '비즈 메인 상단 배너 클릭',
  },
  introduce_jobs_start: {
    category: Category.AD,
    description: '당근알바 소개 페이지에서 당근알바 시작하기 클릭',
  },
  advertisement_create_submit: {
    category: Category.AD,
    params: {
      job_post_id: Param.String.Required,
      plan: Param.Enum('TOP_PREMIUM', 'STANDARD').Required,
      advertisement_id: Param.String.Required,
      duration: Param.Number.Required,
      daily_budget: Param.Number.Required,
      region_range: Param.RegionRange.Required,
      budget: Param.Number.Required,
      business_type: Param.BusinessType.Required,
      ad_job_post_count: Param.Number.Required,
      passed_minutes_from_created: Param.Number.Required,
    },
    logs: [Log.AMPLITUDE, Log.MIXPANEL],
    description: '광고 생성 폼 제출',
  },
  advertisement_update_submit: {
    category: Category.AD,
    params: {
      job_post_id: Param.String.Required,
      advertisement_id: Param.String.Required,
      rest_days_to_end_at: Param.Number.Required,
      rest_days_to_end_at_from: Param.Number.Required,
      daily_budget: Param.Number.Required,
      daily_budget_from: Param.Number.Required,
      region_range: Param.RegionRange.Required,
      region_range_from: Param.RegionRange.Optional,
      business_type: Param.BusinessType.Required,
      passed_minutes_from_created: Param.Number.Required,
    },
    logs: [Log.MIXPANEL],
    description: '광고 생성 폼 제출',
  },
  advertisement_reserve_without_change: {
    category: Category.AD,
    params: {
      job_post_id: Param.String.Required,
      business_type: Param.BusinessType.Required,
      daily_budget: Param.Number.Required,
      duration: Param.Number.Required,
    },
    logs: [],
    description: '광고 설정 변경없이 재광고 생성',
  },
  current_advertisement: {
    category: Category.AD,
    params: {
      status: Param.String.Required,
      is_advertisement_cash_insufficient: Param.Boolean.Required,
    },
    logs: [],
    description: '광고중 클릭',
  },
  end_advertisement: {
    category: Category.AD,
    logs: [],
    description: '광고 중지 클릭',
  },
  advertisement_cash_insufficient: {
    category: Category.AD,
    params: {
      status: Param.String.Required,
      cash: Param.Number.Required,
    },
    logs: [],
    description: '광고 잔액부족 클릭',
  },
  refresh_top_feed: {
    category: Category.AD,
    params: {},
    description: '최상단 광고 더보기 클릭',
  },
  create_alarm_nudge: {
    category: Category.ALARM,
    logs: [],
    description: '새글 필터알림 넛징',
  },
  on_new_job_post_filter_subscription: {
    category: Category.ALARM,
    params: {
      workDays: Param.WorkDays.Required,
      // Braze 공통발송 삭제되면 안됨
      workPeriods: Param.WorkPeriods.Required,
      jobTasks: Param.Array([Param.String.Required]).Required,
    },
    logs: [Log.BRAZE],
    description: '새글 필터알림 구독 재개',
  },
  off_new_job_post_filter_subscription: {
    category: Category.ALARM,
    params: {
      workDays: Param.WorkDays.Required,
      workPeriods: Param.WorkPeriods.Required,
      jobTasks: Param.Array([Param.String.Required]).Required,
    },
    logs: [],
    description: '새글 필터알림 구독 정지',
  },
  create_new_job_post_filter_subscription: {
    category: Category.ALARM,
    params: {
      workDays: Param.WorkDays.Required,
      isShortWorkPeriod: Param.Boolean.Required,
      // Braze 공통발송 삭제되면 안됨
      workPeriods: Param.WorkPeriod.Required,
      jobTasks: Param.Array([Param.String.Required]).Required,
    },
    logs: [Log.BRAZE],
    description: '새글 필터알림 신규 생성',
  },
  set_new_job_post_filter_subscription: {
    category: Category.ALARM,
    params: {
      workDays: Param.WorkDays.Required,
      workPeriods: Param.WorkPeriods.Required,
      jobTasks: Param.Array([Param.String.Required]).Required,
      isShortWorkPeriod: Param.Boolean.Required,
    },
    logs: [],
    description: '새글 필터알림 설정 변경',
  },
  application_reject: {
    category: Category.APPLICATION,
    logs: [],
    description: '지원자 거절하기 버튼 클릭',
  },
  submit_reject_with_message: {
    category: Category.APPLICATION,
    logs: [],
    params: {
      count: Param.Number.Required,
    },
    description: '메시지와 함께 지원자 거절',
  },
  submit_reject_without_message: {
    category: Category.APPLICATION,
    params: {
      count: Param.Number.Required,
    },
    logs: [],
    description: '메시지 없이 지원자 거절',
  },
  late_submit_application: {
    category: Category.APPLY,
    params: {
      job_post_id: Param.String.Optional,
      reason: Param.Enum('CLOSED', 'SLOT_FULL').Required,
    },
    logs: [],
    description: '공고마감 후 지원서 제출',
  },
  apply: {
    category: Category.APPLY,
    params: {
      is_first_apply: Param.Boolean.Required,
      badges: Param.String.Optional,
      bring_up_count: Param.Number.Optional,
      watch_count: Param.Number.Optional,
      react_seconds: Param.Number.Required,
      application_count: Param.Number.Optional,
      welfare: Param.String.Optional,
      considered_qualifications: Param.String.Optional,
      is_personal: Param.Boolean.Required,
      business_type: Param.BusinessType.Optional,
      is_walking_distance: Param.Boolean.Required,
      job_post_type: Param.Enum('EVENT', 'NORMAL').Required,
      is_advertisement: Param.Boolean.Required,
      is_ad_ongoing: Param.Boolean.Required,
      last_activated_at: Param.String.Optional,
      // Braze 공통발송 삭제되면 안됨
      job_post_id: Param.String.Optional,
      job_post_title: Param.String.Nullable,
      is_short_work_period: Param.Boolean.Required,
      job_tasks: Param.JobTasks.Optional,
    },
    logs: [Log.JOBS_LOG, Log.MIXPANEL, Log.BRAZE],
    description: '지원서 제출',
  },
  apply_button: {
    category: Category.APPLY,
    params: {
      is_first_apply: Param.Boolean.Required,
      is_short_work_period: Param.Boolean.Required,
      badges: Param.String.Optional,
      bring_up_count: Param.Number.Optional,
      watch_count: Param.Number.Optional,
      react_seconds: Param.Number.Required,
      application_count: Param.Number.Optional,
      welfare: Param.String.Optional,
      considered_qualifications: Param.String.Optional,
      is_personal: Param.Boolean.Required,
      // Braze 공통발송 삭제되면 안됨
      job_post_id: Param.String.Optional,
      job_post_title: Param.String.Nullable,
    },
    logs: [Log.BRAZE],
    description: '지원하기 클릭',
  },
  apply_cancel: {
    category: Category.APPLY,
    params: {
      job_post_id: Param.String.Optional,
      is_short_work_period: Param.Boolean.Required,
      is_viewed: Param.Boolean.Required,
      passed_minutes: Param.Number.Required,
    },
    logs: [],
    description: '지원 취소하기 클릭',
  },
  delete_application: {
    category: Category.APPLY,
    logs: [],
    description: '지원 삭제하기 클릭',
  },
  native_alarm_setting: {
    category: Category.APPLY,
    logs: [],
    description: '네이티브 알람 설정 클릭',
  },
  activity_badge_item: {
    category: Category.BADGE,
    params: {
      role: Param.Role.Required,
      item_code: Param.String.Required,
      item_amount: Param.Number.Required,
    },
    logs: [],
    description: '개별 활동배지 항목 클릭',
  },
  search_business_number: {
    category: Category.CERTIFICATION,
    params: {
      success: Param.Boolean.Required,
      is_owner_name_fail_potential: Param.Boolean.Required,
    },
    logs: [],
    description: '사업자번호 검색',
  },
  update_business_certification_field: {
    category: Category.CERTIFICATION,
    params: {
      field_name: Param.Enum('ownerName', 'businessOpenDate', 'businessNumber').Required,
    },
    logs: [],
    description: '사업자번호 인증 필드 입력',
  },
  certificate_image: {
    category: Category.CERTIFICATION,
    params: {
      businessNumber: Param.String.Required,
      businessOpenDate: Param.String.Optional,
      ownerName: Param.String.Required,
    },
    logs: [Log.MIXPANEL],
    description: '사업자등록증 OCR 이미지 업로드 후 인증 클릭',
  },
  request_identification: {
    category: Category.CERTIFICATION,
    params: {},
    logs: [Log.JOBS_LOG],
    description: '인증 요청',
  },
  verify_identification: {
    category: Category.CERTIFICATION,
    params: {
      success: Param.Boolean.Required,
      failure_reason: Param.String.Optional,
    },
    logs: [Log.JOBS_LOG, Log.MIXPANEL],
    description: '인증 성공 여부',
  },
  certificate_ask: {
    category: Category.CERTIFICATION,
    logs: [Log.MIXPANEL],
    description: '사업자인증 문의하기 클릭',
  },
  register_certification_image: {
    category: Category.CERTIFICATION,
    description: '사업자인증 OCR 실패 후 이미지 등록',
    logs: [Log.MIXPANEL],
  },
  certificate_business: {
    category: Category.CERTIFICATION,
    params: {
      success: Param.Boolean.Required,
      use_image: Param.Boolean.Required,
    },
    logs: [Log.MIXPANEL, Log.BRAZE],
    description: '사업자인증',
  },
  start_hoian_chat: {
    category: Category.CONTACT,
    params: {
      role: Param.Role.Required,
      resume_id: Param.String.Required,
      passed_minutes_from_apply: Param.Number.Required,
      is_first_contact: Param.Boolean.Required,
      is_walking_distance: Param.Boolean.Required,
    },
    logs: [Log.BRAZE],
    description: '채팅 열기',
  },
  open_hoian_chat: {
    category: Category.CONTACT,
    params: {
      role: Param.Role.Required,
    },
    description: '이미 열린 채팅하기 클릭',
  },
  phone_call: {
    category: Category.CONTACT,
    params: {
      role: Param.Role.Required,
      job_post_id: Param.String.Optional,
      resume_id: Param.String.Optional,
      is_applied: Param.Boolean.Optional,
      is_first_contact: Param.Boolean.Optional,
      passed_minutes_from_apply: Param.Number.Optional,
      is_walking_distance: Param.Boolean.Optional,
      type: Param.Enum('DAANGN', 'SAFE_NUMBER').Required,
    },
    logs: [],
    description: '전화하기',
  },
  event_banner: {
    category: Category.EVENT,
    params: {
      event_kind: Param.String.Required,
      image_type: Param.Number.Optional,
    },
    description: '이벤트 배너 클릭',
  },
  invite_event_share: {
    category: Category.EVENT,
    params: {
      event_kind: Param.String.Required,
    },
    description: '초대하기 이벤트 공유',
  },
  approve_invited_success_halfview: {
    category: Category.EVENT,
    sample: true,
    params: {
      group: Param.Enum('MARKETING_INVITE_202404').Required,
    },
    description: '초대 성공 하프뷰 수락',
  },
  register_invite_event_code: {
    category: Category.EVENT,
    params: {
      event_kind: Param.String.Required,
      not_available: Param.String.Optional,
    },
    description: '초대하기 이벤트 초대 수락 완료',
  },
  register_campaign: {
    category: Category.EVENT,
    params: {
      group: Param.Enum(
        'LGU_REWARD_202405',
        'JOBS_TOP_PREMIUM_202305',
        'MARKETING_PAID_CREATE_JOB_POST_202306',
        'MARKETING_PAY_ACCOUNT_SIGNUP_202309',
        'MARKETING_CREATE_JOB_POST_202309',
        'JOBS_NO_SHOW_COMPENSATION_JOB_POST_202309',
        'JOBS_NO_SHOW_COMPENSATION_ADVERTISEMENT_202309',
        'MARKETING_CREATE_JOB_POST_202310',
        'MARKETING_INVITE_202404',
        'MARKETING_PAY_ACCOUNT_SIGNUP',
        'MARKETING_TOTALCARE_REGULAR',
        'FLY_202310',
        'MARKETING_TOTALCARE_202403',
        'MARKETING_APPLICANT_WARRANTY_202406'
      ).Required,
      message_type: Param.String.Optional,
      is_new_user: Param.Boolean.Optional,
    },
    logs: [Log.MIXPANEL, Log.JOBS_LOG],
    description: '이벤트 참여 신청',
  },
  set_filter: {
    category: Category.FILTER,
    params: {
      job_tasks: Param.String.Optional,
      region_range: Param.RegionRange.Optional,
      work_days: Param.String.Optional,
      status: Param.WorkPeriod.Optional,
      work_time_start: Param.String.Optional,
      work_time_end: Param.String.Optional,
    },
    description: '피드 필터 폼 값 변경(반영 전)',
  },
  job_post_favorite: {
    category: Category.FAVORITE,
    params: {
      will_like: Param.Boolean.Required,
      bring_up_count: Param.Number.Required,
      badges: Param.String.Optional,
      // Braze 공통발송 삭제되면 안됨
      job_post_id: Param.String.Required,
      job_post_title: Param.String.Nullable,
      is_short_work_period: Param.Boolean.Required,
      job_tasks: Param.JobTasks.Optional,
    },
    logs: [Log.BRAZE],
    description: '관심 설정/해제',
  },
  replace_to_main: {
    category: Category.HOME,
    description: '홈이 아닌 루트로 진입 후 홈 바로가기 클릭',
    params: {
      section: Param.String.Optional,
    },
  },
  redirect_home: {
    category: Category.HOME,
    description: '공고 상세에서 당근마켓 피드로 이동',
  },
  hire: {
    category: Category.HIRE,
    params: {
      resume_id: Param.String.Required,
      passed_minutes_from_apply: Param.Number.Required,
      selected_review_count: Param.Number.Required,
      comment_review_count: Param.Number.Required,
    },
    logs: [],
    description: '채용을 확정해요.',
  },
  copy_address: {
    category: Category.MAP,
    description: '주소 복사 클릭',
  },
  map: {
    category: Category.MAP,
    params: {
      is_personal: Param.Boolean.Required,
      is_walking_distance: Param.Boolean.Required,
    },
    description: '지도 클릭',
  },
  biz_main_menu: {
    category: Category.MENU,
    description: '구인자 홈 클릭',
  },
  me_menu: {
    category: Category.MENU,
    description: '구직자 프로필 클릭',
  },
  resume_menu: {
    category: Category.MENU,
    description: '이력서 페이지',
  },
  open_applicant_review_banner: {
    category: Category.PROFILE,
    description: '피드에서 내가 받은 추천 보기 배너 클릭',
  },
  profile: {
    category: Category.PROFILE,
    params: {
      owner: Param.Role.Required,
    },
    logs: [],
    description: '사용자 프로필 클릭',
  },
  more_author_posts_at_job_post: {
    category: Category.RECOMMEND,
    description: '공고 상세 구인자 다른 글 더보기 클릭',
  },
  show_all_posts: {
    category: Category.RECOMMEND,
    params: {
      job_post_id: Param.String.Optional,
    },
    description: '공고 상세 우리동데 알바 더보기 클릭',
  },
  close_experience_auto_added_message: {
    category: Category.RESUME,
    logs: [],
    description: '자동추가된 경력 삭제',
    // TODO: delete_experience와 별도 구현될 이유 있는지 확인
  },
  add_resume_experience: {
    category: Category.RESUME,
    params: {
      content_length: Param.Number.Required,
    },
    logs: [],
    description: '경력 추가',
  },
  delete_experience: {
    category: Category.RESUME,
    logs: [],
    params: {
      isAutoAdded: Param.Boolean.Required,
    },
    description: '경력 삭제',
  },
  create_resume: {
    category: Category.RESUME,
    params: {
      has_name: Param.Boolean.Required,
      has_image: Param.Boolean.Required,
      experience_count: Param.Number.Required,
      content_length: Param.Number.Required,
      characters: Param.Characters.Required,
      extra_features: Param.ExtraFeatures.Required,
    },
    description: '이력서 생성',
    logs: [Log.BRAZE],
  },
  update_resume: {
    category: Category.RESUME,
    description: '이력서 업데이트',
    params: {
      type: Param.Enum(...Object.values(ResumeFormType)).Required,
    },
    logs: [],
  },
  resume_favorite: {
    category: Category.RESUME,
    params: {
      will_like: Param.Boolean.Required,
    },
    description: '지원서 별표 표시/해제',
  },
  delete_resume_privacy: {
    category: Category.RESUME,
    description: '개인정보 삭제',
  },
  written_review: {
    category: Category.REVIEW,
    params: {
      role: Param.Role.Required,
      job_post_id: Param.String.Optional,
      is_short_work_period: Param.Boolean.Optional,
      rating: Param.Rating.Optional,
    },
    logs: [],
    description: '보낸 후기/추천 보기 클릭',
  },
  write_review: {
    category: Category.REVIEW,
    description: '후기/추천 보내기 클릭',
  },
  create_review: {
    category: Category.REVIEW,
    params: {
      role: Param.Role.Required,
      is_short_work_period: Param.Boolean.Optional,
      rating: Param.Rating.Optional,
      // Braze 공통발송 삭제되면 안됨
      job_post_id: Param.String.Optional,
      job_post_title: Param.String.Nullable,
    },
    log: [Log.BRAZE],
    description: '후기/추천 작성 완료',
  },
  more_comment_reviews: {
    category: Category.REVIEW,
    description: '받은 주관 후기 더보기 클릭',
  },
  more_selected_reviews: {
    category: Category.REVIEW,
    logs: [],
    description: '받은 객관 후기/추천 더보기 클릭',
  },
  popular_review_author: {
    category: Category.REVIEW,
    params: {
      job_post_id: Param.String.Required,
      is_live: Param.Boolean.Required,
    },
    description: '인기 알바 내 구인자 프로필 클릭',
  },
  popular_review_comment: {
    category: Category.REVIEW,
    logs: [],
    description: '인기알바 내 후기 영역 클릭',
  },
  review_list_to_send: {
    category: Category.REVIEW,
    logs: [],
    description: '미작성 후기 모음 페이지',
  },
  delete_job_review: {
    category: Category.REVIEW,
    description: '후기 삭제',
  },
  recently_job_post_search_word: {
    category: Category.SEARCH,
    params: {
      keyword: Param.String.Required,
    },
    description: '최근 검색어 클릭',
  },
  suggested_event_search_word: {
    category: Category.SEARCH,
    params: {
      keyword: Param.String.Required,
    },
    logs: [],
    description: '제안 검색어 클릭',
  },
  search_job_post: {
    category: Category.SEARCH,
    params: {
      keyword: Param.String.Required,
    },
    description: '검색어 입력 후 검색 버튼 클릭',
  },
  share_event_group_feed: {
    category: Category.SHARE,
    params: {
      group: Param.String.Required,
    },
    logs: [],
    description: '키워드 피드 공유',
  },
  share_curation_feed: {
    category: Category.SHARE,
    params: {},
    logs: [],
    description: '큐레이션 피드 공유',
  },
  shared_service: {
    category: Category.SHARE,
    description: '당근알바 서비스 공유',
  },
  share_job_post: {
    category: Category.SHARE,
    params: {
      job_post_id: Param.String.Required,
      badges: Param.String.Optional,
      bring_up_count: Param.Number.Optional,
    },
    logs: [],
    description: '공고 공유',
  },
  share_event_job_post: {
    category: Category.SHARE,
    params: {
      job_post_id: Param.String.Required,
      event_group: Param.String.Optional,
    },
    description: '이벤트 공고 공유',
  },
  slot_overflow_ask_paid: {
    category: Category.SLOT,
    logs: [],
    description: '기업형 상품 문의하기',
  },
  survey_banner: {
    category: Category.SURVEY,
    description: '설문조사',
  },
  beginner_job_post: {
    category: Category.JOB_POST,
    params: {
      job_post_id: Param.String.Required,
      advertisement_index: Param.Number.Required,
    },
    description: '초보알바 공고 클릭',
  },
  teenager_job_post: {
    category: Category.JOB_POST,
    params: {
      job_post_id: Param.String.Required,
      advertisement_index: Param.Number.Required,
    },
    description: '10대알바 공고 클릭',
  },
  senior_job_post: {
    category: Category.JOB_POST,
    params: {
      job_post_id: Param.String.Required,
      advertisement_index: Param.Number.Required,
    },
    description: '시니어알바 공고 클릭',
  },
  walking_distance_job_post: {
    category: Category.JOB_POST,
    params: {
      job_post_id: Param.String.Required,
      advertisement_index: Param.Number.Required,
    },
    description: '걸어서10분 알바 공고 클릭',
  },
  ignore_job_post: {
    category: Category.JOB_POST,
    logs: [],
    params: {
      job_post_id: Param.String.Required,
      advertisement: Param.Boolean.Required,
    },
    description: '공고 안 보기',
  },
  job_post_card: {
    category: Category.JOB_POST,
    sample: false,
    params: {
      job_post_id: Param.String.Required,
      bring_up_count: Param.Number.Optional,
      badges: Param.String.Optional,
      advertisement: Param.Boolean.Required,
      keyword: Param.String.Optional,
      page: Param.Number.Required,
      index: Param.Number.Required,
    },
    description: '피드에서 공고 클릭',
  },
  famous_job_post: {
    category: Category.JOB_POST,
    params: {
      job_post_id: Param.String.Optional,
      rank: Param.Number.Optional,
    },
    description: '인기알바 공고 클릭',
  },
  job_task: {
    category: Category.JOB_POST,
    params: {
      task: Param.String.Required,
    },
    description: '공고 상새 내 하는 일 칩 클릭',
  },
  create_job_post: {
    category: Category.JOB_POST_CREATE,
    params: {
      is_copy: Param.Boolean.Optional,
      is_today: Param.Boolean.Optional,
      salary_type: Param.SalaryType.Optional,
      migrate: Param.Boolean.Optional,
      interval_days: Param.Number.Optional,
      business_type: Param.BusinessType.Required,
      ad_job_post_count: Param.Number.Required,
      bm_target: Param.Boolean.Optional,
      // Braze 공통발송 삭제되면 안됨
      job_post_id: Param.String.Optional,
      job_post_title: Param.String.Nullable,
      work_period: Param.WorkPeriod.Optional,
      is_personal: Param.Boolean.Optional,
      job_post_count: Param.Number.Required,
    },
    logs: [Log.AMPLITUDE, Log.MIXPANEL, Log.BRAZE],
    description: '공고 생성',
  },
  checkin_region_at_creation: {
    category: Category.JOB_POST_CREATE,
    logs: [],
    params: {
      uid: Param.String.Required,
      title: Param.String.Required,
    },
    description: '공고 작성 중 동네인증 클릭',
  },
  write_job_post: {
    category: Category.JOB_POST_CREATE,
    description: '글쓰기 버튼 클릭',
  },
  job_post_preview_back_button: {
    category: Category.JOB_POST_CREATE,
    description: '공고 작성 미리보기 화면에서 뒤로가기 클릭',
  },
  rewrite_job_post: {
    category: Category.JOB_POST_CREATE,
    params: {
      job_post_id: Param.String.Required,
    },
    logs: [],
    description: '새로 구인하기 클릭',
  },
  reexamine_job_post: {
    category: Category.JOB_POST_CREATE,
    params: {
      job_post_id: Param.String.Required,
      is_personal: Param.Boolean.Required,
    },
    logs: [],
    description: '반려된 공고 재심사 요청',
  },
  job_post_form_input_change: {
    category: Category.JOB_POST_CREATE,
    params: {
      field: Param.String.Required,
    },
    logs: [],
    description: '공고 폼 입력',
  },
  job_post_form_preview: {
    category: Category.JOB_POST_CREATE,
    description: '공고 작성 중 미리보기 수',
  },
  job_post_form_submit: {
    category: Category.JOB_POST_CREATE,
    description: '공고 폼 작성 후 완료하기 클릭',
  },
  promise_min_salary: {
    category: Category.JOB_POST_CREATE,
    logs: [],
    description: '최저임금 준수 약속',
  },
  select_job_post_for_copy: {
    category: Category.JOB_POST_CREATE,
    description: '불러오기 페이지에서 공고 선택',
  },
  copy_job_post: {
    category: Category.JOB_POST_CREATE,
    logs: [Log.MIXPANEL],
    description: '불러오기 클릭',
  },
  manage_job_post: {
    category: Category.JOB_POST_MANAGE,
    description: '작성한 공고 클릭',
  },
  manage_applications: {
    category: Category.JOB_POST_MANAGE,
    description: '지원자 목록 클릭',
  },
  approve_application_open_confirm_dialog: {
    category: Category.JOB_POST_MANAGE,
    description: '지원자 공고 열람 다이얼로그 수락',
    logs: [Log.MIXPANEL],
    params: {
      application_id: Param.String.Required,
    },
  },
  cancel_application_open_confirm_dialog: {
    category: Category.JOB_POST_MANAGE,
    description: '지원자 공고 열람 다이얼로그 취소',
    logs: [Log.MIXPANEL],
    params: {
      application_id: Param.String.Required,
    },
  },
  bring_up_guide_approve: {
    category: Category.JOB_POST_MANAGE,
    params: {
      job_post_id: Param.String.Required,
      bring_up_count: Param.Number.Required,
    },
    description: '끌올 가이드 확인 후 끌올하기 클릭',
  },
  bring_up_guide_cancel: {
    category: Category.JOB_POST_MANAGE,
    params: {
      job_post_id: Param.String.Required,
      bring_up_count: Param.Number.Required,
    },
    description: '끌올 가이드 닫기',
  },
  close_job_post: {
    category: Category.JOB_POST_MANAGE,
    logs: [Log.BRAZE],
    params: {
      // Braze 공통발송 삭제되면 안됨
      job_post_id: Param.String.Required,
      job_post_title: Param.String.Nullable,
    },
    description: '공고 마감',
  },
  cancel_close_job_post: {
    category: Category.JOB_POST_MANAGE,
    logs: [],
    params: {
      job_post_id: Param.String.Required,
    },
    description: '공고 마감 취소',
  },
  open_job_post: {
    category: Category.JOB_POST_MANAGE,
    params: {
      passed_days_from_created_at: Param.Number.Required,
    },
    logs: [],
    description: '구인공고 재오픈',
  },
  bring_up_job_post: {
    category: Category.JOB_POST_MANAGE,
    params: {
      job_post_id: Param.String.Required,
      bring_up_count: Param.Number.Required,
      created_at: Param.String.Optional,
      is_short_work_period: Param.Boolean.Required,
      welfare: Param.Array([
        'INSURANCE',
        'PAID_VACATION',
        'HOLIDAY_WORKING_ALLOWANCE',
        'ANNUAL_LEAVE',
        'RETIREMENT_BENEFIT',
        'REST_TIME_GUARANTEE',
        'MEAL_PROVIDED',
      ] as const).Required,
      qualifications: Param.Array(['BEGINNER', 'FOREIGNER', 'ADULT_ALL_AGES', 'MINOR'] as const).Required,
      added_images_count: Param.Number.Required,
      added_welfare_count: Param.Number.Required,
      added_qualifications_count: Param.Number.Required,
    },
    logs: [],
    description: '끌어올리기',
  },
  update_extend_job_post: {
    category: Category.JOB_POST_MANAGE,
    params: {
      job_post_id: Param.String.Required,
      images_action: Param.Enum('CREATE', 'UPDATE', 'DELETE').Optional,
      welfare_action: Param.Enum('CREATE', 'UPDATE', 'DELETE').Optional,
      considered_qualifications_action: Param.Enum('CREATE', 'UPDATE', 'DELETE').Optional,
    },
    logs: [],
    description: '공고 추가정보 제출',
  },
  delete_job_post: {
    category: Category.JOB_POST_MANAGE,
    logs: [],
    description: '알바공고 삭제',
  },
  floating_alarm_registration_nudge: {
    category: Category.ETC,
    description: '알림 등록 유도 UI 등록하기 클릭 (임시)',
  },
  update_job_post: {
    category: Category.JOB_POST_UPDATE,
    params: {
      job_post_id: Param.String.Required,
      images_action: Param.Enum('CREATE', 'UPDATE', 'DELETE').Optional,
    },
    logs: [],
    description: '구인공고 수정',
  },
  job_post_content_outlink: {
    category: Category.ETC,
    params: {
      job_post_id: Param.String.Required,
    },
    logs: [],
    description: '외부 링크 클릭',
  },
  report_job_post: {
    category: Category.ETC,
    params: {
      application_status: Param.ApplicationStatusType.Optional,
    },
    logs: [],
    description: '신고하기 클릭',
  },
  report_applicant: {
    category: Category.ETC,
    params: {
      application_status: Param.ApplicationStatusType.Required,
    },
    logs: [],
    description: '신고하기 클릭',
  },
  campaign_page: {
    category: Category.EVENT,
    description: '내/외부 캠페인 페이지 내 클릭 트래킹',
    params: {
      button_id: Param.Enum(
        'alba_campaign_author_202307_cta',
        'alba_campaign_author_202307_share',
        'alba_campaign_author_202307_banner',
        'alba_campaign_author_202307_floating_share',
        'alba_campaign_applicant_202307_cta',
        'alba_campaign_applicant_202307_share',
        'alba_campaign_applicant_202307_banner',
        'alba_campaign_applicant_202307_floating_share'
      ).Required,
      button_context: Param.String.Optional,
    },
  },
  // 캠페인 종료 시 확인하고 삭제 예정
  check_campaign_detail: {
    category: Category.EVENT,
    description: '구인글 작성후 캠페인 상세 확인하기 클릭',
    params: {
      event_kind: Param.String.Required,
    },
  },
  create_confirm_for_campaign_primary: {
    category: Category.EVENT,
    description: '구인글 작성 후 캠페인 상세 페이지 primary 버튼 클릭',
    params: {
      event_kind: Param.Enum(
        'LGU_REWARD_202405',
        'MARKETING_CREATE_JOB_POST_202309',
        'MARKETING_CREATE_JOB_POST_202310',
        'MARKETING_TOTALCARE_REGULAR',
        'MARKETING_TOTALCARE_202403',
        'MARKETING_APPLICANT_WARRANTY_202406'
      ).Required,
    },
    logs: [],
  },
  create_confirm_for_campaign_secondary: {
    category: Category.EVENT,
    description: '구인글 작성 후 캠페인 상세 페이지 secondary 버튼 클릭',
    params: {
      event_kind: Param.Enum(
        'LGU_REWARD_202405',
        'MARKETING_CREATE_JOB_POST_202309',
        'MARKETING_CREATE_JOB_POST_202310',
        'MARKETING_TOTALCARE_REGULAR',
        'MARKETING_TOTALCARE_202403',
        'MARKETING_APPLICANT_WARRANTY_202406'
      ).Required,
    },
    logs: [],
  },
  job_post_create_purchase: {
    category: Category.BM,
    description: 'BM 상품 구매',
    params: {
      job_post_id: Param.String.Required,
      passed_minutes_from_created_at: Param.Number.Required,
      exposure_hours: Param.Number.Required,
      payment_price: Param.Number.Required,
      with_escape_section: Param.Boolean.Required,
      job_tasks: Param.JobTasks.Required,
      job_post_count: Param.Number.Required,
      advertised_job_post_count: Param.Number.Required,
      workplace_region_id: Param.Number.Optional,
    },
    logs: [Log.AMPLITUDE],
  },
  job_post_create_free: {
    category: Category.BM,
    description: 'BM 상품 기다무 선택',
    params: {
      waiting_hours: Param.Number.Required,
      with_escape_section: Param.Boolean.Required,
    },
    logs: [Log.AMPLITUDE],
  },
  job_post_create_free_guide_halfview: {
    category: Category.BM,
    description: 'BM 상품 기다무 가이드 하프뷰',
    logs: [Log.AMPLITUDE],
  },
  application_open_right_purchase: {
    category: Category.BM,
    description: '지원서 조회 상품 구매',
    params: {
      job_post_id: Param.String.Required,
      selected_count: Param.Number.Required,
      waiting_application_count: Param.Number.Required,
      payment_price: Param.Number.Required,
    },
    logs: [Log.MIXPANEL],
  },
  excute_create_job_post_callback: {
    category: Category.JOB_POST_CREATE,
    description: '공고 생성 완료 후 콜백 실행',
    params: {
      description: Param.String.Required,
    },
    logs: [Log.MIXPANEL_ONLY],
  },
  add_job_post_image: {
    category: Category.JOB_POST_MANAGE,
    description: '구인글 사진 추가하기 유도 버튼',
    params: {
      job_post_id: Param.String.Required,
    },
    logs: [Log.AMPLITUDE],
  },
  edit_job_post_image: {
    category: Category.JOB_POST_MANAGE,
    description: '구인글 사진 수정하기 유도 버튼',
    params: {
      job_post_id: Param.String.Required,
    },
    logs: [Log.AMPLITUDE],
  },
  job_post_filter_subscription: {
    category: Category.JOB_POST,
    description: '키워드 알림 받기 버튼',
    params: {
      job_post_id: Param.String.Required,
    },
  },
  author_fast_response_nudge_button: {
    category: Category.ETC,
    description: '구인자 빠른응답 동기부여 바로보기 버튼',
    params: {
      engagement_score: Param.Number.Nullable,
    },
    logs: [Log.MIXPANEL],
  },
} as const

export const _clickEventsForOnlyTypeCheck: AnalyticsEvents = analyticsClickEvents

export type AnalyticsClickEventKey = keyof typeof analyticsClickEvents

export type InferClickEventParams<T extends AnalyticsClickEventKey> = (typeof analyticsClickEvents)[T] extends {
  params: any
}
  ? { params: PartialOnUndefinedDeep<(typeof analyticsClickEvents)[T]['params']> }
  : Empty
