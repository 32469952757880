import { IconChevronRightRegular } from '@seed-design/icon'
import React from 'react'

import Image from '@src/components/base/image/Image'
import { IMAGE_JOBS_COLORED } from '@src/constants/images'
import { useRefreshJobs } from '@src/hooks/useRefreshJobs'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { styled, theme } from '@src/stitches/stitches.config'

type Props = {
  isTopOnDocument?: boolean
}

const ReplaceToMain: React.FCC<Props> = ({ isTopOnDocument = true, children, ...props }) => {
  const { refreshJobs } = useRefreshJobs()
  const { logClickEvent } = useLogAnalyticsEvent()

  return (
    <Container
      onClick={() => {
        logClickEvent({
          name: 'replace_to_main',
          params: {
            section: 'static_banner',
          },
        })
        refreshJobs()
      }}
      isTopOnDocument={isTopOnDocument}
      {...props}>
      <Content>
        <JobsIconImage source={IMAGE_JOBS_COLORED} />
        <Text>
          <strong>당근알바</strong> 바로가기
        </Text>
      </Content>
      <IconChevronRightRegular width={15} height={15} color={theme.colors.gray600.computedValue} />
    </Container>
  )
}

export default ReplaceToMain

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 16px',

  variants: {
    isTopOnDocument: {
      true: {
        background: '$paperDefault-semantic',
      },
      false: {
        background: '$paperContents-semantic',
      },
    },
  },

  defaultVariants: {
    isTopOnDocument: true,
  },
})

const Content = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const JobsIconImage = styled(Image, {
  width: '18px',
  height: '18px',
})

const Text = styled('div', {
  color: '$gray700',
  margin: '0 8px 0 6px',
  $text: 'caption1Bold',
})
