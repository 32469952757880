import { useActions, useActivity, useActivityParams, useStack } from '@stackflow/react'
import { pickBy } from 'lodash'
import React from 'react'

import { useGetPageCodeInfo } from '@src/contexts/PageCodeInfoContext'
import { useReferrerContext } from '@src/packages/logAnalyticsEvent/ReferrerContext'
import { ActivityName, JobsActivity } from '@src/router/activityNames'
import { ActivityParamsSchema } from '@src/router/activityParams'
import { bridge } from '@src/sdks/bridge'
import { getLiveAcitivites, isTopActivityMain } from '@src/stackflow/utils'

// eslint-disable-next-line @typescript-eslint/ban-types

type PageCodeParams = 'entry' | 'entrySection' | 'entryContext'
type RefererParams = 'referrer' | 'referrer_context'
type PushOptions = {
  reuseEntryParams?: boolean
}
type ReplaceOptions = {
  reuseEntryParams?: boolean
}

const removeUndefined = (object: Record<string, string | undefined | number>) =>
  pickBy(object, (value) => value !== undefined) as Record<string, string>

export function useNavigator() {
  const actions = useActions()
  const { name: currentActivityName, params: currentActivityParams, isRoot } = useActivity()
  const { pageCode, pageSectionCode, pageContext } = useGetPageCodeInfo()
  const { entry, entrySection, entryContext } = useEntryParams()
  const { referrer, referrer_context } = useReferrerParams()
  const { referrerInfo } = useReferrerContext()

  const push = React.useCallback(
    <T extends ActivityName>(
      activityName: T,
      params: ActivityParamsSchema<T>,
      options?: Parameters<ReturnType<typeof useActions>['push']>[2] & PushOptions
    ) => {
      const querystrings = removeUndefined(
        options?.reuseEntryParams
          ? {
              entry,
              entrySection,
              entryContext,
              referrer: referrer ?? referrerInfo?.referrer,
              referrer_context: referrer_context ?? referrerInfo?.referrer_context,
            }
          : {
              entry: pageCode,
              entrySection: pageSectionCode,
              entryContext: pageContext,
              referrer: referrer ?? referrerInfo?.referrer,
              referrer_context: referrer_context ?? referrerInfo?.referrer_context,
            }
      )

      return actions.push(activityName, { ...params, ...querystrings }, options)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions,
      pageCode,
      pageSectionCode,
      pageContext,
      entry,
      entrySection,
      entryContext,
      referrer,
      referrer_context,
      referrerInfo,
    ]
  )

  const replace = React.useCallback(
    <T extends ActivityName>(
      activityName: T,
      params: ActivityParamsSchema<T>,
      options?: Parameters<ReturnType<typeof useActions>['replace']>[2] & ReplaceOptions
    ) => {
      const querystrings = removeUndefined(
        options?.reuseEntryParams
          ? {
              entry,
              entrySection,
              entryContext,
              referrer: referrer ?? referrerInfo?.referrer,
              referrer_context: referrer_context ?? referrerInfo?.referrer_context,
            }
          : {
              entry: pageCode,
              entrySection: pageSectionCode,
              entryContext: pageContext,
              referrer: referrer ?? referrerInfo?.referrer,
              referrer_context: referrer_context ?? referrerInfo?.referrer_context,
            }
      )

      actions.replace(activityName, { ...params, ...querystrings }, { animate: false, ...options })
    },
    [
      actions,
      pageCode,
      pageSectionCode,
      pageContext,
      entry,
      entrySection,
      entryContext,
      referrer,
      referrer_context,
      referrerInfo,
    ]
  )

  const refresh = () => {
    actions.replace(currentActivityName, currentActivityParams, { animate: false })
  }

  type PopParams = Parameters<typeof actions.pop>
  const pop = React.useCallback(
    (popCount: PopParams[0] = 1, options?: PopParams[1]) => {
      if (isRoot) {
        bridge.router.close()
      } else {
        actions.pop(popCount, options)
      }
    },
    [isRoot, actions]
  )

  return { push, replace, refresh, pop }
}

export const useEntryParams = () => {
  const { entry, entrySection, entryContext } = useActivityParams<{ [key in PageCodeParams]: string }>()

  return React.useMemo(
    () => ({
      entry,
      entrySection,
      entryContext,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export const useReferrerParams = () => {
  const { referrer, referrer_context } = useActivityParams<{ [key in RefererParams]: string }>()

  return React.useMemo(
    () => ({
      referrer,
      referrer_context,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export const useAppNavigation = () => {
  const { activities } = useStack()
  const currentActivity = useActivity()
  const { referrer } = useActivityParams<{ [key in RefererParams]: string }>()
  const liveActivities = getLiveAcitivites(activities)

  const getJobPostDetailOrder = () => {
    const jobPostActivityNames = [
      JobsActivity.JOB_POST,
      JobsActivity.JOB_POST_AD,
      JobsActivity.BIZ_JOB_POST_MANAGE,
    ] as string[]
    const jobPostDetailActivities = liveActivities.filter((activity) => jobPostActivityNames.includes(activity.name))
    const index = jobPostDetailActivities.findIndex((activity) => activity.id === currentActivity.id)
    return index === -1 ? -1 : index + 1
  }

  const isJobsMainShortcutVisibleDepth = React.useMemo(() => {
    return isTopActivityMain(liveActivities) ? getJobPostDetailOrder() > 1 : true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isKarrotMainShortcutVisible = React.useMemo(() => {
    const isUserFromKarrotFeed = referrer === 'feed'
    return isUserFromKarrotFeed && getJobPostDetailOrder() > 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isJobsMainShortcutVisibleDepth, isKarrotMainShortcutVisible }
}
