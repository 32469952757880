/**
 * @generated SignedSource<<cb8c25db75a83fad55d80e10087a6bca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApplicationStatus = "HIRED" | "IN_PROGRESS" | "REJECTED" | "WAITING" | "%future added value";
export type ConfirmStatus = "CONFIRMED" | "DENIED" | "NOT_RESPONSED" | "%future added value";
export type JobReviewRate = "BAD" | "GOOD" | "GREAT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ApplicantReviewCard_jobReview$data = {
  readonly _id: string;
  readonly application: {
    readonly jobPost: {
      readonly author: {
        readonly hoianUser?: {
          readonly id: string;
        };
      };
      readonly id: string;
      readonly title: string | null | undefined;
    } | null | undefined;
    readonly status: ApplicationStatus;
  };
  readonly comment: string | null | undefined;
  readonly createdAt: Dateable;
  readonly id: string;
  readonly rating: JobReviewRate;
  readonly reviewee: {
    readonly _id: number;
  };
  readonly reviewer: {
    readonly _id: number;
    readonly displayRegionCheckinsCount: number;
    readonly image: {
      readonly url: string;
    } | null | undefined;
    readonly nickname: string | null | undefined;
    readonly region: {
      readonly name3: string | null | undefined;
    } | null | undefined;
  };
  readonly workingConfirmed: ConfirmStatus;
  readonly " $fragmentType": "ApplicantReviewCard_jobReview";
};
export type ApplicantReviewCard_jobReview$key = {
  readonly " $data"?: ApplicantReviewCard_jobReview$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicantReviewCard_jobReview">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplicantReviewCard_jobReview",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Application",
      "kind": "LinkedField",
      "name": "application",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobPost",
          "kind": "LinkedField",
          "name": "jobPost",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "author",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "hoianUser",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "HoianUserAuthor",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "reviewer",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nickname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name3",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayRegionCheckinsCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "reviewee",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workingConfirmed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "JobReview",
  "abstractKey": null
};
})();

(node as any).hash = "0206afc249751ff0a88ffccf6c3fa980";

export default node;
