export const priceFormat = (numberPrice?: number | string | null): string | number => {
  try {
    if (numberPrice == null) {
      return ''
    }

    return String(numberPrice).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
  } catch (e) {
    return numberPrice || ''
  }
}

const priceUnit = {
  억: 100000000,
  만: 10000,
  '': 1,
}

export const maskPrice = (numberPrice?: number | null) => {
  if (!numberPrice) return numberPrice

  const maskedPrice = Object.entries(priceUnit).reduce(
    (acc, [key, value]) => {
      const divPrice = Math.floor(acc.price / value)
      const restPrice = acc.price % value

      if (divPrice <= 1) {
        return { price: acc.price, formattedString: acc.formattedString }
      }

      const formattedPrice = `${priceFormat(divPrice)}${key}`
      const formattedString = acc.formattedString ? `${acc.formattedString} ${formattedPrice}` : formattedPrice

      return { price: restPrice, formattedString }
    },
    { price: numberPrice, formattedString: '' }
  )

  return `${maskedPrice.formattedString}원`
}

export const priceFormatToNumber = (stringPrice?: string | null) => {
  if (!stringPrice) return 0

  return Number(stringPrice.replace(/[^\d]+/g, ''))
}

export const phoneFormatter = (num: string, masking = false) => {
  let formatNum = ''

  try {
    if (num.length === 11) {
      if (masking) {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3')
      } else {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
      }
    } else if (num.length === 8) {
      formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2')
    } else {
      if (num.indexOf('02') === 0) {
        if (masking) {
          formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3')
        } else {
          formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
        }
      } else {
        if (masking) {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3')
        } else {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        }
      }
    }
  } catch (e) {
    formatNum = num
  }

  return formatNum
}

export const validateBusinessRegistrationNumber = (businessRegistrationNumber: string | number) => {
  const valueMap = String(businessRegistrationNumber)
    .replace(/-/gi, '')
    .split('')
    .map(function (item) {
      return Number(item)
    })

  if (valueMap.length === 10) {
    const multiply = [1, 3, 7, 1, 3, 7, 1, 3, 5]
    let checkSum = 0

    for (let i = 0; i < multiply.length; ++i) {
      checkSum += multiply[i] * valueMap[i]
    }

    checkSum += Math.floor(Number((multiply[8] * valueMap[8]) / 10))
    return Math.floor(valueMap[9]) === (10 - (checkSum % 10)) % 10
  }

  return false
}

export const formatBusinessRegistrationNumber = (businessRegistrationNumber: string) => {
  try {
    if (businessRegistrationNumber.length !== 10) {
      throw new Error('사업자등록번호는 반드시 길이가 10 이어야 합니다.')
    }

    if (/^\d+$/.test(businessRegistrationNumber) === false) {
      throw new Error('사업자등록번호는 [0-9] 이어야 합니다.')
    }

    return businessRegistrationNumber.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')
  } catch (e) {
    return businessRegistrationNumber
  }
}

export const formatBusinessReportNumber = (businessReportNumber: string) => {
  try {
    if (businessReportNumber.length !== 11) {
      throw new Error('영업신고번호는 반드시 길이가 11 이어야 합니다.')
    }

    if (/^\d+$/.test(businessReportNumber) === false) {
      throw new Error('영업신고번호는 [0-9] 이어야 합니다.')
    }

    return businessReportNumber.replace(/(\d{4})(\d{7})/, '$1-$2')
  } catch (e) {
    return businessReportNumber
  }
}

export const formatUniqueBusinessNumber = (uniqueBusinessNumber: string) => {
  try {
    if (uniqueBusinessNumber.length !== 10) {
      throw new Error('고유번호증번호는 반드시 길이가 10 이어야 합니다.')
    }

    if (/^\d+$/.test(uniqueBusinessNumber) === false) {
      throw new Error('고유번호증번호는 [0-9] 이어야 합니다.')
    }

    return uniqueBusinessNumber.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')
  } catch (e) {
    return uniqueBusinessNumber
  }
}

export const formatNumberByBusinessCodeType = (
  businessCode: string,
  businessCodeType?: 'registration' | 'report' | 'unique'
) => {
  switch (businessCodeType) {
    case 'registration':
      return formatBusinessRegistrationNumber(businessCode)
    case 'report':
      return formatBusinessReportNumber(businessCode)
    case 'unique':
      return formatUniqueBusinessNumber(businessCode)
    default:
      return businessCode
  }
}

export const percent = (lessThan: number) => {
  return Math.random() * 100 < lessThan
}
