import { BoxButton } from '@daangn/sprout-components-button'
import { IconCheckFlowerFill, IconEditFill } from '@seed-design/icon'
import { rgba } from 'polished'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFragment, graphql } from 'react-relay'

import { Author_jobPost$key } from '@src/__generated__/Author_jobPost.graphql'
import Avatar from '@src/components/base/avatar/Avatar'
import LoadMoreContent from '@src/components/base/LoadMoreContent'
import TagGroup from '@src/components/base/tag/TagGroup'
import { SCHEME_PREFIX } from '@src/config'
import { BIZ_PLATFORM_KEY } from '@src/constants/api'
import { IMAGE_RESUME_DEFAULT } from '@src/constants/images'
import { useEnhancedRouter } from '@src/hooks/useEnhancedRouter'
import { useGwangsanguUser } from '@src/hooks/useGwangsanguUser'
import { useMannerTemperatureItems } from '@src/hooks/useMannerTemperatureItems'
import { useNavigator } from '@src/hooks/useNavigator'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { JobsActivity } from '@src/router/activityNames'
import { userIdSelector } from '@src/selectors/user'
import { styled, theme } from '@src/stitches/stitches.config'
import { textEllipsis } from '@src/stitches/text'
import { AuthorFormType } from '@src/types/author'
import { calcPassedDate } from '@src/utils/date'

interface Props {
  jobPostRef: Author_jobPost$key
}

const INTRODUCE_ELLIPSIS_LENGTH = 50

const Author: React.FCC<Props> = ({ jobPostRef }) => {
  const { push: routerPush } = useEnhancedRouter()
  const { logClickEvent } = useLogAnalyticsEvent()

  const jobPost = useFragment(
    graphql`
      fragment Author_jobPost on JobPost {
        _id
        author {
          ... on BizAccountAuthor {
            bizAccount {
              _id
              region {
                name3
              }
            }
          }
          ... on HoianUserAuthor {
            hoianUser {
              _id
              nickname
              image {
                thumbnail
              }
              region {
                name3
              }
              temperature
              displayRegionCheckinsCount
              isOfficial
              author {
                comment
              }
              lastActivatedAt
            }
          }
        }
        companyName
        eventGroup {
          type
        }
      }
    `,
    jobPostRef
  )

  const { push } = useNavigator()
  const isPersonal = !jobPost.companyName
  const isOfficial = jobPost.author.hoianUser?.isOfficial ?? false
  const { isGwangsanguUser } = useGwangsanguUser(jobPost.author.hoianUser?._id)
  const isDaangnEvent = jobPost.eventGroup?.type === 'DAANGN_EVENT'
  const mannerTemperature = jobPost.author?.hoianUser?.temperature || 0
  const { textColor } = useMannerTemperatureItems(mannerTemperature)
  const userId = useSelector(userIdSelector)
  const isUserAuthor = jobPost.author.hoianUser?._id === userId
  const comment = jobPost.author.hoianUser?.author?.comment
  const lastActivatedAt = jobPost.author.hoianUser?.lastActivatedAt
  const thumbnail = jobPost.author.hoianUser?.image?.thumbnail

  const handleBizClick = () => {
    logClickEvent({
      name: 'profile',
      params: {
        owner: 'JOB_POST_AUTHOR',
      },
    })

    if (jobPost.author.bizAccount) {
      routerPush({
        appKey: BIZ_PLATFORM_KEY,
        path: `/biz_accounts/${jobPost.author.bizAccount._id}/manager/home?entry=jobs`,
      })
      return
    }

    if (jobPost.author.hoianUser) {
      // TODO: generateScheme 등으로 개선 필요 (SCHEME_PREFIX일 때 `://` 붙이는 로직)
      const url = `${SCHEME_PREFIX}://users/${jobPost.author.hoianUser._id} `
      window.location.href = url
    }
  }

  const handleUpdateIntroductionClick = () => {
    push(JobsActivity.BIZ_AUTHOR_UPDATE, {
      type: AuthorFormType.INTRODUCTION,
    })
  }

  return (
    <Container>
      <InfoContainer onClick={isPersonal ? undefined : handleBizClick}>
        <Avatar size="large" border={true} source={thumbnail ?? IMAGE_RESUME_DEFAULT} />
        <Info>
          <InfoTitle>
            <InfoTitleText>{jobPost.author.hoianUser?.nickname}</InfoTitleText>
            {isOfficial && <IconCheckFlower width={16} height={16} />}
            <TemperatureText css={{ color: textColor, background: rgba(textColor, 0.12) }}>
              {mannerTemperature.toFixed(1)}
              &#8451;
            </TemperatureText>
          </InfoTitle>
          {!isDaangnEvent && (
            <InfoCategory>
              <TagGroup
                items={
                  isOfficial
                    ? [jobPost.author.hoianUser?.region?.name3, { value: '공공기관', visible: isGwangsanguUser }]
                    : [
                        `${jobPost.author.hoianUser?.region?.name3}${
                          jobPost.author.hoianUser?.displayRegionCheckinsCount
                            ? ` 인증 ${jobPost.author.hoianUser?.displayRegionCheckinsCount}회`
                            : ''
                        }`,
                        {
                          visible: !!lastActivatedAt,
                          value: lastActivatedAt && (
                            <React.Fragment>
                              <ActivityTime>
                                {calcPassedDate(lastActivatedAt)} <span>전</span>
                              </ActivityTime>{' '}
                              활동
                            </React.Fragment>
                          ),
                        },
                      ]
                }
                size="small"
              />
            </InfoCategory>
          )}
        </Info>
      </InfoContainer>

      {comment && (
        <IntroductionContainer>
          <LoadMoreContent content={comment} length={INTRODUCE_ELLIPSIS_LENGTH} />
        </IntroductionContainer>
      )}

      {isUserAuthor &&
        (comment ? (
          <BoxButton
            size="medium"
            variant="secondary"
            UNSAFE_style={{
              display: 'flex',
              width: '100%',
              margin: '16px 0 0',
            }}
            onClick={handleUpdateIntroductionClick}>
            내 소개 수정
          </BoxButton>
        ) : (
          <AddIntroduction onClick={handleUpdateIntroductionClick}>
            <IconEditFill width={16} height={16} color={theme.colors['primary-semantic'].computedValue} />
            <span>내 소개 추가하기</span>
          </AddIntroduction>
        ))}
    </Container>
  )
}

export default Author

const Container = styled('div', {})

const InfoContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  minWidth: 0,
})

const Info = styled('div', {
  flex: 1,
  margin: '0 16px 0',
  minWidth: 0,
})

const InfoTitle = styled('div', {
  $text: 'subtitle1Bold',
  display: 'flex',
  alignItems: 'center',
})

const InfoTitleText = styled('span', textEllipsis, {
  $text: 'title3Bold',
})

const InfoCategory = styled('div', {
  $text: 'caption1Regular',
  margin: '2px 0 0',
  color: '$gray600',
})

const IconCheckFlower = styled(IconCheckFlowerFill, {
  flex: 'none',
  margin: '0 0 0 4px',
  width: '16px',
  height: '16px',
  color: '$green500',
})

const TemperatureText = styled('div', {
  margin: '0 0 0 4px',
  $text: 'label5Bold',
  padding: '2px 6px',
  borderRadius: '10px',
})

const ActivityTime = styled('span', {
  color: '$blue500',
})

const IntroductionContainer = styled('div', {
  margin: '20px 0 0',
})

const AddIntroduction = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '20px 0 0',
  padding: '12.5px',
  borderRadius: '8px',
  border: 'dashed 1px $carrot200',
  background: '$paperAccent-semantic',
  color: '$primary-semantic',
  $text: 'label3Bold',

  '& > span': {
    margin: '0 0 0 4px',
  },
})
