import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { JobPostStringifiedWorkDatesAndDaysResolver$key } from '@src/__generated__/JobPostStringifiedWorkDatesAndDaysResolver.graphql'
import { stringifyWorkDatesAndDays } from '@src/utils/jobPost'

/**
 *
 * @RelayResolver JobPost.stringifiedWorkDateAndDays: String
 * @rootFragment JobPostStringifiedWorkDatesAndDaysResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function stringifiedWorkDateAndDays(jobPostRef: JobPostStringifiedWorkDatesAndDaysResolver$key): string {
  const { workDates, workDays } = readFragment(
    graphql`
      fragment JobPostStringifiedWorkDatesAndDaysResolver on JobPost {
        workDates
        workDays
      }
    `,
    jobPostRef
  )

  return stringifyWorkDatesAndDays({ workDates, workDays, isPastWorkDatesVisible: true })
}
