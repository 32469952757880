/**
 * @generated SignedSource<<687e1caa5eeaa3f6effe89644c2ac788>>
 * @relayHash c778bffafdfcab2cb774be771c789b50
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c778bffafdfcab2cb774be771c789b50

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Day = "FRI" | "MON" | "SAT" | "SUN" | "THU" | "TUE" | "WED" | "%future added value";
export type JobTaskType = "BAKING" | "BEVERAGE_MAKING" | "CLEANING" | "CONVENIENCE_STORE" | "ETC" | "HOUSEWORK" | "KIDS_PICK_UP" | "KITCHEN_ASSISTANCE" | "KITCHEN_COOK" | "LEAFLET_DISTRIBUTION" | "LIGHT_WORK" | "NURSING" | "SERVING" | "SHOP_MANAGEMENT" | "TUTORING" | "%future added value";
export type WorkPeriod = "LESS_THAN_A_MONTH" | "MORE_THAN_A_MONTH" | "%future added value";
export type SubscribeNewJobPostFilterWhereInput = {
  regions: ReadonlyArray<number>;
};
export type SubscribeNewJobPostFilterDataInput = {
  jobTasks: ReadonlyArray<JobTaskType>;
  workDays: ReadonlyArray<Day>;
  workPeriods: ReadonlyArray<WorkPeriod>;
};
export type useNewJobPostFilterSubscriptionMutationSubscribeMutation$variables = {
  data: SubscribeNewJobPostFilterDataInput;
  where: SubscribeNewJobPostFilterWhereInput;
};
export type useNewJobPostFilterSubscriptionMutationSubscribeMutation$data = {
  readonly subscribeNewJobPostFilter: {
    readonly user: {
      readonly newJobPostFilterSubscription: {
        readonly isHolding: boolean;
        readonly jobTasks: ReadonlyArray<JobTaskType>;
        readonly regions: ReadonlyArray<{
          readonly _id: number;
          readonly name3: string | null | undefined;
        }>;
        readonly workDays: ReadonlyArray<Day>;
        readonly workPeriods: ReadonlyArray<WorkPeriod>;
      } | null | undefined;
    };
  };
};
export type useNewJobPostFilterSubscriptionMutationSubscribeMutation = {
  response: useNewJobPostFilterSubscriptionMutationSubscribeMutation$data;
  variables: useNewJobPostFilterSubscriptionMutationSubscribeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "data"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v2 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHolding",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name3",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workDays",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workPeriods",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTasks",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useNewJobPostFilterSubscriptionMutationSubscribeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SubscribeNewJobPostFilterResult",
        "kind": "LinkedField",
        "name": "subscribeNewJobPostFilter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewJobPostSubscription",
                "kind": "LinkedField",
                "name": "newJobPostFilterSubscription",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "regions",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useNewJobPostFilterSubscriptionMutationSubscribeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SubscribeNewJobPostFilterResult",
        "kind": "LinkedField",
        "name": "subscribeNewJobPostFilter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewJobPostSubscription",
                "kind": "LinkedField",
                "name": "newJobPostFilterSubscription",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "regions",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "c778bffafdfcab2cb774be771c789b50",
    "metadata": {},
    "name": "useNewJobPostFilterSubscriptionMutationSubscribeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "092aae877296f252c3059c58e131c69e";

export default node;
