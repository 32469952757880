import { AppScreen } from '@stackflow/plugin-basic-ui'
import React from 'react'

export type CustomAppScreenState = Omit<React.ComponentProps<typeof AppScreen>, 'children'>

type SetAppScreenOptions = React.Dispatch<React.SetStateAction<CustomAppScreenState>>

export type CustomAppScreenContextValue = {
  setAppScreenOptions: SetAppScreenOptions
}

export const CustomAppScreenContext = React.createContext<CustomAppScreenContextValue>(
  {} as CustomAppScreenContextValue
)

export const useSetAppScreenOptions = (
  appScreenOptions: Parameters<SetAppScreenOptions>[0],
  deps: React.DependencyList = []
) => {
  const context = React.useContext(CustomAppScreenContext)

  React.useLayoutEffect(() => {
    context?.setAppScreenOptions?.(appScreenOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
