import { v4 as uuid } from 'uuid'

import { bridge } from './sdks/bridge'

const TARGET = process.env.NODE_ENV

export const API_URLS = process.env.API_URLS
export const SCHEME_PREFIX = process.env.SCHEME_PREFIX
export const APP_URLS = process.env.APP_URLS
export const APP_ENV = process.env.APP_ENV
export const VERSION = process.env.VERSION
export const SENTRY_APP_VERSION = process.env.SENTRY_APP_VERSION
export const APP_BUILD_NUMBER = Number(
  VERSION.split('.')
    .map((str, i) => {
      const padCounts: { [key: number]: number } = {
        0: 2,
        1: 4,
        2: 2,
      }
      return str.padStart(padCounts[i], '0')
    })
    .join('')
)

export const IS_ALPHA = APP_ENV === 'alpha'
export const IS_PROD = APP_ENV === 'production'
export const IS_NATIVE = TARGET === 'production'

export const IS_DEV = TARGET === 'development'
export const IS_LOG_ENABLED = IS_PROD && IS_NATIVE
export const IS_DEV_NATIVE = IS_DEV && bridge.environment !== 'Web'

export const AUTH_TOKEN =
  (IS_ALPHA ? process.env.REACT_APP_ALPHA_AUTH_TOKEN : process.env.REACT_APP_PROD_AUTH_TOKEN) || ''
export const USER_ID = (IS_ALPHA ? process.env.REACT_APP_ALPHA_USER_ID : process.env.REACT_APP_PROD_USER_ID) || ''
export const USER_AGENT = process.env.REACT_APP_DEFAULT_USER_AGENT || ''
export const TARGET_LOCALE = process.env.REACT_APP_TARGET_LOCALE || ''
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || ''
export const TEST_DATE = IS_ALPHA ? process.env.REACT_APP_TEST_DATE || '' : ''
export const KAKAO_MAP_KEY = process.env.REACT_APP_KAKAO_MAP_KEY || ''
export const GA4_TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID || ''
export const AMPLITUDE_JOBS_API_KEY = process.env.REACT_APP_AMPLITUDE_JOBS_API_KEY || ''
export const MIXPANEL_API_KEY = process.env.REACT_APP_MIXPANEL_API_KEY || ''
export const JOBS_SCRAPER_API_KEY = process.env.REACT_APP_JOBS_SCRAPER_API_KEY || ''
export const DATADOG_APP_ID = process.env.REACT_APP_DATADOG_APP_ID || ''
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN || ''

export const ADMIN_USERS = Object.freeze(
  IS_PROD
    ? [
        /* 알바 팀 */
        //Steve
        2179186,
        //Kit
        10537899,
        //Josh
        10466994,
        //Mark
        19578348,
        //Jennie
        23789971,
        //Sophie
        16782016,
        // Huey
        14550583,
        // Bash
        8910541,
        // Winny
        31506514,
        // Sia
        15922114,
        // Kimmy
        24657890,
        // Livia
        13088065,

        /* 마케팅 팀 */
        //Nicole
        2614045,
        //Jace
        13829824,
        //Linda
        9615698,
        //Jerome
        17733579,
        //Mac
        15016117,
        //Jony
        689001,
        //Halla
        3790,

        /* 당근 계정 */
        10588,
      ]
    : [
        /* 알바 팀 */
        // Steve
        3173,
        // Sophie
        9791,
        // Jennie
        10000243,
        // Mark
        4090,
        // Kit
        5991,
        // Josh
        3131,
        // Huey
        10003037,
        // Bash
        10003014,
        // Winny
        10003983,
        // Sia
        10003697,
        // Kimmy
        10003975,
        // Livia
        10004133,

        /* 마케팅 팀 */
        // Linda
        9644,
        // Mac
        10001888,
        // Jerome
        5429,
        // Jace
        10000818,
        // Halla
        10155,
        // Halla-test
        10001914,

        /* 당근페이 팀 */
        // Ben.lee
        10002297,
      ]
)

export const DESIGN_ADMIN_USERS = Object.freeze(
  IS_PROD
    ? [
        //Steve
        2179186,
        //Kit
        10537899,
        //Sophie
        16782016,
        // Huey
        14550583,
        // Winny
        31506514,
        // Livia
        13088065,
      ]
    : [
        // Steve
        3173,
        // Sophie
        9791,
        // Kit
        5991,
        // Huey
        10003037,
        // Winny
        10003983,
        // Livia
        10004133,
      ]
)

export const RED_ADMIN_USERS = Object.freeze(
  IS_PROD
    ? [
        // Steve
        2179186,
        // Sophie
        16782016,
        // Mark
        19578348,
        // Jennie
        23789971,
      ]
    : [
        // Steve
        3173,
        // Sophie
        9791,
        // Mark
        4090,
        // Jennie
        10000243,
      ]
)

export const OUTLINK_TEST_BIZ_USERS = [...ADMIN_USERS]

export const GWANGSANGU_USERS = [
  // 광산구청
  72475873,
].concat(IS_ALPHA ? [3173, 5991, 9791, 10002047] : [])

export const GWANGSANGU_USER_NODE_ID = IS_ALPHA
  ? 'MTpIb2lhblVzZXJBdXRob3I6MzE3Mw'
  : 'MTpIb2lhblVzZXJBdXRob3I6NzI0NzU4NzM'

export const DAANGN_SERVICE_NAME = '당근'

export const SESSION_ID = uuid()
