import { Activity } from '@stackflow/core'
import { StackflowReactPlugin } from '@stackflow/react'
import React from 'react'

import { ReferrerHandler as ReferrerProvider } from '@src/packages/logAnalyticsEvent'
import { useReferrerContext } from '@src/packages/logAnalyticsEvent/ReferrerContext'

const referrerPlugin = (): StackflowReactPlugin => {
  return () => ({
    key: 'referrerPlugin',
    wrapStack({ stack }) {
      return <ReferrerProvider>{stack.render()}</ReferrerProvider>
    },
    wrapActivity({ activity }) {
      return <ReferrerHandler activity={activity}>{activity.render()}</ReferrerHandler>
    },
  })
}

export default referrerPlugin

const ReferrerHandler: React.FC<
  React.PropsWithChildren<{ activity: Activity & { render: () => React.ReactNode } }>
> = ({ activity, children }) => {
  const { setReferrerInfo } = useReferrerContext()

  React.useLayoutEffect(() => {
    setReferrerInfo({
      activity_name: activity.name,
      referrer: activity.params.referrer?.toLowerCase(),
      referrer_context: activity.params.referrer_context,
      funnelFrom: activity.params.funnelFrom,
      message_kind: activity.params.message_kind,
      message_context: activity.params.message_context,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}
