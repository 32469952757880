import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { UserReceivedApplicantBadReviewCountResolver$key } from '@src/__generated__/UserReceivedApplicantBadReviewCountResolver.graphql'

/**
 *
 * @RelayResolver User.receivedApplicantBadReviewCount: Int
 * @rootFragment UserReceivedApplicantBadReviewCountResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function receivedApplicantBadReviewCount(userRef: UserReceivedApplicantBadReviewCountResolver$key): number {
  const user = readFragment(
    graphql`
      fragment UserReceivedApplicantBadReviewCountResolver on User {
        receivedJobReviewsBadCountByApplicant: receivedJobReviewsCount(filter: { evaluator: APPLICANT, rating: [BAD] })
      }
    `,
    userRef
  )

  return user.receivedJobReviewsBadCountByApplicant ?? 0
}
