import React from 'react'

type ReferrerContextType = {
  referrerInfo?: {
    referrer?: string
    referrer_context?: string
    activity_name?: string
    funnelFrom?: string
    message_kind?: string
    message_context?: string
  }
  setReferrerInfo: (value: {
    activity_name?: string
    referrer?: string
    referrer_context?: string
    funnelFrom?: string
    message_kind?: string
    message_context?: string
  }) => void
}

export const ReferrerContext = React.createContext<ReferrerContextType>({
  referrerInfo: undefined,
  setReferrerInfo: () => {},
})

export const useReferrerContext = () => React.useContext(ReferrerContext)
