import Linkify from 'linkify-react'
import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { Content_jobPost$key } from '@src/__generated__/Content_jobPost.graphql'
import Highlighter from '@src/components/base/Highlighter'
import LoadMoreContent from '@src/components/base/LoadMoreContent'
import { OUTLINK_TEST_BIZ_USERS } from '@src/config'
import { useGwangsanguUser } from '@src/hooks/useGwangsanguUser'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { userSelectText } from '@src/stitches/action'
import { css, styled } from '@src/stitches/stitches.config'
import { maskPhoneNumber } from '@src/utils/mask'

// const longlongString = `waefawefawefawef

// \nawef           awefawefawefawef

// \nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefawef\nawefawefawefawefaweffawefawefawefawefawefawef`

interface Props {
  jobPostRef: Content_jobPost$key
  isLoadMore?: boolean
}

const Content: React.FCC<Props> = ({ jobPostRef, isLoadMore }) => {
  const jobPost = useFragment(
    graphql`
      fragment Content_jobPost on JobPost @argumentDefinitions(isMasking: { type: "Boolean!", defaultValue: false }) {
        _id
        maskingContent: content(masking: true)
        unMaskingContent: content(masking: false) @include(if: $isMasking)
        author {
          ... on HoianUserAuthor {
            hoianUser {
              _id
            }
          }
        }
      }
    `,
    jobPostRef
  )

  const authorId = jobPost.author?.hoianUser?._id
  const isBizTestUser = authorId && OUTLINK_TEST_BIZ_USERS.includes(authorId)
  const { logClickEvent } = useLogAnalyticsEvent()
  const { isGwangsanguUser } = useGwangsanguUser(authorId)

  if (!jobPost.maskingContent) {
    return null
  }

  const content = isGwangsanguUser ? jobPost.maskingContent : maskPhoneNumber(jobPost.maskingContent)

  return isLoadMore ? (
    <LoadMoreContent content={content} />
  ) : (
    <ContentText>
      <Linkify
        options={{
          className: LinkStyle().className,
          target: '_blank',
          render: {
            url: ({ attributes, content }) => {
              if (isBizTestUser) {
                return <a {...attributes}>{content}</a>
              }

              return <>{content}</>
            },
          },
          attributes: {
            onClick: () => {
              logClickEvent({
                name: 'job_post_content_outlink',
                params: {
                  job_post_id: jobPost._id,
                },
              })
            },
          },
        }}>
        {jobPost.unMaskingContent ? (
          <Highlighter masking={jobPost.maskingContent} unMasking={jobPost.unMaskingContent} />
        ) : (
          jobPost.maskingContent
        )}
      </Linkify>
    </ContentText>
  )
}

export default Content

const ContentText = styled('p', userSelectText, {
  $text: 'bodyL1Regular',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  overflow: 'hidden',
})

const LinkStyle = css({
  color: '$primary-semantic',
})
