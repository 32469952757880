import { IconBackwardRegular, IconCloseRegular } from '@seed-design/icon'
import { useActivity } from '@stackflow/react'
import React from 'react'

import { useGetRouteInfo } from '@src/hocs/withRouteInfo'
import { useCurrentScreen } from '@src/hooks/useCurrentScreen'
import { useKarrotframeScreenPresent } from '@src/hooks/useKarrotframeScreenPresent'
import { useAppNavigation } from '@src/hooks/useNavigator'
import { useStepNavigator } from '@src/hooks/useStepNavigator'
import { navIconWrapperStyle } from '@src/stitches/navigation'
import { styled, theme } from '@src/stitches/stitches.config'

import CustomAppScreen from './CustomAppScreen'
import { useSetAppScreenOptions } from './CustomAppScreenContext'
import ReplaceToMainLayout from './ReplaceToMainLayout'

type JobsAppScreenProps = React.ComponentProps<typeof CustomAppScreen> & {
  onTopClick?: () => void
  onBackClick?: () => void
  noBackButton?: boolean
  noCloseButton?: boolean
  noBorder?: boolean
  initialRenderKey?: number
  useIgnoreAppScreen?: boolean
  useIgnoreReplaceToMain?: boolean
}

const JobsAppScreen: React.FCC<JobsAppScreenProps> = ({
  initialRenderKey,
  noBorder,
  noBackButton,
  noCloseButton,
  children,
  onTopClick,
  onBackClick,
  useIgnoreAppScreen,
  useIgnoreReplaceToMain,
  ...props
}) => {
  const { isPresent } = useKarrotframeScreenPresent()
  const { isRoot } = useCurrentScreen()
  const { steps } = useActivity()
  const { stepPop } = useStepNavigator()
  const { isJobsMainShortcutVisibleDepth } = useAppNavigation()
  const [renderKey, setRenderKey] = React.useState(0)
  const routeInfo = useGetRouteInfo()

  const handleBackClick = (e: React.MouseEvent) => {
    onBackClick?.()
    stepPop()
    e.preventDefault()
  }

  useSetAppScreenOptions(
    useIgnoreAppScreen
      ? { appBar: undefined }
      : (prev) => ({
          ...prev,
          appBar: {
            overflow: 'visible',
            onTopClick,
            border: !noBorder,
            backButton: noBackButton
              ? {
                  render: () => null,
                }
              : isPresent ||
                  prev.CUPERTINO_ONLY_modalPresentationStyle ||
                  props.CUPERTINO_ONLY_modalPresentationStyle ||
                  isRoot
                ? {
                    renderIcon: () => (
                      <IconWrapper>
                        <IconCloseRegular width={24} height={24} color={theme.colors.gray900.computedValue} />
                      </IconWrapper>
                    ),
                    onClick: steps.length === 1 ? undefined : handleBackClick,
                  }
                : {
                    onClick: steps.length === 1 ? undefined : handleBackClick,
                  },
            closeButton: noCloseButton
              ? { render: () => null }
              : {
                  renderIcon: () => {
                    return steps.length === 1 ? (
                      <IconWrapper>
                        <IconCloseRegular width={24} height={24} color={theme.colors.gray900.computedValue} />
                      </IconWrapper>
                    ) : (
                      <IconWrapper>
                        <IconBackwardRegular width={24} height={24} color={theme.colors.gray900.computedValue} />
                      </IconWrapper>
                    )
                  },
                  onClick: steps.length === 1 ? undefined : handleBackClick,
                },
            ...props.appBar,
          },
        }),
    [renderKey]
  )

  React.useEffect(() => {
    if (initialRenderKey === undefined) return

    setRenderKey((prev) => prev + 1)
  }, [initialRenderKey])

  return (
    <>
      {!isJobsMainShortcutVisibleDepth || useIgnoreReplaceToMain || !routeInfo.withReplaceToMain ? (
        children
      ) : (
        <ReplaceToMainLayout>{children}</ReplaceToMainLayout>
      )}
    </>
  )
}

export default JobsAppScreen

const IconWrapper = styled('div', navIconWrapperStyle, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
