/**
 * @generated SignedSource<<d0d4120b5b22069384671be8bd1eb03f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventGroupType = "BUDDHA_BIRTHDAY" | "CHILDREN_DAY" | "CHRISTMAS" | "Chuseok" | "DAANGN_EVENT" | "LunarNewYear" | "MEMORIAL_DAY" | "PEPERO_DAY" | "WHITE_DAY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobPostOtherPosts_jobPost$data = {
  readonly _id: string;
  readonly eventGroup: {
    readonly type: EventGroupType;
  } | null | undefined;
  readonly workplaceRegion: {
    readonly _id: number;
  } | null | undefined;
  readonly " $fragmentType": "JobPostOtherPosts_jobPost";
};
export type JobPostOtherPosts_jobPost$key = {
  readonly " $data"?: JobPostOtherPosts_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostOtherPosts_jobPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostOtherPosts_jobPost",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "workplaceRegion",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventGroup",
      "kind": "LinkedField",
      "name": "eventGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};
})();

(node as any).hash = "82196126d79cc2a1d35ccff675629b91";

export default node;
