import React from 'react'
import { useSelector } from 'react-redux'
import { useFragment, graphql } from 'react-relay'

import { JobPostCardRecommend_jobPost$key } from '@src/__generated__/JobPostCardRecommend_jobPost.graphql'
import { advertisement as advertisementAPI } from '@src/api/api'
import BadgeSquare from '@src/components/base/badge/BadgeSquare'
import Image from '@src/components/base/image/Image'
import TagGroup from '@src/components/base/tag/TagGroup'
import AdvertisementHereButton from '@src/components/common/advertisement/AdvertisementHereButton'
import AdvertisementTag from '@src/components/common/advertisement/AdvertisementTag'
import FastResponseBadge from '@src/components/common/badge/FastResponseBadge'
import ReviewCountBadge from '@src/components/common/badge/ReviewCountBadge'
import WalkingDistanceBadge from '@src/components/common/badge/WalkingDistanceBadge'
import { useCalcWalkingDistance } from '@src/hooks/useCalcWalkingDistance'
import { useNavigator } from '@src/hooks/useNavigator'
import { JobsActivity } from '@src/router/activityNames'
import { currentPositionSelector } from '@src/selectors/user'
import { styled } from '@src/stitches/stitches.config'
import { textEllipsis } from '@src/stitches/text'

interface Props {
  jobPostRef: JobPostCardRecommend_jobPost$key
  advertisement?: {
    isAdvertisement: boolean
    hideUrl?: Nullable<string>
    showAdvertiseNudge?: boolean
    showAdvertisementLabel?: boolean
  }
  additionalInfoVisible?: boolean
  logger?: () => void
}

type BadgeType = {
  visible: boolean
  item: JSX.Element
}

const JobPostCardRecommend: React.FCC<Props> = ({
  jobPostRef,
  advertisement,
  additionalInfoVisible = true,
  logger,
}) => {
  const jobPost = useFragment(
    graphql`
      fragment JobPostCardRecommend_jobPost on JobPost {
        id
        closed
        title
        companyName
        workplaceRegion {
          name3
        }
        stringifiedSalary
        images {
          thumbnail
        }
        # eslint-disable-next-line relay/unused-fields
        workplaceLocation {
          lat
          lng
        }
        author {
          ... on HoianUserAuthor {
            badge {
              items {
                ... on FastResponseBadge {
                  __typename
                  name
                }
              }
            }
            hoianUser {
              receivedHasCommentJobReviewsCount: receivedJobReviewsCount(
                filter: { hasComment: true, evaluator: APPLICANT }
              )
            }
          }
        }
      }
    `,
    jobPostRef
  )
  const { push } = useNavigator()
  const currentPosition = useSelector(currentPositionSelector)
  const [isIgnoredAdvertisement, setIsIgnoredAdvertisement] = React.useState(false)
  const { isWalkingDistance: walkingDistanceWithinTenMinutes } = useCalcWalkingDistance({
    positions: [currentPosition?.position, jobPost.workplaceLocation],
  })

  const reviewCount = jobPost.author.hoianUser?.receivedHasCommentJobReviewsCount ?? 0

  const badges = React.useMemo(() => {
    const MAX_VISIBLE_BADGE_COUNT = 3

    const _badges = [
      {
        visible: reviewCount > 0,
        item: <ReviewCountBadge count={reviewCount} />,
      },
      {
        visible: walkingDistanceWithinTenMinutes,
        item: <WalkingDistanceBadge />,
      },
      ...(jobPost.author.badge?.items.map((item) =>
        item.__typename === 'FastResponseBadge'
          ? {
              visible: true,
              item: <FastResponseBadge />,
            }
          : {}
      ) ?? []),
    ] as const

    return _badges.filter((badge) => badge.visible).slice(0, MAX_VISIBLE_BADGE_COUNT) as BadgeType[]
  }, [reviewCount, walkingDistanceWithinTenMinutes, jobPost.author.badge])
  const isBadgeVisible = badges.some((badges) => badges.visible)

  const handleJobPostCardClick = () => {
    logger?.()

    if (advertisement?.isAdvertisement) {
      push(JobsActivity.JOB_POST_AD, { jobPostId: jobPost.id })
    } else {
      push(JobsActivity.JOB_POST, { jobPostId: jobPost.id })
    }
  }

  const handleJobPostIgnoreClick = () => {
    if (advertisement?.hideUrl) {
      advertisementAPI.hide(advertisement.hideUrl)
      setIsIgnoredAdvertisement(true)
    }
  }

  return (
    <>
      {!isIgnoredAdvertisement ? (
        <>
          <Container onClick={handleJobPostCardClick}>
            <ContentContainer>
              <JobPostInfo>
                <TitleContainer>
                  {jobPost.closed && (
                    <StyledBadgeSquare isOutlined={false} isBold={true} size="small">
                      마감
                    </StyledBadgeSquare>
                  )}
                  <Title>{jobPost.title}</Title>
                </TitleContainer>
                <TagGroup
                  size="small"
                  items={[
                    jobPost.companyName,
                    jobPost.workplaceRegion?.name3,
                    {
                      visible: !!advertisement?.showAdvertisementLabel,
                      value: <AdvertisementTag onIgnoreJobPostClick={handleJobPostIgnoreClick}>광고</AdvertisementTag>,
                    },
                  ]}
                />
                <SubInfo>
                  <Salary>{jobPost.stringifiedSalary}</Salary>

                  {additionalInfoVisible && isBadgeVisible && (
                    <BadgeContainer>
                      {badges.map((badge, index) => {
                        return <BadgeWrapper key={index}>{badge.item}</BadgeWrapper>
                      })}
                    </BadgeContainer>
                  )}
                </SubInfo>
              </JobPostInfo>
            </ContentContainer>
            {jobPost.images?.length ? <ThumbnailImage border={true} source={jobPost.images[0].thumbnail} /> : null}
          </Container>
          {!!advertisement?.showAdvertiseNudge && (
            <AdvertisementHereButtonWrapper>
              <AdvertisementHereButton inventory="recommend_job_post_card" />
            </AdvertisementHereButtonWrapper>
          )}
        </>
      ) : (
        <IgnoredJobPostContainer>광고를 숨겼어요.</IgnoredJobPostContainer>
      )}
    </>
  )
}

export default JobPostCardRecommend

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
})

const ContentContainer = styled('div', { minWidth: 0 })

const JobPostInfo = styled('div', {
  flex: 1,
  margin: '2px 0 0',
})

const TitleContainer = styled('div', {
  margin: '0 0 2px',
  $text: 'bodyM1Regular',
  wordBreak: 'break-all',
  display: 'flex',
  alignItems: 'center',
})

const Title = styled('div', textEllipsis, {})

const StyledBadgeSquare = styled(BadgeSquare, {
  marginRight: '4px',
})

const SubInfo = styled('div', {
  margin: '8px 0 0',
})

const Salary = styled('span', {
  $text: 'subtitle2Bold',
  color: '$gray700',
  whiteSpace: 'nowrap',
})

const BadgeContainer = styled('div', {
  margin: '8px 0 0',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',

  '&:empty': {
    margin: 0,
  },
})

const BadgeWrapper = styled('div', {
  margin: '0 0 0 4px',

  '&:first-of-type': {
    margin: 0,
  },
})

const ThumbnailImage = styled(Image, {
  width: '72px',
  height: '72px',
  minWidth: '72px',
  margin: '0 0 0 16px',
  borderRadius: '5px',
})

const AdvertisementHereButtonWrapper = styled('div', {
  margin: '16px 0 0',
})

const IgnoredJobPostContainer = styled('div', {
  borderRadius: '10px',
  padding: '16px 18px',
  background: '$gray100',
  color: '$gray900',
  $text: 'bodyM2Regular',
})
