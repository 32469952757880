const cssVars = {} as any

export function findKarrotColorToken(rgbString: string) {
  if (Object.keys(cssVars).length === 0) {
    for (const sheet of document.styleSheets) {
      try {
        if (sheet.cssRules) {
          for (const rule of sheet.cssRules) {
            if (rule instanceof CSSStyleRule && rule.style) {
              for (const prop of rule.style) {
                if (prop.startsWith('--') && prop.includes('color')) {
                  const value = rule.style.getPropertyValue(prop).trim()
                  const token = prop.split('color-').pop()
                  if (token && token.length <= 20) {
                    cssVars[value] = token
                  }
                }
              }
            }
          }
        }
      } catch (e) {
        console.error('Error accessing stylesheet', e)
      }
    }
  }

  const token = cssVars[rgbString]

  if (!token) {
    return rgbString
  }

  return token
}
