import { Portal, TransitionOut, TransitionOutProps, TransitionStatus } from '@daangn/karrot-clothes'
import React from 'react'

import { zIndices } from '@src/stitches/stitches.config'

import ModalBaseInner from './ModalBaseInner'

export interface ModalProps {
  open: boolean
  /**
   * overlay 클릭 시 실행됌.
   */
  onClose?: () => void
  onClosed?: () => void
  onEnter?: () => void
  zIndex?: number
  /**
   * millisecond
   * @default 300
   */
  duration?: number
  /**
   * 모달 overlay 의 배경 색상
   */
  overlayBackgroundColor?: string
  className?: string
  bodyClassName?: string
  render?: TransitionOutProps['render']
  children?: React.ReactNode

  ignoreIntro?: boolean
  ignoreOutro?: boolean

  bodyScrollBlockOnEnter?: boolean
}

const ModalBase: React.FCC<ModalProps> = ({
  open,
  onClose,
  children,
  duration: propDuration = 300,
  onClosed,
  overlayBackgroundColor,
  zIndex = zIndices.global1,
  className,
  ignoreIntro,
  ignoreOutro,
  bodyScrollBlockOnEnter,
  bodyClassName,
  render,
  onEnter,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialOpen = React.useMemo<TransitionStatus>(() => (open ? 'before-open' : 'after-close'), [])

  if (!open) return null

  return (
    <Portal>
      <TransitionOut
        open={open}
        duration={propDuration}
        onClosed={onClosed}
        ignoreOutro={ignoreOutro}
        ignoreIntro={ignoreIntro}
        onEnter={onEnter}
        render={(props) => {
          const { visible, duration, status, type } = props
          return (
            <ModalBaseInner
              duration={duration}
              open={visible}
              onClose={onClose}
              overlayBackgroundColor={overlayBackgroundColor}
              className={className}
              bodyClassName={bodyClassName}
              bodyScrollBlockOnEnter={bodyScrollBlockOnEnter}
              zIndex={zIndex}>
              {render ? render({ duration, status, type, visible }) : children}
            </ModalBaseInner>
          )
        }}
        initial={initialOpen}
      />
    </Portal>
  )
}

export default ModalBase
