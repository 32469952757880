import React from 'react'

import { styled } from '@src/stitches/stitches.config'

type Props = {
  children: React.ReactNode
}

const CaptionLayout: React.FC<Props> = ({ children }) => {
  return <BorderDiscriptionContainer>{children}</BorderDiscriptionContainer>
}

export default CaptionLayout

const BorderDiscriptionContainer = styled('section', {
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  left: '18px',
  fontSize: '10px',
  fontWeight: '700',

  background: '$gray00',
  opacity: 1,

  '&:nth-of-type(1)': {
    bottom: '24px',
  },

  '&:nth-of-type(2)': {
    bottom: '42px',
  },

  '&:nth-of-type(3)': {
    bottom: '60px',
  },

  '&:nth-of-type(4)': {
    bottom: '78px',
  },

  '&:nth-of-type(5)': {
    bottom: '96px',
  },
})
