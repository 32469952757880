import React from 'react'
import { useSelector } from 'react-redux'

import { userRoleSelector } from '@src/selectors/user'
import { calcUserRole } from '@src/utils/user'

export const useUserRole = () => {
  const userRole = useSelector(userRoleSelector)
  return React.useMemo(() => calcUserRole({ role: userRole }), [userRole])
}
