import { makeKarrotBridge, installKarrotBridgeDriver } from '@daangn/karrotbridge'
import { Empty } from '@daangn/karrotbridge/lib/__generated__/makeKarrotBridge'
import { Bridge } from '@daangn/webview-bridge-modern'
import { PluginAnalytics } from '@daangn/webview-bridge-modern/lib/plugins/Analytics'
import { PluginGeolocation } from '@daangn/webview-bridge-modern/lib/plugins/Geolocation'
import { PluginInfo } from '@daangn/webview-bridge-modern/lib/plugins/Info'
import { PluginRouter } from '@daangn/webview-bridge-modern/lib/plugins/Router'
import { PluginShare } from '@daangn/webview-bridge-modern/lib/plugins/Share'
import { PluginToast } from '@daangn/webview-bridge-modern/lib/plugins/Toast'
import { timer } from 'rxjs'

import { VERSION, IS_DEV, SESSION_ID } from '@src/config'
import { Unwrap } from '@src/types/utils'
import { appInfoStorage } from '@src/utils/appInfo'
const { driver } = installKarrotBridgeDriver()

const handleHapticProtocol = (protocol: (req: Record<string, never>) => Promise<Empty>, req: Record<string, never>) => {
  if (
    appInfoStorage.satisfyAppVersion('Android', '6.4.9', '>=') ||
    appInfoStorage.satisfyAppVersion('iOS', '6.8.3', '>=')
  ) {
    return protocol(req)
  }

  return Promise.resolve({})
}

const _karrotBridge = makeKarrotBridge({ driver })

export const karrotBridge = {
  ..._karrotBridge,
  setHapticSuccess: handleHapticProtocol.bind(this, _karrotBridge.setHapticSuccess),
  setHapticError: handleHapticProtocol.bind(this, _karrotBridge.setHapticError),
  setHapticSelect: handleHapticProtocol.bind(this, _karrotBridge.setHapticSelect),
  setHapticLightFeedback: handleHapticProtocol.bind(this, _karrotBridge.setHapticLightFeedback),
}

export const bridge = new Bridge({
  dangerouslyInjectDriver: driver,
})

bridge
  .addPlugin(PluginRouter)
  .addPlugin(PluginInfo)
  .addPlugin(PluginToast)
  .addPlugin(PluginAnalytics)
  .addPlugin(PluginShare)
  .addPlugin(PluginGeolocation)

const _segments: Record<string, string> = {}
export const logAnalyticsEvent = (data: { name: string; params: { [key: string]: any } }): void => {
  const event = { ...data, params: { ...data.params, ..._segments, session_id: SESSION_ID, version: VERSION } }

  if (IS_DEV) {
    // eslint-disable-next-line no-console
    console.log(`analytics event called: ${JSON.stringify(event)}`)
    return
  }

  bridge.analytics.log(event)
}

export const toast = (message: string) => {
  if (IS_DEV) {
    // eslint-disable-next-line no-console
    console.log(`bridge.toast.open: ${message}`)
    return
  }

  bridge.toast.open({ body: message })
}

export const theme = {
  get environment() {
    switch (bridge.environment) {
      case 'Web':
        return 'Cupertino'
      default:
        return bridge.environment
    }
  },
}

export const setBridgeSwipable = (backSwipable: boolean) => {
  bridge.router.style({ backSwipable, navbar: false, scrollable: false })
}

export const getCurrentPosition = () => {
  return new Promise<Unwrap<ReturnType<typeof bridge.geolocation.currentPosition>>>((res) => {
    const timeout = timer(250).subscribe(() => {
      res(null)
    })

    bridge.geolocation
      .currentPosition()
      .then((data) => {
        timeout.unsubscribe()
        res(data)
      })
      .catch(() => {
        timeout.unsubscribe()
        res(null)
      })
  })
}

type BridgeImageConfig = Parameters<typeof karrotBridge.configureImage>[0]['image']
const DEFAULT_IMAGE_CONFIG: BridgeImageConfig = {
  maxImageCount: 10,
  disableCompress: true,
}

export const setBridgeImageConfig = (config: BridgeImageConfig) => {
  karrotBridge.configureImage({
    image: {
      ...DEFAULT_IMAGE_CONFIG,
      ...config,
    },
  })
}

export const resetBridgeImageConfig = () => {
  setBridgeImageConfig(DEFAULT_IMAGE_CONFIG)
}
