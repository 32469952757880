import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { UserReceivedApplicantCommentReviewCountResolver$key } from '@src/__generated__/UserReceivedApplicantCommentReviewCountResolver.graphql'

/**
 *
 * @RelayResolver User.receivedApplicantCommentReviewCount: Int
 * @rootFragment UserReceivedApplicantCommentReviewCountResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function receivedApplicantCommentReviewCount(
  userRef: UserReceivedApplicantCommentReviewCountResolver$key
): number {
  const user = readFragment(
    graphql`
      fragment UserReceivedApplicantCommentReviewCountResolver on User {
        receivedHasCommentJobReviewsCountByApplicant: receivedJobReviewsCount(
          filter: { hasComment: true, evaluator: APPLICANT, rating: [GREAT, GOOD] }
        )
      }
    `,
    userRef
  )

  return user.receivedHasCommentJobReviewsCountByApplicant ?? 0
}
