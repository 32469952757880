import { Activity } from '@stackflow/core'
import { stackDepthChangePlugin } from '@stackflow/plugin-stack-depth-change'
import { StackflowReactPlugin } from '@stackflow/react'
import { last } from 'lodash'

import { JobsActivity } from '@src/router/activityNames'
import { bridge } from '@src/sdks/bridge'

const onBridgeBackSwipable = () => {
  bridge.router.style({ backSwipable: true, navbar: false, scrollable: false })
}

const offBridgeBackSwipable = () => {
  bridge.router.style({ backSwipable: false, navbar: false, scrollable: false })
}

const handleRouterStyle = (depth: number, activeActivities: Activity[]) => {
  const isHalfViewFromExternal = last(activeActivities)?.name === JobsActivity.BIZ_JOB_POST_STATUS_CHANGE
  if (isHalfViewFromExternal) return

  if (depth <= 1) {
    onBridgeBackSwipable()
  } else {
    offBridgeBackSwipable()
  }
}

const depthChangePlugin = (): StackflowReactPlugin => {
  return stackDepthChangePlugin({
    onInit: ({ depth, activeActivities }) => {
      handleRouterStyle(depth, activeActivities)
    },
    onDepthChanged: ({ depth, activeActivities }) => {
      const isLastActivityMain = last(activeActivities)?.name === JobsActivity.MAIN

      if (isLastActivityMain) {
        onBridgeBackSwipable()
        return
      }

      handleRouterStyle(depth, activeActivities)
    },
  })
}

export default depthChangePlugin
