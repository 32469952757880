import { CircularProgress, Portal } from '@daangn/karrot-clothes'
import React from 'react'

import { useGlobalLoader } from '@src/hooks/useGlobalLoader'
import { styled, zIndices } from '@src/stitches/stitches.config'

interface Props {
  isOpen?: boolean
  zIndex?: number
  delayTimeMs?: number
  isCenterMode?: boolean
}

const GlobalLoader: React.FCC<Props> = ({
  isOpen = false,
  zIndex = zIndices.global1,
  delayTimeMs = 800,
  isCenterMode = true,
}) => {
  const [isGlobalLoaderOpen, setGlobalLoaderOpen] = useGlobalLoader()
  const [isOpenState, setIsOpenState] = React.useState(false)

  React.useEffect(() => {
    if (isGlobalLoaderOpen) {
      const timerId = setTimeout(() => {
        setIsOpenState(true)
      }, delayTimeMs)
      return () => {
        timerId && clearTimeout(timerId)
      }
    }
    setIsOpenState(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobalLoaderOpen])

  React.useEffect(() => {
    if (isOpen) {
      setGlobalLoaderOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Portal>
      {isGlobalLoaderOpen ? (
        <Base isOpen={isOpenState} css={{ zIndex }} isCenterMode={isCenterMode}>
          <CircularProgress />
        </Base>
      ) : null}
    </Portal>
  )
}

const Base = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  transition: 'opacity 200ms',
  opacity: 0,

  variants: {
    isOpen: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
    isCenterMode: {
      true: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
})

export default GlobalLoader
