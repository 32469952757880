import { IconExpandMoreRegular } from '@seed-design/icon'
import React from 'react'

import storage from '@src/api/storage'
import Button from '@src/components/base/buttons/Button'
import ActionChip from '@src/components/base/chips/ActionChip'
import CheckboxSquare from '@src/components/base/formUi/CheckboxSquare'
import Spacer from '@src/components/base/Spacer'
import Tag from '@src/components/base/tag/Tag'
import { DEVTOOL_RECENT_ROUTES } from '@src/constants/api'
import { routes } from '@src/router/activities'
import { ActivityName } from '@src/router/activityNames'
import { activityParamsSchemas, ActivityParamsSchemaKey } from '@src/router/activityParams'
import { keyframes, styled, theme } from '@src/stitches/stitches.config'
import { generatePath } from '@src/utils/router'

interface Props {
  onClick?: () => void
}

enum RouteItemType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

const REFERRER_KEY = 'referrer'

type RouteItem = {
  type: RouteItemType
  activityName: ActivityName
  label: string
  path: string
}

const defaultRoutesItems: RouteItem[] = Object.entries(routes)
  .filter(([activityName, routeInfo]) => routeInfo.path !== '*' && !activityName.startsWith('legacy'))
  .map(([activityName, routeInfo]) => {
    return {
      activityName: activityName as ActivityName,
      type: RouteItemType.DEFAULT,
      label: activityName,
      path: routeInfo.path,
    }
  })

const routeItems: RouteItem[] = [...defaultRoutesItems]

const DevToolRoutePage: React.FCC<Props> = () => {
  const [activityNameRequireParams, setActivityNameRequireParams] = React.useState<ActivityName>()
  const recentRouteNames = storage.getItemFromLocal<string[]>(DEVTOOL_RECENT_ROUTES) ?? []
  const recentRoutes = recentRouteNames.map((activityName) => {
    return {
      activityName: activityName as ActivityName,
      type: RouteItemType.CUSTOM,
      label: activityName,
      path: routes[activityName as ActivityName].path,
    }
  })

  const handleRouteClick = (item: RouteItem) => {
    const nextRecentRoutes = [
      item.activityName,
      ...recentRouteNames.filter((name) => name !== item.activityName),
    ].slice(0, 5)

    storage.setItemAtLocal(DEVTOOL_RECENT_ROUTES, nextRecentRoutes)

    setActivityNameRequireParams(item.activityName)
  }

  if (activityNameRequireParams) {
    return <Container>{<RouteParamsForm activityName={activityNameRequireParams} />}</Container>
  }

  return (
    <Container>
      {!!recentRouteNames.length && (
        <CustomRouteList>
          <RouteSectionTitle>즐겨찾는 페이지</RouteSectionTitle>
          {recentRoutes.map((item, i) => {
            return (
              <RouteButton key={i} onClick={() => handleRouteClick(item)}>
                <ButtonContent>{item.label}</ButtonContent>
              </RouteButton>
            )
          })}
        </CustomRouteList>
      )}
      <RouteList>
        <RouteSectionTitle>페이지 목록</RouteSectionTitle>
        {routeItems
          .filter((item) => item.label !== '*')
          .map((item, i) => {
            return (
              <RouteButton key={i} onClick={() => handleRouteClick(item)}>
                <ButtonContent>{item.label}</ButtonContent>
              </RouteButton>
            )
          })}
      </RouteList>
    </Container>
  )
}

export default DevToolRoutePage

const Container = styled('div', {
  minWidth: '200px',
  maxHeight: '80vh',
  padding: '16px',
  background: '$paperDefault-semantic',
  borderRadius: '6px',
  $scrollable: 'vertical',
})

const CustomRouteList = styled('div', {})

const RouteSectionTitle = styled('div', {
  fontSize: '1.4rem',
  fontWeight: 'bold',
  margin: '0 0 16px',
})

const RouteList = styled('div', {
  margin: '1.5rem 0 0',
})

const RouteButtonAnimation = keyframes({
  from: {
    transform: 'translateX(0)',
  },
  to: {
    transform: 'translateX(calc(-100% + 50px))',
  },
})

const RouteButton = styled(Button, {
  position: 'relative',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  defaultVariants: {
    priority: 'primary',
  },

  [`&:nth-of-type(n+2)`]: {
    margin: '8px 0 0',
  },
})

const ButtonContent = styled('div', {
  '&:hover': {
    animation: `${RouteButtonAnimation} 3s linear infinite`,
  },
})

const RouteParamsForm = ({ activityName }: { activityName: ActivityName }) => {
  const params = activityParamsSchemas[activityName as ActivityParamsSchemaKey] ?? {}
  const [paramsFormValues, setParamsFormValues] = React.useState<Record<string, any>>({
    ...Object.fromEntries(Object.keys(params).map((key) => [key, undefined])),
    referrer: undefined,
  })
  const craeteHandleSingleValueChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      e.preventDefault()
      setParamsFormValues((prev) => ({ ...prev, [key]: e.target.value }))
    }
  const handleReferrerChange = craeteHandleSingleValueChange(REFERRER_KEY)
  const isAllRequiredFilled = Object.entries(params).every(([key, schema]) => {
    if (!schema.required) return true
    if (schema.type === 'String') return !!paramsFormValues[key]
    if (schema.type === 'Array') return !paramsFormValues[key]?.length
    return paramsFormValues[key] !== undefined
  })

  const handlePageMoveClick = () => {
    const path = generatePath(routes[activityName].path, compactParams(paramsFormValues))
    window.location.href = path
  }

  const handleGenerateSchemeClick = () => {
    const path = generatePath(routes[activityName].path, compactParams(paramsFormValues))
    const alpha = generateScheme({ useAlpha: true, path })
    const prod = generateScheme({ useAlpha: false, path })
    window.navigator.clipboard.writeText(`Alpha Scheme: ${alpha}\nProd Scheme: ${prod}\n`)
  }

  return (
    <RouteParamsFormBase>
      {Object.entries(params).map(([name, schema]) => {
        const input = (() => {
          switch (schema.type) {
            case 'Array': {
              return (
                <ActionChipsWrapper>
                  {schema.oneOf.map((option: string) => {
                    const values = paramsFormValues[name] || []

                    const handleOptionClick = () => {
                      const nextValues = values.includes(option)
                        ? values.filter((value: string) => value !== option)
                        : [...values, option]
                      setParamsFormValues((prev) => ({ ...prev, [name]: nextValues }))
                    }

                    return (
                      <StyledActionChip
                        isActive={values.includes(option)}
                        onClick={handleOptionClick}
                        size="small"
                        key={option}
                        label={option}
                      />
                    )
                  })}
                </ActionChipsWrapper>
              )
            }
            case 'Enum': {
              const handleSelectChange = craeteHandleSingleValueChange(name)

              return (
                <SelectWrapper key={name}>
                  <Dropdown
                    type="xsmall"
                    id={name}
                    onChange={handleSelectChange}
                    value={!paramsFormValues[name] ? '' : paramsFormValues[name]}>
                    <option key="none" value="">
                      입력 없음
                    </option>
                    {schema.oneOf
                      .filter((option: string) => option !== '%future added value')
                      .map((option: string) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                  </Dropdown>
                  <ArrowDownPositionExpandMoreOutlineS
                    width={24}
                    height={24}
                    color={theme.colors.gray900.computedValue}
                  />
                </SelectWrapper>
              )
            }
            case 'Boolean': {
              const handleCheckboxClick = () => {
                setParamsFormValues((prev) => ({ ...prev, [name]: !prev[name] }))
              }
              return <CheckboxSquare key={name} isChecked={paramsFormValues[name]} onClick={handleCheckboxClick} />
            }
            case 'String':
            case 'Number':
            case 'Any':
            default: {
              const handleInputChange = craeteHandleSingleValueChange(name)
              return <Input id={name} value={paramsFormValues[name] ?? ''} onChange={handleInputChange} />
            }
          }
        })()

        return (
          <React.Fragment key={name}>
            <InputContainer>
              <Info>
                <Label htmlFor={name}>{name}</Label>
                <Tag size="small">{`${formatType(schema.type)}(${schema.required ? 'Required' : 'Optional'})`}</Tag>
              </Info>
              {input}
            </InputContainer>
          </React.Fragment>
        )
      })}
      <InputContainer>
        <Info>
          <Label htmlFor={REFERRER_KEY}>referrer</Label>
          <Tag size="small">{`${formatType('String')}(Optional)`}</Tag>
        </Info>
        <Input id={REFERRER_KEY} value={paramsFormValues[REFERRER_KEY] ?? ''} onChange={handleReferrerChange} />
      </InputContainer>
      <Button onClick={handlePageMoveClick} disabled={!isAllRequiredFilled} priority="primary" size="medium">
        페이지 이동
      </Button>
      <Spacer h={12} />
      <Button onClick={handleGenerateSchemeClick} disabled={!isAllRequiredFilled} priority="secondary" size="medium">
        Scheme 생성
      </Button>
    </RouteParamsFormBase>
  )
}

const compactParams = (params: Record<string, any>) => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (value === undefined || value === '' || (!Array.isArray(value) && typeof value === 'object')) return acc
    return { ...acc, [key]: value }
  }, {})
}
const formatType = (type: string) => {
  switch (type) {
    case 'String':
      return '문자열'
    case 'Number':
      return '숫자'
    case 'Array':
      return '배열'
    default:
      return type
  }
}

function generateScheme({ useAlpha, path }: { useAlpha: boolean; path: string }) {
  const host = useAlpha ? 'https://jobs.alpha.karrotwebview.com' : 'https://jobs.karrotwebview.com'
  const encodedPath = encodeURIComponent(`${host}${path}`)
  const scheme = useAlpha
    ? `karrot.alpha://minikarrot/router?remote=${encodedPath}&navbar=false&scrollable=false`
    : `karrot://minikarrot/router?remote=${encodedPath}&navbar=false&scrollable=false`

  return scheme
}

const RouteParamsFormBase = styled('div')

const InputContainer = styled('div', {
  margin: '0 0 12px',
})

const Info = styled('div', {
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 8px',
})

const Label = styled('label', {
  display: 'block',
  color: '$gray900',
  $text: 'label3Bold',
  margin: '0 8px 0 0',
})

const SelectWrapper = styled('div', {
  position: 'relative',
})

const Dropdown = styled('select', {
  display: 'block',
  width: '100%',
  color: '$gray900',
  border: '1px solid $gray400',
  borderRadius: '5px',
  background: 'transparent',
  appearance: 'none',
  outline: 'none',

  '&:focus, &:active': {
    border: '1px solid $gray700',
  },

  '&:disabled': {
    border: '1px solid $gray400',
    color: '$gray400',
    background: '$gray100',
  },

  variants: {
    type: {
      large: {
        $text: 'title3Regular',
        padding: '16px 9px 16px 16px',
      },
      medium: {
        $text: 'label2Regular',
        padding: '13px 9px 13px 16px',
      },
      small: {
        $text: 'label3Regular',
        padding: '11px 9px 11px 16px',
      },
      xsmall: {
        $text: 'label3Regular',
        padding: '8px 9px 8px 16px',
      },
    },
    accent: {
      true: {
        fontWeight: 'bold',
      },
    },
    isInvalid: {
      true: {
        borde: '1px solid $red800',
      },
    },
  },

  defaultVariants: {
    type: 'medium',
  },
})

const ArrowDownPositionExpandMoreOutlineS = styled(IconExpandMoreRegular, {
  position: 'absolute',
  top: '50%',
  right: '10px',
  transform: 'translateY(-50%)',
})

const ActionChipsWrapper = styled('div', {
  margin: '-6px 0 0 -6px',
})

const StyledActionChip = styled(ActionChip, {
  margin: '6px 0 0 6px',
})

const Input = styled('input', {
  $text: 'bodyL2Regular',
  display: 'block',
  margin: '0 0 10px',
  padding: '10px',
  width: '100%',
  minWidth: '250px',
  border: '1px solid $gray400',
  borderRadius: '6px',
  color: '$gray900',
  background: '$paperDefault-semantic',
  resize: 'none',

  '&::placeholder': {
    color: '$gray600',
  },

  '&:focus': {
    '&::placeholder': {
      color: 'transparent',
    },
  },
})
