/**
 * @generated SignedSource<<bf1da55a9bed5fb1efae6f22a6cc3e6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostFeedCardRecommend_feedCard$data = {
  readonly jobPost: {
    readonly " $fragmentSpreads": FragmentRefs<"JobPostCardRecommend_jobPost">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"JobPostCardContainer_feedCard">;
  readonly " $fragmentType": "JobPostFeedCardRecommend_feedCard";
};
export type JobPostFeedCardRecommend_feedCard$key = {
  readonly " $data"?: JobPostFeedCardRecommend_feedCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostFeedCardRecommend_feedCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostFeedCardRecommend_feedCard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JobPost",
      "kind": "LinkedField",
      "name": "jobPost",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "JobPostCardRecommend_jobPost"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobPostCardContainer_feedCard"
    }
  ],
  "type": "FeedCard",
  "abstractKey": null
};

(node as any).hash = "7f2c2cafe288788c88a84146de003190";

export default node;
