import { nextTick } from 'async'

import { IS_DEV, AUTH_TOKEN, USER_AGENT, TARGET_LOCALE, USER_ID } from '@src/config'
import { DEFAULT_COUNTRY_KEY } from '@src/constants/api'
import { IMAGE_RESUME_DEFAULT } from '@src/constants/images'
import { karrotBridge, getCurrentPosition } from '@src/sdks/bridge'
import { captureMessage } from '@src/sdks/sentry'
import { AppInfo } from '@src/types/common'

const mockData = {
  app: {
    deviceIdentity: 'developer',
    userAgent: USER_AGENT,
    locale: TARGET_LOCALE,
    appHost: '',
    country: DEFAULT_COUNTRY_KEY as 'GB' | 'KR',
  },
  region: {
    id: 367,
    name: '역삼1동',
    name1: '서울특별시',
    name2: '강남구',
    name3: '역삼1동',
    fullname: '서울특별시 강남구 역삼1동',
    parentId: 1,
    centerCoordinates: {
      latitude: 37.5062203134857,
      longitude: 127.005051680109,
    },
  },
  user: {
    phone: '01099810965',
    profileImage: IMAGE_RESUME_DEFAULT.WEBP['3x'],
    authToken: AUTH_TOKEN,
    id: Number(USER_ID),
    nickname: '테스터',
    temperature: 36.5,
    userExtend: {
      region2: {
        id: 6082,
        name: '신설동',
        name1: '서울특별시',
        name2: '동대문구',
        name3: '신설동',
        fullname: '서울특별시 동대문구 신설동',
        parentId: 1,
        centerCoordinates: {
          latitude: 37.4912814432289,
          longitude: 127.029275725304,
        },
      },
    },
    displayRegionCheckinsCount: 1,
  },
  currentPosition: {
    position: {
      latitude: 37.4912814432289,
      longitude: 127.029275725304,
    },
    timestamp: 1628580000000,
  },
}

export const useEnhancedInfo = () => {
  const getInfo = () => {
    if (IS_DEV) {
      return new Promise<AppInfo>((resolve) => {
        nextTick(() => {
          const info = mockData
          resolve(info)
        })
      })
    }

    return Promise.all([
      karrotBridge.getAppInfo({}),
      karrotBridge.getUserInfo({}),
      karrotBridge.getRegionInfo({}),
      getCurrentPosition(),
    ]).then(
      async ([
        {
          info: { app },
        },
        {
          info: { user },
        },
        {
          info: { region },
        },
        currentPosition,
      ]) => {
        if (!region?.id) {
          captureMessage(`유효하지 않은 region(id)`, {
            extra: { region, app, user, undefinedTest: undefined, nullTest: null, emptyObjTest: {} },
          })
        }

        return {
          app,
          region,
          user,
          currentPosition,
        }
      }
    )
  }

  return { getInfo }
}
