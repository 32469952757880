type EnumType<T extends readonly string[]> = T[number]
type ElementType<T extends readonly unknown[]> = T[number]

const EnumGenerator = <T extends string[]>(...args: T) => {
  return {
    Required: {
      type: 'Enum',
      oneOf: args,
      required: true,
    } as unknown as EnumType<T>,
    Optional: {
      type: 'Enum',
      oneOf: args,
      required: false,
    } as unknown as EnumType<T> | undefined,
    Nullable: {
      type: 'Enum',
      oneOf: args,
      required: false,
    } as unknown as EnumType<T> | null | undefined,
  }
}

const ArrayGenerator = <T extends readonly unknown[]>(arr: T) => {
  return {
    Required: {
      type: 'Array',
      oneOf: arr,
      required: true,
    } as unknown as ElementType<T>[],
    Optional: {
      type: 'Array',
      oneOf: arr,
      required: false,
    } as unknown as ElementType<T>[] | undefined,
    Nullable: {
      type: 'Array',
      oneOf: arr,
      required: false,
    } as unknown as ElementType<T>[] | null | undefined,
  }
}

export const valueTypeGenerator = {
  String: {
    Required: {
      type: 'String',
      required: true,
    } as unknown as string,
    Optional: {
      type: 'String',
      required: false,
    } as unknown as string | undefined,
    Nullable: {
      type: 'String',
      required: false,
    } as unknown as string | null | undefined,
  },
  Boolean: {
    Required: {
      type: 'Boolean',
      required: true,
    } as unknown as boolean,
    Optional: {
      type: 'Boolean',
      required: false,
    } as unknown as boolean | undefined,
    Nullable: {
      type: 'Boolean',
      required: false,
    } as unknown as boolean | null | undefined,
  },
  Number: {
    Required: {
      type: 'Number',
      required: true,
    } as unknown as number,
    Optional: {
      type: 'Number',
      required: false,
    } as unknown as number | undefined,
    Nullable: {
      type: 'Number',
      required: false,
    } as unknown as number | null | undefined,
  },
  Date: {
    Required: {
      type: 'Date',
      required: true,
    } as unknown as Date,
    Optional: {
      type: 'Date',
      required: false,
    } as unknown as Date | undefined,
    Nullable: {
      type: 'Date',
      required: false,
    } as unknown as Date | null | undefined,
  },
  Enum: EnumGenerator,
  Array: ArrayGenerator,
  Any: {
    type: 'Any',
  } as any,
  Empty: { type: 'Empty' } as unknown as never,
}

const Empty = {}
export type Empty = typeof Empty
