import ToggleIcon from '@src/packages/falcon-react/components/ToggleIcon'
import Drawer from '@src/packages/falcon-react/drawers/Drawer'
import useClickedElement from '@src/packages/falcon-react/hooks/useClickedEleement'
import useToggleClickActivation from '@src/packages/falcon-react/hooks/useToggleClickActivation'

const Falcon = () => {
  const { isActive, toggleActive } = useToggleClickActivation(true)
  const isFalconOn = !isActive
  const { clickedElement, clickedTime } = useClickedElement(isFalconOn)

  return (
    <>
      <ToggleIcon on={isFalconOn} onClick={toggleActive} />
      <Drawer element={clickedElement} elementId={clickedTime} />
    </>
  )
}

export default Falcon
