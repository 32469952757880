import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { addAmplitudeSegments } from '@src/sdks/amplitude'
import { AppInfo } from '@src/types/common'
import { ExperiencePeriod } from '@src/types/resume'
import { UserRole } from '@src/types/user'
import { hasAcquisition } from '@src/utils/acquisition'
import { sampleGroupKeyToSegment } from '@src/utils/experiment'
import { calcUserRole } from '@src/utils/user'

import { initApplication } from './common'

type ResumeExperience = {
  _id: string
  workplaceName: string
  year?: number | null
  period?: ExperiencePeriod | '%future added value' | null
  content?: string | null
  createdAt: Dateable
  updatedAt: Dateable
}

type MeState = {
  nodeId: string
  role: UserRole
  birthYear?: number
  resettableDeviceId?: string
  initialReferrer?: string
  paidAcquiredAuthor: boolean
  isUserApplied?: boolean
}

type UserState =
  | ({ [key: string]: any } & {
      currentPosition?: AppInfo['currentPosition']
      resumeExperience?: ResumeExperience
    } & Partial<MeState>)
  | null

type RoleUpdateAction = 'apply' | 'create_job_post'

export const updateRole = createAsyncThunk<
  { action: RoleUpdateAction; paidAcquired: boolean },
  { action: RoleUpdateAction }
>('user/updateRole', async ({ action }, { getState }) => {
  const user = (getState() as any).user as UserState
  const isAuthor = calcUserRole(user).isAuthor || action === 'create_job_post'
  const isPaidAcquiredAuthor = isAuthor && hasAcquisition()
  if (isPaidAcquiredAuthor) {
    addAmplitudeSegments([sampleGroupKeyToSegment('PAID_JOB_POST_AUTHOR')])
  }

  return { action, paidAcquired: hasAcquisition() }
})

const INITIAL_STATE: UserState = null

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE as UserState,
  reducers: {
    updateExperience: (state, { payload }: PayloadAction<ResumeExperience>) => {
      if (!state) return state
      return { ...state, resumeExperience: payload } as UserState
    },
    setUserByMe: (state, { payload }: PayloadAction<MeState>) => {
      return {
        ...state,
        ...payload,
      }
    },
    updatePaidAcquiredAuthor: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      paidAcquiredAuthor: payload,
    }),
    updateInitialReferrer: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      initialReferrer: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(initApplication, (state, { payload }) => {
      return { ...state, ...payload.user, currentPosition: payload.currentPosition }
    })
    builder.addCase(
      updateRole.fulfilled,
      (state, { payload }: PayloadAction<{ action: RoleUpdateAction; paidAcquired: boolean }>) => {
        const { isApplicant, isAuthor } = calcUserRole({ role: state?.role })
        const [toApplicant, toAuthor] = [payload.action === 'apply', payload.action === 'create_job_post']
        const nextRole: UserRole = toApplicant
          ? isAuthor
            ? 'AUTHOR_APPLICANT'
            : 'APPLICANT'
          : toAuthor
            ? isApplicant
              ? 'AUTHOR_APPLICANT'
              : 'AUTHOR'
            : 'NONE'

        if (state?.role === nextRole) return state

        const isPaidAcquiredAuthor = !!payload.paidAcquired && (isAuthor || toAuthor) ? true : false

        return {
          ...state,
          role: nextRole,
          paidAcquiredAuthor: isPaidAcquiredAuthor,
        }
      }
    )
  },
})

export const { updateExperience, setUserByMe, updatePaidAcquiredAuthor, updateInitialReferrer } = userSlice.actions

export default userSlice.reducer
