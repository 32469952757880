/**
 * @generated SignedSource<<3fa75e6219dca2e343c52ba553cca78e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Content_jobPost$data = {
  readonly _id: string;
  readonly author: {
    readonly hoianUser?: {
      readonly _id: number;
    };
  };
  readonly maskingContent: string | null | undefined;
  readonly unMaskingContent?: string | null | undefined;
  readonly " $fragmentType": "Content_jobPost";
};
export type Content_jobPost$key = {
  readonly " $data"?: Content_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"Content_jobPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isMasking"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Content_jobPost",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "maskingContent",
      "args": [
        {
          "kind": "Literal",
          "name": "masking",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "content",
      "storageKey": "content(masking:true)"
    },
    {
      "condition": "isMasking",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "unMaskingContent",
          "args": [
            {
              "kind": "Literal",
              "name": "masking",
              "value": false
            }
          ],
          "kind": "ScalarField",
          "name": "content",
          "storageKey": "content(masking:false)"
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "hoianUser",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};
})();

(node as any).hash = "d58952ef27a24174d773e28fd41d3635";

export default node;
