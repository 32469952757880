/**
 * @generated SignedSource<<5c853c249f2615f573aaec18c0748f02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SalaryType = "DAILY" | "HOURLY" | "MONTHLY" | "PER_CASE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobPostStringifiedSalaryResolver$data = {
  readonly salary: number | null | undefined;
  readonly salaryType: SalaryType | null | undefined;
  readonly " $fragmentType": "JobPostStringifiedSalaryResolver";
};
export type JobPostStringifiedSalaryResolver$key = {
  readonly " $data"?: JobPostStringifiedSalaryResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostStringifiedSalaryResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostStringifiedSalaryResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salaryType",
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "894ffe03444b4c3535d24d38677652a2";

export default node;
