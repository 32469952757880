import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { UserReceivedApplicantGreatReviewCountResolver$key } from '@src/__generated__/UserReceivedApplicantGreatReviewCountResolver.graphql'

/**
 *
 * @RelayResolver User.receivedApplicantGreatReviewCount: Int
 * @rootFragment UserReceivedApplicantGreatReviewCountResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function receivedApplicantGreatReviewCount(userRef: UserReceivedApplicantGreatReviewCountResolver$key): number {
  const user = readFragment(
    graphql`
      fragment UserReceivedApplicantGreatReviewCountResolver on User {
        receivedJobReviewsGreatCountByApplicant: receivedJobReviewsCount(
          filter: { evaluator: APPLICANT, rating: [GREAT] }
        )
      }
    `,
    userRef
  )

  return user.receivedJobReviewsGreatCountByApplicant ?? 0
}
