import React from 'react'

// For making ErrorBoundary get asyncError
// https://github.com/facebook/react/issues/14981#issuecomment-468460187
export const useAsyncError = () => {
  const [, setError] = React.useState()

  return (err: Error) => {
    setError(() => {
      throw err
    })
  }
}
