import React from 'react'

import { PageCodeInfoProvider } from '@src/contexts/PageCodeInfoContext'
import { styled } from '@src/stitches/stitches.config'

type TouchTabsProps = {
  defaultTabIndex?: number
  tabs: { key: string; name: string; render: () => React.ReactElement<any, any> }[]
}

const TouchTabs: React.FCC<TouchTabsProps> = ({ defaultTabIndex = 0, tabs }) => {
  const [activeTabKey, setActiveTabKey] = React.useState(tabs.length > 0 ? tabs[defaultTabIndex].key : '')
  const activeTabIndex =
    tabs.findIndex((tab) => tab.key === activeTabKey) !== -1 ? tabs.findIndex((tab) => tab.key === activeTabKey) : 0

  return (
    <>
      <Nav>
        {tabs.map((tab) => (
          <NavItem
            key={tab.key}
            isActive={tab.key === activeTabKey}
            onClick={() => (tab.key === activeTabKey ? null : setActiveTabKey(tab.key))}>
            {tab.name}
          </NavItem>
        ))}
        <NavIndicator
          css={{
            width: `${100 / tabs.length}%`,
            transform: `translateX(${100 * activeTabIndex}%)`,
          }}
        />
      </Nav>
      <PageCodeInfoProvider pageSectionCode={activeTabKey}>
        {tabs.find((tab) => tab.key === activeTabKey)?.render()}
      </PageCodeInfoProvider>
    </>
  )
}

const Nav = styled('div', {
  position: 'relative',
  background: '$paperDefault-semantic',
  display: 'flex',
  borderBottom: '1px solid $divider3-semantic',
})

const NavItem = styled('div', {
  $text: 'label3Bold',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',

  variants: {
    isActive: {
      true: {
        color: '$gray900',
      },
      false: {
        color: '$gray600',
      },
    },
  },
})

const NavIndicator = styled('div', {
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: '2px',
  backgroundColor: '$gray900',
  transition: 'transform 300ms',
  willChange: 'transform',
})

export default TouchTabs
