import { captureMessage } from '@src/sdks/sentry'

import Request from './Request'
import storage from './storage'

export default class Discovery extends Request {
  constructor(baseUrl: string) {
    super(baseUrl)
    this.setPlantaeInterceptor()
    this.setTokenHeader()
    this.setUserAgentHeader()
    this.setRequestIdHeader()
  }

  public async visited() {
    try {
      await this.post({
        url: `/public/shortcut/v1/users/me/services/VISITABLE_SERVICE_JOBS/visited`,
        data: {
          visitedAt: new Date().toISOString(),
        },
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      captureMessage('Discovery visited error', {
        extra: {
          err,
          isTokenExist: !!storage.getToken(),
        },
      })
    }
  }
}
