import React from 'react'

import Button from '@src/components/base/buttons/Button'
import BottomSheet from '@src/packages/modal/bottomSheet/BottomSheet'
import BottomSheetItem from '@src/packages/modal/bottomSheet/BottomSheetItem'
import { styled, zIndices } from '@src/stitches/stitches.config'

type Props = Omit<React.ComponentProps<typeof BottomSheet>, 'children'> & {
  items: {
    visible?: boolean
    description?: string
    disabled?: boolean
    label: string
    onClick: () => void
    type: 'warning' | 'info'
  }[]
  showCloseButton?: boolean
}

const ListModal: React.FCC<Props> = ({ items, showCloseButton, zIndex = zIndices.global1, ...props }) => {
  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    props.onClose?.()
  }

  return (
    <BottomSheet zIndex={zIndex} {...props}>
      {items
        .filter((item) => item.visible !== false)
        .map((item) => {
          const { visible, onClick, ...itemProps } = item
          const handleClick = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
            onClick()
          }
          return <BottomSheetItem key={itemProps.label} onClick={handleClick} {...itemProps} />
        })}
      {showCloseButton && (
        <ButtonWrapper onClick={handleClose}>
          <Button>닫기</Button>
        </ButtonWrapper>
      )}
    </BottomSheet>
  )
}

export default ListModal

const ButtonWrapper = styled('div', {
  padding: '12px 16px 16px',
})
