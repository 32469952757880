import storage from '@src/api/storage'
import { WRITE_REVIEW_NUDGE_CLOSED } from '@src/constants/api'
import { reviewCodeMap, reviewCodes } from '@src/constants/form'

import { isPastDateAddDaysLimit } from './date'

export function sortSelectedReviews(reviews: { code: string; count: number }[], revieweeType: 'APPLICANT' | 'AUTHOR') {
  const sortOrder: string[] = {
    AUTHOR: [
      // Great and Good
      reviewCodes.RESPECTED,
      reviewCodes.KEEP_BREAKTIME,
      reviewCodes.GIVE_ALLOWANCE,
      reviewCodes.PAYMENT_INTIME,
      reviewCodes.GOOD_ENVIRONMENT,
      reviewCodes.SATISFIED,
      reviewCodes.LOTS_OF_LEARNS,
      reviewCodes.FAST_RESPONSE,
      reviewCodes.SPECIFIC_EXPLANATION,
      reviewCodes.NICE_MANNER,
      // Bad
      reviewCodes.NOT_RESPECTED,
      reviewCodes.IGNORE_BREAKTIME,
      reviewCodes.DIFFERENT_SALARY,
      reviewCodes.NO_ALLOWANCE,
      reviewCodes.DELAY_SALARY,
      reviewCodes.BAD_ENVIRONMENT,
      reviewCodes.DIFFERENT_WORK,
      reviewCodes.NO_ANSWER_CHAT,
      reviewCodes.DIFFERENT_WORKPLACE_ADDRESS,
      reviewCodes.DISEASE,
    ],
    APPLICANT: [
      reviewCodes.KEEP_TIME,
      reviewCodes.NICE_MANNER,
      reviewCodes.SINCERE,
      reviewCodes.MATURE_WORK,
      reviewCodes.FAST_LERANER,
      reviewCodes.KEEP_CONTRACT_PERIOD,
      reviewCodes.FAST_RESPONSE,
    ],
  }[revieweeType]

  return reviews
    .filter((review) => sortOrder.includes(review.code))
    .sort((a, b) =>
      a.count === b.count ? sortOrder.indexOf(a.code) - sortOrder.indexOf(b.code) : b.count - a.count
    ) as { code: keyof typeof reviewCodeMap; count: number }[]
}

export const getIsWriteReviewNudgeRecentlyClosedFromLocal = (): boolean => {
  const data = storage.getItemFromLocal(WRITE_REVIEW_NUDGE_CLOSED)

  if (!data) {
    return false
  }

  return !isPastDateAddDaysLimit({ targetDate: new Date(data), days: 90 })
}

export const setIsWriteReviewNudgeRecentlyClosedAtLocal = () => {
  return storage.setItemAtLocal(WRITE_REVIEW_NUDGE_CLOSED, new Date())
}
