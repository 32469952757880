import {
  IconCalendarFill,
  IconCalendarThin,
  IconClockFill,
  IconConfirmationFill,
  IconMoneyWonFill,
  IconMyFill,
} from '@seed-design/icon'
import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { Info_jobPost$key } from '@src/__generated__/Info_jobPost.graphql'
import BadgeSquare from '@src/components/base/badge/BadgeSquare'
import Highlighter from '@src/components/base/Highlighter'
import Image from '@src/components/base/image/Image'
import TagGroup from '@src/components/base/tag/TagGroup'
import InfoWorkDatesCalendar from '@src/components/common/jobPost/InfoWorkDatesCalendar'
import { ConsideredQualification, jobTasksMap, Welfare } from '@src/constants/form'
import { IMAGE_LOGO_GWANGSANGU } from '@src/constants/images'
import use202307CampaignMarketing from '@src/hooks/use202307CampaignMarketing'
import { useGwangsanguUser } from '@src/hooks/useGwangsanguUser'
import { useNavigator } from '@src/hooks/useNavigator'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { JobsActivity } from '@src/router/activityNames'
import { userSelectText } from '@src/stitches/action'
import { styled, theme } from '@src/stitches/stitches.config'
import { calcShortWorkPeriod, stringifyConsideredQualifications, stringifyWelfare } from '@src/utils/jobPost'

interface Props {
  jobPostRef: Info_jobPost$key
}

const Info: React.FCC<Props> = ({ jobPostRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment Info_jobPost on JobPost @argumentDefinitions(isMasking: { type: "Boolean!", defaultValue: false }) {
        closed
        companyName
        maskingTitle: title(masking: true)
        unMaskingTitle: title(masking: false) @include(if: $isMasking)
        workPeriod
        jobTasks
        lastBringUpDate
        publishedAt
        stringifiedSalary
        stringifiedBringUpPassedDate
        stringifiedWorkDateAndDays
        stringifiedWorkTime
        consideredQualifications
        welfare
        eventGroup {
          type
          view {
            logo {
              fullImageUrl
            }
          }
        }
        author {
          ... on HoianUserAuthor {
            hoianUser {
              _id
            }
          }
        }
        ...InfoWorkDatesCalendar_jobPost
      }
    `,
    jobPostRef
  )
  const { push } = useNavigator()
  const isShortWorkPeriod = calcShortWorkPeriod(jobPost.workPeriod)
  const handleCalendarIconClick = () => setCalendarOpen((prev) => !prev)
  const is202307Campaign = use202307CampaignMarketing()
  const isDaangnEvent = jobPost.eventGroup?.type === 'DAANGN_EVENT'
  const eventGroupLogoImage = jobPost.eventGroup?.view?.logo?.fullImageUrl
  const { isGwangsanguUser } = useGwangsanguUser(jobPost.author.hoianUser?._id)
  const [isCalendarOpen, setCalendarOpen] = React.useState(false)
  const { logClickEvent } = useLogAnalyticsEvent()

  const handleEventGroupClick = () => {
    if (!jobPost.eventGroup?.type) return
    push(JobsActivity.FEED_EVENT_GROUP, { eventGroup: jobPost.eventGroup.type as any })
  }

  return (
    <>
      {isGwangsanguUser ? (
        <LogoImage source={IMAGE_LOGO_GWANGSANGU} />
      ) : eventGroupLogoImage ? (
        <EventGroupLink onClick={handleEventGroupClick}>
          <LogoImage source={eventGroupLogoImage} />
        </EventGroupLink>
      ) : null}
      <JobTasksContainer>
        {is202307Campaign || isDaangnEvent ? (
          <JobTask>이벤트</JobTask>
        ) : (
          jobPost.jobTasks.map((task, i) => {
            const taskText = jobTasksMap[task as keyof typeof jobTasksMap]
            const handleJobTaskClick = () => {
              logClickEvent({ name: 'job_task', params: { task: taskText } })
            }

            return (
              <JobTask size="large" key={i} onClick={handleJobTaskClick}>
                {taskText}
              </JobTask>
            )
          })
        )}
      </JobTasksContainer>
      <Title>
        {jobPost.closed ? <Closed>마감 </Closed> : null}
        {jobPost.unMaskingTitle && jobPost.maskingTitle ? (
          <Highlighter masking={jobPost.maskingTitle} unMasking={jobPost.unMaskingTitle} />
        ) : (
          jobPost.maskingTitle
        )}
      </Title>
      <TagGroupWrapper>
        <TagGroup
          size="medium"
          items={[
            jobPost.companyName,
            {
              visible: !is202307Campaign && !isDaangnEvent && (!!jobPost.lastBringUpDate || !!jobPost.publishedAt),
              value: jobPost.stringifiedBringUpPassedDate,
            },
          ]}
        />
      </TagGroupWrapper>

      <LightDivider />

      <InfoContainer>
        <InfoWrapper>
          <IconMoneyWonFill width={18} height={18} color={theme.colors.gray600.computedValue} />
          <MoneyInfoText>{jobPost.stringifiedSalary}</MoneyInfoText>
        </InfoWrapper>
        <InfoWrapper>
          <IconCalendarFill width={18} height={18} color={theme.colors.gray600.computedValue} />
          <DateInfoContainer>
            <DateInfoText>{jobPost.stringifiedWorkDateAndDays}</DateInfoText>
            {isShortWorkPeriod && (
              <CalendarToggleContainer onClick={handleCalendarIconClick}>
                <IconCalendarThin width={18} height={18} color={theme.colors['accent-semantic'].computedValue} />
                <CalendarInfoText>달력보기</CalendarInfoText>
              </CalendarToggleContainer>
            )}
          </DateInfoContainer>
        </InfoWrapper>

        <InfoWorkDatesCalendar isOpen={isCalendarOpen} jobPostRef={jobPost} />
        <InfoWrapper>
          <IconClockFill width={18} height={18} color={theme.colors.gray600.computedValue} />
          <InfoText>{jobPost.stringifiedWorkTime}</InfoText>
        </InfoWrapper>
        {!!jobPost.welfare?.length && (
          <InfoWrapper>
            <StyledIconConfirmationFill width={18} height={18} color={theme.colors.gray600.computedValue} />
            <InfoText>{stringifyWelfare(jobPost.welfare as Welfare[])}</InfoText>
          </InfoWrapper>
        )}
        {!!jobPost.consideredQualifications?.length && (
          <InfoWrapper>
            <StyledIconMyFill width={18} height={18} color={theme.colors.gray600.computedValue} />
            <InfoText>
              {stringifyConsideredQualifications(jobPost.consideredQualifications as ConsideredQualification[])}
            </InfoText>
          </InfoWrapper>
        )}
      </InfoContainer>
    </>
  )
}

export default Info

const LightDivider = styled('div', {
  margin: '20px 0 24px',
  height: '1px',
  background: '$divider1-semantic',
})

const Title = styled('div', userSelectText, {
  $text: 'title2Bold',
})

const Closed = styled('span', {
  color: '$gray600',
})

const TagGroupWrapper = styled('div', userSelectText, {
  margin: '8px 0 0',
})

const InfoContainer = styled('div', {
  margin: '24px 0 0',
})

const InfoWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  margin: '4px 0 0',

  '&:first-of-type': {
    margin: 0,
  },
})

const InfoText = styled('div', userSelectText, {
  $text: 'label2Regular',
  margin: '0 0 0 8px',
  wordBreak: 'keep-all',
  color: '$gray800',
})

const DateInfoContainer = styled(InfoText, {
  display: 'flex',
  alginItems: 'center',
  flexWrap: 'wrap',
})

const DateInfoText = styled('div', {
  margin: '0 8px 0 0',
})

const CalendarToggleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  $text: 'bodyL2Regular',
})

const CalendarInfoText = styled('div', {
  position: 'relative',
  margin: '0 0 0 4px',
  color: '$accent-semantic',
})

const MoneyInfoText = styled(InfoText, {
  whiteSpace: 'nowrap',
})

const JobTasksContainer = styled('div', {
  margin: '-6px 0 12px -6px',
})

const JobTask = styled(BadgeSquare, {
  margin: '6px 0 0 6px',
})

const StyledIconConfirmationFill = styled(IconConfirmationFill, {
  flexShrink: 0,
})

const StyledIconMyFill = styled(IconMyFill, {
  flexShrink: 0,
})

const EventGroupLink = styled('div', {
  padding: '12px',
  margin: '-12px',
})

const LogoImage = styled(Image, {
  display: 'block',
  height: '16px',
  margin: '0 0 12px ',
})
