import React from 'react'

import ErrorPage from '@src/components/base/error/ErrorPage'
import { styled } from '@src/stitches/stitches.config'

const RootErrorPage: React.FCC = () => {
  const handleRefreshClick = () => {
    window.location.reload()
  }

  return (
    <Base>
      <ErrorPage
        showButton
        onClickButton={handleRefreshClick}
        buttonMessage="새로고침"
        messages={['일시적으로 문제가 발생했어요.', '잠시 후 다시 시도해주세요.']}
      />
    </Base>
  )
}

const Base = styled('div', {
  height: '100%',
  position: 'relative',
  background: '$paperDefault-semantic',
})

export default RootErrorPage
