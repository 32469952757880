import { IS_DEV, IS_PROD } from '@src/config'
import { captureMessage } from '@src/sdks/sentry'

import Request from './Request'
import storage from './storage'

export default class Advertisement extends Request {
  private isBlocked = IS_DEV && IS_PROD

  constructor() {
    super()

    this.setPlantaeInterceptor()
    this.setTokenHeader()
    this.setUserAgentHeader()
    this.setRequestIdHeader()
    this.setRetry({
      retries: 5,
      retryDelay: (count) => {
        return count * 2000
      },
    })
  }

  public async impression(url: string) {
    if (this.isBlocked) {
      // eslint-disable-next-line no-console
      console.log('advertisement impression triggered', url)
      return
    }

    try {
      await this.post({
        url,
      })
    } catch (err) {
      captureMessage('advertisement impression error', {
        extra: {
          url,
          err,
          tokenExist: !!storage.getToken(),
        },
      })
    }
  }

  public async click(url: string) {
    if (this.isBlocked) {
      // eslint-disable-next-line no-console
      console.log('advertisement click triggered', url)
      return
    }

    try {
      await this.post({
        url,
      })
    } catch (err) {
      captureMessage('advertisement click error', {
        extra: {
          url,
          err,
          tokenExist: !!storage.getToken(),
        },
      })
    }
  }

  public async hide(url: string) {
    if (this.isBlocked) {
      // eslint-disable-next-line no-console
      console.log('advertisement hide triggered', url)
      return
    }

    try {
      await this.post({
        url,
      })
    } catch (err) {
      captureMessage('advertisement click error', {
        extra: {
          url,
          err,
          tokenExist: !!storage.getToken(),
        },
      })
    }
  }

  public async sendClickEvent(callback?: Nullable<{ click?: string | null; impression?: string | null }>) {
    const advertisementClickUrl = callback?.click
    const advertisementImpressionUrl = callback?.impression

    if (!advertisementClickUrl || !advertisementImpressionUrl) return
    await this.impression(advertisementImpressionUrl)
    this.click(advertisementClickUrl)
  }
}
