import React from 'react'

import { styled } from '@src/stitches/stitches.config'
import { getMiddleDotStr } from '@src/utils/string'

import Tag from './Tag'

type Props = {
  size: 'medium' | 'small'
  items: Array<{ visible: boolean; value: any } | string | number | undefined | null>
  noWrap?: boolean
  isInline?: boolean
  maxLineCount?: number
} & React.ComponentProps<typeof Base> &
  Omit<JSX.IntrinsicElements['div'], 'ref'>

const TagGroup: React.FCC<Props> = ({ items, size, noWrap = true, isInline, maxLineCount, ...props }) => {
  const tagContents = items
    .filter((item) => (typeof item === 'object' ? item !== null && item.visible !== false : true))
    .map((item) => (typeof item === 'object' ? item !== null && item.value : item))
    .filter((item) => item !== undefined && item !== null && item !== '')

  return (
    <Base size={size} maxLineCount={maxLineCount}>
      {tagContents.map((tag, i) => (
        <React.Fragment key={i}>
          {i > 0 && <StyledTag {...props}> {getMiddleDotStr()} </StyledTag>}
          {typeof tag === 'object' ? (
            tag
          ) : (
            <Tag isInline={isInline} noWrap={noWrap} {...props}>
              {tag}
            </Tag>
          )}
        </React.Fragment>
      ))}
    </Base>
  )
}

export default TagGroup

const Base = styled('div', {
  '& > *': {
    display: 'inline-block',
  },

  color: '$gray600',

  variants: {
    size: {
      medium: {
        $text: 'caption1Regular',
      },
      small: {
        $text: 'caption2Regular',
      },
    },
    maxLineCount: {
      1: {
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },
      2: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },
      3: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },
    },
  },
})

const StyledTag = styled(Tag, {
  margin: '0 3.5px',
})
