import { styled } from './stitches.config'

export const SwiperContainer = styled('div', {
  // outermost swiper container
  '& .swiper': {
    height: '100%',
  },

  // bullets' container
  '& .swiper-pagination-bullets, & .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '6px',
  },

  // bulletClass default class name
  '& .swiper-pagination-bullet': {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    margin: '0 4px',
    background: '$grayAlpha500',
    opacity: 0.2,
  },

  // bulletActiveClass default class name
  '& .swiper-pagination-bullet-active': {
    background: '$primary-semantic',
    opacity: 1,
  },
})

export const FullSizeSwiperWrapper = styled('div', {
  '& .swiper': {
    height: '100%',
  },

  '& .swiper-pagination-bullets, & .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets': {
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '38px',
    background: 'linear-gradient(360deg, rgba(0, 0, 0, 0.3) 5.21%, rgba(0, 0, 0, 0.0001) 100%)',
    backgroundBlendMode: 'multiply',
  },

  '& .swiper-pagination-bullet': {
    width: '6px',
    height: '6px',
    background: '$paperDefault-semantic',
    opacity: 0.3,
  },

  '& .swiper-pagination-bullet-active': {
    opacity: 1,
  },
})
