import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface InitialState {
  readJobPosts: {
    [key: string]: string
  }
  isJobPostsStale: boolean
  unadvertisedJobPostId: string | null
  isJobPostWritable: boolean
}

const INITIAL_STATE: InitialState = {
  readJobPosts: {},
  isJobPostsStale: false,
  unadvertisedJobPostId: null,
  isJobPostWritable: false,
}

const jobPostSlice = createSlice({
  name: 'jobPost',
  initialState: INITIAL_STATE,
  reducers: {
    setIsJobPostsStale: (state, { payload }: PayloadAction<boolean>) => {
      state.isJobPostsStale = payload
    },
    setUnadvertisedJobPostId: (state, { payload }: PayloadAction<string | null>) => {
      state.unadvertisedJobPostId = payload
    },
    setIsJobPostWritable: (state, { payload }: PayloadAction<boolean>) => {
      state.isJobPostWritable = payload
    },
  },
})

export const { setIsJobPostsStale, setUnadvertisedJobPostId, setIsJobPostWritable } = jobPostSlice.actions

export default jobPostSlice.reducer
