import { useMutation, graphql } from 'react-relay'

import { useReportJobReviewMutationMutation } from '@src/__generated__/useReportJobReviewMutationMutation.graphql'

export function useReportJobReviewMutation() {
  const [reportJobReview, reportJobReviewLoading] = useMutation<useReportJobReviewMutationMutation>(graphql`
    mutation useReportJobReviewMutationMutation($where: ReportJobReviewWhereInput!) {
      reportJobReview(where: $where) {
        jobReview {
          id
        }
      }
    }
  `)

  return {
    loading: reportJobReviewLoading,
    reportJobReview,
  }
}
