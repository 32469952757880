import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@src/components/base/buttons/Button'
import Radio from '@src/components/base/form/Radio'
import { EXPERIMENTS, ExperimentName } from '@src/constants/experiment'
import { changeExperiments } from '@src/ducks/devTool'
import { segmentsSelector } from '@src/selectors/devTool'
import { styled } from '@src/stitches/stitches.config'
import {
  isControlSegment,
  isExcludeSegment,
  makeAllSegmentsByExperimentName,
  makeExcludeSegment,
  parseSegment,
} from '@src/utils/experiment'

type Prefix = string
type Segment = string

interface Props {
  onCloseModal: () => void
}

const DevToolExperimentsModal: React.FCC<Props> = ({ onCloseModal }) => {
  const dispatch = useDispatch()
  const allSegments = useSelector(segmentsSelector)

  const initialSegments = React.useMemo(() => {
    return Object.fromEntries(
      EXPERIMENTS.map((experiment) => {
        const segment = allSegments.find((segment) => parseSegment(segment).name === experiment.name)
        return [experiment.name, segment ?? makeExcludeSegment(experiment.name, experiment.version)]
      })
    ) as Record<ExperimentName, Segment>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [selectedSegments, setSelectedSegments] = React.useState<Record<ExperimentName, Segment>>(initialSegments)
  const handleSelectedSegments = ({ name, segment }: { name: ExperimentName; segment: string }) => {
    setSelectedSegments((prev) => ({ ...prev, [name]: segment }))
  }

  const handleChangeButtonClick = () => {
    const segments = Object.values(selectedSegments)

    dispatch(changeExperiments({ segments }))
    onCloseModal()
  }

  return (
    <Container>
      {EXPERIMENTS.map((experiment) => {
        const segments = makeAllSegmentsByExperimentName(experiment.name)
        if (!segments) return null

        return (
          <>
            <InfoWrapper>
              <Name>
                {'<'}
                {experiment.description}
                {'>'}
              </Name>
              <Prefix>name: {experiment.name}</Prefix>
            </InfoWrapper>
            <SegmentListWrapper>
              {segments.map((segment) => {
                const isSelected = selectedSegments[experiment.name] === segment
                const formattedSegmentLabel = getFormattedSegmentLabel({ segment })

                return (
                  <RadioLabel
                    key={segment}
                    onClick={() => handleSelectedSegments({ name: experiment.name, segment })}
                    isSelected={isSelected}>
                    {formattedSegmentLabel}
                  </RadioLabel>
                )
              })}
            </SegmentListWrapper>
          </>
        )
      })}
      <ButtonWrapper>
        <Button onClick={handleChangeButtonClick} priority="primary" size="medium">
          변경하기
        </Button>
      </ButtonWrapper>
    </Container>
  )
}

export default DevToolExperimentsModal

const Container = styled('div', {
  position: 'relative',
  minWidth: '320px',
  maxHeight: '80vh',
  padding: '16px',
  background: '$paperDefault-semantic',
  borderRadius: '6px',
  $scrollable: 'vertical',
  overflow: 'auto',
})

const InfoWrapper = styled('div', {
  width: '100%',
  marginBottom: '10px',
  paddingBottom: '10px',
  borderBottom: '2px solid $gray600',
})

const Name = styled('div', {
  $text: 'title2Bold',
  color: '$gray900',
  marginBottom: '8px',
})

const Prefix = styled('div', {
  $text: 'caption2Regular',
  color: '$gray600',
})

const ButtonWrapper = styled('div', {
  position: 'sticky',
  bottom: 0,
  left: 0,
})

const SegmentListWrapper = styled('div', {
  margin: '8px 0',
})

const getFormattedSegmentLabel = ({ segment }: { segment: string }) => {
  const { name, treatmentType } = parseSegment(segment)
  const experiment = EXPERIMENTS.find((exp) => exp.name === name)
  const description = experiment?.treatments.find((treatment) => treatment.group === treatmentType)?.description

  switch (true) {
    case isExcludeSegment(segment):
      return '실험 제외'
    case isControlSegment(segment):
      return '대조군'
    default:
      return `실험군 ${treatmentType}${description ? ` | ${description}` : ''}`
  }
}

const RadioLabel = ({
  onClick,
  isSelected,
  children,
}: {
  onClick: () => void
  isSelected: boolean
  children: React.ReactNode
}) => {
  return (
    <RadioLabelContainer onClick={onClick}>
      <LabelText>{children}</LabelText>
      <Radio isSelected={isSelected} />
    </RadioLabelContainer>
  )
}

const RadioLabelContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 0',
})

const LabelText = styled('div', {
  $text: 'caption1Regular',
})
