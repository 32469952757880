/**
 * @generated SignedSource<<3b4b44a572c54e3051eb095e4c8c4715>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserReceivedApplicantCommentReviewCountResolver$data = {
  readonly receivedHasCommentJobReviewsCountByApplicant: number;
  readonly " $fragmentType": "UserReceivedApplicantCommentReviewCountResolver";
};
export type UserReceivedApplicantCommentReviewCountResolver$key = {
  readonly " $data"?: UserReceivedApplicantCommentReviewCountResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserReceivedApplicantCommentReviewCountResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserReceivedApplicantCommentReviewCountResolver",
  "selections": [
    {
      "alias": "receivedHasCommentJobReviewsCountByApplicant",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "evaluator": "APPLICANT",
            "hasComment": true,
            "rating": [
              "GREAT",
              "GOOD"
            ]
          }
        }
      ],
      "kind": "ScalarField",
      "name": "receivedJobReviewsCount",
      "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"hasComment\":true,\"rating\":[\"GREAT\",\"GOOD\"]})"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "a298f0c19184a561a6295b67d3605db1";

export default node;
