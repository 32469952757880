import { throttle } from 'lodash'
import React from 'react'

import { usePaginationScrollElement } from './context'

type Props = {
  onPageChange: (page: number) => void
}

const PaginationRefHandler: React.FCC<Props> = ({ onPageChange }) => {
  const elementRef = usePaginationScrollElement()
  const prevPageRef = React.useRef(1)

  React.useEffect(() => {
    if (!elementRef.current) return

    const el = elementRef.current
    const paginationHandler = throttle(() => {
      const page = el.scrollLeft * 2 + el.offsetWidth >= el.scrollWidth ? 2 : 1
      if (page === prevPageRef.current) return
      prevPageRef.current = page
      onPageChange(page)
    }, 200)

    el.addEventListener('scroll', paginationHandler)

    return () => {
      el.removeEventListener('scroll', paginationHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPageChange])

  return null
}

export default PaginationRefHandler
