// @confluence 업로드시 에러 대응
type Dateable = string | number | Date

export type GenderType = 'FEMALE' | 'MALE' | 'NONE'
export type AgeType = 'TEENAGER' | 'TWENTY' | 'THIRTY' | 'FORTY' | 'FIFTY_OR_OLDER'
export type ExperiencePeriod =
  | 'LESS_THAN_A_MONTH'
  | 'LESS_THAN_THREE_MONTH'
  | 'LESS_THAN_SIX_MONTH'
  | 'MORE_THAN_SIX_MONTH_LESS_THAN_ONE_YEAR'
  | 'MORE_THAN_ONE_YEAR_LESS_THAN_TWO_YEAR'
  | 'MORE_THAN_TWO_YEAR_LESS_THAN_THREE_YEAR'
  | 'MORE_THAN_THREE_YEAR'
export type AutoAddedBy = 'DAANGN_MARKET' | 'GWANGJU_GWANGSAN'

export type ExperienceType = {
  isAutoAdded?: boolean
  autoAddedBy?: AutoAddedBy | '%future added value' | null
  workplaceName: string
  year?: number | null
  period?: ExperiencePeriod | '%future added value' | null
  content?: string | null
}

// 경력 관리기능 mutation 일관성 맞추기 전까지 임시 사용
export type ExperienceManageType = ExperienceType & {
  _id: string
  isAutoAdded: boolean
  createdAt: Dateable
  updatedAt: Dateable
}

export type CharacterType =
  | 'DILIGENCE'
  | 'KINDNESS'
  | 'THOROUGHNESS'
  | 'INITIATIVE'
  | 'PUNCTUALITY'
  | 'QUICK_LEARNER'
  | 'STAMINA'
  | 'AGILITY'
  | 'CLEAN'

export type ExtraFeatureType =
  | 'NON_SMOKER'
  | 'HEALTH_CERTIFICATE'
  | 'LONG_TERM_WORK_POSSIBLE'
  | 'CAR_LICENSE'
  | 'ENGINE_LICENSE'
  | 'NURSING_ASSISTANT_CERTIFICATE'
  | 'OWN_CAR'
  | 'MILITARY_SERVICE_COMPLETED_OR_EXEMPTED'
  | 'FLUENT_KOREAN'
  | 'COOKING_SKILLS'
  | 'COOKING_CERTIFICATE'
  | 'COMPUTER_SKILLS'
  | 'MARTIAL_ARTS_DAN'
  | 'PET_CARE_EXPERIENCE'
  | 'PARENTING_EXPERIENCE'

export type ResumeType = {
  name?: string | null
  name_first: string
  name_last: string
  phone?: string | null
  images: Image[]
  gender?: GenderType | null
  age?: AgeType | null
  birthYear?: number | null
  experiences: ExperienceType[]
  content?: string | null
  characters: CharacterType[]
  extraFeatures: ExtraFeatureType[]
  required?: boolean | null
  hasNotExperience?: boolean | null
}

export type ResumeServerInputs = {
  name: string
  phone: string
  images: string[]
  gender: GenderType
  age: AgeType
  birthYear: number
  experiences: {
    workplaceName: string
    year: number
    period: ExperiencePeriod
    content: string
    isAutoAdded?: boolean
    autoAddedBy?: AutoAddedBy
  }[]
  content: string
  characters: string[]
  extraFeatures: string[]
}

interface Image {
  id: string
  thumbnail: string
}

export enum ResumeFormType {
  REQUIRED = 'required',
  IMAGE = 'image',
  EXPERIENCE = 'experience',
  CONTENT = 'content',
  INITIAL = 'initial',
  CHARACTERS = 'characters',
  EXTRA_FEATURES = 'extraFeatures',
}
