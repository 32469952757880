/**
 * @generated SignedSource<<c4d09934e573aef10df8336d8e6914a7>>
 * @relayHash cc121cc059e84eab556796f2a3635fb6
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cc121cc059e84eab556796f2a3635fb6

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WatchKind = "JOB_POST" | "%future added value";
export type CreateWatchDataInput = {
  kind?: WatchKind | null | undefined;
  target: string;
};
export type useWatchMutationCreateWatchMutation$variables = {
  data: CreateWatchDataInput;
};
export type useWatchMutationCreateWatchMutation$data = {
  readonly createWatch: {
    readonly watchEdge: {
      readonly node: {
        readonly _id: string;
        readonly deleted: boolean;
        readonly kind: WatchKind | null | undefined;
        readonly target: {
          readonly _id?: string;
          readonly deleted?: boolean;
          readonly myWatch?: {
            readonly _id: string;
            readonly id: string;
          } | null | undefined;
        } | null | undefined;
      };
    };
  };
};
export type useWatchMutationCreateWatchMutation = {
  response: useWatchMutationCreateWatchMutation$data;
  variables: useWatchMutationCreateWatchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Watch",
      "kind": "LinkedField",
      "name": "myWatch",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useWatchMutationCreateWatchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateWatchResult",
        "kind": "LinkedField",
        "name": "createWatch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WatchEdge",
            "kind": "LinkedField",
            "name": "watchEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Watch",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "target",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useWatchMutationCreateWatchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateWatchResult",
        "kind": "LinkedField",
        "name": "createWatch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WatchEdge",
            "kind": "LinkedField",
            "name": "watchEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Watch",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "target",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "cc121cc059e84eab556796f2a3635fb6",
    "metadata": {},
    "name": "useWatchMutationCreateWatchMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "cbc840169e5dad917ba2b11581b3836d";

export default node;
