/**
 * @generated SignedSource<<588b19965ee4a0088b665c7e075bdaf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostFooterApplyButton_user$data = {
  readonly id: string;
  readonly " $fragmentType": "JobPostFooterApplyButton_user";
};
export type JobPostFooterApplyButton_user$key = {
  readonly " $data"?: JobPostFooterApplyButton_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostFooterApplyButton_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostFooterApplyButton_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "42797e0a2c54ee2f1f0b02df4c4035b9";

export default node;
