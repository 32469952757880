import Badge from '@src/components/common/badge/Badge'

type Props = {
  count: number
}

const ReviewCountBadge: React.FCC<Props> = ({ count, ...props }) => {
  return <Badge type="normal" size={'small'} bold={true} text={`후기 ${count}`} {...props} />
}

export default ReviewCountBadge
