import { IS_ALPHA, IS_PROD } from '@src/config'
import { RetryMiddlewareError } from '@src/graphql/middlewares/retry'
import { toast } from '@src/sdks/bridge'
import { captureMessage, captureException } from '@src/sdks/sentry'

const DEFAULT_MESSAGE = '알 수 없는 오류가 발생했어요.'
const NETWORK_ERROR_MESSAGE = '네트워크에 문제가 생겼어요. 네트워크 상태를 확인해주세요.'

const getMessageFromRelayError = (jobsErrorObject: any) => {
  // Todo: Network Error 전반적으로 개선이 필요함, 임시조치로 네트워크 연결 불량 시 해결
  const message =
    jobsErrorObject?.message ||
    jobsErrorObject?.res?.errors?.[0]?.message ||
    jobsErrorObject?.source?.errors?.[0]?.message ||
    DEFAULT_MESSAGE

  if (message.includes('Failed to fetch') || message.includes('Aborted in beforeRetry() callback')) {
    return NETWORK_ERROR_MESSAGE
  }

  return IS_PROD ? DEFAULT_MESSAGE : message
}

// Lagacy 향후 handleRelayErrors로 변경예정
export const handleRelayError = (jobsErrorObject: any, customMessage?: string) => {
  IS_ALPHA && console.error(jobsErrorObject)

  try {
    const code =
      jobsErrorObject?.res?.errors?.[0]?.extensions?.code ||
      jobsErrorObject?.source?.errors?.[0]?.extensions?.code ||
      '00000'
    const queryName = jobsErrorObject?.req?.operation.name || jobsErrorObject?.req?.id
    const message = getMessageFromRelayError(jobsErrorObject)

    captureMessage(`relay network error - ${queryName}`, { extra: jobsErrorObject })

    IS_PROD
      ? toast(`[오류코드 ${code}]: ${customMessage ?? message}`)
      : window.alert(`[오류코드 ${code}]: ${customMessage ?? message}`)
  } catch (err) {
    captureException(err)
  }
}

export const isNetworkError = (error: Error) => {
  const networkMessages = ['Failed to fetch', 'Aborted in beforeRetry() callback']
  return networkMessages.includes(error.message) || !window.navigator.onLine || error instanceof RetryMiddlewareError
}
