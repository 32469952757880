import { useTabFocusBoundary } from '@daangn/karrot-clothes'
import cn from 'classnames'
import React from 'react'

import { css, zIndices } from '@src/stitches/stitches.config'

interface Props {
  zIndex?: number
  className?: string
  open: boolean
  onClose?: () => void
  duration: number
  bodyClassName?: string
  overlayBackgroundColor?: string
  bodyScrollBlockOnEnter?: boolean
}

const ModalBaseInner: React.FCC<Props> = ({
  onClose,
  children,
  className,
  zIndex = zIndices.global1,
  duration,
  open,
  overlayBackgroundColor,
  bodyClassName,
  bodyScrollBlockOnEnter = false,
}) => {
  const overlayClicked = React.useRef(false)

  const handleMosueDown = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    overlayClicked.current = e.target === e.currentTarget
  }, [])

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (overlayClicked.current) {
      overlayClicked.current = false

      onClose?.()
    }
  }

  const handleCloseClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onClose?.()
  }

  const ref = useTabFocusBoundary()

  React.useEffect(() => {
    const onKeyDown = ({ key }: KeyboardEvent) => {
      if (key === 'Escape') onClose?.()
    }
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [onClose])

  React.useEffect(() => {
    if (open && bodyScrollBlockOnEnter && document.body) {
      document.body.style.overflow = 'hidden'

      return () => {
        document.body.style.overflow = ''
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const transition = `opacity ${duration}ms cubic-bezier(0.4, 0, 0.2, 1)`

  return (
    <div className={cn(modalBaseInner(), className)} style={{ zIndex }} role="dialog" ref={ref} onClick={handleClick}>
      <div
        className={overlay({ open })}
        style={{
          transition,
          msTransition: transition,
          MozTransition: transition,
          WebkitTransition: transition,
          backgroundColor: overlayBackgroundColor,
        }}
        onClick={handleCloseClick}
      />

      <div
        className={cn(cardContainer({ open }), bodyClassName)}
        style={{ transition, msTransition: transition, MozTransition: transition, WebkitTransition: transition }}
        onMouseDown={handleMosueDown}>
        {children}
      </div>
    </div>
  )
}

export default ModalBaseInner

const fullScreen = css({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
})

const modalBaseInner = css(fullScreen, { position: 'fixed' })

const overlay = css(fullScreen, {
  position: 'absolute',
  zIndex: '$negative',
  backgroundColor: '$overlayDim-semantic',
  WebkitTapHighlightColor: 'transparent',
  transition: `opacity 0ms cubic-bezier(0.4, 0, 0.2, 1)`,
  willChange: 'opacity',

  variants: {
    open: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },
  defaultVariants: {
    open: false,
  },
})

const cardContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  transition: `opacity 0ms cubic-bezier(0.4, 0, 0.2, 1)`,
  willChange: 'opacity',

  variants: {
    open: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },
  defaultVariants: {
    open: false,
  },
})
