import React from 'react'

import { JobPostStatus } from '@src/types/jobPost'

export const useJobPostStatus = (status?: JobPostStatus | null) => {
  return React.useMemo(() => {
    if (!status) return {}

    const isClosed = status === 'CLOSED'
    const isPending = status === 'PENDING'
    const isAccepted = status === 'ACCEPTED'
    const isDeleted = status === 'DELETED'
    const isRejected = status === 'REJECTED'
    const isReturned = status === 'RETURNED'
    const isActive = isAccepted
    const isLive = isAccepted || isClosed
    const isBlocked = !(isAccepted || isClosed || isPending)
    const statusText = isClosed
      ? '마감'
      : isPending
        ? '승인 대기중'
        : isRejected || isReturned
          ? '승인불가'
          : isDeleted
            ? '삭제됨'
            : null

    return { isClosed, isPending, isRejected, isReturned, isDeleted, isActive, isLive, isBlocked, statusText }
  }, [status])
}
