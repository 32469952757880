import React from 'react'

import createEventStorage, { EventStorage } from './storage'
import { Event } from './types'

interface EventContextValue<T extends Event> {
  eventStorage: EventStorage<T>
}

const EventManagerContext = React.createContext<EventContextValue<any>>({
  eventStorage: createEventStorage<any>({}),
})

export const useEventManagerContext = <T extends Event>() => React.useContext<EventContextValue<T>>(EventManagerContext)

type EventContextProviderProps<T extends Event> = {
  eventStorage: EventStorage<T>
  children: React.ReactNode
}

export function EventContextProvider<T extends Event>({ eventStorage, children }: EventContextProviderProps<T>) {
  return <EventManagerContext.Provider value={{ eventStorage }}>{children}</EventManagerContext.Provider>
}
