import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { SampleGroupKey, SAMPLE_GROUPS } from '@src/constants/experiment'
import { addAmplitudeSegments } from '@src/sdks/amplitude'
import { addMixpanelSegments } from '@src/sdks/mixpanel'
import { UserRole } from '@src/types/user'
import {
  initEventSampleGroupSeed,
  getSampleGroupSegmentsFromLocal,
  getSegmentsFromLocal,
  sampleGroupKeyToSegment,
  getSampleGroupSeed,
  parseSegment,
} from '@src/utils/experiment'

type SampleGroups = Record<SampleGroupKey, boolean>

interface InitialState {
  sampleGroups: SampleGroups
  segments: string[]
  isBigFont: boolean
}

const INITIAL_STATE: InitialState = {
  sampleGroups: {} as SampleGroups,
  segments: [],
  isBigFont: false,
}

export const initExperiments = createAsyncThunk<string[], string[]>('devTools/initExperiments', async (stateProps) => {
  const segments = stateProps
  return segments
})

export const initEventSampleGroup = createAsyncThunk<SampleGroups, { role: UserRole }>(
  'devTools/initEventSampleGroup',
  async ({ role }) => {
    initEventSampleGroupSeed()
    const groups = getSampleGroupSegmentsFromLocal({ role })
    const segments = getSegmentsFromLocal()
    addAmplitudeSegments([...segments, ...groups])
    addMixpanelSegments([...segments, ...groups])
    const sampleGroups = Object.keys(SAMPLE_GROUPS).reduce((acc, key) => {
      return {
        ...acc,
        [key]: groups.some((group) => group.endsWith(key.toLowerCase())),
      }
    }, {}) as SampleGroups

    return sampleGroups
  }
)

export const addEventSampleGroups = createAsyncThunk<
  Partial<Record<SampleGroupKey, boolean>>,
  { groups: Array<SampleGroupKey> }
>('devTools/addEventSampleGroup', async ({ groups }) => {
  const addGroups = groups.reduce((acc, group) => {
    const userSeed = initEventSampleGroupSeed()
    const sampleStandardSeed = getSampleGroupSeed(group)

    if (userSeed >= sampleStandardSeed) return acc
    return { ...acc, [group]: true }
  }, {})

  const sampleSegments = (Object.keys(addGroups) as Array<SampleGroupKey>).map(sampleGroupKeyToSegment)
  addAmplitudeSegments(sampleSegments)

  return addGroups
})

export const switchBigFont = createAsyncThunk<void, void>('devTools/switchBigFont', async () => {
  const width = parseInt(getComputedStyle(document.documentElement).width, 10)
  const fontSize = parseInt(getComputedStyle(document.documentElement).fontSize, 10)
  window.document.documentElement.style.fontSize = fontSize === 16 ? Math.round(width / 16) + 'px' : '16px'
})

const devToolSlice = createSlice({
  name: 'devTool',
  initialState: INITIAL_STATE,
  reducers: {
    changeExperiment: (state, action: PayloadAction<{ segment: string }>) => {
      const { segment } = action.payload
      const { name } = parseSegment(segment)
      const oldSegmentIdx = state.segments.findIndex((s) => parseSegment(s).name === name)
      state.segments = [...state.segments.slice(0, oldSegmentIdx), segment, ...state.segments.slice(oldSegmentIdx + 1)]
    },
    changeExperiments: (state, action: PayloadAction<{ segments: string[] }>) => {
      const { segments } = action.payload
      state.segments = segments
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initExperiments.fulfilled, (state, { payload }) => {
        state.segments = payload
        return state
      })
      .addCase(initEventSampleGroup.fulfilled, (state, { payload }) => {
        state.sampleGroups = payload
      })
      .addCase(addEventSampleGroups.fulfilled, (state, { payload }) => {
        state.sampleGroups = {
          ...state.sampleGroups,
          ...payload,
        }
        return state
      })
      .addCase(switchBigFont.fulfilled, (state) => {
        state.isBigFont = !state.isBigFont
      })
  },
})

export const { changeExperiments, changeExperiment } = devToolSlice.actions

export default devToolSlice.reducer
