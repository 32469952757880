import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@src/ducks/rootReducer'
import { AppInfo } from '@src/types/common'

export const userSelector = createSelector(
  (state: RootState) => state.user,
  (d) => d
)

// user 객체는 있지만, 로그인이 안된 케이스가 있어서 authToken으로 확인함
export const isLoginSelector = createSelector(userSelector, (d) => !!d?.authToken)

// TODO: userId 없는경우 근원적으로 튕겨내도록 처리해야함
export const userIdSelector = createSelector(userSelector, (d) => d?.id as number)
export const meNodeIdSelector = createSelector(userSelector, (d) => d?.nodeId ?? 'REDUX_NODEID_UNDEFINED')
export const phoneSelector = createSelector(userSelector, (d) => d?.phone)
export const authTokenSelector = createSelector(userSelector, (d) => d?.authToken)
export const nicknameSelector = createSelector(userSelector, (d) => d?.nickname)
export const birthYearSelector = createSelector(userSelector, (d) => d?.birthYear)
export const currentPositionSelector = createSelector(
  userSelector,
  (d) => d?.currentPosition as AppInfo['currentPosition']
)

export const userRoleSelector = createSelector(userSelector, (d) => d?.role ?? 'NONE')
export const targetExperienceSelector = createSelector(userSelector, (d) => d?.resumeExperience)
export const resettableDeviceIdSelector = createSelector(userSelector, (d) => d?.resettableDeviceId)
export const userInitialReferrerSelector = createSelector(userSelector, (d) => d?.initialReferrer)
export const userPaidAcquiredAuthorSelector = createSelector(userSelector, (d) => d?.paidAcquiredAuthor)
