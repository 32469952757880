import { JobsSdk } from '@daangn/jobs-sdk'

import { jobs } from '@src/api/api'
import { IS_DEV } from '@src/config'

import { bridge } from './bridge'

let userId: number | undefined

export const initJobsLog = (options: { userId?: number }) => {
  userId = options.userId
}

export const logJobsEvent = (body: { [key: string]: any }) => {
  const handledBody = {
    ...body,
    userId,
  }

  if (IS_DEV) {
    // eslint-disable-next-line no-console
    console.log(`jobs event called: ${JSON.stringify(handledBody)}`)
    return
  }

  if (!userId) {
    // eslint-disable-next-line no-console
    console.log('logJobsEvent is not initialized')
    return
  }

  jobs.log(handledBody)
}

export const jobsSdk = new JobsSdk(bridge)
