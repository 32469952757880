import { MapInstance, MapInstanceEvent } from '@daangn/maps'
import { Map as KarrotMap, Marker, Pin } from '@daangn/maps-react'
import { IconCopyRegular, IconLocationRegular } from '@seed-design/icon'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { useFragment, graphql } from 'react-relay'

import { KarrotMap_jobPost$key } from '@src/__generated__/KarrotMap_jobPost.graphql'
import Button from '@src/components/base/buttons/Button'
import Image from '@src/components/base/image/Image'
import IconTownSettingWalkFill from '@src/components/icons/TownSettingWalkFill'
import { IMAGE_POI_PIN } from '@src/constants/images'
import { setIsMapQuotaExceeded } from '@src/ducks/app'
import withSuspense from '@src/hocs/withSuspense'
import { useCalcWalkingDistance } from '@src/hooks/useCalcWalkingDistance'
import { useEnhancedRouter } from '@src/hooks/useEnhancedRouter'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { toast } from '@src/sdks/bridge'
import { captureMessage } from '@src/sdks/sentry'
import { currentPositionSelector } from '@src/selectors/user'
import { userSelectText } from '@src/stitches/action'
import { styled, theme } from '@src/stitches/stitches.config'
import { calcProtectedOffsetLocation } from '@src/utils/jobPost'
import { getMiddleDotStr } from '@src/utils/string'

import Subway from './Subway'

interface Props {
  jobPostRef: KarrotMap_jobPost$key
}

const KarrotMapContainer: React.FCC<Props> = ({ jobPostRef }) => {
  const dispatch = useDispatch()
  const mapRef = React.useRef<MapInstance | null>(null)
  const { push } = useEnhancedRouter()
  const { logClickEvent } = useLogAnalyticsEvent()
  const jobPost = useFragment(
    graphql`
      fragment KarrotMap_jobPost on JobPost {
        companyName
        workplaceRoadAddress
        workplaceLocation {
          lat
          lng
        }
        workplaceRegion {
          name1
          name2
          name3
        }
        ...Subway_jobPost
      }
    `,
    jobPostRef
  )

  const isMapVisible = !!jobPost.workplaceLocation

  const currentPosition = useSelector(currentPositionSelector)
  const isPersonalAuthor = !jobPost.companyName
  const safeRoadAddress = [
    jobPost.workplaceRegion?.name1,
    jobPost.workplaceRegion?.name2,
    jobPost.workplaceRegion?.name3,
  ]
    .filter((name) => !!name)
    .join(' ')

  const {
    isWalkingDistance,
    minutes: walkingMinutes,
    meter: walkingDistance,
  } = useCalcWalkingDistance({
    positions: [currentPosition?.position, jobPost.workplaceLocation],
  })

  const handleMapClick = () => {
    if (!jobPost.workplaceLocation || !jobPost.companyName) return
    const params = encodeURIComponent(
      `${safeRoadAddress},${jobPost.workplaceLocation.lat},${jobPost.workplaceLocation.lng}`
    )
    const url = `https://map.kakao.com/link/map/${params}`

    logClickEvent({
      name: 'map',
      params: {
        is_personal: !jobPost.companyName,
        is_walking_distance: isWalkingDistance,
      },
    })

    push({ remote: url, navbar: true })
  }

  const handleCopyAddressClick = () => {
    logClickEvent({
      name: 'copy_address',
    })
  }

  React.useEffect(() => {
    if (!jobPost.workplaceLocation) {
      captureMessage(`workplaceLocation does not exist`, { extra: { jobPost } })
      return
    }

    if (!mapRef.current) {
      return
    }

    const handleError = () => {
      dispatch(setIsMapQuotaExceeded(true))
    }

    mapRef.current.on('error', handleError)

    return () => {
      mapRef.current?.off('error', handleError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current])

  const handleOnLoad = ({ target }: { target: MapInstance }) => {
    mapRef.current = target
  }

  const mixedWorkplaceAddress = jobPost.workplaceRoadAddress
    ?.split(' ')
    .slice(1)
    .join(' ')
    .concat(jobPost.workplaceRegion?.name3 ? `(${jobPost.workplaceRegion.name3})` : '')

  return (
    <>
      {isMapVisible && (
        <MapContainer>
          {isWalkingDistance && currentPosition ? (
            <MultipleSpotMap
              currentPosition={{ lat: currentPosition.position.latitude, lng: currentPosition.position.longitude }}
              workplacePosition={jobPost.workplaceLocation}
              onMapClick={handleMapClick}
              onLoad={handleOnLoad}
              isPersonalAuthor={isPersonalAuthor}
              walkingDistance={walkingDistance}
            />
          ) : isPersonalAuthor ? (
            <SingleSpotSafeMap center={jobPost.workplaceLocation} onMapClick={handleMapClick} onLoad={handleOnLoad} />
          ) : (
            <SingleSpotMap center={jobPost.workplaceLocation} onMapClick={handleMapClick} onLoad={handleOnLoad} />
          )}
        </MapContainer>
      )}

      {isWalkingDistance && (
        <DistanceContainer>
          <WalkIconWrapper>
            <IconTownSettingWalkFill width={20} height={20} color={theme.colors.green500.computedValue} />
          </WalkIconWrapper>
          <MoveMinutes>{`걸어서 ${walkingMinutes}분 예상`}</MoveMinutes>
          <Dot>{getMiddleDotStr()}</Dot>
          <Distance>{`${walkingDistance}m`}</Distance>
        </DistanceContainer>
      )}

      <WorkplaceRoadAddressContainer>
        <WorkplaceRoadAddress>{isPersonalAuthor ? safeRoadAddress : mixedWorkplaceAddress}</WorkplaceRoadAddress>
        <CopyToClipboard
          text={isPersonalAuthor ? safeRoadAddress : jobPost.workplaceRoadAddress || ''}
          onCopy={() => toast('주소가 복사되었어요.')}>
          <CopyItem onClick={handleCopyAddressClick}>
            <IconCopyRegular width={16} height={16} color={theme.colors.blue800.computedValue} />
            <CopyKeyword>복사</CopyKeyword>
          </CopyItem>
        </CopyToClipboard>
      </WorkplaceRoadAddressContainer>

      <SubwayContainer>
        <Subway jobPostRef={jobPost} />
      </SubwayContainer>

      {!isMapVisible && (
        <LinkMapButton onClick={handleMapClick}>
          <IconLocationRegular width={16} height={16} color={theme.colors.gray900.computedValue} />
          <LinkMapButtonText>지도에서 위치보기</LinkMapButtonText>
        </LinkMapButton>
      )}
    </>
  )
}

export default withSuspense(React.memo(KarrotMapContainer))

type SingleSpotMapProps = {
  center: { lat: number; lng: number }
  onMapClick: () => void
  onLoad?: (event: MapInstanceEvent) => void
}

export const SingleSpotMap: React.FCC<SingleSpotMapProps> = ({ center, onMapClick, onLoad }) => {
  return (
    <MapWrapper onClick={onMapClick}>
      <Map
        key={`${center.lat}-${center.lng}`}
        height="short"
        initialMapState={{
          center: {
            lat: center.lat,
            lng: center.lng,
          },
          offsetByPixels: { y: 20 },
        }}
        onLoad={onLoad}
        interactive={false}
        module={false}>
        <Marker position={{ lat: center.lat, lng: center.lng }} anchor="bottom">
          <Pin type="pin-single-type" style={{ width: 36, height: 36 }} />
        </Marker>
      </Map>
    </MapWrapper>
  )
}

type SingleSpotSafeMapProps = {
  center: { lat: number; lng: number }
  onMapClick: () => void
  onLoad?: (event: MapInstanceEvent) => void
}

export const SingleSpotSafeMap: React.FCC<SingleSpotSafeMapProps> = ({ center: originCenter, onMapClick, onLoad }) => {
  const CIRCLE_RADIUS_IN_METERS = 86
  const CIRCLE_RADIUS = Number((CIRCLE_RADIUS_IN_METERS / 1000 / 111).toFixed(5))

  const center = React.useMemo(() => {
    return calcProtectedOffsetLocation({
      lat: originCenter.lat,
      lng: originCenter.lng,
      meterOffset: Math.floor(CIRCLE_RADIUS_IN_METERS * 0.5),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originCenter])

  return (
    <MapWrapper onClick={onMapClick}>
      <Map
        key={`${center.lat}-${center.lng}`}
        height="short"
        initialMapState={{
          center: {
            lat: center.lat,
            lng: center.lng,
          },
        }}
        onLoad={onLoad}
        interactive={false}
        module={false}>
        <Marker position={{ lat: center.lat - CIRCLE_RADIUS, lng: center.lng }} anchor="bottom">
          <CircleImage />
        </Marker>
      </Map>
    </MapWrapper>
  )
}

type MultipleSpotMapProps = {
  currentPosition: { lat: number; lng: number }
  workplacePosition: { lat: number; lng: number }
  onMapClick: () => void
  isPersonalAuthor: boolean
  onLoad?: (event: MapInstanceEvent) => void
  walkingDistance: number | null
}

const MultipleSpotMap: React.FCC<MultipleSpotMapProps> = ({
  currentPosition,
  workplacePosition,
  onMapClick,
  isPersonalAuthor,
  onLoad,
  walkingDistance,
}) => {
  const CIRCLE_RADIUS_IN_METERS = 86
  const CIRCLE_RADIUS = Number((CIRCLE_RADIUS_IN_METERS / 1000 / 111).toFixed(5))

  const safeMapCenter = React.useMemo(() => {
    return {
      lat: (currentPosition.lat + workplacePosition.lat) / 2,
      lng: (currentPosition.lng + workplacePosition.lng) / 2,
      meterOffset: Math.floor(CIRCLE_RADIUS_IN_METERS * 0.5),
    }
  }, [currentPosition, workplacePosition])

  const mapOriginCenter = React.useMemo(() => {
    return {
      lat: (currentPosition.lat + workplacePosition.lat) / 2,
      lng: (currentPosition.lng + workplacePosition.lng) / 2,
    }
  }, [currentPosition, workplacePosition])

  const center = isPersonalAuthor ? safeMapCenter : mapOriginCenter

  return (
    <MapWrapper onClick={onMapClick}>
      <Map
        height="long"
        initialMapState={{
          zoom: (walkingDistance ?? 0) <= 400 ? 15 : 14,
          center: {
            lat: center.lat,
            lng: center.lng,
          },
        }}
        onLoad={onLoad}
        module={false}
        interactive={false}>
        <Marker
          position={isPersonalAuthor ? { lat: center.lat - CIRCLE_RADIUS, lng: center.lng } : workplacePosition}
          anchor="bottom">
          {isPersonalAuthor ? <CircleImage /> : <IconImage source={IMAGE_POI_PIN.END} />}
        </Marker>
        <Marker
          position={{
            lat: currentPosition.lat - meterToRadius(46),
            lng: currentPosition.lng - meterToRadius(46),
          }}
          anchor="bottom">
          <IconImage source={IMAGE_POI_PIN.GPS} />
        </Marker>
      </Map>
    </MapWrapper>
  )
}

const meterToRadius = (meter: number) => {
  const meterToKilometer = (meter: number) => meter / 1000
  const kilometerToRadius = (kilometer: number) => kilometer / 111
  return Number(kilometerToRadius(meterToKilometer(meter)).toFixed(5))
}

const MapContainer = styled('div', {
  position: 'relative',
  margin: '0 0 16px',
})

const Map = styled(KarrotMap, {
  width: '100%',
  $border: '1px solid $grayAlpha50',
  borderRadius: '6px',

  variants: {
    height: {
      long: {
        height: '300px',
      },
      short: {
        height: '120px',
      },
    },
  },

  defaultVariants: {
    height: 'short',
  },
})

const MapWrapper = styled('div')

const WorkplaceRoadAddressContainer = styled('div', userSelectText, {
  $text: 'bodyM1Regular',
  display: 'flex',
  alignItems: 'center',
})

const WorkplaceRoadAddress = styled('p', {
  $text: 'label2Regular',
})

const CopyItem = styled('div', {
  display: 'flex',
  $text: 'label3Regular',
  color: '$blue800',
  alignItems: 'center',
  margin: '0 0 0 8px',
})

const CopyKeyword = styled('div', {
  margin: '0 0 0 2px',
})

const DistanceContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$gray600',
  $text: 'caption1Regular',
  margin: '0 0 8px',
})

const WalkIconWrapper = styled('span', {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '$green500',
})

const Dot = styled('span', {
  margin: '0 4px',
})

const Distance = styled('span')

const MoveMinutes = styled('span', {
  $text: 'caption1Bold',
  color: '$green500',
})

const LinkMapButton = styled(Button, {
  margin: '13px 0 0',
})

const LinkMapButtonText = styled('span', {
  $text: 'label3Regular',
  margin: '0 0 1px 4px',
})

const IconImage = styled(Image, {
  width: '46px',
  height: '46px',
})

const CircleImage = styled('div', {
  borderRadius: '50%',
  background: '$primaryLowHover-semantic',
  width: '86px',
  height: '86px',
})

const SubwayContainer = styled('div', {
  margin: '16px 0 0',

  '&:empty': {
    display: 'none',
  },
})
