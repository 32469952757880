/**
 * @generated SignedSource<<d9ada413a961c8f8f97c1a7ea4f71072>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApplicationStatus = "HIRED" | "IN_PROGRESS" | "REJECTED" | "WAITING" | "%future added value";
export type ConsideredQualification = "ADULT_ALL_AGES" | "BEGINNER" | "FOREIGNER" | "MINOR" | "%future added value";
export type Welfare = "ANNUAL_LEAVE" | "HOLIDAY_WORKING_ALLOWANCE" | "INSURANCE" | "MEAL_PROVIDED" | "PAID_VACATION" | "REST_TIME_GUARANTEE" | "RETIREMENT_BENEFIT" | "%future added value";
export type WorkPeriod = "LESS_THAN_A_MONTH" | "MORE_THAN_A_MONTH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobPostFooterApplyButton_jobPost$data = {
  readonly _id: string;
  readonly author: {
    readonly badge?: {
      readonly items: ReadonlyArray<{
        readonly __typename: "FastResponseBadge";
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      }>;
    } | null | undefined;
    readonly hoianUser?: {
      readonly _id: number;
    };
  };
  readonly bringUpCount: number;
  readonly closed: boolean;
  readonly companyName: string | null | undefined;
  readonly consideredQualifications: ReadonlyArray<ConsideredQualification> | null | undefined;
  readonly id: string;
  readonly myApplication: {
    readonly _id: string;
    readonly canceled: boolean;
    readonly createdAt: Dateable;
    readonly deleted: boolean;
    readonly status: ApplicationStatus;
    readonly viewedAt: Dateable | null | undefined;
  } | null | undefined;
  readonly publishedAt: Dateable | null | undefined;
  readonly title: string | null | undefined;
  readonly totalApplicationCountInfo: {
    readonly totalCount: number;
  };
  readonly watchCount: number;
  readonly welfare: ReadonlyArray<Welfare> | null | undefined;
  readonly workPeriod: WorkPeriod | null | undefined;
  readonly " $fragmentType": "JobPostFooterApplyButton_jobPost";
};
export type JobPostFooterApplyButton_jobPost$key = {
  readonly " $data"?: JobPostFooterApplyButton_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostFooterApplyButton_jobPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostFooterApplyButton_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "hoianUser",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AuthorBadge",
              "kind": "LinkedField",
              "name": "badge",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "items",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "type": "FastResponseBadge",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closed",
      "storageKey": null
    },
    {
      "alias": "totalApplicationCountInfo",
      "args": null,
      "concreteType": "JobPostApplicationCountInfo",
      "kind": "LinkedField",
      "name": "applicationCountInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Application",
      "kind": "LinkedField",
      "name": "myApplication",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canceled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deleted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bringUpCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "watchCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "welfare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consideredQualifications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};
})();

(node as any).hash = "2b78000dd58a424e8ec4124d82fea971";

export default node;
