import { parseToRgb, rgbToColorString } from 'polished'
import React from 'react'

import ColorBox from '@src/packages/falcon-react/components/ColorBox'
import CaptionLayout from '@src/packages/falcon-react/layouts/CaptionLayout'
import { findKarrotColorToken } from '@src/packages/falcon-react/utils/findKarrotColorToken'
import { styled, theme } from '@src/stitches/stitches.config'

type Props = {
  element: HTMLElement | SVGElement
}

const ExpressedContent: React.FCC<Props> = ({ element }) => {
  const { left, top } = element.getBoundingClientRect()
  const style = getComputedStyle(element)

  const padding = {
    top: parseInt(style.paddingTop, 10),
    right: parseInt(style.paddingRight, 10),
    bottom: parseInt(style.paddingBottom, 10),
    left: parseInt(style.paddingLeft, 10),
  }
  const color = findKarrotColorToken(rgbToColorString(parseToRgb(style.color)))
  const background = findKarrotColorToken(rgbToColorString(parseToRgb(style.backgroundColor)))
  const [width, height] =
    element instanceof SVGElement
      ? [parseInt(style.width, 10), parseInt(style.height, 10)]
      : [element.offsetWidth - padding.left - padding.right, element.offsetHeight - padding.top - padding.bottom]

  return (
    <>
      <Base
        style={{
          background: theme.colors['blueAlpha200'].computedValue,
          width: `${width}px`,
          height: `${height}px`,
          top: `${top + padding.top}px`,
          left: `${left + padding.left}px`,
        }}></Base>
      <CaptionLayout>
        <Size>{`content: ${width}x${height}px`}</Size>
        <Color style={{ margin: '0 4px 0 6px' }}>
          | color({color}) | bg({background})
        </Color>
        <ColorBox size={12} color={background} />
      </CaptionLayout>
    </>
  )
}

export default ExpressedContent

const Base = styled('div', {
  position: 'fixed',
})

const Color = styled('div', {})

const Size = styled('div', {})
