import React from 'react'

import GlobalLoaderOpen from '@src/components/base/loader/GlobalLoaderOpen'

export default <Props,>(Comp: React.FCC<Props>, FallbackComponent?: React.FCC<unknown> | null) => {
  const WrappedComponent: React.FCC<Props> = (props) => {
    return (
      <React.Suspense fallback={FallbackComponent ? <FallbackComponent /> : <GlobalLoaderOpen />}>
        <Comp {...props} />
      </React.Suspense>
    )
  }

  return WrappedComponent
}
