import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@src/ducks/rootReducer'

export const currentRegionSelector = createSelector(
  (state: RootState) => state.region.current,
  (d) => d
)

export const otherRegionSelector = createSelector(
  (state: RootState) => state.region.other,
  (d) => d
)

export const currentRegionIdSelector = createSelector(currentRegionSelector, (d) => d?.id)
export const currentRegionNameSelector = createSelector(currentRegionSelector, (d) => d?.name)
export const currentRegionCoordSelector = createSelector(currentRegionSelector, (d) => d?.centerCoordinates)
export const otherRegionIdSelector = createSelector(otherRegionSelector, (d) => d?.id)
