import { useMutation, UseMutationConfig, graphql } from 'react-relay'

import {
  useApplicationApplyCancelMutationMutation,
  useApplicationApplyCancelMutationMutation$variables,
} from '@src/__generated__/useApplicationApplyCancelMutationMutation.graphql'
import { useRelayConnectionHandler } from '@src/hooks/useRelayConnectionHandler'

type MutationParams = Omit<UseMutationConfig<useApplicationApplyCancelMutationMutation>, 'variables'> & {
  variables: Omit<useApplicationApplyCancelMutationMutation$variables, 'deleteConnections'>
}

export function useApplicationApplyCancelMutation() {
  const connectionHandler = useRelayConnectionHandler()

  const [mutate, isLoading] = useMutation<useApplicationApplyCancelMutationMutation>(graphql`
    mutation useApplicationApplyCancelMutationMutation(
      $where: CancelApplicationWhereInput!
      $deleteConnections: [ID!]!
    ) {
      cancelApplication(where: $where) {
        application {
          id @deleteEdge(connections: $deleteConnections)
          canceled
          viewedAt
          status
          resume {
            experiences {
              _id
              workplaceName
              year
              period
              content
              isAutoAdded
              autoAddedBy
            }
          }
          user {
            applicationCountInfo {
              # eslint-disable-next-line relay/unused-fields
              totalCount
              countByStatus {
                count
                status
              }
            }
            activityBadge {
              items {
                name
                count
              }
            }
          }
        }
      }
    }
  `)

  const mutateWithCallback = (config: MutationParams) => {
    if (isLoading) return

    const { variables, ...rest } = config

    mutate({
      variables: {
        ...variables,
        deleteConnections: [
          connectionHandler.MePostsApplicationsList_total_applications(),
          connectionHandler.MePostsApplicationsList_total_applications_opened(),
          connectionHandler.MePostsApplicationsList_waiting_applications(),
          connectionHandler.MePostsApplicationsList_waiting_applications_opened(),
          connectionHandler.MePostsApplicationsList_in_progress_applications(),
          connectionHandler.MePostsApplicationsList_in_progress_applications_opened(),
          connectionHandler.MePostsApplicationsList_hired_applications(),
          connectionHandler.MePostsApplicationsList_hired_applications_opened(),
          connectionHandler.MePostsApplicationsList_rejected_applications(),
          connectionHandler.MePostsApplicationsList_rejected_applications_opened(),
        ],
      },
      ...rest,
    })
  }

  return {
    isLoading,
    mutate,
    mutateWithCallback,
  }
}
