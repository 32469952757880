import React from 'react'

import { styled, theme } from '@src/stitches/stitches.config'

type Props = {
  element: HTMLElement
}

const ExpressedMargin: React.FCC<Props> = ({ element }) => {
  const style = getComputedStyle(element)
  const margin = {
    top: parseInt(style.marginTop, 10),
    right: parseInt(style.marginRight, 10),
    bottom: parseInt(style.marginBottom, 10),
    left: parseInt(style.marginLeft, 10),
  }

  const isNoMargin = Object.values(margin).every((value) => value === 0)
  if (isNoMargin) {
    return null
  }

  const { left, top } = element.getBoundingClientRect()

  return (
    <MarginBase
      style={{
        background: theme.colors['carrotAlpha200'].computedValue,
        width: `${element.offsetWidth + margin.left + margin.right}px`,
        height: `${element.offsetHeight + margin.top + margin.bottom}px`,
        top: `${top - margin.top}px`,
        left: `${left - margin.left}px`,
      }}>
      {margin.top > 0 && <MarginTop>{margin.top}</MarginTop>}
      {margin.left > 0 && <MarginLeft>{margin.left}</MarginLeft>}
      {margin.right > 0 && <MarginRight>{margin.right}</MarginRight>}
      {margin.bottom > 0 && <MarginBottom>{margin.bottom}</MarginBottom>}
    </MarginBase>
  )
}

export default ExpressedMargin

const MarginBase = styled('div', {
  position: 'fixed',
})

const MarginFlagStyle = {
  color: '$red700',
  fontSize: '10px',
  fontWeight: '700',
  position: 'absolute',
}

const MarginTop = styled('div', MarginFlagStyle, {
  left: '50%',
  top: '-6px',
  transform: 'translateX(-50%)',
})

const MarginBottom = styled('div', MarginFlagStyle, {
  left: '50%',
  bottom: '-6px',
  transform: 'translateX(-50%)',
})

const MarginRight = styled('div', MarginFlagStyle, {
  right: '-6px',
  top: '50%',
  transform: 'translateY(-6px)',
})

const MarginLeft = styled('div', MarginFlagStyle, {
  left: '-6px',
  top: '50%',
  transform: 'translateY(-6px)',
})
