import { identify, Identify, init, track } from '@amplitude/analytics-browser'

import { AMPLITUDE_JOBS_API_KEY, IS_LOG_ENABLED, VERSION } from '@src/config'

type TypeInitParams = Parameters<typeof init>
type TypeTrackPrams = Parameters<typeof track>

let initialized = false
let isUserIdentifiable = false

export const initAmplitude = async (userId: TypeInitParams['1'], options?: TypeInitParams['2']) => {
  // init을 실행하는 시점에 바로 initialized를 갱신해 주어야 함
  initialized = true
  await init(AMPLITUDE_JOBS_API_KEY, userId, options).promise

  if (userId) {
    isUserIdentifiable = true
  }
}

export const logAmplitudeEvent = (...args: TypeTrackPrams) => {
  if (!IS_LOG_ENABLED) {
    // eslint-disable-next-line no-console
    console.log(`amplitude event called: ${JSON.stringify(args)}`)
    return
  }
  if (!initialized) {
    // eslint-disable-next-line no-console
    console.log('Amplitude is not initialized')
    return
  }

  track(...args)
}

export const addAmplitudeSegments = (segments: string[]) => {
  if (!IS_LOG_ENABLED) {
    // eslint-disable-next-line no-console
    console.log(`amplitude segments added: ${segments}`)
    return
  }
  if (!initialized) {
    // eslint-disable-next-line no-console
    console.log('Amplitude is not initialized')
    return
  }
  if (!isUserIdentifiable) {
    // eslint-disable-next-line no-console
    console.log('User is not identifiable')
    return
  }

  const identifyObj = new Identify()
  identifyObj.set('segments', segments)
  identify(identifyObj, { app_version: VERSION })
}

export const addAmplitudeAcquisitions = (acquisitions: string[]) => {
  if (!IS_LOG_ENABLED) {
    // eslint-disable-next-line no-console
    console.log(`amplitude acquisitions added: ${acquisitions}`)
    return
  }
  if (!initialized) {
    // eslint-disable-next-line no-console
    console.log('Amplitude is not initialized')
    return
  }
  if (!isUserIdentifiable) {
    // eslint-disable-next-line no-console
    console.log('User is not identifiable')
    return
  }

  const identifyObj = new Identify()
  identifyObj.postInsert('acquisitions', acquisitions)
  identify(identifyObj, { app_version: VERSION })
}
