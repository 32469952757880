import { stringify } from 'querystring'

import { INVITE_EVENT_KIND_VALUES } from '@src/constants/invite'
import { INVITE_EVENT_KIND_REFERRER_MAP } from '@src/constants/referrer'

const addQueryParamsToPath = (path: string, additionalQueryParams: Record<string, string | undefined>) => {
  const [href, _querystring] = path.split('?')
  return `${href}?${_querystring ? `${_querystring}&` : ''}${stringify(additionalQueryParams)}`
}

export const generatePath = (path: string, params: Record<string, any>) => {
  let _path = path

  Object.entries(params).forEach(([key, _value]) => {
    const value = ['string', 'undefined'].includes(typeof _value)
      ? _value
      : Array.isArray(_value)
        ? _value.join(',')
        : JSON.stringify(_value)

    if (!value) return

    if (_path.includes(`:${key}`)) {
      _path = _path.replace(`:${key}`, value)
    } else {
      _path = addQueryParamsToPath(_path, { [key]: value })
    }
  })

  return _path
}

export function combinePathAndQueryString(path: string, queryString: string) {
  if (queryString) {
    const separator = path.includes('?') ? '&' : '?'
    return path + separator + queryString
  } else {
    return path
  }
}

export function objectToQueryString(obj: Record<string, any>) {
  return Object.keys(obj)
    .filter((key) => obj[key] !== undefined && obj[key] !== null)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    .join('&')
}

export const generateURL = (origin: string, path: string, params?: Record<string, any>) => {
  const _origin = origin.endsWith('/') ? origin.slice(0, -1) : origin
  const _path = path.startsWith('/') ? path.slice(1) : path
  const _querystring = objectToQueryString(params ?? {})
  return combinePathAndQueryString(`${_origin}/${_path}`, _querystring)
}

export const getExternalReferrer = () => {
  const pathName = window.location.pathname

  const externalMatch = /\/external\/(\w+)/.exec(pathName)
  if (externalMatch) {
    return externalMatch[1]
  }

  const inviteKind = [/\/invite-event\/(\w+)\/share/, /\/invite-event\/\w+\/(\w+)/].reduce((acc, exp) => {
    if (acc) return acc
    const match = exp.exec(pathName)
    return match?.[1] ?? ''
  }, '')
  if (inviteKind) {
    return INVITE_EVENT_KIND_REFERRER_MAP[inviteKind as INVITE_EVENT_KIND_VALUES]
  }

  return undefined
}
