import React from 'react'

import { ROUTE_EVENT } from '@src/constants/event'

type EventName = ROUTE_EVENT['type']
type EventPayload<T extends EventName> = Extract<ROUTE_EVENT, { type: T; payload?: any }>['payload']

type Subscription = {
  unsubscribe: () => void
}

type Callback<T> = (data: T) => void

type Event<T> = {
  subscribers: Callback<T>[]
}

type Events = Record<string, Event<any>>

type ContextState = {
  subscribe: <T extends EventName>(eventName: T, callback: Callback<EventPayload<T>>) => Subscription
  publish: <T extends EventName>(eventName: T, data: EventPayload<T>) => void
}

const EventContext = React.createContext<ContextState | null>(null)

export const EventContextProvider: React.FCC = ({ children }) => {
  const [events, setEvents] = React.useState<Events>({})

  const subscribe: ContextState['subscribe'] = (eventName, callback) => {
    const { subscribers } = events[eventName] ?? { subscribers: [] }
    const newSubscribers = [...subscribers, callback]
    setEvents((prevEvents) => ({
      ...prevEvents,
      [eventName]: {
        subscribers: newSubscribers,
      },
    }))

    return {
      unsubscribe: () => {
        setEvents((prevEvents) => ({
          ...prevEvents,
          [eventName]: {
            subscribers: prevEvents[eventName].subscribers.filter((cb: any) => cb !== callback),
          },
        }))
      },
    }
  }

  const publish: ContextState['publish'] = (eventName, data) => {
    const { subscribers } = events[eventName] ?? { subscribers: [] }
    subscribers.forEach((callback) => callback(data))
  }

  return <EventContext.Provider value={{ subscribe, publish }}>{children}</EventContext.Provider>
}

export const useEventPubSub = () => {
  const context = React.useContext(EventContext)

  if (!context) {
    throw new Error('usePubSub must be used within a EventContextProvider')
  }

  const { publish, subscribe } = context

  return { publish, subscribe }
}
