import {
  OPEN_INVITED_FAILURE,
  OPEN_INVITED_SUCCESS,
  OPEN_INVITE_SHARE,
  PURCHASE_APPLICATION_SLOT,
  RESET_FILTERS,
  SET_JOB_POST_FILTER_SUBSCRIPTION,
  SHOW_AUTHOR_SECTION,
  SHOW_EXPERIENCE_AUTO_ADDED_AT_RESUME_MANAGE,
  SHOW_POPULAR_AUTHOR_REVIEWS,
  SHOW_REVIEWS_RECEIVED_AT_JOB_POST,
} from '@src/constants/action'
import { workDayValues } from '@src/constants/form'
import { INVITE_EVENT_KIND } from '@src/constants/invite'
import { valueTypeGenerator } from '@src/packages/valueTypeGenerator/valueTypeGenerator'
import { AdvertisementAction, AdvertisementType } from '@src/types/advertisement'
import { ApplicationStatus } from '@src/types/application'
import { AuthorFormType } from '@src/types/author'
import { PostsFilterStatus } from '@src/types/filter'
import { RegionRange, workPeriods } from '@src/types/jobPost'
import { ResumeFormType } from '@src/types/resume'

import { JobsActivity } from './activityNames'
import { JobPostApplyPageNestedPage } from './routes'

const { String, Number, Enum, Array, Boolean } = valueTypeGenerator
const RouteMethod = valueTypeGenerator.Enum('create', 'update', 'delete', 'migrate')

export const activityParamsSchemas = {
  // ------------------------------- Common ------------------------------------
  [JobsActivity.MAIN]: {
    regionRange: Enum(...Object.values(RegionRange)).Optional,
    workPeriod: Enum(...workPeriods).Optional,
    workDays: Array(workDayValues).Optional,
    actions: Array([
      RESET_FILTERS,
      SHOW_POPULAR_AUTHOR_REVIEWS,
      OPEN_INVITE_SHARE,
      OPEN_INVITED_SUCCESS,
      OPEN_INVITED_FAILURE,
      SET_JOB_POST_FILTER_SUBSCRIPTION,
    ] as const).Optional,
    action_values: String.Optional,
  },
  [JobsActivity.MAIN_EXTERNAL]: {},
  [JobsActivity.SEARCH]: {
    keyword: String.Optional,
  },
  [JobsActivity.SEARCH_RESULT]: {
    keyword: String.Required,
    regionRange: Enum(...Object.values(RegionRange)).Optional,
    workPeriod: Enum(...workPeriods).Optional,
    actions: Array([RESET_FILTERS] as const).Optional,
  },
  [JobsActivity.REVIEW_WRITE]: {
    applicationId: String.Required,
    delayable: Boolean.Optional,
  },
  [JobsActivity.REVIEW_WRITTEN]: {
    applicationId: String.Required,
    closeable: Boolean.Optional,
  },
  [JobsActivity.REVIEW_RECEIVED]: {
    applicationId: String.Required,
  },
  [JobsActivity.INVITE_EVENT_INVITED]: {
    inviteEventCode: String.Required,
    inviteEventKind: Enum(...Object.values(INVITE_EVENT_KIND)).Required,
  },
  [JobsActivity.INVITE_EVENT_SHARE]: {
    inviteEventKind: Enum(...Object.values(INVITE_EVENT_KIND)).Required,
  },
  [JobsActivity.INVITE_EVENT_PRIZE_CHECK]: {
    campaignEntryId: String.Required,
    inviteEventKind: Enum(...Object.values(INVITE_EVENT_KIND)).Required,
  },
  [JobsActivity.ADVERTISEMENT_ACCOUNT_CREATE]: {
    mode: Enum('test_first_user').Optional,
  },
  [JobsActivity.CAMPAIGN]: {
    campaignKind: String.Optional,
    routeOption: Enum('direct').Optional,
    eventKind: Enum(
      'LGU_REWARD_202405',
      'MARKETING_CREATE_JOB_POST_202310',
      'MARKETING_TOTALCARE_202310',
      'MARKETING_TOTALCARE_REGULAR',
      'MARKETING_TOTALCARE_202403'
    ).Optional,
  },

  // ------------------------------- Applicant ---------------------------------
  [JobsActivity.JOB_POST]: {
    jobPostId: String.Required,
    actions: Array([SHOW_REVIEWS_RECEIVED_AT_JOB_POST, SHOW_AUTHOR_SECTION] as const).Optional,
  },
  [JobsActivity.JOB_POST_AD]: {
    jobPostId: String.Required,
  },
  [JobsActivity.JOB_POST_EXTERNAL]: {
    jobPostId: String.Required,
  },
  [JobsActivity.JOB_POST_APPLY]: {
    jobPostId: String.Required,
    advertisement: Boolean.Optional,
    page: Enum(...Object.values(JobPostApplyPageNestedPage)).Optional,
  },
  [JobsActivity.JOB_POST_APPLY_CONFIRM]: {
    jobPostId: String.Required,
  },
  [JobsActivity.JOB_POST_BUSINESS_CERTIFICATE]: {
    jobPostId: String.Required,
  },
  [JobsActivity.AUTHOR_JOB_POSTS]: {
    authorId: String.Required,
  },
  [JobsActivity.AUTHOR_REVIEWS_COMMENT]: {
    authorId: String.Required,
  },
  [JobsActivity.ME]: {
    tab: Enum('applications', 'favorites', 'activityBadge').Optional,
    mePostsFilter: Enum(...Object.values(PostsFilterStatus)).Optional,
  },
  [JobsActivity.RESUME_MANAGE]: {
    actions: Array([SHOW_EXPERIENCE_AUTO_ADDED_AT_RESUME_MANAGE] as const).Optional,
    targetId: String.Optional,
  },
  [JobsActivity.RESUME_UPDATE]: {
    type: Enum(...Object.values(ResumeFormType)).Required,
  },
  [JobsActivity.RESUME_CREATE_AND_APPLY]: {
    jobPostId: String.Required,
    advertisement: Boolean.Optional,
  },
  [JobsActivity.REGION_INFO]: {
    regionRange: Enum(...Object.values(RegionRange)).Optional,
  },
  [JobsActivity.RESUME_EXPERIENCE_ADD]: {
    method: RouteMethod.Optional,
    page: String.Optional,
    experience: String.Optional,
  },
  [JobsActivity.APPLICATION_CONFIRM_HIRED]: {
    applicationId: String.Required,
  },
  [JobsActivity.NEW_JOB_POST_FILTER_SUBSCRIPTION]: {
    jobPostId: String.Optional,
    workPeriods: Array(workPeriods).Optional,
  },
  [JobsActivity.COLLABORATION_RECRUITMENT]: {
    organizationName: String.Required,
  },
  [JobsActivity.FEED_EVENT_GROUP]: {
    eventGroup: String.Required,
  },

  // ------------------------------- Employer ----------------------------------
  [JobsActivity.BIZ_MAIN]: {
    tab: Enum('activeJobPosts', 'closedJobPosts').Optional,
  },
  [JobsActivity.BIZ_JOB_POST_MANAGE]: {
    readonly: Boolean.Optional,
    jobPostId: String.Required,
    actions: Array(Object.values(AdvertisementAction)).Optional,
    actionValues: String.Optional,
  },
  [JobsActivity.BIZ_JOB_POST_PREVIEW]: {
    jobPostId: String.Required,
  },
  [JobsActivity.BIZ_JOB_POST_CREATE]: {
    continue: Boolean.Optional,
    advertisement: String.Optional,
    page: Enum('preview', 'payment').Optional,
  },
  [JobsActivity.BIZ_JOB_POST_CREATE_PURCHASE]: {
    jobPostId: String.Required,
    useFreeOption: Boolean.Required,
  },
  [JobsActivity.BIZ_JOB_POST_CREATE_CONFIRM_REGISTER_FEE]: {
    jobPostId: String.Required,
    budget: Number.Required,
  },
  [JobsActivity.BIZ_JOB_POST_CREATE_CONFIRM_CAMPAIGN]: {
    campaignKind: Enum(
      'LGU_REWARD_202405',
      'MARKETING_CREATE_JOB_POST_202309',
      'MARKETING_CREATE_JOB_POST_202310',
      'MARKETING_TOTALCARE_REGULAR',
      'MARKETING_TOTALCARE_202403',
      'MARKETING_APPLICANT_WARRANTY_202406'
    ).Required,
  },
  [JobsActivity.BIZ_JOB_POST_MIGRATE]: {
    jobPostId: String.Required,
  },
  [JobsActivity.BIZ_JOB_POST_UPDATE]: {
    jobPostId: String.Required,
  },
  [JobsActivity.BIZ_JOB_POST_UPDATE_EXTEND]: {
    jobPostId: String.Required,
  },
  [JobsActivity.BIZ_JOB_POST_BRING_UP]: {
    jobPostId: String.Required,
  },
  [JobsActivity.BIZ_JOB_POST_ADVERTISEMENT]: {
    jobPostId: String.Required,
  },
  [JobsActivity.BIZ_JOB_POST_ADVERTISEMENT_TYPE_DETAIL]: {
    type: Enum(...Object.values(AdvertisementType)).Required,
  },
  [JobsActivity.BIZ_JOB_POST_ADVERTISEMENT_CREATE]: {
    jobPostId: String.Required,
  },
  [JobsActivity.BIZ_JOB_POST_ADVERTISEMENT_UPDATE]: {
    jobPostId: String.Required,
    advertisementId: String.Required,
    page: Enum('regions').Optional,
    modify: Enum('regionRange', 'exposeDates', 'budget').Optional,
  },
  [JobsActivity.BIZ_JOB_POST_ADVERTISEMENT_RESERVE]: {
    jobPostId: String.Required,
    page: Enum('regions').Optional,
  },
  [JobsActivity.BIZ_JOB_POST_ADVERTISEMENT_RESERVE_REQUEST_DONE]: {
    jobPostId: String.Required,
  },
  [JobsActivity.BIZ_JOB_POST_LARGE_SCALE_BUSINESS_GUIDE]: {
    jobPostId: String.Required,
    e: String.Optional,
  },
  [JobsActivity.BIZ_JOB_POST_SLOT_OVERFLOW]: {
    title: String.Optional,
  },
  [JobsActivity.BIZ_JOB_POST_SEARCH_ADDRESS]: {
    method: RouteMethod.Required,
  },
  [JobsActivity.BIZ_APPLICATIONS]: {
    jobPostId: String.Required,
    applicationsFilter: Enum(...Object.values(ApplicationStatus)).Optional,
    actions: Array([PURCHASE_APPLICATION_SLOT] as const).Optional,
  },
  [JobsActivity.BIZ_APPLICATION_DETAIL]: {
    applicationId: String.Required,
  },
  [JobsActivity.BIZ_APPLICATION_PURCHASE]: {
    jobPostId: String.Optional,
    applicationId: String.Optional,
  },
  [JobsActivity.BIZ_APPLICATION_REVIEWS_SELECTED]: {
    applicationId: String.Required,
  },
  [JobsActivity.BIZ_APPLICATION_REJECT]: {
    applicationId: String.Required,
    close: Boolean.Optional,
  },
  [JobsActivity.BIZ_APPLICATIONS_REJECT]: {
    jobPostId: String.Required,
    applicationId: String.Required,
  },
  [JobsActivity.BIZ_APPLICATIONS_SELECT]: {
    jobPostId: String.Required,
  },
  [JobsActivity.BIZ_APPLICATIONS_SELECT_CONFIRM]: {
    jobPostId: String.Required,
  },
  [JobsActivity.BIZ_IDENTIFICATION_CERTIFICATE]: {
    advertisement: String.Optional,
    jobPostId: String.Optional,
    e: String.Optional,
    page: Enum('verify', 'certificated').Optional,
    returnTo: Enum(...Object.values(JobsActivity)).Optional,
  },
  [JobsActivity.BIZ_BUSINESS_CERTIFICATE]: {
    advertisement: String.Optional,
    jobPostId: String.Optional,
    e: String.Optional,
  },
  [JobsActivity.BIZ_BUSINESS_CERTIFICATE_MANAGE]: {
    type: Enum('manage', 'renew', 'view').Optional,
  },
  [JobsActivity.BIZ_ADVERTISEMENT_CREATE]: {
    jobPostId: String.Optional,
    e: String.Optional,
    campaignFreeCash: String.Optional,
    select: String.Optional,
  },
  [JobsActivity.BIZ_BUSINESS_CERTIFICATE_ADD_COMPANY_NAME]: {
    advertisement: String.Optional,
    jobPostId: String.Optional,
    e: String.Optional,
  },
  [JobsActivity.BIZ_AUTHOR_UPDATE]: {
    type: Enum(...Object.values(AuthorFormType)).Required,
  },
  [JobsActivity.BIZ_JOB_POSTS]: {
    status: Enum('active', 'closed').Required,
  },
  // ------------------------------- External Halfview -------------------------
  [JobsActivity.BIZ_JOB_POST_STATUS_CHANGE]: {
    jobPostId: String.Required,
  },
  [JobsActivity.NOT_FOUND]: {
    resource: String.Optional,
  },

  // ------------------------------- Legacy Handling ---------------------------
  [JobsActivity.APPLICATIONS_MANAGE]: {
    tab: Enum('applications').Optional,
  },
  [JobsActivity.FAVORITES_MANAGE]: {
    tab: Enum('favorites').Optional,
  },
  [JobsActivity.APPLICATION_VIEW]: {
    applicationId: String.Required,
  },
}

export type ActivityParamsSchemaKey = keyof typeof activityParamsSchemas

type UndefinedKeys<T> = {
  [P in keyof T]: undefined extends T[P] ? P : never
}[keyof T]

type PartialUndefined<T> = Partial<Pick<T, UndefinedKeys<T>>> & Omit<T, UndefinedKeys<T>>

export type ActivityParamsSchema<T> = T extends keyof typeof activityParamsSchemas
  ? PartialUndefined<(typeof activityParamsSchemas)[T]> & { referrer?: string }
  : { referrer?: string }
