import Bowser from 'bowser'

const parser = Bowser.parse

export const getOsFromUserAgent = (userAgent: string) => {
  return parser(userAgent).os
}

export const isIOS = () => {
  return /iphone|ipad|ipod/i.test(window.navigator.userAgent.toLowerCase())
}
