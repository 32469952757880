/**
 * @generated SignedSource<<6b5519c0974112fc6feb8c59c781e77b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { receivedApplicantReviewCount as userReceivedApplicantReviewCountResolverType } from "../graphql/resolvers/UserReceivedApplicantReviewCount";
export type JobPostAuthorReputationTabs_jobPost$data = {
  readonly author: {
    readonly _jobPosts?: {
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    };
    readonly hoianUser?: {
      readonly receivedApplicantReviewCount: number | null | undefined;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"JobPostAuthorOtherPostTab_jobPost" | "JobPostAuthorReviewTab_jobPost">;
  readonly " $fragmentType": "JobPostAuthorReputationTabs_jobPost";
};
export type JobPostAuthorReputationTabs_jobPost$key = {
  readonly " $data"?: JobPostAuthorReputationTabs_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostAuthorReputationTabs_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostAuthorReputationTabs_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "_jobPosts",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "status": [
                      "ACCEPTED",
                      "CLOSED"
                    ]
                  }
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 1
                }
              ],
              "concreteType": "JobPostConnection",
              "kind": "LinkedField",
              "name": "jobPosts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "jobPosts(filter:{\"status\":[\"ACCEPTED\",\"CLOSED\"]},first:1)"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "hoianUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserReceivedApplicantReviewCountResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "receivedApplicantReviewCount",
                  "resolverModule": require('./../graphql/resolvers/UserReceivedApplicantReviewCount').receivedApplicantReviewCount,
                  "path": "author.hoianUser.receivedApplicantReviewCount"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobPostAuthorReviewTab_jobPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobPostAuthorOtherPostTab_jobPost"
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "3ed7aff9556898090360d4a5b672d9ef";

export default node;
