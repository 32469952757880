export function kstToISO(year: number, month: number, day: number, hour = 0, minute = 0, second = 0): Dateable {
  if (hour === 24) {
    hour = 0
    day++
  }
  const date = new Date(Date.UTC(year, month - 1, day, hour - 9, minute, second))
  return date.toISOString()
}

export const CURRENT_YEAR = new Date().getFullYear()

export const TOTAL_CARE_DATE = {
  START: '2023-11-22T15:00:00.000Z',
  END: '2024-04-30T15:00:00.000Z',
  PAYABLE: '2024-04-30T15:00:00.000Z',
  // disabled
  HALFVIEW_FLOW: '2024-05-31T15:00:00.000Z',
}

export const NEW_SEMESTER_202403_DATE = {
  // Invite Event
  APPLICANT_START: '2024-03-06T15:00:00.000Z',
  APPLICANT_END: '2024-03-28T15:00:00.000Z',
  AUTHOR_START: '2024-02-27T15:00:00.000Z',
  AUTHOR_END: '2024-03-19T15:00:00.000Z',
  // Total Care
  TOTAL_CARE_START: '2024-02-27T15:00:00.000Z',
  TOTAL_CARE_END: '2024-03-19T15:00:00.000Z',
  TOTAL_CARE_PAYABLE: '2024-03-26T15:00:00.000Z',
}

export const REGULAR_INVITE_DATE = {
  START: '2024-04-23T01:00:00.000Z',
  END: '2024-07-14T15:00:00.000Z',
}

export const BALANCE_GAME_DATE = {
  START: kstToISO(2024, 5, 20, 10),
  END: kstToISO(2024, 6, 10),
}

export const PROMOTION_202406_DATE = {
  AUTHOR_START: kstToISO(2024, 6, 17, 15),
  AUTHOR_END: kstToISO(2024, 6, 30, 23, 59, 59),
}

export const CAMPAIGN_BEWARE_ACCOUNT_HAJACKING_DATE = {
  START: kstToISO(2024, 5, 9, 9),
  END: kstToISO(2024, 5, 25),
}

export const GWANGSANGU_DATE = {
  START: kstToISO(2024, 5, 13, 9),
  END: null,
}
