import { keyframes, StitchesCSS } from './stitches.config'

export const bounce = keyframes({
  '0%': {
    transform: 'scale(0.05)',
  },
  '10%': {
    transform: 'scale(0.1)',
  },
  '20%': {
    transform: 'scale(0.2)',
  },
  '30%': {
    transform: 'scale(0.3)',
  },
  '40%': {
    transform: 'scale(0.4)',
  },
  '50%': {
    transform: 'scale(1.1)',
  },
  '60%': {
    transform: 'scale(1.2)',
  },
  '70%': {
    transform: 'scale(1.3)',
  },
  '80%': {
    transform: 'scale(1.2)',
  },
  '90%': {
    transform: 'scale(1.1)',
  },
  '100%': {
    transform: 'scale(1)',
  },
})

export const bounceAnimationStyle: StitchesCSS = {
  animationName: `${bounce}`,
  animationDuration: '300ms',
  animationFillMode: 'forwards',
  animationTimingFunction: 'ease-out',
}

export const fadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
})

export const fadeInAnimationStyle: StitchesCSS = {
  animationName: `${fadeIn}`,
  animationDuration: '1s',
  animationFillMode: 'forwards',
  animationTimingFunction: 'ease-out',
}
