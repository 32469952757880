/**
 * @generated SignedSource<<07ab89c265099e0c9c06a64d10097e5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type KarrotMap_jobPost$data = {
  readonly companyName: string | null | undefined;
  readonly workplaceLocation: {
    readonly lat: number;
    readonly lng: number;
  } | null | undefined;
  readonly workplaceRegion: {
    readonly name1: string | null | undefined;
    readonly name2: string | null | undefined;
    readonly name3: string | null | undefined;
  } | null | undefined;
  readonly workplaceRoadAddress: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Subway_jobPost">;
  readonly " $fragmentType": "KarrotMap_jobPost";
};
export type KarrotMap_jobPost$key = {
  readonly " $data"?: KarrotMap_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"KarrotMap_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KarrotMap_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workplaceRoadAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Point",
      "kind": "LinkedField",
      "name": "workplaceLocation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lng",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "workplaceRegion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name3",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Subway_jobPost"
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "38f355bab6fc2415a268a58c9b3d0c6a";

export default node;
