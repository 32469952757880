import React from 'react'

import { globalCss, theme } from './stitches/stitches.config'

/*
 ** --color-carrot500은 PullToRefresh loading indicator의 color 의존성 때문에 필요해요.
 ** Todo: stitches는 --colors-carrt500으로 선언하고 있어서 해결이 필요해요.
 */
const globalStyle = globalCss({
  body: {
    color: theme.colors.gray900,
    background: theme.colors['paperDefault-semantic'],
    '--color-carrot500': theme.colors.carrot500,
    '--color-gray900': theme.colors.gray900,
  },
  '#root > div': {
    '--kf_navigator_backgroundColor': theme.colors['paperDefault-semantic'],
    '--kf_navigator_navbar-iconColor': theme.colors.gray900,
    '--kf_navigator_navbar-borderColor': theme.colors.gray200,
    '--kf_navigator_navbar-center-textColor': theme.colors.gray900,
    '--kf_tabs_tabMain-backgroundColor': 'transparent',
    '--kf_tabs_tabBar-backgroundColor': theme.colors['paperDefault-semantic'],
    '--kf_tabs_tabBar-activeFontColor': theme.colors.gray900,
    '--kf_tabs_tabBar-indicator-color': theme.colors.gray900,
    '--kf_tabs_tabBar-borderColor': theme.colors['divider3-semantic'],
  },
  '@font-face': {
    fontFamily: 'karrotSans',
    src: `url(/KarrotSans230818-Bold.woff) format('woff')`,
  },
})

const GlobalStyle: React.FCC = () => {
  globalStyle()
  return null
}

export default GlobalStyle
