import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { JobPostStringifiedSalaryResolver$key } from '@src/__generated__/JobPostStringifiedSalaryResolver.graphql'
import { stringifySalary } from '@src/utils/jobPost'

/**
 *
 * @RelayResolver JobPost.stringifiedSalary: String
 * @rootFragment JobPostStringifiedSalaryResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function stringifiedSalary(jobPostRef: JobPostStringifiedSalaryResolver$key): string {
  const { salary, salaryType } = readFragment(
    graphql`
      fragment JobPostStringifiedSalaryResolver on JobPost {
        salary
        salaryType
      }
    `,
    jobPostRef
  )

  return stringifySalary({ salary, salaryType })
}
