import { vars } from '@seed-design/design-token'
import { AppScreen } from '@stackflow/plugin-basic-ui'
import React from 'react'

import GlobalLoaderOpen from '@src/components/base/loader/GlobalLoaderOpen'

import { CustomAppScreenContext, CustomAppScreenContextValue, CustomAppScreenState } from './CustomAppScreenContext'

interface Props extends CustomAppScreenState {
  children: React.ReactNode
}

const defaultAppScreenOptions = {
  backgroundColor: vars.$semantic.color.paperDefault,
  dimBackgroundColor: vars.$semantic.color.overlayDim,
  appBar: {},
} as const

const CustomAppScreen = ({ children, ...props }: Props) => {
  const [appScreenOptions, setAppScreenOptions] = React.useState<CustomAppScreenState>({
    ...defaultAppScreenOptions,
    ...props,
  })

  const value = React.useMemo<CustomAppScreenContextValue>(
    () => ({
      setAppScreenOptions,
    }),
    []
  )

  return (
    <AppScreen {...appScreenOptions}>
      <React.Suspense fallback={<GlobalLoaderOpen />}>
        <CustomAppScreenContext.Provider value={value}>{children}</CustomAppScreenContext.Provider>
      </React.Suspense>
    </AppScreen>
  )
}

export default CustomAppScreen
