import React from 'react'

import { TOGGLE_ICON_ID } from '@src/packages/falcon-react/components/ToggleIcon'

const useToggleClickActivation = (initialState = true) => {
  const [isClickActive, setIsClickActive] = React.useState<boolean>(initialState)

  React.useEffect(() => {
    const interceptClickEvent = (e: MouseEvent) => {
      if (!isClickActive) {
        const elementId = (e.target as HTMLElement).id
        if (elementId === TOGGLE_ICON_ID) {
          return
        }
        e.stopPropagation()
        e.preventDefault()
      }
    }

    document.addEventListener('click', interceptClickEvent, true)

    return () => {
      document.removeEventListener('click', interceptClickEvent, true)
    }
  }, [isClickActive])

  return {
    isActive: isClickActive,
    toggleActive: () => setIsClickActive((prev) => !prev),
  }
}

export default useToggleClickActivation
