import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { JobPostAuthorReputationTabs_jobPost$key } from '@src/__generated__/JobPostAuthorReputationTabs_jobPost.graphql'
import TouchTabs from '@src/components/base/tabs/TouchTabs'
import { styled } from '@src/stitches/stitches.config'

import JobPostAuthorOtherPostTab from './JobPostAuthorOtherPostTab'
import JobPostAuthorReviewTab from './JobPostAuthorReviewTab'

interface Props {
  jobPostRef: JobPostAuthorReputationTabs_jobPost$key
}

const JobPostAuthorReputationTabs: React.FCC<Props> = ({ jobPostRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment JobPostAuthorReputationTabs_jobPost on JobPost {
        author {
          ... on HoianUserAuthor {
            _jobPosts: jobPosts(first: 1, filter: { status: [ACCEPTED, CLOSED] }) {
              pageInfo {
                hasNextPage
              }
            }
            hoianUser {
              receivedApplicantReviewCount
            }
          }
        }
        ...JobPostAuthorReviewTab_jobPost
        ...JobPostAuthorOtherPostTab_jobPost
      }
    `,
    jobPostRef
  )

  const isAllTabsEmpty =
    !jobPost.author.hoianUser?.receivedApplicantReviewCount && !jobPost.author._jobPosts?.pageInfo.hasNextPage
  if (isAllTabsEmpty) return null

  return (
    <Container>
      <TouchTabs
        defaultTabIndex={jobPost.author.hoianUser?.receivedApplicantReviewCount ? 0 : 1}
        tabs={[
          {
            key: 'review',
            name: '후기',
            render() {
              return <JobPostAuthorReviewTab jobPostRef={jobPost} />
            },
          },
          {
            key: 'otherJobPosts',
            name: '다른 알바',
            render() {
              return <JobPostAuthorOtherPostTab jobPostRef={jobPost} />
            },
          },
        ]}
      />
    </Container>
  )
}

export default JobPostAuthorReputationTabs

const Container = styled('div', {
  position: 'relative',
  height: '100%',
  margin: '20px 0 0',
})
