import { useActivity, useActivityParams } from '@stackflow/react'
import React from 'react'

import { useNavigator } from '@src/hooks/useNavigator'

import { ActivityName } from './activityNames'
import { ActivityParamsSchema } from './activityParams'

interface Props<T extends ActivityName> {
  to: T
  params: ActivityParamsSchema<T>
  animate?: boolean
}

const Redirect = <T extends ActivityName>({ to, params, animate = false }: Props<T>) => {
  const { replace } = useNavigator()
  const { id, isRoot } = useActivity()
  const querystring = useActivityParams()

  React.useEffect(() => {
    // FIXME: https://daangn.slack.com/archives/C0223MK1NSZ/p1670222747322269
    if (isRoot) {
      replace(to, { ...querystring, ...params } as ActivityParamsSchema<T>, { animate })
    } else {
      replace(to, { ...querystring, ...params } as ActivityParamsSchema<T>, { animate, activityId: id })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Redirect
