import React from 'react'

interface Options {
  ref: React.RefObject<HTMLElement>
  callback: () => void
  threshold?: number
  disable?: boolean
  reverse?: boolean
}

export const useObserveShowElement = (
  { callback, ref, threshold = 1, disable = false, reverse = false }: Options,
  deps: React.DependencyList
) => {
  React.useEffect(() => {
    if (disable) return
    if (typeof IntersectionObserver === 'undefined') return
    if (ref.current) {
      const onIntersect = ([entry]: IntersectionObserverEntry[]) => {
        const isIntersecting = reverse ? !entry.isIntersecting : entry.isIntersecting

        if (isIntersecting) {
          callback()
        }
      }
      const observer = new IntersectionObserver(onIntersect, { threshold })
      observer.observe(ref.current)
      return () => observer.disconnect()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const useImpression = <R extends HTMLElement = HTMLElement>({
  onImpression,
  onImpressionExit,
  ref: paramRef,
  threshold = 1,
}: {
  onImpression: () => void
  /**
    onImpressionExit 주입 시 exit 이후 onImpression이 매번 실행 됨 
  */
  onImpressionExit?: () => void
  ref?: React.RefObject<R>
  threshold?: number
}) => {
  const _ref = React.useRef<R>(null)
  const hasAlreadyBeenShownRef = React.useRef(false)

  const ref = paramRef ?? _ref

  useObserveShowElement(
    {
      ref,
      callback: () => {
        if (hasAlreadyBeenShownRef.current) {
          return
        }

        onImpression()
        hasAlreadyBeenShownRef.current = true
      },
      threshold,
    },
    []
  )

  // onImpressionExit hook
  useObserveShowElement(
    {
      ref,
      callback: () => {
        if (!hasAlreadyBeenShownRef.current) {
          return
        }

        onImpressionExit?.()
        hasAlreadyBeenShownRef.current = false
      },
      threshold,
      disable: !onImpressionExit,
      reverse: true,
    },
    []
  )

  return ref
}
