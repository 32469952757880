import React from 'react'

import { calcJobPostExpired } from '@src/utils/jobPost'

export const useJobPostExpired = (jobPost: Parameters<typeof calcJobPostExpired>[0]) => {
  return React.useMemo(
    () => calcJobPostExpired(jobPost),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobPost.closed]
  )
}
