import { StackflowReactPlugin } from '@stackflow/react'

import { FloatingMessageProvider } from '@src/components/base/snackbar/FloatingMessage'
import { isPastDate } from '@src/utils/date'

import { EventContextProvider } from './context'
import createEventStorage from './storage'
import { ActionEvent } from './types'

export const eventManagerPlugin = (): StackflowReactPlugin => {
  const eventStorage = createEventStorage<ActionEvent>({})

  return () => ({
    key: 'eventManager',
    wrapStack({ stack }) {
      return (
        <EventContextProvider eventStorage={eventStorage}>
          <FloatingMessageProvider>{stack.render()}</FloatingMessageProvider>
        </EventContextProvider>
      )
    },
    wrapActivity({ activity }) {
      if (activity.isActive) {
        const events = eventStorage.pop(activity.name)
        events?.forEach((event) => {
          if (event.expiredAt && !isPastDate(event.expiredAt)) return
          event.action()
        })
      }

      return <>{activity.render()}</>
    },
  })
}
