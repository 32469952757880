/* eslint-disable no-restricted-imports */
import { valueTypeGenerator } from '../../packages/valueTypeGenerator/valueTypeGenerator'

export enum AnalyticsLogOption {
  MIXPANEL = 'mixpanel',
  MIXPANEL_OPTIONAL = 'mixpanelOptional',
  MIXPANEL_ONLY = 'mixpanelOnly',
  MIXPANEL_ONLY_OPTIONAL = 'mixpanelOnlyOptional',
  AMPLITUDE = 'amplitude',
  AMPLITUDE_OPTIONAL = 'amplitudeOptional',
  AMPLITUDE_ONLY = 'amplitudeOnly',
  AMPLITUDE_ONLY_OPTIONAL = 'amplitudeOnlyOptional',
  JOBS_LOG = 'jobsLog',
  JOBS_LOG_OPTIONAL = 'jobsLogOptional',
  JOBS_LOG_ONLY = 'jobsLogOnly',
  JOBS_LOG_ONLY_OPTIONAL = 'jobsLogOnlyOptional',
  BRAZE = 'braze',
}

export enum AnalyticsCategory {
  ACCOUNT = 'account',
  AD = 'advertisement',
  ALARM = 'alarm',
  APPLICATION = 'application',
  APPLY = 'apply',
  BADGE = 'badge',
  CERTIFICATION = 'certification',
  CONTACT = 'contact',
  EVENT = 'event',
  FILTER = 'filter',
  FAVORITE = 'favorite',
  HOME = 'home',
  HIRE = 'hire',
  MAP = 'map',
  MENU = 'menu',
  PROFILE = 'profile',
  RECOMMEND = 'recommend',
  RESUME = 'resume',
  REVIEW = 'review',
  SEARCH = 'search',
  SHARE = 'share',
  SLOT = 'slot',
  SURVEY = 'survey',
  JOB_POST = 'job_post',
  JOB_POST_CREATE = 'job_post_create',
  JOB_POST_UPDATE = 'job_post_update',
  JOB_POST_MANAGE = 'job_post_manage',
  ETC = 'etc',
  BM = 'BM',
}

const { String, Boolean, Number, Date, Enum: EnumGenerator, Array: ArrayGenerator, Any } = valueTypeGenerator

export const AnalyticsParamGenerator = {
  String,
  Boolean,
  Number,
  Date,
  Enum: EnumGenerator,
  Array: ArrayGenerator,
  Any,
  Empty: valueTypeGenerator.Empty,
  Role: EnumGenerator('JOB_POST_AUTHOR', 'APPLICANT'),
  Rating: EnumGenerator('GOOD', 'BAD', 'GREAT'),
  RegionRange: EnumGenerator('MY', 'ADJACENT', 'RANGE_2', 'RANGE_3', 'RANGE_4'),
  WorkPeriod: EnumGenerator('LESS_THAN_A_MONTH', 'MORE_THAN_A_MONTH', 'ALL', '%future added value'),
  WorkPeriods: ArrayGenerator(['LESS_THAN_A_MONTH', 'MORE_THAN_A_MONTH', '%future added value'] as const),
  SalaryType: EnumGenerator('DAILY', 'HOURLY', 'MONTHLY', 'PER_CASE', '%future added value'),
  BusinessType: EnumGenerator(
    'LARGE_SCALE_BUSINESS',
    'SMALL_BUSINESS',
    'FRANCHISE',
    'INDIVIDUAL',
    '%future added value'
  ),
  ApplicationStatusType: EnumGenerator('WAITING', 'IN_PROGRESS', 'HIRED', 'REJECTED', '%future added value'),
  WorkDays: ArrayGenerator(['MON', 'TUE', 'THU', 'WED', 'FRI', 'SAT', 'SUN'] as const),
  Characters: ArrayGenerator([
    'DILIGENCE',
    'KINDNESS',
    'THOROUGHNESS',
    'INITIATIVE',
    'PUNCTUALITY',
    'QUICK_LEARNER',
    'STAMINA',
    'AGILITY',
    'CLEAN',
  ] as const),
  ExtraFeatures: ArrayGenerator([
    'NON_SMOKER',
    'HEALTH_CERTIFICATE',
    'LONG_TERM_WORK_POSSIBLE',
    'CAR_LICENSE',
    'ENGINE_LICENSE',
    'NURSING_ASSISTANT_CERTIFICATE',
    'OWN_CAR',
    'MILITARY_SERVICE_COMPLETED_OR_EXEMPTED',
    'FLUENT_KOREAN',
    'COOKING_SKILLS',
    'COOKING_CERTIFICATE',
    'COMPUTER_SKILLS',
    'MARTIAL_ARTS_DAN',
    'PET_CARE_EXPERIENCE',
    'PARENTING_EXPERIENCE',
  ] as const),
  JobTasks: ArrayGenerator([
    'SERVING',
    'KITCHEN_ASSISTANCE',
    'KITCHEN_COOK',
    'CONVENIENCE_STORE',
    'SHOP_MANAGEMENT',
    'BEVERAGE_MAKING',
    'BAKING',
    'TUTORING',
    'LEAFLET_DISTRIBUTION',
    'CLEANING',
    'HOUSEWORK',
    'NURSING',
    'KIDS_PICK_UP',
    'LIGHT_WORK',
    'ETC',
  ]),
}

export type AnalyticsEvent = {
  category: AnalyticsCategory
  params?: Record<string, any>
  /** show인 경우 default false, click인 경우 default true */
  sample?: boolean
  logs?: readonly AnalyticsLogOption[]
  description: string
}

export type AnalyticsEvents = Record<string, AnalyticsEvent>

const Empty = {}
export type Empty = typeof Empty
