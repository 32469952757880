import React from 'react'

import { styled } from '@src/stitches/stitches.config'
import { textEllipsis } from '@src/stitches/text'

type Props = React.ComponentProps<typeof Base> & {
  label?: string
  description?: string
}

const BottomSheetItem: React.FCC<Props> = ({ label, description, ...props }) => {
  return (
    <Base {...props}>
      {label && <Label>{label}</Label>}
      {description && <Description>{description}</Description>}
    </Base>
  )
}

export default BottomSheetItem

const Base = styled('button', textEllipsis, {
  $text: 'bodyL1Regular',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '12px 0',

  '&:first-of-type': {
    borderTop: 'none',
  },

  '&:disabled': {
    color: '$gray400',
  },

  '&:active': {
    backgroundColor: '$grayAlpha50',
  },

  '@motion': {
    transition: 'background-color 200ms',
    willChange: 'background-color',
  },

  variants: {
    type: {
      warning: {
        color: '$danger-semantic',
      },
      info: {
        color: '$gray900',
      },
    },
  },

  defaultVariants: {
    type: 'info',
  },
})

const Label = styled('div')

const Description = styled('div', {
  margin: '0 0 0 6px',
  $text: 'caption1Regular',
})
