import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@src/ducks/rootReducer'

const jobPostSelector = createSelector(
  (state: RootState) => state.jobPost,
  (d) => d
)

export const isJobPostsStaleSelector = createSelector(jobPostSelector, (d) => d.isJobPostsStale)
export const unadvertisedJobPostIdSelector = createSelector(jobPostSelector, (d) => d?.unadvertisedJobPostId)
export const isJobPostWritableSelector = createSelector(jobPostSelector, (d) => d?.isJobPostWritable)
