import React from 'react'

export const HalfviewContext = React.createContext<{
  height: number
  getRenderedHeight: () => number | undefined
  setHeight: React.Dispatch<React.SetStateAction<number>>
}>({} as any)

export const useHalfviewContext = () => React.useContext(HalfviewContext)
export const useHalfviewSetHeight = () => React.useContext(HalfviewContext).setHeight
