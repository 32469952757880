import { VariantProps } from '@stitches/react'
import React from 'react'

import { styled } from '@src/stitches/stitches.config'

type Props = React.PropsWithChildren<VariantProps<typeof Container> & Omit<JSX.IntrinsicElements['div'], 'ref'>>

const BadgeSquare: React.FCC<Props> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>
}

export default BadgeSquare

const Container = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  whiteSpace: 'nowrap',

  variants: {
    type: {
      basic: {},
      primary: {},
      success: {},
      accent: {},
      error: {},
    },
    size: {
      large: {
        $text: 'bodyL2Regular',
        padding: '4px 8px',
      },
      medium: {
        $text: 'label5Regular',
        padding: '2px 6px',
      },
      small: {
        $text: 'label6Regular',
        padding: '1px 4px 1.5px',
      },
    },
    isBold: {
      true: {
        fontWeight: 'bold !important',
      },
      false: {},
    },
    isOutlined: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      type: 'basic',
      isBold: false,
      css: {
        color: '$gray700',
        background: '$gray100',
        border: '1px solid $gray100',
      },
    },
    {
      type: 'basic',
      isBold: true,
      isOutlined: false,
      css: {
        color: '$white-static',
        background: '$gray700',
        border: '1px solid $gray700',
      },
    },
    {
      type: 'basic',
      isBold: true,
      isOutlined: true,
      css: {
        color: '$gray900',
        border: '1px solid $gray900',
      },
    },
    {
      type: 'primary',
      isBold: false,
      isOutlined: true,
      css: {
        color: '$primary-semantic',
        background: '$carrotAlpha100',
        border: '1px solid $carrotAlpha100',
      },
    },
    {
      type: 'primary',
      isBold: false,
      isOutlined: false,
      css: {
        color: '$primary-semantic',
        background: '$carrotAlpha100',
      },
    },
    {
      type: 'primary',
      isBold: true,
      isOutlined: false,
      css: {
        color: '$onPrimary-semantic',
        background: '$primary-semantic',
        border: '1px solid $primary-semantic',
      },
    },
    {
      type: 'primary',
      isBold: true,
      isOutlined: true,
      css: {
        color: '$primary-semantic',
        border: '1px solid $primary-semantic',
      },
    },
    {
      type: 'success',
      css: {
        color: '$success-semantic',
        border: '1px solid $success-semantic',
      },
    },
    {
      type: 'success',
      isBold: false,
      css: {
        color: '$green700',
        background: '$successLow-semantic',
        border: '1px solid $successLow-semantic',
      },
    },
    {
      type: 'success',
      isBold: true,
      isOutlined: false,
      css: {
        color: '$white-static',
        background: '$success-semantic',
        border: '1px solid $success-semantic',
      },
    },
    {
      type: 'success',
      isBold: true,
      isOutlined: true,
      css: {
        color: '$success-semantic',
        border: '1px solid $success-semantic',
      },
    },
    {
      type: 'accent',
      isBold: false,
      css: {
        color: '$blue800',
        background: '$blue50',
        border: '1px solid $blue50',
      },
    },
    {
      type: 'accent',
      isBold: true,
      isOutlined: false,
      css: {
        color: '$white-static',
        background: '$accent-semantic',
        border: '1px solid $accent-semantic',
      },
    },
    {
      type: 'accent',
      isBold: true,
      isOutlined: true,
      css: {
        color: '$blue700',
        border: '1px solid $blue700',
      },
    },
    {
      type: 'error',
      isBold: false,
      css: {
        color: '$danger-semantic',
        background: '$dangerLow-semantic',
        border: '1px solid $dangerLow-semantic',
      },
    },
    {
      type: 'error',
      isBold: true,
      isOutlined: false,
      css: {
        color: '$white-static',
        background: '$danger-semantic',
        border: '1px solid $danger-semantic',
      },
    },
    {
      type: 'error',
      isBold: true,
      isOutlined: true,
      css: {
        color: '$danger-semantic',
        border: '1px solid $danger-semantic',
      },
    },
  ],
  defaultVariants: {
    type: 'basic',
    size: 'medium',
    isBold: false,
    isOutlined: false,
  },
})
