import { captureMessage } from '@src/sdks/sentry'

import Request from './Request'

export default class Jobs extends Request {
  constructor(baseUrl: string) {
    super(baseUrl)
    this.setPlantaeInterceptor()
    this.setTokenHeader()
    this.setUserAgentHeader()
    this.setRequestIdHeader()
  }

  public async log(body: { [key: string]: any }) {
    try {
      await this.post({
        url: '/api/event/jobs_events',
        data: {
          ...body,
        },
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      captureMessage('jobs log error', {
        extra: {
          err,
          body,
        },
      })
    }
  }
}
