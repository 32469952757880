/**
 * @generated SignedSource<<43edcc20f7a51ecf9ed2f740ac1c3c3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthorStats_jobPost$data = {
  readonly author: {
    readonly hoianUser?: {
      readonly author: {
        readonly applicationCount: {
          readonly hire: number;
        };
        readonly applicationResponse: {
          readonly rate: number | null | undefined;
        };
        readonly reviewCount: {
          readonly total: number;
        };
        readonly roleAssignedAt: Dateable | null | undefined;
      } | null | undefined;
      readonly promiseMinimumWage: {
        readonly lastReportedAt: Dateable | null | undefined;
        readonly promise: boolean | null | undefined;
      } | null | undefined;
    };
  };
  readonly businessLicense: {
    readonly __typename: "BusinessLicense";
  } | null | undefined;
  readonly identification: {
    readonly __typename: "Identification";
  } | null | undefined;
  readonly " $fragmentType": "AuthorStats_jobPost";
};
export type AuthorStats_jobPost$key = {
  readonly " $data"?: AuthorStats_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthorStats_jobPost">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthorStats_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessLicense",
      "kind": "LinkedField",
      "name": "businessLicense",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Identification",
      "kind": "LinkedField",
      "name": "identification",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "hoianUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PromiseMinimumWage",
                  "kind": "LinkedField",
                  "name": "promiseMinimumWage",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "promise",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastReportedAt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Author",
                  "kind": "LinkedField",
                  "name": "author",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AuthorApplicationCount",
                      "kind": "LinkedField",
                      "name": "applicationCount",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hire",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AuthorReviewCount",
                      "kind": "LinkedField",
                      "name": "reviewCount",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "total",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AuthorApplicationResponse",
                      "kind": "LinkedField",
                      "name": "applicationResponse",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rate",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "roleAssignedAt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "HoianUserAuthor",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};
})();

(node as any).hash = "f9f67ee0954a3152b075e8279c622a12";

export default node;
