import { CircularProgress } from '@daangn/karrot-clothes'
import React from 'react'

import { styled, keyframes, theme } from '@src/stitches/stitches.config'

type Props = {
  isOpen: boolean
  animationDurationMs?: number
  size?: number
} & Omit<JSX.IntrinsicElements['div'], 'ref'>

const InlineStyleLoader: React.FCC<Props> = ({ isOpen, animationDurationMs = 500, size = 14, ...props }) => {
  const [isOpenState, setIsOpenState] = React.useState(isOpen)

  React.useEffect(() => {
    if (!isOpen) {
      const timerId = setTimeout(() => {
        setIsOpenState(false)
      }, animationDurationMs)
      return () => {
        timerId && clearTimeout(timerId)
      }
    }
    setIsOpenState(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <>
      {isOpenState ? (
        <Container isOpen={isOpen} css={{ animationDurationMs: `${animationDurationMs}ms` }} {...props}>
          <CircularProgress size={size} color={theme.colors.gray900.computedValue} />
        </Container>
      ) : null}
    </>
  )
}

export default InlineStyleLoader

const easeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
})

const easeOut = keyframes({
  '0%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
})

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  animationFillMode: 'forwards',
  animationTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
  '&:empty': {
    padding: 0,
  },
  variants: {
    isOpen: {
      true: {
        animationName: `${easeIn}`,
      },
      false: {
        animationName: `${easeOut}`,
      },
    },
  },
  defaultVariants: {
    isOpen: true,
  },
})
