import React from 'react'
import { createPortal } from 'react-dom'

import ReplaceToMain from '@src/components/ReplaceToMain'
import { styled } from '@src/stitches/stitches.config'

const ReplaceToMainLayout: React.FCC = ({ children, ...props }) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [portal, setPortal] = React.useState<HTMLElement | null>(null)

  React.useEffect(() => {
    if (!containerRef.current) return
    const scrollContainer = findClosestScrollableChild(containerRef.current, 3)
    if (!scrollContainer) return

    const portalEl = document.createElement('div')
    scrollContainer.insertBefore(portalEl, scrollContainer.firstChild)
    setPortal(portalEl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Base {...props}>
      {portal && createPortal(<ReplaceToMain />, portal)}
      <BodyWrapper ref={containerRef}>{children}</BodyWrapper>
    </Base>
  )
}

export default ReplaceToMainLayout

const Base = styled('div', {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  overflow: 'hidden',
})

const BodyWrapper = styled('div', {
  height: '100%',
  overflow: 'hidden',
  overflowY: 'scroll',
  scrollBehavior: 'smooth',
})

function isScrollable(element: Element) {
  const style = getComputedStyle(element)
  const overflowY = style.overflowY
  const isScrollable = overflowY === 'scroll' || overflowY === 'auto'
  const canScroll = element.scrollHeight > element.clientHeight

  return isScrollable && canScroll
}

function findClosestScrollableChild(element: Element, maxDepth: number) {
  if (maxDepth < 0 || isScrollable(element)) {
    return isScrollable(element) ? element : null
  }

  const children = Array.from(element.children)
  for (const child of children) {
    const scrollableChild = findClosestScrollableChild(child, maxDepth - 1) as Element | undefined
    if (scrollableChild) {
      return scrollableChild
    }
  }

  return element
}
