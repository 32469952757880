import React from 'react'
import { useSelector } from 'react-redux'
import { graphql, useFragment } from 'react-relay'

import { JobPostFooterApplyButton_jobPost$key } from '@src/__generated__/JobPostFooterApplyButton_jobPost.graphql'
import { JobPostFooterApplyButton_user$key } from '@src/__generated__/JobPostFooterApplyButton_user.graphql'
import Button from '@src/components/base/buttons/Button'
import Dialog from '@src/components/base/dialog/Dialog'
import { useGetPageCodeInfo } from '@src/contexts/PageCodeInfoContext'
import { useGwangsanguUser } from '@src/hooks/useGwangsanguUser'
import useModalState from '@src/hooks/useModalState'
import { useNavigator } from '@src/hooks/useNavigator'
import { useApplicationApplyCancelMutation } from '@src/mutations/application/useApplicationApplyCancelMutation'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { JobsActivity } from '@src/router/activityNames'
import { toast } from '@src/sdks/bridge'
import { isLoginSelector } from '@src/selectors/user'
import { styled } from '@src/stitches/stitches.config'
import { calcApplicationStatus } from '@src/utils/application'
import { getPassedMinutes } from '@src/utils/date'
import { handleRelayError } from '@src/utils/error'
import { calcJobPostExpired, calcShortWorkPeriod } from '@src/utils/jobPost'

import JobPostGwangsanguHalfview from './JobPostGwangsanguHalfview'

interface Props {
  jobPostRef: JobPostFooterApplyButton_jobPost$key
  userRef: Nullable<JobPostFooterApplyButton_user$key>
}

const JobPostFooterApplyButton: React.FCC<Props> = ({ jobPostRef, userRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment JobPostFooterApplyButton_jobPost on JobPost {
        id
        _id
        title
        workPeriod
        author {
          ... on HoianUserAuthor {
            hoianUser {
              _id
            }
            badge {
              items {
                ... on FastResponseBadge {
                  __typename
                }
              }
            }
          }
        }
        # eslint-disable-next-line relay/unused-fields
        closed
        totalApplicationCountInfo: applicationCountInfo {
          totalCount
        }
        myApplication {
          _id
          canceled
          deleted
          status
          viewedAt
          createdAt
        }
        bringUpCount
        watchCount
        publishedAt
        welfare
        consideredQualifications
        companyName
      }
    `,
    jobPostRef
  )
  const user = useFragment(
    graphql`
      fragment JobPostFooterApplyButton_user on User {
        id
      }
    `,
    userRef
  )
  const { push } = useNavigator()
  const isLogin = useSelector(isLoginSelector)
  const { pageCode } = useGetPageCodeInfo()
  const { mutateWithCallback: cancelApply, isLoading: isApplyCancelLoading } = useApplicationApplyCancelMutation()
  const { isGwangsanguUser } = useGwangsanguUser(jobPost?.author.hoianUser?._id)
  const [isApplyCancelDialogOpen, setApplyCancelDialogOpen, setApplyCancelDialogClose] = useModalState()
  const [isGwangsanguHalfviewOpen, setIsGwangsanguHalfviewOpen] = React.useState(false)
  const { logClickEvent } = useLogAnalyticsEvent()
  const isApplied = !!jobPost.myApplication && !jobPost.myApplication.canceled && !jobPost.myApplication.deleted
  const isExpired = calcJobPostExpired(jobPost)
  const { isRejected } = calcApplicationStatus(jobPost.myApplication?.status)
  const isApplicable = !isApplied && !isExpired && !isRejected
  const jobPostId = jobPost.id
  const badges = jobPost?.author.badge?.items

  const showLoginMessage = () => {
    toast('해당기능을 사용하시려면 로그인이 필요해요')
  }

  const handleApplyClick = () => {
    if (isApplyCancelLoading) return

    if (!isLogin) {
      showLoginMessage()
      return
    }

    if (isApplied) {
      setApplyCancelDialogOpen()
      return
    }

    logClickEvent({
      name: 'apply_button',
      params: {
        job_post_id: jobPost?._id,
        job_post_title: jobPost?.title,
        is_first_apply: jobPost?.totalApplicationCountInfo.totalCount === 0,
        is_short_work_period: calcShortWorkPeriod(jobPost.workPeriod),
        badges: badges?.map((a) => a.__typename).join(',') || undefined,
        bring_up_count: jobPost?.bringUpCount,
        watch_count: jobPost?.watchCount,
        react_seconds: getPassedMinutes(jobPost?.publishedAt ?? Date.now()) * 60,
        application_count: jobPost?.totalApplicationCountInfo.totalCount ?? 0,
        welfare: jobPost?.welfare?.join(',') || undefined,
        considered_qualifications: jobPost?.consideredQualifications?.join(',') || undefined,
        is_personal: !jobPost?.companyName,
      },
    })

    push(
      JobsActivity.JOB_POST_APPLY,
      { jobPostId, advertisement: pageCode === JobsActivity.JOB_POST_AD },
      { reuseEntryParams: true }
    )
  }

  const handleApplyClickWithGwangsanguHandling = () => {
    if (!isApplied && isGwangsanguUser) {
      setIsGwangsanguHalfviewOpen(true)
      return
    }

    handleApplyClick()
  }

  const handleCancelApplicationClick = () => {
    const cancelApplication = () => {
      const myApplication = jobPost?.myApplication
      const userId = user?.id

      if (!myApplication || !userId) return

      cancelApply({
        variables: {
          where: {
            _id: myApplication._id,
          },
        },
        onCompleted: () => {
          toast('지원을 취소했어요.')
        },
        onError: (err) => {
          handleRelayError(err)
        },
      })
    }

    setApplyCancelDialogClose(() => {
      const isShortWorkPeriod = calcShortWorkPeriod(jobPost.workPeriod)

      cancelApplication()
      logClickEvent({
        name: 'apply_cancel',
        params: {
          job_post_id: jobPost?._id,
          is_short_work_period: isShortWorkPeriod,
          is_viewed: !!jobPost?.myApplication?.viewedAt,
          passed_minutes: getPassedMinutes(jobPost?.myApplication?.createdAt ?? Date.now()),
        },
      })
    })
  }

  return (
    <>
      {isApplicable ? (
        <StyledButton
          priority="primary"
          size="xlarge"
          onClick={handleApplyClickWithGwangsanguHandling}
          disabled={isExpired}>
          지원하기
        </StyledButton>
      ) : (
        <StyledButton
          priority="secondary"
          size="xlarge"
          onClick={handleApplyClickWithGwangsanguHandling}
          disabled={isExpired || isRejected}>
          {isExpired ? '구인마감' : isRejected ? '지원 불가' : '지원 취소'}
        </StyledButton>
      )}

      <Dialog
        open={isApplyCancelDialogOpen}
        onClose={setApplyCancelDialogClose}
        title="지원 취소할까요?"
        description="지원 취소하면 구인자가 내 지원서를 볼 수 없어요."
        buttons={[
          { label: '아니오', onClick: setApplyCancelDialogClose },
          { label: '예', onClick: handleCancelApplicationClick },
        ]}
      />

      <JobPostGwangsanguHalfview
        isOpen={isGwangsanguHalfviewOpen}
        onClose={() => setIsGwangsanguHalfviewOpen(false)}
        onConfirm={handleApplyClick}
      />
    </>
  )
}

export default JobPostFooterApplyButton

const StyledButton = styled(Button, {
  paddingLeft: 0,
  paddingRight: 0,

  [`&:nth-of-type(n+2)`]: {
    margin: '0 0 0 8px',
  },
})
