import { LazyLoadImage } from 'react-lazy-load-image-component'

import { styled } from '@src/stitches/stitches.config'
import { BaseImageSourcesType } from '@src/types/baseImage'

type ImageLoadingProps =
  | { width: number; height: number; radius?: number; lazyLoad: boolean }
  | { width?: never; height?: never; radius?: never; lazyLoad?: never }

type ImageDefaultProps = {
  border?: boolean
  onClick?: () => void
  alt?: string
  source: BaseImageSourcesType | string
}

type ImageProps = Omit<JSX.IntrinsicElements['img'], 'ref'> & ImageLoadingProps & ImageDefaultProps

const Image: React.FCC<ImageProps> = ({ border = false, alt, lazyLoad, radius, source, onClick, ...props }) => {
  const webpSrcSet = (source: BaseImageSourcesType) => {
    const { WEBP } = source
    if (WEBP) {
      return Object.entries(WEBP)
        .reduce((acc: string[], [key, value]) => {
          return acc.concat(`${value} ${key}`)
        }, [])
        .join(', ')
    }

    return ''
  }

  if (lazyLoad) {
    const radiusStyle = radius
      ? {
          ...(border ? { '&:after': { borderRadius: `${radius}px` } } : {}),
          '& img': {
            borderRadius: `${radius}px`,
          },
        }
      : {}

    return (
      <LazyLoadImageWrapper css={radiusStyle} border={border}>
        {typeof source === 'string' ? (
          <LazyLoadImage
            effect="opacity"
            width={props.width}
            height={props.height}
            src={source}
            alt={alt}
            onClick={onClick}
            {...props}
          />
        ) : (
          <picture>
            <source type="image/webp" srcSet={webpSrcSet(source)} />
            <LazyLoadImage
              effect="opacity"
              width={props.width}
              height={props.height}
              srcSet={webpSrcSet(source)}
              alt={alt}
              onClick={onClick}
              {...props}
            />
          </picture>
        )}
      </LazyLoadImageWrapper>
    )
  }

  return (
    <>
      {typeof source === 'string' ? (
        <StyledImg src={source} border={border} alt={alt} onClick={onClick} {...props} />
      ) : (
        <picture>
          <source type="image/webp" srcSet={webpSrcSet(source)} />
          <StyledImg srcSet={webpSrcSet(source)} border={border} alt={alt} onClick={onClick} {...props} />
        </picture>
      )}
    </>
  )
}

export default Image

const LazyLoadImageWrapper = styled('div', {
  lineHeight: 0,
  borderRadius: '6px',

  '& img': {
    objectFit: 'cover',
  },

  variants: {
    border: {
      true: {
        position: 'relative',
        '&:after': {
          position: 'absolute',
          display: 'block',
          content: '',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          border: '1px solid $grayAlpha50',
        },
      },
      false: {},
    },
  },
})

const StyledImg = styled('img', {
  variants: {
    border: {
      true: {
        boxShadow: '0 0 0 1px $grayAlpha50 inset',
      },
      false: {},
    },
  },
})
