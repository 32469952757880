import React from 'react'

import { theme } from '@src/stitches/stitches.config'
interface RadioProps {
  isSelected: boolean
  isDisabled?: boolean
}

const Radio: React.FCC<RadioProps> = ({ isSelected, isDisabled = false }) => {
  switch (true) {
    case isSelected && isDisabled:
      return (
        <RadioIcon
          outerCircleFill={theme.colors.gray200.computedValue}
          middleCircleFill={theme.colors['paperDefault-semantic'].computedValue}
          innerCircleFill={theme.colors.gray200.computedValue}
        />
      )
    case isSelected && !isDisabled:
      return (
        <RadioIcon
          outerCircleFill={theme.colors['primary-semantic'].computedValue}
          middleCircleFill={theme.colors['primary-semantic'].computedValue}
          innerCircleFill={theme.colors['onPrimary-semantic'].computedValue}
        />
      )
    case !isSelected && isDisabled:
      return (
        <RadioIcon
          outerCircleFill={theme.colors.gray300.computedValue}
          middleCircleFill={theme.colors.gray200.computedValue}
          innerCircleFill={theme.colors.gray200.computedValue}
        />
      )
    case !isSelected && !isDisabled:
      return (
        <RadioIcon
          outerCircleFill={theme.colors.gray500.computedValue}
          middleCircleFill={theme.colors['paperDefault-semantic'].computedValue}
          innerCircleFill={theme.colors['paperDefault-semantic'].computedValue}
        />
      )
    default:
      return null
  }
}

export default Radio

type IconProps = {
  width?: number
  height?: number
  outerCircleFill?: string
  middleCircleFill?: string
  innerCircleFill?: string
} & JSX.IntrinsicElements['svg']

const RadioIcon: React.FCC<IconProps> = ({
  width = 24,
  height = 24,
  outerCircleFill = 'var(--colors-white-static)',
  middleCircleFill = 'var(--colors-white-static)',
  innerCircleFill = 'var(--colors-white-static)',
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill={outerCircleFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
        fill={middleCircleFill}
      />
      <path
        d="M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
        fill={innerCircleFill}
      />
    </svg>
  )
}
