import React from 'react'

type Props = {
  width?: number
  height?: number
} & JSX.IntrinsicElements['svg']

const Manner01: React.FCC<Props> = ({ width = 24, height = 24, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FADD65"
      />
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="url(#paint0_linear_1769_31361)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.35018 18.75L7.06078 18.2677C6.79439 18.4275 6.70801 18.773 6.86784 19.0394C7.0276 19.3057 7.37285 19.3921 7.63918 19.2326L7.63888 19.2328L7.63859 19.2329L7.63858 19.2329C7.63862 19.2329 7.63882 19.2328 7.63919 19.2326L7.63959 19.2323L7.64519 19.2291L7.67596 19.2116C7.70469 19.1956 7.74963 19.1711 7.80978 19.1403C7.93012 19.0785 8.11089 18.9913 8.34396 18.8945C8.81084 18.7007 9.48303 18.4707 10.2966 18.3292C11.9188 18.047 14.0957 18.1168 16.3521 19.527C16.6155 19.6916 16.9625 19.6116 17.1272 19.3481C17.2918 19.0847 17.2117 18.7376 16.9483 18.573C14.4047 16.9832 11.9315 16.9029 10.1038 17.2208C9.19234 17.3793 8.43952 17.6368 7.91266 17.8555C7.64885 17.965 7.44055 18.0652 7.29606 18.1394C7.22378 18.1765 7.16735 18.2071 7.12782 18.2292C7.10805 18.2402 7.0925 18.2491 7.08128 18.2556L7.06773 18.2635L7.06342 18.2661L7.06189 18.267L7.06128 18.2674C7.06102 18.2675 7.06078 18.2677 7.35018 18.75Z"
        fill="#895F00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08361 12.3808C6.37037 12.2613 6.6997 12.3969 6.81918 12.6837C7.12729 13.4231 7.56192 13.6298 7.89242 13.6512C8.26608 13.6753 8.63943 13.4767 8.83192 13.188C9.00425 12.9295 9.35349 12.8597 9.61197 13.032C9.87045 13.2043 9.9403 13.5535 9.76798 13.812C9.36047 14.4233 8.60882 14.8247 7.81999 14.7738C6.98798 14.7202 6.22261 14.1769 5.78072 13.1163C5.66124 12.8296 5.79684 12.5003 6.08361 12.3808Z"
        fill="#895F00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9161 12.3808C18.2029 12.5003 18.3385 12.8296 18.219 13.1163C17.7771 14.1769 17.0117 14.7202 16.1797 14.7738C15.3909 14.8247 14.6392 14.4233 14.2317 13.812C14.0594 13.5535 14.1293 13.2043 14.3877 13.032C14.6462 12.8597 14.9955 12.9295 15.1678 13.188C15.3603 13.4767 15.7336 13.6753 16.1073 13.6512C16.4378 13.6298 16.8724 13.4231 17.1805 12.6837C17.3 12.3969 17.6294 12.2613 17.9161 12.3808Z"
        fill="#895F00"
      />
      <path
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5738 9.26105C17.6782 9.43994 17.6177 9.66956 17.4389 9.77391L13.8389 11.8739C13.7229 11.9416 13.5796 11.9421 13.4631 11.8752C13.3467 11.8083 13.2749 11.6843 13.2749 11.55V10.2C13.2749 9.99289 13.4428 9.825 13.6499 9.825C13.857 9.825 14.0249 9.99289 14.0249 10.2V10.8971L17.061 9.12608C17.2398 9.02172 17.4695 9.08215 17.5738 9.26105Z"
        fill="#C69238"
      />
      <path
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43007 9.25445C6.53807 9.07773 6.76888 9.02201 6.9456 9.13001L6.55451 9.76997C6.37779 9.66197 6.32208 9.43117 6.43007 9.25445ZM6.55451 9.76997L6.67289 9.84212C6.74869 9.88825 6.85794 9.95461 6.99182 10.0356C7.25953 10.1977 7.62598 10.4185 8.02075 10.6535C8.8046 11.1201 9.71748 11.653 10.1823 11.8854C10.3676 11.978 10.5928 11.9029 10.6855 11.7177C10.7781 11.5325 10.703 11.3072 10.5178 11.2146C10.0826 10.997 9.19551 10.4799 8.40436 10.009C8.01163 9.77523 7.64683 9.55542 7.38017 9.39402C7.24686 9.31333 7.13813 9.24728 7.06277 9.20142L6.9456 9.13001L6.55451 9.76997Z"
        fill="#C69238"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1769_31361"
          x1="11.9676"
          y1="-1.49475"
          x2="11.9676"
          y2="16.4565"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F61FF" stopOpacity="0.5" />
          <stop offset="0.0185834" stopColor="#4565F9" stopOpacity="0.5117" />
          <stop offset="0.2298" stopColor="#878DB4" stopOpacity="0.6446" />
          <stop offset="0.4206" stopColor="#BAAD7E" stopOpacity="0.7647" />
          <stop offset="0.5841" stopColor="#DEC76B" stopOpacity="0.8676" />
          <stop offset="0.7142" stopColor="#F4D865" stopOpacity="0.9495" />
          <stop offset="0.7945" stopColor="#FADD65" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Manner01
