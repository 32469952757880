import * as vars from '@seed-design/design-token'

// Apdapter by.Tim
type LooseValueType<T> = T extends object
  ? { readonly [K in keyof T]: LooseValueType<T[K]> }
  : T extends string
    ? string
    : never

type TokenObject = LooseValueType<typeof vars>['vars']

type Theme = NewColorTheme

type NewColorTheme = Record<Color, string>

export type Color = SemanticColor | ScaleColor | StaticColor

type RemovePrefix<T extends string, K> = K extends `${T}${infer U}` ? U : K

type SemanticColor = `${keyof TokenObject['$semantic']['color']}-semantic`
type ScaleColor = keyof TokenObject['$scale']['color']
type StaticColor = `${Uncapitalize<RemovePrefix<'static', keyof TokenObject['$static']['color']>>}-static`

const uncapitalize = (str: string) => str[0].toLowerCase() + str.slice(1)

export function colorThemeAdapter(tokenObject: TokenObject): Theme {
  const colors: any = {}
  for (const [key, value] of Object.entries(tokenObject.$static.color)) {
    colors[`${uncapitalize(key.substring('static'.length))}-static`] = value
  }
  for (const [key, value] of Object.entries(tokenObject.$scale.color)) {
    colors[key] = value
  }
  for (const [key, value] of Object.entries(tokenObject.$semantic.color)) {
    colors[`${key}-semantic`] = value
  }

  return {
    ...colors,
  } as NewColorTheme
}
