import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { CountStatus_jobPost$key } from '@src/__generated__/CountStatus_jobPost.graphql'
import TagGroup from '@src/components/base/tag/TagGroup'

interface Props {
  jobPostRef: CountStatus_jobPost$key
}

const CountStatus: React.FCC<Props> = ({ jobPostRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment CountStatus_jobPost on JobPost {
        viewCount
        watchCount
      }
    `,
    jobPostRef
  )

  return (
    <TagGroup
      size="medium"
      items={[{ visible: jobPost.watchCount > 0, value: `관심 ${jobPost.watchCount}` }, `조회 ${jobPost.viewCount}`]}
    />
  )
}

export default CountStatus
