import { useMutation, graphql } from 'react-relay'

import { useDevRemoveResumeMutation } from '@src/__generated__/useDevRemoveResumeMutation.graphql'

export default () => {
  return useMutation<useDevRemoveResumeMutation>(graphql`
    mutation useDevRemoveResumeMutation {
      deleteResume {
        resume {
          id
        }
      }
    }
  `)
}
