/**
 * @generated SignedSource<<18668a6616dc5abe75e5f351010f93dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WorkPeriod = "LESS_THAN_A_MONTH" | "MORE_THAN_A_MONTH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InfoWorkDatesCalendar_jobPost$data = {
  readonly workDates: ReadonlyArray<Dateable> | null | undefined;
  readonly workPeriod: WorkPeriod | null | undefined;
  readonly " $fragmentType": "InfoWorkDatesCalendar_jobPost";
};
export type InfoWorkDatesCalendar_jobPost$key = {
  readonly " $data"?: InfoWorkDatesCalendar_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"InfoWorkDatesCalendar_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InfoWorkDatesCalendar_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workDates",
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "fdc604c7758d5630c60e8bb1e1a0750d";

export default node;
