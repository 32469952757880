import React from 'react'

import Button from '@src/components/base/buttons/Button'
import { styled } from '@src/stitches/stitches.config'

interface Props {
  placeholder?: string
  onSubmit: (value: string) => void
}

const DevToolInputModal: React.FCC<Props> = ({ placeholder, onSubmit }) => {
  const [inputValue, setInputValue] = React.useState('')

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    onSubmit(inputValue)
  }

  return (
    <Container onSubmit={handleSubmit}>
      <Input type="url" value={inputValue} placeholder={placeholder} onChange={(e) => setInputValue(e.target.value)} />
      <Button priority="primary" size="medium" type="submit">
        확인
      </Button>
    </Container>
  )
}

export default DevToolInputModal

const Container = styled('form', {
  padding: '16px',
  background: '$paperDefault-semantic',
  borderRadius: '6px',
})

const Input = styled('input', {
  $text: 'bodyL2Regular',
  display: 'block',
  margin: '0 0 10px',
  padding: '10px',
  width: '100%',
  minWidth: '250px',
  border: '1px solid $gray400',
  borderRadius: '6px',
  color: '$gray900',
  background: '$paperDefault-semantic',
  resize: 'none',

  '&::placeholder': {
    color: '$gray600',
  },

  '&:focus': {
    '&::placeholder': {
      color: 'transparent',
    },
  },
})
