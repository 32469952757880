import { parseToRgb, rgbToColorString } from 'polished'
import React from 'react'

import ColorBox from '@src/packages/falcon-react/components/ColorBox'
import CaptionLayout from '@src/packages/falcon-react/layouts/CaptionLayout'
import { findKarrotColorToken } from '@src/packages/falcon-react/utils/findKarrotColorToken'
import { styled, theme } from '@src/stitches/stitches.config'

type Props = {
  element: HTMLElement
}

const ExpressedBorder: React.FCC<Props> = ({ element }) => {
  const style = getComputedStyle(element)
  const borderWidth = parseInt(style.borderWidth, 0)
  const borderColor = style.borderColor
  const borderTopRightRadius = parseInt(style.borderTopRightRadius, 10)
  const borderBottomRightRadius = parseInt(style.borderBottomRightRadius, 10)
  const borderBottomLeftRadius = parseInt(style.borderBottomLeftRadius, 10)
  const borderTopLeftRadius = parseInt(style.borderTopLeftRadius, 10)
  const { left, top } = element.getBoundingClientRect()
  const hasBorder = borderWidth > 0
  const hasRadius = [borderTopRightRadius, borderBottomRightRadius, borderBottomLeftRadius, borderTopLeftRadius].some(
    (value) => value > 0
  )

  if (!hasBorder && !hasRadius) {
    return null
  }

  return (
    <>
      <RadiusBase
        style={{
          background: theme.colors['blueAlpha200'].computedValue,
          width: `${element.offsetWidth}px`,
          height: `${element.offsetHeight}px`,
          top: `${top}px`,
          left: `${left}px`,
        }}>
        {borderTopLeftRadius > 0 && <RadiusTopLeft>{borderTopLeftRadius}</RadiusTopLeft>}
        {borderTopRightRadius > 0 && <RadiusTopRight>{borderTopRightRadius}</RadiusTopRight>}
        {borderBottomLeftRadius > 0 && <RadiusBottomLeft>{borderBottomLeftRadius}</RadiusBottomLeft>}
        {borderBottomRightRadius > 0 && <RadiusBottomRight>{borderBottomRightRadius}</RadiusBottomRight>}
      </RadiusBase>
      {borderWidth > 0 && (
        <CaptionLayout>
          <BorderDescription>{`border: ${borderWidth}px ${findKarrotColorToken(
            rgbToColorString(parseToRgb(borderColor))
          )}`}</BorderDescription>
          <ColorBox size={12} color={rgbToColorString(parseToRgb(borderColor))} />
        </CaptionLayout>
      )}
    </>
  )
}

export default ExpressedBorder

const RadiusBase = styled('div', {
  position: 'fixed',
})

const BorderDescription = styled('div', {
  margin: '0 4px 0 0',
})

const RadiusFlagStyle = {
  color: '$blue700',
  fontSize: '10px',
  fontWeight: '700',
  position: 'absolute',
}

const RadiusTopLeft = styled('div', RadiusFlagStyle, {
  left: '-5px',
  top: '-5px',
})

const RadiusTopRight = styled('div', RadiusFlagStyle, {
  right: '-5px',
  top: '-5px',
})

const RadiusBottomLeft = styled('div', RadiusFlagStyle, {
  left: '-5px',
  bottom: '-5px',
})

const RadiusBottomRight = styled('div', RadiusFlagStyle, {
  right: '-5px',
  bottom: '-5px',
})
