import Request from './Request'

export default class Identification extends Request {
  constructor(baseUrl: string) {
    super(baseUrl)
    this.setPlantaeInterceptor()
    this.setTokenHeader()
    this.setUserAgentHeader()
    this.setRequestIdHeader()
  }

  public async identify() {
    try {
      const res = await this.get({ url: `/identification/v2/karrot-users/me/kr/identification` })
      return res
    } catch (err) {
      const response = (err as any).response
      return { ...response, json: () => response.data }
      // eslint-disable-next-line no-console
    }
  }
}
