import { useActivityParams, useActivity } from '@stackflow/react'
import React from 'react'

import { ActivityParamsSchema, ActivityParamsSchemaKey, activityParamsSchemas } from '@src/router/activityParams'
import { captureMessage } from '@src/sdks/sentry'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleError = <T extends ActivityParamsSchemaKey>(
  name: T,
  params: ActivityParamsSchema<T>,
  schema: ActivityParamsSchema<T>
) => {
  if (!schema) return

  const requiredFields = Object.entries(schema)
    .filter(([, v]) => !!(v as any)?.required)
    .map(([key]) => key)
  const hasError = requiredFields.some((field) => {
    const value = params[field as keyof typeof params]
    return value === undefined || value === null
  })

  if (!hasError) return

  const message = 'Required param is not exist'
  const errorDetail = {
    extra: { name, params, schema, referrer: params.referrer },
  }

  captureMessage(message, errorDetail)
}

const handleParamValue = (paramSchema: any, val: any) => {
  switch ((paramSchema as any).type) {
    case 'String':
      return String(val)
    case 'Number':
      return Number(val)
    case 'Boolean':
      return ['1', 'true'].includes(String(val))
    case 'Array': {
      const items = Array.isArray(val) ? val : String(val).split(',')
      return items.filter((item) => ((paramSchema as any).oneOf ?? []).includes(item))
    }
    case 'Enum': {
      return ((paramSchema as any).oneOf ?? []).includes(val) ? val : undefined
    }
    default:
      return val
  }
}

export const useJobsActivityParams = <T extends ActivityParamsSchemaKey>(): ActivityParamsSchema<T> => {
  const { name } = useActivity()
  const params = useActivityParams() as ActivityParamsSchema<T>
  const errorRef = React.useRef<Array<string>>([])
  const schema = activityParamsSchemas[name as T]

  React.useEffect(() => {
    handleError(name as ActivityParamsSchemaKey, params, schema)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!schema) {
    return params
  }

  const handledParams = Object.entries(params).reduce((acc, [key, val]) => {
    if (val === undefined || val === null) {
      return acc
    }

    const paramSchema = schema[key as keyof typeof schema]
    if (!paramSchema) {
      return { ...acc, [key]: val }
    }

    const handledValue = handleParamValue(paramSchema, val)

    if (handledValue === undefined) {
      if ((paramSchema as any).required) {
        errorRef.current.push(key)
      }
      return acc
    }

    return { ...acc, [key]: handledValue }
  }, {})

  return handledParams as ActivityParamsSchema<T>
}
