export const MARKETING_CAMPAIGN_APPLICANT_202307_JOB_POST_IDS = [
  // 로마
  'MTpKb2JQb3N0OjY0YWY2MzFlZWE2MGI1MDA0ZTQ3ZDY2OA',
  // 발리
  'MTpKb2JQb3N0OjY0YWY2NGEzZmFmN2VkMDA0ZWQxNmVmYQ',
  // 오사카
  'MTpKb2JQb3N0OjY0YWY2ODRmMmE4MWE2MDA0ZTM4MWQ1YQ',
  // 여수
  'MTpKb2JQb3N0OjY0YWY2OTEwM2E5YWZlMDA0ZTc2NDI3NA',
]

export const MARKETING_CAMPAIGN_202307_URL = 'https://daangn-jobs-campaign-2023.karrotwebview.com'
