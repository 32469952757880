/**
 * @generated SignedSource<<079d3977194bbfa2d9475fc310aaa7f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostStringifiedBringUpPassedDateResolver$data = {
  readonly lastBringUpDate: Dateable | null | undefined;
  readonly publishedAt: Dateable | null | undefined;
  readonly " $fragmentType": "JobPostStringifiedBringUpPassedDateResolver";
};
export type JobPostStringifiedBringUpPassedDateResolver$key = {
  readonly " $data"?: JobPostStringifiedBringUpPassedDateResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostStringifiedBringUpPassedDateResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostStringifiedBringUpPassedDateResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastBringUpDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "01714a6de5fb62605b72b967f238a54a";

export default node;
