import deepEqual from 'deep-equal'
import { isEmpty, omitBy } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import storage from '@src/api/storage'
import { FEED_FILTER_STATE } from '@src/constants/api'
import { setFilters } from '@src/ducks/filter'
import { captureException } from '@src/sdks/sentry'
import { filterSelector } from '@src/selectors/filter'
import { JobTaskType, RegionRange, WorkDay, WorkPeriod, WorkTime } from '@src/types/jobPost'
import { MS } from '@src/utils/date'
import { checkWorkTimeValid } from '@src/utils/string'

type State = {
  regionRange: RegionRange
  workPeriod?: WorkPeriod
  jobTasks?: JobTaskType[]
  workDays?: WorkDay[]
  workTime?: {
    start: WorkTime
    end: WorkTime
  }
  filterStatus: {
    any: boolean
    region: boolean
    workPeriod: boolean
    jobTasks: boolean
    workDays: boolean
    workTime: boolean
  }
}

export const DEFAULT_FILTER_SET: State = {
  regionRange: RegionRange.RANGE_3,
  workPeriod: undefined,
  jobTasks: [],
  workDays: [],
  workTime: undefined,
  filterStatus: {
    any: false,
    region: false,
    workPeriod: false,
    jobTasks: false,
    workDays: false,
    workTime: false,
  },
}

export const calcFilterStatus = (state: State): State['filterStatus'] => {
  const newSet = {
    region: state.regionRange !== DEFAULT_FILTER_SET.regionRange,
    workPeriod: state.workPeriod !== DEFAULT_FILTER_SET.workPeriod,
    jobTasks: !deepEqual(state.jobTasks, DEFAULT_FILTER_SET.jobTasks),
    workDays: !deepEqual(state.workDays, DEFAULT_FILTER_SET.workDays),
    workTime: state.workTime !== DEFAULT_FILTER_SET.workTime,
  }

  return {
    ...newSet,
    any: Object.values(newSet).some(Boolean),
  }
}

export const useFilterState = (initialState?: Omit<State, 'filterStatus'>) => {
  const [isInitialRender, setIsInitialRender] = React.useState(true)
  const filterState = useSelector(filterSelector)
  const dispatch = useDispatch()

  const _initialState = React.useMemo(() => {
    const data = { ...DEFAULT_FILTER_SET }
    if (initialState) {
      const newData = {
        ...data,
        ...initialState,
      }
      newData.filterStatus = calcFilterStatus(newData)
      return newData
    }
    try {
      const localData = storage.getItemFromLocal<{
        state: State
        date: Date
      }>(FEED_FILTER_STATE)
      if (!localData) return data
      if (new Date().getTime() - new Date(localData.date).getTime() > MS.hour * 3) {
        storage.removeItemAtLocal(FEED_FILTER_STATE)
        return data
      }
      const isWorkTimeValid =
        localData.state.workTime &&
        checkWorkTimeValid({
          start: localData.state.workTime.start as WorkTime,
          end: localData.state.workTime.end as WorkTime,
        })
      const newState: State = isWorkTimeValid
        ? localData.state
        : {
            ...localData.state,
            workTime: undefined,
          }
      storage.setItemAtLocal(FEED_FILTER_STATE, { state: newState, date: new Date() })
      return newState
    } catch (err) {
      captureException(err)
    }
    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    dispatch(setFilters(_initialState))
    setIsInitialRender(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isInitialRender ? _initialState : filterState
}

export const createInitialState = (initialState: Partial<Omit<State, 'filterStatus'>>) => {
  const compactedState = omitBy(initialState, (i) => !i)
  return isEmpty(compactedState)
    ? undefined
    : {
        ...compactedState,
        regionRange: initialState.regionRange ?? DEFAULT_FILTER_SET.regionRange,
      }
}
