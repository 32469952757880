import { IconExpandMoreRegular } from '@seed-design/icon'
import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { SelectedReviewItemList_user$key } from '@src/__generated__/SelectedReviewItemList_user.graphql'
import Button from '@src/components/base/buttons/Button'
import { reviewCodeMap } from '@src/constants/form'
import { styled } from '@src/stitches/stitches.config'
import { sortSelectedReviews } from '@src/utils/review'
interface Props {
  revieweeType: 'AUTHOR' | 'APPLICANT'
  userRef: SelectedReviewItemList_user$key
  previewCount?: number
  moreVisible?: boolean
}

const SelectedReviewItemList: React.FCC<Props> = ({ userRef, revieweeType, previewCount, moreVisible = true }) => {
  const [isMoreOpen, setIsMoreOpen] = React.useState(false)

  const user = useFragment(
    graphql`
      fragment SelectedReviewItemList_user on User
      @argumentDefinitions(
        evaluator: { type: "Actor" }
        rating: { type: "[JobReviewRate!]", defaultValue: [GREAT, GOOD] }
      ) {
        receivedJobReviewsExtend(filter: { evaluator: $evaluator, rating: $rating }) {
          countBySelectedItemCode {
            code
            count
          }
        }
      }
    `,
    userRef
  )

  const handleMoreOpenClick = () => {
    setIsMoreOpen((prev) => !prev)
  }

  const totalSelectedReviewCount = user.receivedJobReviewsExtend.countBySelectedItemCode.length

  const selectedReviews = sortSelectedReviews(
    user.receivedJobReviewsExtend.countBySelectedItemCode.slice(),
    revieweeType
  ).slice(0, !previewCount || isMoreOpen ? totalSelectedReviewCount : previewCount)

  return (
    <div>
      {selectedReviews.map((review) => (
        <SelectedReviewItem key={review.code}>
          <Emoji>{reviewCodeMap[review.code].emoji}</Emoji> {reviewCodeMap[review.code].text}{' '}
          <ReviewItemCount>{review.count}</ReviewItemCount>
        </SelectedReviewItem>
      ))}

      {moreVisible && !!previewCount && totalSelectedReviewCount > previewCount && (
        <MoreButton size="small" priority="secondary" onClick={handleMoreOpenClick}>
          <MoreButtonContent>
            <MoreButtonContentText>{isMoreOpen ? '접기' : '모두 보기'}</MoreButtonContentText>
            <MoreButtonIconWrapper isOpen={isMoreOpen}>
              <ArrowDownPositionExpandMoreRegular width={14} height={14} />
            </MoreButtonIconWrapper>
          </MoreButtonContent>
        </MoreButton>
      )}
    </div>
  )
}

export default SelectedReviewItemList

const SelectedReviewItem = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  alignItems: 'center',
  background: '$gray100',
  borderRadius: '0 10px 10px 10px',
  padding: '11px 16px 11px 12px',
  $text: 'subtitle2Bold',
  color: '$gray700',

  '& + &': {
    margin: '8px 0 0',
  },
})

const ReviewItemCount = styled('span', {
  $text: 'caption1Regular',
  color: '$gray600',
})

const Emoji = styled('span', {
  margin: '0 8px 0 0',
})

const MoreButton = styled(Button, {
  margin: '12px 0 0',
})

const MoreButtonContent = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const MoreButtonContentText = styled('div', {
  $text: 'label3Bold',
})

const MoreButtonIconWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 0 0 4px',

  variants: {
    isOpen: {
      true: {
        transform: 'rotate(180deg)',
      },
    },
  },
})

const ArrowDownPositionExpandMoreRegular = styled(IconExpandMoreRegular, {
  margin: '0 0 0 2px',
})
