/**
 * @generated SignedSource<<a6fd27a8c90313ea31999a6d08bfcab6>>
 * @relayHash 463b33deec1bf41f622b6f9c1d50644b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 463b33deec1bf41f622b6f9c1d50644b

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventGroupType = "BUDDHA_BIRTHDAY" | "CHILDREN_DAY" | "CHRISTMAS" | "Chuseok" | "DAANGN_EVENT" | "LunarNewYear" | "MEMORIAL_DAY" | "PEPERO_DAY" | "WHITE_DAY" | "%future added value";
export type RegionRange = "ADJACENT" | "MY" | "NO_PUBLISH" | "RANGE_2" | "RANGE_3" | "RANGE_4" | "%future added value";
export type UserRole = "APPLICANT" | "AUTHOR" | "AUTHOR_APPLICANT" | "NONE" | "%future added value";
export type EventGroupsWhereInput = {
  region: number;
  regionRange?: RegionRange;
};
export type useJobsRelayEnvironmentQuery$variables = {
  eventGroupsWhere: EventGroupsWhereInput;
  regionExist: boolean;
};
export type useJobsRelayEnvironmentQuery$data = {
  readonly eventGroups?: ReadonlyArray<{
    readonly hasJobPost: boolean;
    readonly name: string | null | undefined;
    readonly type: EventGroupType;
  }> | null | undefined;
  readonly me: {
    readonly id: string;
    readonly initialReferrer: string | null | undefined;
    readonly jobPosts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly advertisements: ReadonlyArray<{
            readonly __typename: "JobPostAdvertisement";
          }> | null | undefined;
          readonly id: string;
        };
      }>;
    };
    readonly jobPostsExtend: {
      readonly availableSlotCount: number;
    };
    readonly resettableDeviceId: string | null | undefined;
    readonly resume: {
      readonly birthYear: number | null | undefined;
    } | null | undefined;
    readonly role: UserRole;
  } | null | undefined;
};
export type useJobsRelayEnvironmentQuery = {
  response: useJobsRelayEnvironmentQuery$data;
  variables: useJobsRelayEnvironmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "eventGroupsWhere"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regionExist"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthYear",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "filter",
  "value": {
    "status": [
      "ACCEPTED",
      "PENDING"
    ]
  }
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "JobPostExtend",
  "kind": "LinkedField",
  "name": "jobPostsExtend",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableSlotCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resettableDeviceId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initialReferrer",
  "storageKey": null
},
v11 = {
  "condition": "regionExist",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "eventGroupsWhere"
        }
      ],
      "concreteType": "EventGroup",
      "kind": "LinkedField",
      "name": "eventGroups",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasJobPost",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v12 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 2
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useJobsRelayEnvironmentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Resume",
            "kind": "LinkedField",
            "name": "resume",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "jobPosts",
            "args": [
              (v4/*: any*/)
            ],
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "__MainWritingsList_jobPosts_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostAdvertisement",
                        "kind": "LinkedField",
                        "name": "advertisements",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": "__MainWritingsList_jobPosts_connection(filter:{\"status\":[\"ACCEPTED\",\"PENDING\"]})"
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useJobsRelayEnvironmentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Resume",
            "kind": "LinkedField",
            "name": "resume",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostAdvertisement",
                        "kind": "LinkedField",
                        "name": "advertisements",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": "jobPosts(filter:{\"status\":[\"ACCEPTED\",\"PENDING\"]},first:2)"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "filters": [
              "filter"
            ],
            "handle": "connection",
            "key": "MainWritingsList_jobPosts",
            "kind": "LinkedHandle",
            "name": "jobPosts"
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/)
    ]
  },
  "params": {
    "id": "463b33deec1bf41f622b6f9c1d50644b",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "me",
            "jobPosts"
          ]
        }
      ]
    },
    "name": "useJobsRelayEnvironmentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e6c3d265d757f6ca7f217e8d6e78c119";

export default node;
