/**
 * @generated SignedSource<<938dc9fbf68dc8ceb14539ec38616d88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostFilterAlarmRegistration_user$data = {
  readonly newJobPostFilterSubscription: {
    readonly deleted: boolean;
    readonly id: string;
    readonly isHolding: boolean;
  } | null | undefined;
  readonly " $fragmentType": "JobPostFilterAlarmRegistration_user";
};
export type JobPostFilterAlarmRegistration_user$key = {
  readonly " $data"?: JobPostFilterAlarmRegistration_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostFilterAlarmRegistration_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostFilterAlarmRegistration_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NewJobPostSubscription",
      "kind": "LinkedField",
      "name": "newJobPostFilterSubscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isHolding",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deleted",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "960a6ea3306b688c27d16c52b7d46c03";

export default node;
