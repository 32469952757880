import { useStack } from '@stackflow/react'

import { routes } from '@src/router/activities'
import { ActivityName } from '@src/router/activityNames'
import { ActivityParamsSchema } from '@src/router/activityParams'

import { useNavigator } from './useNavigator'
import { useRefreshJobs } from './useRefreshJobs'

type Props = {
  useBridgeReplace?: boolean
}

export const usePopOrReplace = ({ useBridgeReplace }: Props = {}) => {
  const { refreshJobs } = useRefreshJobs()
  const { replace, pop } = useNavigator()
  const { activities } = useStack()
  const popOrReplace = <T extends ActivityName>(activityName: T, replaceParams?: ActivityParamsSchema<T>) => {
    const activeActivities = activities.filter((item) => ['enter-done', 'enter-active'].includes(item.transitionState))
    const lastIndex = activeActivities.length - 1
    const activityIndex = activeActivities.findIndex((item) => item.name === activityName)
    if (activityIndex === lastIndex) {
      return
    }
    if (activityIndex === -1) {
      const route = routes[activityName as ActivityName]

      if (useBridgeReplace && route) {
        refreshJobs(route.path, replaceParams)
        return
      }

      replace(activityName, replaceParams || ({} as ActivityParamsSchema<T>))
      return
    }
    const popCount = lastIndex - activityIndex
    pop(popCount)
  }

  return popOrReplace
}
