import React from 'react'

import { useNavigator } from '@src/hooks/useNavigator'

import ScreenErrorPage from './ScreenErrorPage'

const CaseDefaultErrorPage: React.FCC = () => {
  const { pop } = useNavigator()

  return (
    <ScreenErrorPage
      messages={['일시적으로 문제가 발생했어요.', '잠시 후 다시 시도해주세요.']}
      buttonMessage="뒤로가기"
      onButtonClick={pop}
    />
  )
}

export default CaseDefaultErrorPage
