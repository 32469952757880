/**
 * @generated SignedSource<<9efc753314e4f5d9a24e6d48bccb75d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostFooter_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobPostFooterApplyButton_user" | "JobPostFooterLikeButton_user">;
  readonly " $fragmentType": "JobPostFooter_user";
};
export type JobPostFooter_user$key = {
  readonly " $data"?: JobPostFooter_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostFooter_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostFooter_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobPostFooterLikeButton_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobPostFooterApplyButton_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f78e920e3c700bafa37d8a9a603b6426";

export default node;
