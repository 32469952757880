import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DEFAULT_COUNTRY_KEY } from '@src/constants/api'
import { AppInfo } from '@src/types/common'

import { initApplication } from './common'

type AppState = AppInfo['app']
type Modal = {
  type: 'alarm'
  data: { isAlarmSet: boolean; from: 'guide' | 'menu'; nickname?: string | null }
} | null
type InitialState = AppState & {
  modal: Modal
  isGlobalLoaderOpen: boolean
  isMapQuotaExceeded: boolean
}

const INITIAL_STATE: InitialState = {
  deviceIdentity: '',
  userAgent: '',
  locale: '',
  appHost: '',
  country: DEFAULT_COUNTRY_KEY,
  modal: null,
  isGlobalLoaderOpen: false,
  isMapQuotaExceeded: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    setIsGlobalLoaderOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isGlobalLoaderOpen = payload
    },
    setIsMapQuotaExceeded: (state, { payload }: PayloadAction<boolean>) => {
      state.isMapQuotaExceeded = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initApplication, (state, { payload }) => {
      Object.assign(state, payload.app)
    })
  },
})

export const { setIsGlobalLoaderOpen, setIsMapQuotaExceeded } = appSlice.actions

export default appSlice.reducer
