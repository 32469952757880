import React from 'react'
import { Environment } from 'react-relay'
import { fetchQuery, GraphQLTaggedNode, OperationType } from 'relay-runtime'

export const usePreloadQuery = <T extends OperationType>(query: GraphQLTaggedNode) => {
  const [isLoaded, setIsLoaded] = React.useState(false)

  return {
    fetch: ({
      environment,
      variables,
      onSuccess,
      onError,
      onFinally,
    }: {
      environment: Environment
      variables: T['variables']
      onSuccess: (res: T['response']) => void
      onError: (e: Error) => void
      onFinally?: () => void
    }) => {
      fetchQuery(environment, query, variables)
        .toPromise()
        .then(onSuccess)
        .catch(onError)
        .finally(() => {
          setIsLoaded(true)
          onFinally?.()
        })
    },
    ignoreFetch: () => {
      setIsLoaded(true)
    },
    isLoaded,
  }
}
