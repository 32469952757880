import { useActivityParams } from '@stackflow/react'
import { nextTick } from 'async'
import React from 'react'

import { useStepNavigator } from './useStepNavigator'
import { useUUID } from './useUUID'

export default ({
  initialState = false,
}: {
  initialState?: boolean
} = {}): [
  boolean,
  () => void, // open
  (cb?: (() => void) | { [key: string]: any }) => void, //close
] => {
  const uuid = useUUID()
  const key = `modal-${uuid.slice(0, 8)}`

  const { stepPush, stepReplace, stepPop } = useStepNavigator()
  const activityParams = useActivityParams<any>()
  const isModalOpen = ['true', true].includes(activityParams[key])
  const callBackRef = React.useRef<(() => void) | null>(null)

  React.useEffect(() => {
    if (initialState) {
      handleOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const callback = callBackRef.current
    callBackRef.current = null
    nextTick(() => {
      callback?.()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen])

  const handleOpen = React.useCallback(() => {
    if (isModalOpen) return

    stepPush({
      ...activityParams,
      [key]: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = (cb?: (() => void) | any) => {
    callBackRef.current = typeof cb === 'function' ? cb : null

    if (isModalOpen) {
      stepPop()
    } else {
      stepReplace({
        ...activityParams,
        [key]: false,
      })
    }
  }

  return [isModalOpen, handleOpen, handleClose]
}
