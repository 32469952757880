import { isNumber } from 'lodash'
import React from 'react'

import { styled } from '@src/stitches/stitches.config'

interface Props {
  w?: number
  h?: number
}

const Spacer: React.FCC<Props> = ({ w, h }) => {
  return <SpacerElement css={{ width: isNumber(w) ? `${w}px` : '100%', height: isNumber(h) ? `${h}px` : '100%' }} />
}

export default Spacer

const SpacerElement = styled('div')
