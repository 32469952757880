import { BoxButton } from '@daangn/sprout-components-button'
import { IconChevronRightRegular } from '@seed-design/icon'
import { compact } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFragment, graphql } from 'react-relay'

import { JobPostAuthorReviewTab_jobPost$key } from '@src/__generated__/JobPostAuthorReviewTab_jobPost.graphql'
import Button from '@src/components/base/buttons/Button'
import Divider from '@src/components/base/divider/Divider'
import Spacer from '@src/components/base/Spacer'
import ApplicantReviewCard from '@src/components/common/review/ApplicantReviewCard'
import ApplicantReviewStats from '@src/components/common/review/ApplicantReviewStats'
import SelectedReviewItemList from '@src/components/common/review/SelectedReviewItemList'
import { SCHEME_PREFIX } from '@src/config'
import { SHOW_REVIEWS_RECEIVED_AT_JOB_POST } from '@src/constants/action'
import { useJobsActivityParams } from '@src/hooks/useJobsActivityParams'
import { useNavigator } from '@src/hooks/useNavigator'
import { useScrollToElement } from '@src/hooks/useScrollToElement'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { JobsActivity } from '@src/router/activityNames'
import Redirect from '@src/router/Redirect'
import { userIdSelector } from '@src/selectors/user'
import { styled, theme } from '@src/stitches/stitches.config'

const PREVIEW_COMMENT_REVIEW_COUNT = 3
const SCROLL_DELAY = 400
interface Props {
  jobPostRef: JobPostAuthorReviewTab_jobPost$key
}

const JobPostAuthorReviewTab: React.FCC<Props> = ({ jobPostRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment JobPostAuthorReviewTab_jobPost on JobPost {
        companyName
        author {
          ... on HoianUserAuthor {
            id
            hoianUser {
              _id
              receivedApplicantReviewCount
              receivedApplicantPositiveReviewCount
              receivedApplicantCommentReviewCount
              previewReviews(first: 3) {
                id
                ...ApplicantReviewCard_jobReview
              }
              ...ApplicantReviewStats_user
              ...SelectedReviewItemList_user @arguments(evaluator: APPLICANT)
            }
          }
        }
      }
    `,
    jobPostRef
  )

  const { push } = useNavigator()
  const { logClickEvent } = useLogAnalyticsEvent()
  const { actions } = useJobsActivityParams<typeof JobsActivity.JOB_POST>()
  const commentsContainerRef = React.useRef<HTMLDivElement>(null)
  const scrollToCommentsContainer = useScrollToElement(commentsContainerRef)
  const userId = useSelector(userIdSelector)
  const authorUserId = jobPost.author.hoianUser?._id
  const isAuthor = authorUserId === userId
  const isPersonal = !jobPost.companyName

  const receivedApplicantCommentReviewCount = jobPost.author.hoianUser?.receivedApplicantCommentReviewCount || 0
  const receivedApplicantReviewCount = jobPost.author.hoianUser?.receivedApplicantReviewCount || 0
  const receivedApplicantPositiveReviewCount = jobPost.author.hoianUser?.receivedApplicantPositiveReviewCount || 0

  const previewReviews = compact(jobPost.author.hoianUser?.previewReviews)

  const handleMoreCommentReviewsByApplicantClick = () => {
    if (!jobPost.author.id) return

    logClickEvent({
      name: 'more_comment_reviews',
    })

    push(JobsActivity.AUTHOR_REVIEWS_COMMENT, { authorId: jobPost.author.id })
  }

  const handleApplicantProfileClick = () => {
    logClickEvent({
      name: 'profile',
      params: {
        owner: 'APPLICANT',
      },
    })
  }

  const handleRecommendReviewClick = () => {
    push(JobsActivity.BIZ_MAIN, {})
  }

  const handleReferenceReviewClick = () => {
    if (isPersonal) return
    const url = `${SCHEME_PREFIX}://users/${authorUserId}`
    window.location.href = url
  }

  React.useEffect(() => {
    const clearIds: Array<NodeJS.Timeout> = []

    actions?.forEach((action) => {
      if (action === SHOW_REVIEWS_RECEIVED_AT_JOB_POST) {
        const clearId = setTimeout(() => {
          scrollToCommentsContainer()
        }, SCROLL_DELAY)

        clearIds.push(clearId)
      }
    })

    return () => {
      clearIds.forEach((id) => clearTimeout(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!jobPost.author.hoianUser) {
    return <Redirect to={JobsActivity.NOT_FOUND} params={{}} />
  }

  return (
    <>
      {receivedApplicantReviewCount > 0 ? (
        <div>
          <Section>
            <Title>지원자 만족도 {receivedApplicantReviewCount}</Title>
            <Description>이 구인자와 대화하거나 함께 일해본 사람들이 남긴 후기예요.</Description>
          </Section>
          <ApplicantReviewStats userRef={jobPost.author.hoianUser} />

          <Spacer h={20} />
          <Divider h={1} />

          {receivedApplicantPositiveReviewCount > 0 && (
            <>
              <Section>
                <Title>이런 점이 좋았어요</Title>
              </Section>
              <SelectedReviewItemList revieweeType="AUTHOR" userRef={jobPost.author.hoianUser} previewCount={3} />
            </>
          )}

          <Spacer h={8} />

          {previewReviews.length > 0 && (
            <CommentsContainer ref={commentsContainerRef}>
              {previewReviews.slice(0, PREVIEW_COMMENT_REVIEW_COUNT).map((review) => (
                <ApplicantReviewCard onClick={handleApplicantProfileClick} key={review.id} jobReviewRef={review} />
              ))}
            </CommentsContainer>
          )}

          {receivedApplicantCommentReviewCount > PREVIEW_COMMENT_REVIEW_COUNT && (
            <NavButton size="small" onClick={handleMoreCommentReviewsByApplicantClick}>
              <NavButtonText>후기 {receivedApplicantCommentReviewCount}개 모두 보기</NavButtonText>{' '}
              <IconChevronRightRegular width={18} height={18} color={theme.colors.gray900.computedValue} />
            </NavButton>
          )}
        </div>
      ) : isAuthor ? (
        <EmptyTab>
          <EmptyTabText>
            아직 알바 후기가 없어요.
            <br />
            먼저 추천하면 좋은 후기를 받을 확률이 올라가요!
          </EmptyTabText>
          <BoxButton
            variant="secondary"
            size="xsmall"
            suffix={<IconChevronRightRegular width={12} height={12} />}
            onClick={handleRecommendReviewClick}>
            지원자 추천하기
          </BoxButton>
        </EmptyTab>
      ) : (
        <EmptyTab>
          <EmptyTabText>아직 알바 후기가 없어요.</EmptyTabText>
          {!isPersonal && (
            <BoxButton
              variant="secondary"
              size="xsmall"
              suffix={<IconChevronRightRegular width={12} height={12} />}
              onClick={handleReferenceReviewClick}>
              중고거래 후기 참고하기
            </BoxButton>
          )}
        </EmptyTab>
      )}
    </>
  )
}

export default JobPostAuthorReviewTab

const NavButton = styled(Button, {})

const NavButtonText = styled('span', {
  margin: '0 4px 0 0',
})

const EmptyTab = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '63px 0',
  color: '$gray600',
})

const EmptyTabText = styled('div', {
  margin: '0 0 12px',
  $text: 'subtitle1Regular',
  textAlign: 'center',
})

const Section = styled('div', {
  margin: '20px 0',
})

const Title = styled('div', {
  $text: 'title3Bold',
})

const Description = styled('div', {
  margin: '6px 0 0',
  color: '$gray600',
  $text: 'label3Regular',
})

const CommentsContainer = styled('div', {})
