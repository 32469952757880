import { getDay, addDays, isEqual } from 'date-fns'

import { CURRENT_YEAR } from '@src/constants/date'
import { HOLIDAYS, HOLIDAY_KEYS } from '@src/constants/holidays'

type Holiday = {
  // 공휴일 날짜
  date: Date
  // 해당 날짜의 공휴일(이름)들
  name: string[]
}

type AltHolidayParam = {
  // 공휴일 날짜 (당일)
  date: Date
  // 공휴일 이름
  name: string
  // false인 경우, 주어진 date는 목록에 추가하지 않고 계산된 대체공휴일만 추가됨
  addHol?: boolean
  // true인 경우, 일요일과 겹치는 경우에만 대체공휴일 처리됨 (2023년 기준 구정, 추석 연휴)
  altSunOnly?: boolean
  // 전체 공휴일 목록
  holidays: Holiday[]
}

const holidayMemoizer = (func: (year?: number) => string | Holiday[]) => {
  const cache: Record<number, string | Holiday[]> = {}

  return function (year: any = CURRENT_YEAR) {
    if (cache[year] != undefined) {
      return cache[year]
    } else {
      const result = func(year)
      cache[year] = result

      return result
    }
  }
}

// 주어진 날짜를 공휴일 목록에 추가
const addHoliday = (date: Date, name: string, holidays: Holiday[]) => {
  // 전체 공휴일 목록 중, 해당 날짜에 해당하는 공휴일이 이미 있는지 검색
  const _holiday = holidays.find((holiday) => holiday.date.getTime() === date.getTime())
  if (_holiday) {
    // 해당 날짜에 이미 공휴일이 있다면, 공휴일 이름만 새로 추가
    _holiday.name.push(name)
  } else {
    // 해당 날짜에 공휴일이 없었다면, 해당 날짜 및 공휴일 이름을 공휴일 목록에 새로 추가
    holidays.push({ date, name: [name] })
  }
}

// 주어진 날짜를 대체공휴일 포함하여 공휴일 목록에 추가
const addWithAltHoliday = ({ date, name, addHol = false, altSunOnly = false, holidays }: AltHolidayParam) => {
  const weekEnd = altSunOnly ? [0] : [0, 6]

  // 주어진 날짜를 공휴일 목록에 추가
  if (addHol) {
    addHoliday(date, name, holidays)
  }

  // 주어진 날짜의 요일을 계산
  let day = getDay(date)

  // 주어진 날짜에 다른 공휴일이 있는지 계산
  const otherHolidays = holidays.find((holiday) => isEqual(holiday.date, date))?.name.length ?? 0

  // 해당 날짜가 평일이 아니라면 (주말이거나, 다른 공휴일인 경우)
  if (weekEnd.includes(day) || otherHolidays > 1) {
    // 주어진 날짜로부터 하루 뒤를 계산
    let nextDate = addDays(date, 1)
    day = getDay(nextDate)

    // 해당 날짜가 여전히 평일이 아니라면 하루를 더함
    while (weekEnd.includes(day) || holidays.find((holiday) => isEqual(holiday.date, nextDate))) {
      nextDate = addDays(nextDate, 1)
      day = getDay(nextDate)
    }

    // 계산된 날짜를 대체공휴일로 공휴일 목록에 추가
    addHoliday(nextDate, `${name}의 대체 공휴일`, holidays)
  }
}

// 주어진 연도에 대한 공휴일 목록을 생성
const _getHolidays = (year: number = CURRENT_YEAR) => {
  if (year > 2099) return '2099년까지의 공휴일만 계산할 수 있어요.'

  const holidays: Holiday[] = []

  HOLIDAYS.forEach((holiday) => {
    let _date: number[] = []

    // 주어진 공휴일의 날짜 계산
    if (Array.isArray(holiday.dates)) {
      _date = holiday.dates
    } else {
      _date = holiday.dates[year]
    }
    const date = new Date(year, _date[0] - 1, _date[1])

    // 설날, 추석은 연휴이므로 별도 로직으로 처리
    if (holiday.key === HOLIDAY_KEYS.LUNAR_NEW_YEAR_DATES || holiday.key === HOLIDAY_KEYS.MID_AUTUMN_DATES) {
      // 당일
      addHoliday(date, holiday.name, holidays)

      // 전날
      const prevDate = addDays(date, -1)
      addHoliday(prevDate, holiday.name, holidays)

      // 다음날
      const nextDate = addDays(date, +1)
      addHoliday(nextDate, holiday.name, holidays)

      // 각 연휴일에 대한 대체공휴일
      const _holidays = [prevDate, date, nextDate]
      _holidays.forEach((_date) => {
        addWithAltHoliday({
          date: _date,
          name: holiday.name,
          addHol: false,
          altSunOnly: holiday.alterSunOnly,
          holidays,
        })
      })

      return
    }

    // 연휴가 아닌 공휴일 처리
    if (holiday.isAlterHoliday) {
      // 해당 공휴일이 대체공휴일 가능한 경우
      addWithAltHoliday({
        date,
        name: holiday.name,
        addHol: true,
        altSunOnly: holiday.alterSunOnly,
        holidays,
      })
    } else {
      // 해당 공휴일이 대체공휴일 불가능한 경우
      addHoliday(date, holiday.name, holidays)
    }
  })

  return holidays
}

export const getHolidays = holidayMemoizer(_getHolidays)
