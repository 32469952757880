import React from 'react'

import { styled } from '@src/stitches/stitches.config'

type Props = React.PropsWithChildren<
  Omit<JSX.IntrinsicElements['div'], 'ref'> & { noPadding?: boolean; noBorder?: boolean; isOpen?: boolean }
>

const Footer: React.FCC<Props> = ({ isOpen = true, children, noPadding = false, ...props }) => {
  const footerRef = React.useRef<HTMLDivElement>(null)
  const [footerHeight, setFooterHeight] = React.useState(0)
  const [initialParentElementPaddingBottom, setInitialParentElementPaddingBottom] = React.useState('0px')

  React.useLayoutEffect(() => {
    const parentElement = footerRef.current?.parentElement as HTMLElement | null
    if (!parentElement) return

    setInitialParentElementPaddingBottom(getComputedStyle(parentElement).paddingBottom)
  }, [])

  React.useLayoutEffect(() => {
    const parentElement = footerRef.current?.parentElement as HTMLElement | null
    if (!parentElement) return

    parentElement.style.paddingBottom = `calc(${initialParentElementPaddingBottom} + ${footerHeight}px)`

    return () => {
      parentElement.style.paddingBottom = initialParentElementPaddingBottom
    }
  }, [initialParentElementPaddingBottom, footerHeight])

  React.useEffect(() => {
    if (typeof ResizeObserver === 'function') {
      const element = footerRef.current
      if (!element) return

      const observer = new ResizeObserver((entries) => {
        setFooterHeight(entries[0].target.clientHeight)
      })
      observer.observe(element)

      return () => observer.unobserve(element)
    }
  }, [])

  if (!isOpen) return null

  return (
    <FooterContainer padding={!noPadding} ref={footerRef} {...props}>
      {children}
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled('div', {
  position: 'absolute',
  zIndex: '$page1',
  left: 0,
  right: 0,
  bottom: 0,
  background: '$paperDefault-semantic',
  borderTop: '0.5px solid $divider3-semantic',

  variants: {
    padding: {
      true: {
        padding: '12px 16px 8px',
      },
    },
    noBorder: {
      true: {
        border: 'none',
      },
    },
  },

  defaultVariants: {
    padding: false,
  },
})
