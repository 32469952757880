import { StackflowReactPlugin } from '@stackflow/react'
import React from 'react'

import { logJobsEvent } from '@src/sdks/jobs'

const jobsVisitedPlugin = (): StackflowReactPlugin => {
  let isLogTrigger = false

  const JobsVisitedLogger: React.FCC = ({ children }) => {
    React.useEffect(() => {
      if (isLogTrigger) return

      const params = new URLSearchParams(window.location.search)
      const referrer = params.get('referrer')

      isLogTrigger = true
      logJobsEvent({
        name: 'visited',
        referrer,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return children
  }

  return () => ({
    key: 'jobsVisitedPlugin',
    wrapStack({ stack }) {
      return <JobsVisitedLogger>{stack.render()}</JobsVisitedLogger>
    },
  })
}

export default jobsVisitedPlugin
