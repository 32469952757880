import { addBreadcrumb, withScope } from '@sentry/react'
import React from 'react'

import { captureException } from '@src/sdks/sentry'

interface Props {
  /**
   * fallback이 없으면 null 을 렌더링합니다.
   */
  fallback?: React.ReactNode | ((args: any) => React.ReactNode)
  children?: React.ReactNode
}

interface State {
  error?: Error
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: undefined }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
    })
    addBreadcrumb({
      message: 'React ErrorBoundary!',
      data: {
        errorInfo,
      },
    })

    if ('extra' in error) {
      withScope((scope) => {
        scope.setExtra('data', error.extra)
        captureException(error)
      })
    } else {
      captureException(error)
    }
  }

  render() {
    const { fallback, children } = this.props

    if (this.state.error) {
      if (!fallback) {
        return null
      }

      if (typeof fallback === 'function') {
        return fallback({ error: this.state.error })
      }

      return fallback
    }

    return children
  }
}
