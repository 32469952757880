import ReactGA from 'react-ga4'

import { GA4_TRACKING_ID } from '@src/config'
import { getExternalReferrer } from '@src/utils/router'
import { generateURL } from '@src/utils/url'

/**
 * 로그이벤트 전송 (프로덕션일 때만)
 * @param data
 */

let ga4TrackingId: string

export const installGtag = (options: Parameters<typeof ReactGA.initialize>[1] = {}) => {
  ga4TrackingId = GA4_TRACKING_ID

  const url = new URLSearchParams(window.location.search)
  const referrer = url.get('referrer')
  const { gtagOptions, ...restOptions } = options
  const externalReferrer = getExternalReferrer()
  const refererOptions =
    referrer || externalReferrer
      ? {
          page_referrer: generateURL({
            paths: 'http://:referrer.com',
            params: { referrer: referrer ?? externalReferrer },
          }),
        }
      : {}

  const _options = { gtagOptions: { ...gtagOptions, ...refererOptions }, ...restOptions }

  ReactGA.initialize(ga4TrackingId, _options)
}

export const logGtagEvent = (data: { name: string; params: { [key: string]: any } }): void => {
  if (!ga4TrackingId) return

  ReactGA.event(data.name, {
    category: data.params.category,
    action: data.params.action,
    label: data.params.label,
    value: data.params.value,
  })
  return
}

export const logGtagPageView = (pageCode: string) => {
  if (!ga4TrackingId) return

  ReactGA.set({
    page: pageCode,
  })

  ReactGA.send({
    hitType: 'pageview',
    page: pageCode,
    title: pageCode,
  })
}
