/**
 * @generated SignedSource<<dcd3edba23f2f30dfdd9aaa3a2060b3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserReceivedApplicantGreatReviewCountResolver$data = {
  readonly receivedJobReviewsGreatCountByApplicant: number;
  readonly " $fragmentType": "UserReceivedApplicantGreatReviewCountResolver";
};
export type UserReceivedApplicantGreatReviewCountResolver$key = {
  readonly " $data"?: UserReceivedApplicantGreatReviewCountResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserReceivedApplicantGreatReviewCountResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserReceivedApplicantGreatReviewCountResolver",
  "selections": [
    {
      "alias": "receivedJobReviewsGreatCountByApplicant",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "evaluator": "APPLICANT",
            "rating": [
              "GREAT"
            ]
          }
        }
      ],
      "kind": "ScalarField",
      "name": "receivedJobReviewsCount",
      "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"GREAT\"]})"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d8e4f6abab0e450faad09d76602b08f5";

export default node;
