export type SalaryType = 'MONTHLY' | 'DAILY' | 'HOURLY' | 'PER_CASE'

const workDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'] as const
export type WorkDay = (typeof workDays)[number]

export const workTimes = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
] as const
export type WorkTime = (typeof workTimes)[number]

export const workPeriods = ['LESS_THAN_A_MONTH', 'MORE_THAN_A_MONTH', '%future added value'] as const
export type WorkPeriod = (typeof workPeriods)[number]

export type JobPostType = {
  title: string
  jobTasks: JobTaskType[]
  companyName: string | null
  isPersonal: boolean
  phone: string | null
  isPhoneIncontactable: boolean
  workplaceAddress: string
  workplaceRoadAddress: string
  workplaceLat: number | null
  workplaceLng: number | null
  workplaceRequiredActions: string[] | null
  workPeriod: WorkPeriod | null
  workDays: WorkDay[]
  workDates: Date[]
  workTimeStart: WorkTime
  workTimeEnd: WorkTime
  isWorkTimeNegotiable: boolean
  salaryType: SalaryType
  salary: number | null
  content: string
  images: Image[]
  terms: boolean
  authorType: JobPostAuthorType
}

export type JobPostServerInputs = {
  authorType: JobPostAuthorType
  companyName: string
  workplaceAddress: string
  workplaceRoadAddress: string
  visibleDetailedWorkplaceAddress: boolean
  workplaceLat: number
  workplaceLng: number
  workPeriod: WorkPeriod
  workDays: WorkDay[]
  workDates: Date[]
  workTimeStart: string
  workTimeEnd: string
  isWorkTimeNegotiable: boolean
  salaryType: SalaryType
  salary: number
  title: string
  content: string
  images: string[]
  phone: string
  isPhoneContactable: boolean
  eventGroup: JobPostEventGroup
  jobTasks: JobTaskType[]
  exposureItems: JobPostExposureInitialStatus
}

type JobPostEventGroup = 'Chuseok' | 'LunarNewYear'

type JobPostAuthorType = 'HOIAN_USER'
interface Image {
  id: string
  thumbnail: string
}

export type JobPostStatus =
  | 'CREATED'
  | 'PENDING'
  | 'RETURNED'
  | 'ACCEPTED'
  | 'REJECTED'
  | 'CLOSED'
  | 'DELETED'
  | '%future added value'

export type AdvertisementStatus =
  | 'AdvertisementNone'
  | 'AdvertisementReady'
  | 'AdvertisementDenied'
  | 'AdvertisementOngoing'
  | 'AdvertisementFinished'
  | 'AdvertisementPaused'
  | 'AdvertisementStopped'
  | 'AdvertisementDeleted'
  | '%other'

export type AddressInfo = {
  workplaceAddress: string
  workplaceRoadAddress: string
  workplaceLat: number
  workplaceLng: number
}

export enum RegionRange {
  MY = 'MY',
  ADJACENT = 'ADJACENT',
  RANGE_2 = 'RANGE_2',
  RANGE_3 = 'RANGE_3',
  RANGE_4 = 'RANGE_4',
}

export type JobTaskType =
  // 서빙
  | 'SERVING'
  // 주방보조/설거지
  | 'KITCHEN_ASSISTANCE'
  // 주방장/조리사
  | 'KITCHEN_COOK'
  // 편의점
  | 'CONVENIENCE_STORE'
  // 매장관리/판매
  | 'SHOP_MANAGEMENT'
  // 음료 제조
  | 'BEVERAGE_MAKING'
  // 베이킹
  | 'BAKING'
  // 과외
  | 'TUTORING'
  // 전단지
  | 'LEAFLET_DISTRIBUTION'
  // 청소/미화
  | 'CLEANING'
  // 가사/청소
  | 'HOUSEWORK'
  // 돌봄/시팅
  | 'NURSING'
  // 등하원도우미
  | 'KIDS_PICK_UP'
  // 심부름/소일거리
  | 'LIGHT_WORK'
  // 기타
  | 'ETC'

export type JobPostExposureInitialStatus = {
  waitingHours: number
  exposureHours: number
}
