import { Bridge } from '@daangn/webview-bridge-modern'
import { IconAndroidShareRegular, IconIosShareRegular, IconReportRegular, IconHomeRegular } from '@seed-design/icon'
import React from 'react'

import JobsAppScreen from '@src/components/base/stackflow/JobsAppScreen'
import { PageCodeInfoProvider } from '@src/contexts/PageCodeInfoContext'
import { useEnhancedRouter } from '@src/hooks/useEnhancedRouter'
import { useAppNavigation } from '@src/hooks/useNavigator'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { navIconWrapperStyle } from '@src/stitches/navigation'
import { styled, theme } from '@src/stitches/stitches.config'

interface Props {
  environment: Bridge['environment']
  isLogin: boolean
  isEmpty: boolean
  blockReport?: boolean
  onReportClick: () => void
  onShareClick: () => void
}

const JobPostAppScreen: React.FCC<Props> = ({
  environment,
  isLogin,
  isEmpty,
  blockReport,
  onReportClick,
  onShareClick,
  children,
}) => {
  const { logClickEvent } = useLogAnalyticsEvent()
  const { close } = useEnhancedRouter()
  const { isKarrotMainShortcutVisible } = useAppNavigation()

  const handleHomeClick = () => {
    logClickEvent({
      name: 'redirect_home',
    })

    close()
  }

  const handleReport = () => {
    onReportClick()
  }

  return (
    <PageCodeInfoProvider pageSectionCode="job_post_screen">
      <JobsAppScreen
        appBar={{
          renderLeft: () =>
            isKarrotMainShortcutVisible ? (
              <IconWrapper onClick={handleHomeClick}>
                <IconHomeRegular width={24} height={24} color={theme.colors.gray900.computedValue} />
              </IconWrapper>
            ) : null,
          renderRight: () =>
            !isEmpty ? (
              <>
                <IconWrapper onClick={onShareClick}>
                  {environment === 'Android' ? (
                    <IconAndroidShareRegular width={24} height={24} color={theme.colors.gray900.computedValue} />
                  ) : (
                    <IconIosShareRegular width={24} height={24} color={theme.colors.gray900.computedValue} />
                  )}
                </IconWrapper>
                {isLogin && !blockReport ? (
                  <IconWrapper onClick={handleReport}>
                    <IconReportRegular width={24} height={24} color={theme.colors.gray900.computedValue} />
                  </IconWrapper>
                ) : null}
              </>
            ) : null,
        }}>
        {children}
      </JobsAppScreen>
    </PageCodeInfoProvider>
  )
}

export default JobPostAppScreen

const IconWrapper = styled('div', navIconWrapperStyle)
