import { IconChevronRightRegular, IconCloseRegular } from '@seed-design/icon'
import React from 'react'

import { styled } from '@src/stitches/stitches.config'

type Props = {
  title?: string
  content: string | React.ReactNode
  type: 'link' | 'close'
  onIconCloseClick?: () => void
} & Omit<React.ComponentProps<typeof Container>, 'content'>

const CalloutAction: React.FCC<Props> = ({ type, title, content, onIconCloseClick, ...props }) => {
  const handleIconCloseClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onIconCloseClick?.()
  }

  return (
    <Container {...props}>
      <Message state={props.state}>
        {title && (
          <>
            <strong>{title}</strong>{' '}
          </>
        )}
        {content}
      </Message>
      {type === 'link' ? (
        <IconWrapper state={props.state}>
          <IconChevronRightRegular width={18} height={18} />
        </IconWrapper>
      ) : (
        <IconWrapper state={props.state} onClick={handleIconCloseClick}>
          <IconCloseRegular width={18} height={18} />
        </IconWrapper>
      )}
    </Container>
  )
}

export default CalloutAction

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '16px 16px 16px 18px',
  background: '$gray100',
  borderRadius: '10px',
  whiteSpace: 'normal',

  variants: {
    state: {
      normal: {
        background: '$gray100',
      },
      tip: {
        background: '$blueAlpha100',
      },
      alert: {
        background: '$redAlpha50',
      },
      warning: {
        background: '$yellowAlpha100',
      },
    },
  },

  defaultVariants: {
    state: 'normal',
  },
})

const Message = styled('div', {
  $text: 'bodyL2Regular',
  flex: 1,
  margin: '0 16px 0 0',
  wordBreak: 'keep-all',

  variants: {
    state: {
      normal: {},
      tip: {
        color: '$blue950',
      },
      alert: {
        color: '$red950',
      },
      warning: {
        color: '$yellow950',
      },
    },
  },

  defaultVariants: {
    state: 'normal',
  },
})

const IconWrapper = styled('div', {
  variants: {
    state: {
      normal: { color: '$gray900' },
      tip: { color: '$gray900' },
      alert: {
        color: '$red950',
      },
      warning: {
        color: '$yellow950',
      },
    },
  },

  defaultVariants: {
    state: 'normal',
  },
})
