import React from 'react'

export default (
  initialState = false
): [
  boolean,
  () => void, // open
  () => void, //close
  () => void, // toggle
] => {
  const [state, setState] = React.useState(initialState)

  const handleStateToTrue = React.useCallback(() => setState(true), [])
  const handleStateToFalse = React.useCallback(() => setState(false), [])
  const handleStateToggle = React.useCallback(() => setState((prev) => !prev), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => [state, handleStateToTrue, handleStateToFalse, handleStateToggle], [state])
}
