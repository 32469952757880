import React from 'react'
import useDarkMode from 'use-dark-mode'

import { useDevToolLibrary } from './DevToolLibraryManager'

const LocalStorageViewer: React.FCC = () => {
  const { value: isDarkMode } = useDarkMode()
  const { JsonViewer } = useDevToolLibrary()
  const localStorageValues = React.useMemo(() => {
    return { ...localStorage }
  }, [])

  if (!JsonViewer) return null
  return (
    <JsonViewer
      value={localStorageValues}
      indentWidth={2}
      style={{ wordBreak: 'break-all' }}
      defaultInspectDepth={1}
      theme={isDarkMode ? 'dark' : 'light'}
    />
  )
}

export default LocalStorageViewer
