import React from 'react'
import { useSelector } from 'react-redux'

import storage from '@src/api/storage'
import { useRegisterPaidMarketingAcquisition } from '@src/hooks/useRegisterPaidMarketingAcquisition'
import { logAmplitudeEvent } from '@src/sdks/amplitude'
import { logBrazeEvent } from '@src/sdks/braze'
import { logAnalyticsEvent } from '@src/sdks/bridge'
import { logGtagEvent, logGtagPageView } from '@src/sdks/googleAnalytics'
import { logJobsEvent } from '@src/sdks/jobs'
import { logMixpanelEvent } from '@src/sdks/mixpanel'
import { sampleGroupsSelector } from '@src/selectors/devTool'
import {
  resettableDeviceIdSelector,
  userIdSelector,
  userPaidAcquiredAuthorSelector,
  userRoleSelector,
} from '@src/selectors/user'
import { acquisitionEvents } from '@src/utils/acquisition'
import { removeQuotes } from '@src/utils/string'
import { calcUserRole } from '@src/utils/user'

import {
  AnalyticsClickEventKey,
  analyticsClickEvents,
  AnalyticsEvent,
  AnalyticsShowEventKey,
  analyticsShowEvents,
} from './eventSchema'
import { LogAnalyticsEventContext, LogAnalyticsEventType } from './LogAnalyticsEventContext'
import { ReferrerContext } from './ReferrerContext'
import { AnalyticsLogOption } from './type'

const ReferrerHandler: React.FCC = ({ children }) => {
  const initialReferrer = React.useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    return {
      activity_name: undefined,
      referrer: params.get('referrer') ?? undefined,
      referrer_context: params.get('referrer_context') ?? undefined,
      funnelFrom: params.get('funnelFrom') ?? undefined,
      message_kind: params.get('message_kind') ?? undefined,
      message_context: params.get('message_context') ?? undefined,
    }
  }, [])
  const [referrerInfo, _setReferrerInfo] = React.useState<{
    activity_name?: string
    referrer?: string
    referrer_context?: string
    funnelFrom?: string
    message_kind?: string
    message_context?: string
  }>(initialReferrer)
  const isPaidAcquiredAuthor = useSelector(userPaidAcquiredAuthorSelector)
  const userId = useSelector(userIdSelector)
  const { PAID_USER: isPaidSampleUser } = useSelector(sampleGroupsSelector)
  const role = useSelector(userRoleSelector)
  const { isAuthor } = calcUserRole({ role })
  const { ALL: isSampleUserGroup, JOB_POST_AUTHOR: isJobPostAuthorSampleGroup } = useSelector(sampleGroupsSelector)
  const isSampleEventUser = isSampleUserGroup || (isAuthor && isJobPostAuthorSampleGroup)
  const resettableDeviceId = useSelector(resettableDeviceIdSelector)
  const activityNameRef = React.useRef<string>()
  useRegisterPaidMarketingAcquisition()

  const setReferrerInfo = (next: typeof referrerInfo) => {
    _setReferrerInfo((prev) => {
      return {
        activity_name: next.activity_name ?? prev.activity_name,
        referrer: next.referrer?.toLowerCase() ?? prev.referrer,
        referrer_context: next.referrer_context ?? prev.referrer_context,
        funnelFrom: next.funnelFrom ?? prev.funnelFrom,
        message_kind: next.message_kind ?? prev.message_kind,
        message_context: next.message_context ?? prev.message_context,
      }
    })
  }

  const handledLogAnalyticsEvent = React.useMemo<LogAnalyticsEventType>(() => {
    const parseLogOption = ({
      type,
      events,
      name,
      log_condition,
    }: {
      type: 'show' | 'click'
      events: Record<string, AnalyticsEvent>
      name: AnalyticsShowEventKey | AnalyticsClickEventKey
      log_condition?: boolean
    }) => {
      const registeredEvent = (events[name] ?? {}) as AnalyticsEvent
      const category = registeredEvent.category
      const logs = (registeredEvent.logs ?? []) as unknown as AnalyticsLogOption[]
      return {
        category,
        amplitude:
          logs.includes(AnalyticsLogOption.AMPLITUDE) ||
          (logs.includes(AnalyticsLogOption.AMPLITUDE_OPTIONAL) && !!log_condition) ||
          ((isPaidSampleUser || !!isPaidAcquiredAuthor) && acquisitionEvents[type].includes(name as any)),
        amplitudeOnly:
          logs.includes(AnalyticsLogOption.AMPLITUDE_ONLY) ||
          (logs.includes(AnalyticsLogOption.AMPLITUDE_ONLY_OPTIONAL) && !!log_condition),
        amplitudeSample: type === 'click' ? registeredEvent.sample !== false : !!registeredEvent.sample,
        jobsLog:
          logs.includes(AnalyticsLogOption.JOBS_LOG) ||
          (logs.includes(AnalyticsLogOption.JOBS_LOG_OPTIONAL) && !!log_condition),
        jobsLogOnly:
          logs.includes(AnalyticsLogOption.JOBS_LOG_ONLY) ||
          (logs.includes(AnalyticsLogOption.JOBS_LOG_ONLY_OPTIONAL) && !!log_condition),
        mixpanel:
          logs.includes(AnalyticsLogOption.MIXPANEL) ||
          (logs.includes(AnalyticsLogOption.MIXPANEL_OPTIONAL) && !!log_condition),
        mixpanelOnly:
          logs.includes(AnalyticsLogOption.MIXPANEL_ONLY) ||
          (logs.includes(AnalyticsLogOption.MIXPANEL_ONLY_OPTIONAL) && !!log_condition),
        braze: logs.includes(AnalyticsLogOption.BRAZE),
      }
    }

    const logCommonEvents = ({
      type,
      component_type,
      name,
      params,
      originEntry,
      amplitude,
      amplitudeOnly,
      amplitudeSample,
      jobsLog,
      jobsLogOnly,
      mixpanel,
      mixpanelOnly,
      braze,
      ...entryParams
    }: {
      type: 'show' | 'click'
      component_type?: 'page' | 'element'
      name: string
      params: any
      originEntry?: string
      amplitude: boolean
      amplitudeOnly: boolean
      amplitudeSample: boolean
      jobsLog: boolean
      jobsLogOnly: boolean
      mixpanel: boolean
      mixpanelOnly: boolean
      braze: boolean
      entry?: string
      entry_section?: string
      entry_context?: string
      current?: string
      current_section?: string
      current_context?: string
    }) => {
      const singleTypeName = `${type}_${name}`
      const firebaseTypeName = `${type}_jobs`
      const firebaseV2TypeName = `${type === 'click' ? 'clicked' : `shown_${component_type ?? 'element'}`}_jobs`
      const brazeTypeName = `${type}_jobs_${name}`
      const handledParams = Object.entries(params).reduce((acc, [key, value]) => {
        if (value === undefined) {
          return acc
        }

        return {
          ...acc,
          [key]: Array.isArray(value) ? value.join(',') : typeof value === 'string' ? removeQuotes(value) : value,
        }
      }, {})

      const referrer = {
        referrer: referrerInfo.referrer,
        referrer_context: referrerInfo.referrer_context,
        funnelFrom: referrerInfo.funnelFrom,
        message_kind: referrerInfo.message_kind,
        message_context: referrerInfo.message_context,
      }

      const entry = originEntry || referrer.referrer
      const commonParams = {
        ...handledParams,
        ...entryParams,
        ...referrer,
        entry,
      }

      if (amplitude || amplitudeOnly || (isSampleEventUser && amplitudeSample)) {
        logAmplitudeEvent(
          singleTypeName,
          {
            ...commonParams,
          },
          {
            adid: resettableDeviceId,
            idfa: resettableDeviceId,
          }
        )

        if (amplitudeOnly) {
          return
        }
      }

      if (jobsLog || jobsLogOnly) {
        logJobsEvent({
          name: singleTypeName,
          ...commonParams,
        })

        if (jobsLogOnly) {
          return
        }
      }

      if (mixpanel || mixpanelOnly) {
        logMixpanelEvent(singleTypeName, {
          ...commonParams,
        })

        if (mixpanelOnly) {
          return
        }
      }

      if (braze) {
        logBrazeEvent({
          name: brazeTypeName,
          params: {
            ...handledParams,
            ...referrer,
            entry,
            ...entryParams,
            hoian_user_id: userId,
          },
        })
      }

      // Firebase v1 로깅, v2 마이그레이션 이후 제거
      logAnalyticsEvent({
        name: firebaseTypeName,
        params: {
          name,
          ...handledParams,
          ...referrer,
          entry,
          ...entryParams,
        },
      })

      // Firebase v2 로깅
      logAnalyticsEvent({
        name: firebaseV2TypeName,
        params: {
          name,
          ...handledParams,
          ...referrer,
          entry,
          ...entryParams,
        },
      })
    }

    return {
      logShowEvent: (data) => {
        const { name, log_condition, entry: originEntry, ...entryParams } = data
        const logOption = parseLogOption({ type: 'show', events: analyticsShowEvents, name, log_condition })
        logCommonEvents({
          type: 'show',
          name,
          originEntry,
          params: (data as any).params ?? {},
          ...logOption,
          ...entryParams,
        })
      },
      logClickEvent: (data) => {
        const { action, label, name, log_condition, entry: originEntry, ...entryParams } = data
        const logOption = parseLogOption({ type: 'click', events: analyticsClickEvents, name, log_condition })
        logCommonEvents({
          type: 'click',
          name,
          originEntry,
          params: (data as any).params ?? {},
          ...logOption,
          ...entryParams,
        })

        logGtagEvent({
          name: 'click_jobs',
          params: { category: logOption.category, action: action ?? `click_${name}`, label },
        })
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referrerInfo, isSampleEventUser, resettableDeviceId])

  React.useEffect(() => {
    if (referrerInfo.referrer) {
      storage.setReferrerInfo(referrerInfo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referrerInfo])

  React.useEffect(() => {
    const newActivityName = referrerInfo.activity_name
    if (newActivityName !== activityNameRef.current) {
      activityNameRef.current = newActivityName
      logGtagPageView(newActivityName ?? '')
    }
  }, [referrerInfo.activity_name])

  return (
    <ReferrerContext.Provider value={{ referrerInfo, setReferrerInfo }}>
      <LogAnalyticsEventContext.Provider value={handledLogAnalyticsEvent}>{children}</LogAnalyticsEventContext.Provider>
    </ReferrerContext.Provider>
  )
}

export default ReferrerHandler
