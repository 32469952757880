import { IconCopyRegular, IconLocationRegular, IconWalkFill } from '@seed-design/icon'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { useFragment, graphql } from 'react-relay'

import { KakaoMap_jobPost$key } from '@src/__generated__/KakaoMap_jobPost.graphql'
import poiPinSelected from '@src/assets/images/poi_pin_selected.svg'
import Button from '@src/components/base/buttons/Button'
import { KAKAO_MAP_KEY } from '@src/config'
import { IMAGE_POI_PIN } from '@src/constants/images'
import { setIsMapQuotaExceeded } from '@src/ducks/app'
import { useCalcWalkingDistance } from '@src/hooks/useCalcWalkingDistance'
import { useEnhancedRouter } from '@src/hooks/useEnhancedRouter'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { toast } from '@src/sdks/bridge'
import { captureMessage } from '@src/sdks/sentry'
import { isMapQuotaExceededSelector } from '@src/selectors/app'
import { currentPositionSelector } from '@src/selectors/user'
import { userSelectText } from '@src/stitches/action'
import { styled, theme } from '@src/stitches/stitches.config'
import { getMiddleDotStr } from '@src/utils/string'
import { generateURL } from '@src/utils/url'

import Subway from './Subway'

interface Props {
  jobPostRef: KakaoMap_jobPost$key
}

const KakaoMap: React.FCC<Props> = ({ jobPostRef }) => {
  const dispatch = useDispatch()
  const isMapQuotaExceeded = useSelector(isMapQuotaExceededSelector)
  const mapRef = React.useRef<HTMLDivElement>(null)
  const { push } = useEnhancedRouter()
  const { logClickEvent } = useLogAnalyticsEvent()
  const isMapVisible = !isMapQuotaExceeded

  const jobPost = useFragment(
    graphql`
      fragment KakaoMap_jobPost on JobPost {
        companyName
        workplaceRoadAddress
        workplaceLocation {
          lat
          lng
        }
        workplaceRegion {
          name1
          name2
          name3
        }
        ...Subway_jobPost
      }
    `,
    jobPostRef
  )

  const currentPosition = useSelector(currentPositionSelector)
  const {
    isWalkingDistance,
    minutes: walkingMinutes,
    meter: walkingDistance,
  } = useCalcWalkingDistance({
    positions: [currentPosition?.position, jobPost.workplaceLocation],
  })
  const isPersonalAuthor = !jobPost.companyName
  const safeRoadAddress = [
    jobPost.workplaceRegion?.name1,
    jobPost.workplaceRegion?.name2,
    jobPost.workplaceRegion?.name3,
  ]
    .filter((name) => !!name)
    .join(' ')

  const handleMapClick = () => {
    if (!jobPost.workplaceLocation) return

    if (isPersonalAuthor) {
      toast('정확한 위치는 지원 후 대화를 통해 알 수 있어요.')
      return
    }

    const params = encodeURIComponent(
      `${safeRoadAddress},${jobPost.workplaceLocation.lat},${jobPost.workplaceLocation.lng}`
    )

    const url = generateURL({
      paths: ['https://map.kakao.com/link/map', params],
    })

    logClickEvent({
      name: 'map',
      params: {
        is_personal: isPersonalAuthor,
        is_walking_distance: isWalkingDistance,
      },
    })

    push({ remote: url, navbar: true })
  }

  const handleCopyAddressClick = () => {
    logClickEvent({
      name: 'copy_address',
    })
  }

  const drawSingleSpotMap = React.useCallback((isPersonalAuthor: boolean) => {
    const kakao = window.kakao

    // https://apis.map.kakao.com/web/guide/
    kakao.maps.load(() => {
      const container = mapRef.current

      // 카카오 api 버그 대응
      if (!kakao.maps.LatLng) return
      // For component unmount blocking
      if (!container) return
      if (!jobPost.workplaceLocation) {
        captureMessage('jobPost.workplaceLocation does not exist')
        return
      }

      if (isPersonalAuthor) {
        const targetPoint = new kakao.maps.LatLng(jobPost.workplaceLocation.lat, jobPost.workplaceLocation.lng)

        const options = {
          center: targetPoint,
          level: 4,
        }
        const map = new kakao.maps.Map(container, options)

        new kakao.maps.Circle({
          map,
          center: targetPoint,
          radius: 90,
          strokeWeight: 0,
          fillColor: theme.colors['primaryLowHover-semantic'].computedValue,
          fillOpacity: 1,
        })
      } else if (!isPersonalAuthor) {
        const targetPoint = new kakao.maps.LatLng(
          jobPost.workplaceLocation.lat + 0.00026,
          jobPost.workplaceLocation.lng
        )

        const options = {
          center: targetPoint,
          level: 4,
        }
        const map = new kakao.maps.Map(container, options)

        const imageSize = new kakao.maps.Size(46, 46)
        const imageOption = { offset: new kakao.maps.Point(23, 30) }
        const markerImage = new kakao.maps.MarkerImage(poiPinSelected, imageSize, imageOption)

        new kakao.maps.Marker({
          map,
          position: targetPoint,
          image: markerImage,
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const drawMultipleSpotMap = React.useCallback((isPersonalAuthor: boolean) => {
    const kakao = window.kakao

    // https://apis.map.kakao.com/web/guide/
    kakao.maps.load(async () => {
      const container = mapRef.current

      // 카카오 api 버그 대응
      if (!kakao.maps.LatLng) return
      // For component unmount blocking
      if (!container) return
      if (!currentPosition) return
      if (!jobPost.workplaceLocation) {
        captureMessage('jobPost.workplaceLocation does not exist')
        return
      }

      if (isPersonalAuthor) {
        const imageSize = new kakao.maps.Size(46, 46)
        const startImageOption = { offset: new kakao.maps.Point(28, 28) }
        const startMarkerImage = new kakao.maps.MarkerImage(IMAGE_POI_PIN.GPS, imageSize, startImageOption)
        const destinationPosition = { lat: jobPost.workplaceLocation.lat, lng: jobPost.workplaceLocation.lng }

        const centerPosition = {
          lat: (currentPosition.position.latitude + destinationPosition.lat) / 2,
          lng: (currentPosition.position.longitude + destinationPosition.lng) / 2,
        }

        const targetPoint = new kakao.maps.LatLng(centerPosition.lat, centerPosition.lng)

        const options = {
          center: targetPoint,
          level: (walkingDistance ?? 0) <= 400 ? 4 : 5,
        }
        const map = new kakao.maps.Map(container, options)

        new kakao.maps.Circle({
          map,
          center: targetPoint,
          radius: 90,
          strokeWeight: 0,
          fillColor: theme.colors['primaryLowHover-semantic'].computedValue,
          fillOpacity: 1,
        })
        new kakao.maps.Marker({
          map,
          position: new kakao.maps.LatLng(currentPosition.position.latitude, currentPosition.position.longitude),
          image: startMarkerImage,
        })
      } else if (!isPersonalAuthor) {
        const imageSize = new kakao.maps.Size(46, 46)
        const startImageOption = { offset: new kakao.maps.Point(28, 28) }
        const startMarkerImage = new kakao.maps.MarkerImage(IMAGE_POI_PIN.GPS, imageSize, startImageOption)
        const destinationImageOption = { offset: new kakao.maps.Point(20, 43) }
        const destinationMarkerImage = new kakao.maps.MarkerImage(IMAGE_POI_PIN.END, imageSize, destinationImageOption)
        const destinationPosition = { lat: jobPost.workplaceLocation.lat, lng: jobPost.workplaceLocation.lng }

        const centerPosition = {
          lat: (currentPosition.position.latitude + destinationPosition.lat) / 2,
          lng: (currentPosition.position.longitude + destinationPosition.lng) / 2,
        }

        const targetPoint = new kakao.maps.LatLng(centerPosition.lat, centerPosition.lng)
        const options = {
          center: targetPoint,
          level: (walkingDistance ?? 0) <= 400 ? 4 : 5,
        }
        const map = new kakao.maps.Map(container, options)

        new kakao.maps.Marker({
          map,
          position: new kakao.maps.LatLng(destinationPosition.lat, destinationPosition.lng),
          image: destinationMarkerImage,
        })
        new kakao.maps.Marker({
          map,
          position: new kakao.maps.LatLng(currentPosition.position.latitude, currentPosition.position.longitude),
          image: startMarkerImage,
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!jobPost.workplaceLocation) {
      captureMessage(`workplaceLocation does not exist`, { extra: { jobPost } })
      return
    }

    if (!isMapVisible) {
      return
    }

    const drawMap = () =>
      isWalkingDistance ? drawMultipleSpotMap(isPersonalAuthor) : drawSingleSpotMap(isPersonalAuthor)

    if (!window.kakao) {
      const script = document.createElement('script')
      script.onload = function () {
        drawMap()
      }
      script.onerror = function () {
        dispatch(setIsMapQuotaExceeded(true))
      }
      script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${KAKAO_MAP_KEY}&autoload=false`
      document.getElementById('root')?.appendChild(script)

      return
    }

    drawMap()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mixedWorkplaceAddress = jobPost.workplaceRoadAddress
    ?.split(' ')
    .slice(1)
    .join(' ')
    .concat(jobPost.workplaceRegion?.name3 ? `(${jobPost.workplaceRegion.name3})` : '')

  return (
    <>
      {isMapVisible && (
        <MapContainer>
          <MapWrapper ref={mapRef} height={isWalkingDistance ? 'long' : 'short'} />
          <Overlay onClick={handleMapClick} />
        </MapContainer>
      )}

      {isWalkingDistance && (
        <DistanceContainer>
          <WalkIconWrapper>
            <IconWalkFill width={20} height={20} color={theme.colors.blue800.computedValue} />
          </WalkIconWrapper>
          <MoveMinutes>{`현위치에서 걸어서 ${walkingMinutes}분`}</MoveMinutes>
          <Dot>{getMiddleDotStr()}</Dot>
          <Distance>{`${walkingDistance}m`}</Distance>
        </DistanceContainer>
      )}

      <WorkplaceRoadAddressContainer>
        <WorkplaceRoadAddress>{isPersonalAuthor ? safeRoadAddress : mixedWorkplaceAddress}</WorkplaceRoadAddress>
        <CopyToClipboard
          text={isPersonalAuthor ? safeRoadAddress : jobPost.workplaceRoadAddress || ''}
          onCopy={() => toast('주소가 복사되었어요.')}>
          <CopyItem onClick={handleCopyAddressClick}>
            <IconCopyRegular width={16} height={16} color={theme.colors.blue800.computedValue} />
            <CopyKeyword>복사</CopyKeyword>
          </CopyItem>
        </CopyToClipboard>
      </WorkplaceRoadAddressContainer>

      <SubwayContainer>
        <Subway jobPostRef={jobPost} />
      </SubwayContainer>

      {!isMapVisible && (
        <LinkMapButton onClick={handleMapClick}>
          <IconLocationRegular width={16} height={16} color={theme.colors.gray900.computedValue} />
          <LinkMapButtonText>지도에서 위치보기</LinkMapButtonText>
        </LinkMapButton>
      )}
    </>
  )
}

export default React.memo(KakaoMap)

const MapContainer = styled('div', {
  position: 'relative',
  margin: '0 0 16px',
})

const MapWrapper = styled('div', {
  width: '100%',
  $border: '1px solid $grayAlpha50',
  borderRadius: '6px',

  variants: {
    height: {
      long: {
        height: '300px',
      },
      short: {
        height: '120px',
      },
    },
  },

  defaultVariants: {
    height: 'short',
  },
})

const Overlay = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: '$page1',
})

const WorkplaceRoadAddressContainer = styled('div', userSelectText, {
  $text: 'bodyM1Regular',
  display: 'flex',
  alignItems: 'center',
})

const WorkplaceRoadAddress = styled('p', {
  $text: 'label2Regular',
})

const CopyItem = styled('div', {
  display: 'flex',
  $text: 'label3Regular',
  color: '$blue800',
  alignItems: 'center',
  margin: '0 0 0 8px',
})

const CopyKeyword = styled('div', {
  margin: '0 0 0 2px',
})

const DistanceContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$gray600',
  $text: 'caption1Regular',
  margin: '0 0 8px',
})

const WalkIconWrapper = styled('span', {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '$green500',
})

const Dot = styled('span', {
  margin: '0 4px',
})

const Distance = styled('span')

const MoveMinutes = styled('span', {
  $text: 'caption1Bold',
  color: '$blue800',
})

const LinkMapButton = styled(Button, {
  margin: '13px 0 0',
})

const LinkMapButtonText = styled('span', {
  $text: 'label3Regular',
  margin: '0 0 1px 4px',
})

const SubwayContainer = styled('div', {
  margin: '16px 0 0',

  '&:empty': {
    display: 'none',
  },
})
