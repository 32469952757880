import { IconViewCountFill, IconViewCountOffFill } from '@seed-design/icon'
import { styled } from '@stitches/react'
import React from 'react'

import withDraggable from '@src/hocs/withDraggable'

export const TOGGLE_ICON_ID = 'toggle-falcon-react'

type Props = {
  on?: boolean
  onClick: () => void
}

const ToggleIcon: React.FC<Props> = ({ on = false, onClick }) => {
  const [isOn, setOn] = React.useState<boolean>(on)
  const handleClick = () => {
    setOn((prev) => !prev)
    onClick()
  }

  return (
    <IconWrapper initialBottom={300}>
      {isOn ? (
        <ViewOn size={44} id={TOGGLE_ICON_ID} onClick={handleClick} />
      ) : (
        <ViewOff onClick={handleClick} size={44} />
      )}
    </IconWrapper>
  )
}

export default ToggleIcon

const IconWrapper = withDraggable(
  styled('div', {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '$global1',
    right: 0,
    color: '$gray00',
  })
)

const ViewOn = styled(IconViewCountFill, {
  color: '$red500',
})

const ViewOff = styled(IconViewCountOffFill, {
  color: '$gray600',
})
