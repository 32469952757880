import { Portal } from '@daangn/karrot-clothes'
import React from 'react'
import { Transition } from 'react-transition-group'

import SafeAreaBottom from '@src/components/base/layout/SafeAreaBottom'
import { styled, zIndices } from '@src/stitches/stitches.config'

import { HalfviewContext } from './HalfviewContext'

export interface HalfviewProps {
  initialHeight?: number
  marginTop?: number
  onOpen?: () => void
  onClosed?: () => void
  onCloseClick: () => void
  zIndex?: number
  duration?: number
  exitDuration?: number
  delay?: number
  open: boolean
  children: React.ReactNode
}
export interface HalfviewRef {
  setHeight: (height: number) => void
  getHeight: () => number
  getRenderedHeight: () => any
}

const Halfview = React.forwardRef<HalfviewRef, HalfviewProps>(
  (
    {
      initialHeight = 0,
      marginTop = 132,
      onCloseClick,
      onOpen,
      onClosed,
      open,
      children,
      delay = 0,
      duration = 300,
      exitDuration = 300,
      zIndex = zIndices.global1,
    },
    parentRef
  ) => {
    const [height, setHeight] = React.useState(initialHeight)
    const halfviewCardRef = React.useRef<HTMLDivElement>(null)
    const transitionRef = React.useRef<HTMLDivElement>(null)

    const style = React.useMemo(
      () => ({ height: `${height ? height + 'px' : 'auto'}`, maxHeight: `calc(100vh - ${marginTop}px)` }),
      [height, marginTop]
    )

    const value = React.useMemo(
      () => ({ height, getRenderedHeight: () => halfviewCardRef.current?.offsetHeight, setHeight }),
      [height]
    )

    const reflow = React.useCallback(() => {
      transitionRef.current?.scrollTop
    }, [])

    const handleCloseClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      onCloseClick()
    }

    React.useEffect(() => {
      if (open) {
        onOpen?.()
      }
    }, [open, onOpen])

    React.useImperativeHandle(
      parentRef,
      () => ({
        getHeight: () => height,
        getRenderedHeight: () => halfviewCardRef.current?.offsetHeight,
        setHeight,
      }),
      [height]
    )

    return (
      <Portal>
        <Transition
          nodeRef={transitionRef}
          in={open}
          timeout={{ enter: duration, exit: exitDuration }}
          onEnter={reflow}
          onExited={onClosed}
          unmountOnExit>
          {(state) => {
            const isEntered = state === 'entered'
            const isEntering = state === 'entering'
            const isOutro = state === 'exiting' || state === 'exited'

            return (
              <div ref={transitionRef}>
                <Base css={{ zIndex }}>
                  <Overlay
                    isOutro={isOutro}
                    css={{
                      transitionDuration: `${isEntering ? duration : exitDuration}ms`,
                      transitionDelay: `${delay}ms`,
                    }}
                    onClick={isEntered ? handleCloseClick : undefined}
                  />
                  <HalfviewCardWrapper
                    isOutro={isOutro}
                    css={{
                      transitionDuration: `${isEntering ? duration : exitDuration}ms`,
                      transitionDelay: `${delay}ms`,
                    }}>
                    <HalfviewCard ref={halfviewCardRef} style={style}>
                      <HalfviewContext.Provider value={value}>{children}</HalfviewContext.Provider>
                    </HalfviewCard>

                    <SafeAreaBottom />
                  </HalfviewCardWrapper>
                </Base>
              </div>
            )
          }}
        </Transition>
      </Portal>
    )
  }
)

const Base = styled('div', {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  width: '100%',
  overflow: 'hidden',
})

const Overlay = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  width: '100%',
  background: '$overlayDim-semantic',
  transition: 'opacity',

  variants: {
    isOutro: {
      true: {
        opacity: 0,
      },
      false: {
        opacity: 1,
      },
    },
  },
})

const HalfviewCardWrapper = styled('div', {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  overflow: 'hidden',
  borderTopLeftRadius: '0.75rem',
  borderTopRightRadius: '0.75rem',
  backgroundColor: '$paperSheet-semantic',
  transition: 'ease transform',

  variants: {
    isOutro: {
      true: {
        transform: 'translate3d(0, 100%, 0)',
      },
      false: {
        transform: 'translate3d(0, 0, 0)',
      },
    },
  },
})

const HalfviewCard = styled('div', {
  width: '100%',
  transition: 'height 150ms ease',
  overflowY: 'scroll',
})

export default Halfview
