import * as Sentry from '@sentry/react'
import { ScopeContext } from '@sentry/types'

import storage from '@src/api/storage'
import { IS_NATIVE, APP_ENV, SENTRY_APP_VERSION, SENTRY_DSN, IS_PROD } from '@src/config'

const filtered = 'filtered'

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    const maskingItems = IS_PROD
      ? {
          user: {
            userId: state?.user?.id,
          },
          biz: filtered,
        }
      : {}

    return {
      ...state,
      ...maskingItems,
    }
  },
})

export const configSentry = (options: { userId?: string; nativeVersion?: string }) => {
  Sentry.configureScope((scope) => {
    scope.setUser({ ...scope.getUser(), id: options?.userId })
    scope.setTag('native_version', options?.nativeVersion)
  })
}

export const captureException: typeof Sentry.captureException = (error, captureContext) => {
  const exception =
    error instanceof Error ? error : error && (error.error || error.message || error.originalError || error)

  return Sentry.captureException(exception, captureContext)
}

export const captureMessage = (message: string, options?: Partial<ScopeContext>) => {
  Sentry.captureMessage(message, {
    ...options,
    level: options?.level ?? 'warning',
  })
}

const ERROR_SAMPLING_MESSAGES = [
  'advertisement impression error',
  'advertisement click error',
  'advertisement hide error',
  'Discovery visited error',
]

const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: SENTRY_APP_VERSION,
    normalizeDepth: 10,
    environment: APP_ENV,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Loading chunk',
      'Aborted in beforeRetry() callback',
      "Redirected to 'Not Found' page",
      // Native Bridge 에서 발생하는 에러들
      't.messageHandler is not a function',
      'Java exception was raised during method invocation',
    ],
    denyUrls: [/clarity\.js/],
    beforeSend(event) {
      if (!IS_NATIVE) return null

      const message = event.message

      if (message && ERROR_SAMPLING_MESSAGES.some((msg) => message.includes(msg)) && Math.random() > 0.1) {
        return null
      }

      const { referrer, referrer_context } = storage.getReferrerInfo()

      event.tags = {
        ...event.tags,
        referrer,
        referrer_context,
      }

      return event
    },
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        dom: {
          serializeAttribute: 'aria-label',
        },
      }),
    ],
  })
}

initSentry()
