import { APP_ENV, USER_ID } from '@src/config'
import { theme } from '@src/sdks/bridge'
import { appInfoStorage } from '@src/utils/appInfo'

import Request from './Request'

export default class Experimentation extends Request {
  constructor(baseUrl: string) {
    super(baseUrl)

    this.setTokenHeader()
    this.setUserAgentHeader()
  }

  public async getVariablesExperimentKeys(variables: { keys: string[] }) {
    const res = await this.post({
      url: `/api/v1/variables/trigger`,
      data: {
        subject_type: 'SUBJECT_TYPE_USER',
        subject_id: USER_ID,
        experiment_keys: variables.keys,
        subject_metadata: {
          platform: `PLATFORM_${theme.environment === 'Cupertino' ? 'IOS' : 'ANDROID'}`,
          country_code: 'COUNTRY_CODE_KR',
          app_version: appInfoStorage.getAppInfo().appVersion,
          app_type: APP_ENV,
        },
      },
    })
    return res
  }
}
