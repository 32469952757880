import { Modal, Portal } from '@daangn/karrot-clothes'
import React from 'react'
import { useDispatch, useStore } from 'react-redux'
import useDarkMode from 'use-dark-mode'

import storage from '@src/api/storage'
import Button from '@src/components/base/buttons/Button'
import { REGEX_URL } from '@src/constants/regex'
import { switchBigFont } from '@src/ducks/devTool'
import { setBaseUrl, setDefaultUrl } from '@src/graphql/graphql'
import { getCurrentPosition } from '@src/sdks/bridge'
import { userSelectText } from '@src/stitches/action'
import { styled, keyframes, zIndices } from '@src/stitches/stitches.config'
import { handleRelayError } from '@src/utils/error'

import DevToolExperimentsModal from './DevToolExperimentsModal'
import DevToolInputModal from './DevToolInputModal'
import { useDevToolLibrary } from './DevToolLibraryManager'
import DevToolRoutePage from './DevToolRoutePage'
import LocalStorageViewer from './LocalStorageViewer'
import useDevRemoveResume from './useDevRemoveResume'

interface Props {
  isOpen: boolean
  closeHandler: () => void
}

type ModalName = 'apiEndpoint' | 'routePage' | 'experiments' | null

const halfviewAnimationDuration = 500

const DevToolsHalfView: React.FCC<Props> = ({ isOpen, closeHandler }) => {
  const { value: isDarkMode, toggle: toggleDarkMode } = useDarkMode()
  const store = useStore()
  const { JsonViewer } = useDevToolLibrary()
  const dispatch = useDispatch()
  const [isOpenState, setIsOpenState] = React.useState(isOpen)
  const [modalName, setModalName] = React.useState<ModalName>(null)
  const [deleteResume] = useDevRemoveResume()

  const changeJobsEndpoint = (newEndPoint: string) => {
    if (!newEndPoint) return
    if (!REGEX_URL.test(newEndPoint)) {
      alert('잘못된 url 입니다.')
      return
    }

    setBaseUrl(newEndPoint)
    alert('완료')
  }

  const handleSubmit = (value: string) => {
    setModalName(null)

    switch (modalName) {
      case 'apiEndpoint':
        changeJobsEndpoint(value)
        break
    }
  }

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation()
  }

  const handleDefaultClick = () => {
    setDefaultUrl()
    alert('완료')
  }

  const handleChangeEndpointClick = () => {
    setModalName('apiEndpoint')
  }

  const handleClearLocalStorageClick = () => {
    if (!confirm('진짜 비우시겠어요??')) {
      return
    }

    storage.clearLocal()
    alert('완료')
  }

  const handleExperimentsClick = () => {
    setModalName('experiments')
  }

  const handleBigFontSwitchClick = () => {
    dispatch(switchBigFont())
  }

  const handleCurrentLocationClick = async () => {
    const currentPosition = await getCurrentPosition()
    alert(JSON.stringify(currentPosition))
  }

  const handleRoutePageClick = () => {
    setModalName('routePage')
  }

  const handleDeleteResumeClick = () => {
    if (!confirm('진짜 삭제하시겠어요??')) {
      return
    }

    deleteResume({
      variables: {},
      updater: (store, data) => {
        store.delete(data.deleteResume.resume.id)
      },
      onCompleted: () => {
        alert('이력서가 삭제되었어요.')
      },
      onError: (err) => {
        handleRelayError(err)
      },
    })
  }

  React.useEffect(() => {
    if (!isOpen) {
      const timerId = setTimeout(() => {
        setIsOpenState(false)
      }, halfviewAnimationDuration)
      return () => {
        timerId && clearTimeout(timerId)
      }
    }
    setIsOpenState(true)
  }, [isOpen])

  return (
    <>
      <Portal>
        {isOpenState && (
          <Base isOpen={isOpen} onClick={closeHandler}>
            <HalfSection isOpen={isOpen} onClick={stopPropagation} onTouchMove={stopPropagation}>
              <Container>
                <Feature>
                  <StyledButton onClick={handleBigFontSwitchClick}>빅폰트 스위칭</StyledButton>
                </Feature>
                <Feature>
                  <StyledButton onClick={handleDefaultClick}>Endpoint Default 설정으로</StyledButton>
                </Feature>
                <Feature>
                  <StyledButton onClick={handleChangeEndpointClick}>Jobs Endpoint 변경</StyledButton>
                </Feature>
                <Feature>
                  <StyledButton onClick={handleCurrentLocationClick}>현재 위치</StyledButton>
                </Feature>
                <Feature>
                  <StyledButton onClick={handleClearLocalStorageClick}>로컬 스토리지 비우기</StyledButton>
                </Feature>
                <Feature>
                  <StyledButton onClick={handleExperimentsClick}>실험군 변경</StyledButton>
                </Feature>
                <Feature>
                  <StyledButton onClick={handleRoutePageClick}>어디로든 가는 문</StyledButton>
                </Feature>
                <Feature>
                  <StyledButton onClick={toggleDarkMode}>{isDarkMode ? 'light' : 'dark'}모드 변경</StyledButton>
                </Feature>
                <Feature>
                  <StyledButton onClick={handleDeleteResumeClick}>이력서 삭제</StyledButton>
                </Feature>
                <Feature>
                  <Title>&bull; Store Viewer</Title>
                  <ViewerContainer>
                    {JsonViewer && (
                      <JsonViewer
                        value={store.getState()}
                        indentWidth={2}
                        style={{ wordBreak: 'break-all' }}
                        defaultInspectDepth={1}
                        theme={isDarkMode ? 'dark' : 'light'}
                      />
                    )}
                  </ViewerContainer>
                </Feature>
                <Feature>
                  <Title>&bull; LocalStorage Viewer</Title>
                  <ViewerContainer>
                    <LocalStorageViewer />
                  </ViewerContainer>
                </Feature>
              </Container>
            </HalfSection>
          </Base>
        )}
      </Portal>

      <Modal open={!!modalName} onClose={() => setModalName(null)} zIndex={zIndices.global1}>
        {modalName === 'apiEndpoint' && <DevToolInputModal placeholder="Jobs Endpoint: URL" onSubmit={handleSubmit} />}
        {modalName === 'routePage' && <DevToolRoutePage onClick={() => setModalName(null)} />}
        {modalName === 'experiments' && <DevToolExperimentsModal onCloseModal={() => setModalName(null)} />}
      </Modal>
    </>
  )
}

export default DevToolsHalfView

const easeInOpacity = keyframes({
  '0%': { backgroundColor: 'rgba(0, 0, 0, 0)' },
  '100%': { backgroundColor: '$grayAlpha50' },
})

const easeOutOpacity = keyframes({
  '0%': { backgroundColor: '$grayAlpha500' },
  '100%': { backgroundColor: 'rgba(0, 0, 0, 0)' },
})

const easeInTransform = keyframes({
  '0%': { transform: 'translateY(100%)' },
  '100%': { transform: 'translateY(0%)' },
})

const easeOutTransform = keyframes({
  '0%': { transform: 'translateY(0%)' },
  '100%': { transform: 'translateY(100%)' },
})

const Base = styled('div', {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: '$global1',
  overflow: 'hidden',
  animationDuration: `${halfviewAnimationDuration}ms`,
  animationFillMode: 'forwards',
  animationTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',

  variants: {
    isOpen: {
      true: {
        animationName: `${easeInOpacity}`,
      },
      false: {
        animationName: `${easeOutOpacity}`,
      },
    },
  },
})

const HalfSection = styled('div', {
  position: 'absolute',
  display: 'flex',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '0 0 10px',
  maxHeight: '450px',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  boxShadow: '0 -5px 9px rgba(0, 0, 0, 0.07)',
  background: '$paperDefault-semantic',
  animationDuration: `${halfviewAnimationDuration}ms`,
  animationFillMode: 'forwards',
  animationTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',

  variants: {
    isOpen: {
      true: {
        animationName: `${easeInTransform}`,
      },
      false: {
        animationName: `${easeOutTransform}`,
      },
    },
  },
})

const Container = styled('div', userSelectText, {
  padding: '16px',
  flex: 1,
  overflowY: 'scroll',
})

const Feature = styled('div', {
  margin: '0 0 10px',
})

const StyledButton = styled(Button, {
  defaultVariants: {
    priority: 'primary',
  },
})

const Title = styled('div', {
  $text: 'subtitle1Bold',
  margin: '0 0 2px',
})

const ViewerContainer = styled('div', {
  $text: 'bodyL2Regular',
  padding: '5px',
  border: '1px solid $gray200',
  borderRadius: '6px',
})
