/**
 * @generated SignedSource<<b9caebc03e5df24f84c759ea01a3291e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserReceivedApplicantGoodReviewCountResolver$data = {
  readonly receivedJobReviewsGoodCountByApplicant: number;
  readonly " $fragmentType": "UserReceivedApplicantGoodReviewCountResolver";
};
export type UserReceivedApplicantGoodReviewCountResolver$key = {
  readonly " $data"?: UserReceivedApplicantGoodReviewCountResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserReceivedApplicantGoodReviewCountResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserReceivedApplicantGoodReviewCountResolver",
  "selections": [
    {
      "alias": "receivedJobReviewsGoodCountByApplicant",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "evaluator": "APPLICANT",
            "rating": [
              "GOOD"
            ]
          }
        }
      ],
      "kind": "ScalarField",
      "name": "receivedJobReviewsCount",
      "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"GOOD\"]})"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "a0a8b0b9eaa0294cbbe10c084a2fb0e9";

export default node;
