import React from 'react'

import { styled } from '@src/stitches/stitches.config'

type Props = React.ComponentProps<typeof Container> & Omit<JSX.IntrinsicElements['div'], 'ref'>

const Tag: React.FCC<Props> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>
}

export default Tag

const Container = styled('div', {
  display: 'inline-block',
  color: '$gray600',

  variants: {
    size: {
      medium: {
        $text: 'caption1Regular',
      },
      small: {
        $text: 'caption2Regular',
      },
    },
    noWrap: {
      true: {
        whiteSpace: 'nowrap',
      },
      false: {
        whiteSpace: 'normal',
        wordBreak: 'break-all',
      },
    },
    isInline: { true: { display: 'inline' }, false: {} },
  },

  defaultVariants: {
    noWrap: true,
    isInline: false,
  },
})
