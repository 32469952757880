import cjkSlug from 'cjk-slug'
import { graphql, useFragment } from 'react-relay'

import { useJobPostShareMutation_jobPost$key } from '@src/__generated__/useJobPostShareMutation_jobPost.graphql'
import { API_URLS } from '@src/config'
import { DAANGN_KEY } from '@src/constants/api'
import useBoolean from '@src/hooks/useBoolean'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { bridge } from '@src/sdks/bridge'
import { generateURL } from '@src/utils/url'

type ActionParams = {
  jobPostRef?: Nullable<useJobPostShareMutation_jobPost$key>
}

export function useJobPostShareMutation({ jobPostRef }: ActionParams) {
  const { logClickEvent } = useLogAnalyticsEvent()
  const [isLoading, setLoadingTrue, setLoadingFalse] = useBoolean()
  const jobPost = useFragment(
    graphql`
      fragment useJobPostShareMutation_jobPost on JobPost {
        _id
        title
        permalink {
          publicId
        }
        author {
          ... on HoianUserAuthor {
            badge {
              items {
                __typename
              }
            }
          }
        }
        bringUpCount
      }
    `,
    jobPostRef
  )

  const handleShareClick = async () => {
    if (!jobPost || isLoading) return

    setLoadingTrue()

    const url = generateURL({
      base: API_URLS[DAANGN_KEY],
      paths: `/kr/job-posts/${cjkSlug(jobPost.title ?? '')}-${jobPost.permalink.publicId}`,
    })

    logClickEvent({
      name: 'share_job_post',
      params: {
        job_post_id: jobPost._id,
        badges: jobPost.author.badge?.items.map((a) => a.__typename).join(',') || undefined,
        bring_up_count: jobPost.bringUpCount,
      },
    })

    bridge.share.open({ url, text: jobPost.title ?? '' })

    setLoadingFalse()
  }

  return { mutateWithCallback: handleShareClick, isLoading }
}
