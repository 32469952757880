import { useActivityParams } from '@stackflow/react'
import React from 'react'

import { useStepNavigator } from '@src/hooks/useStepNavigator'

import ImageDetailSlider from './ImageDetailSlider'
import ImageSlider from './ImageSlider'

interface Props {
  images: string[]
}

const ImageContainer: React.FCC<Props> = ({ images }) => {
  const { selectedIdx, ...params } = useActivityParams<{ selectedIdx?: string }>()
  const { stepPush, stepPop } = useStepNavigator()
  const selectedImageIdx = selectedIdx !== undefined ? Number(selectedIdx) : null

  const onSelectDetailImage = (idx: number) => {
    stepPush({ ...params, selectedIdx: idx })
  }

  const onCloseDetailImage = () => {
    stepPop()
  }

  return (
    <>
      <ImageSlider images={images} onDetailClick={onSelectDetailImage} />
      <ImageDetailSlider onClose={onCloseDetailImage} initialIndex={selectedImageIdx} images={images} />
    </>
  )
}

export default ImageContainer
