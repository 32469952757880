import React from 'react'

interface UseClickedElementResult {
  clickedElement: HTMLElement | null
  clickedTime: number | null
}

function useClickedElement(enabled: boolean): UseClickedElementResult {
  const [clickedElement, setClickedElement] = React.useState<HTMLElement | null>(null)
  const [clickTime, setClickTime] = React.useState<Date | null>(null)

  React.useEffect(() => {
    if (!enabled) {
      setClickedElement(null)
      setClickTime(null)
      return
    }

    const handleClick = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement || event.target instanceof SVGElement) {
        setClickedElement(event.target as HTMLElement)
        setClickTime(new Date())
      }
    }

    document.addEventListener('click', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [enabled])

  return { clickedElement, clickedTime: clickTime?.getTime() ?? null }
}

export default useClickedElement
