import Image from '@src/components/base/image/Image'
import { styled } from '@src/stitches/stitches.config'

const Avatar = styled(Image, {
  borderRadius: '50%',

  variants: {
    size: {
      xxsmall: {
        width: '20px',
        height: '20px',
      },
      xsmall: {
        width: '24px',
        height: '24px',
      },
      small: {
        width: '36px',
        height: '36px',
      },
      medium: {
        width: '48px',
        height: '48px',
      },
      large: {
        width: '64px',
        height: '64px',
      },
      xlarge: {
        width: '80px',
        height: '80px',
      },
      xxlarge: {
        width: '96px',
        height: '96px',
      },
    },
  },

  defaultVariants: {
    size: 'small',
  },
})

export default Avatar
