import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { UserReceivedApplicantPositiveReviewCountResolver$key as UserReceivedApplicantPositiveReviewCountResolver$key } from '@src/__generated__/UserReceivedApplicantPositiveReviewCountResolver.graphql'

/**
 *
 * @RelayResolver User.receivedApplicantPositiveReviewCount: Int
 * @rootFragment UserReceivedApplicantPositiveReviewCountResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function receivedApplicantPositiveReviewCount(
  userRef: UserReceivedApplicantPositiveReviewCountResolver$key
): number {
  const user = readFragment(
    graphql`
      fragment UserReceivedApplicantPositiveReviewCountResolver on User {
        receivedJobReviewsPositiveCountByApplicant: receivedJobReviewsCount(
          filter: { evaluator: APPLICANT, rating: [GREAT, GOOD] }
        )
      }
    `,
    userRef
  )

  return user.receivedJobReviewsPositiveCountByApplicant ?? 0
}
