import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics'
import { vars } from '@seed-design/design-token'
import { IconCloseRegular } from '@seed-design/icon'
import { basicUIPlugin } from '@stackflow/plugin-basic-ui'
import { devtoolsPlugin } from '@stackflow/plugin-devtools'
import { historySyncPlugin } from '@stackflow/plugin-history-sync'
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic'
import { stackflow, useActions } from '@stackflow/react'
import { get, mapValues } from 'lodash/fp'

import { eventManagerPlugin } from '@src/packages/eventManager/stackflowPlugin'
import { activities, routes, TypeActivities } from '@src/router/activities'
import { JobsActivity } from '@src/router/activityNames'
import { activityParamsSchemas } from '@src/router/activityParams'
import { bridge, karrotBridge, theme } from '@src/sdks/bridge'
import { theme as stitchesTheme } from '@src/stitches/stitches.config'

import depthChangePlugin from './plugins/depthChangePlugin'
import jobsLayoutPlugin from './plugins/jobsLayoutPlugin'
import jobsVisitedPlugin from './plugins/jobsVisitedPlugin'
import referrerPlugin from './plugins/referrerPlugin'

const appBar = {
  borderColor: theme.environment === 'Cupertino' ? vars.$semantic.color.divider3 : vars.$semantic.color.divider2,
  textColor: vars.$scale.color.gray900,
  iconColor: vars.$scale.color.gray900,
  overflow: 'visible',
}

export const { Stack, useStepFlow } = stackflow({
  transitionDuration: 350,
  activities,
  plugins: [
    devtoolsPlugin(),
    basicRendererPlugin(),
    basicUIPlugin({
      theme: theme.environment.toLowerCase() as Lowercase<typeof theme.environment>,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        ...appBar,
        closeButton: {
          renderIcon: () => (
            <IconCloseRegular width={24} height={24} color={stitchesTheme.colors.gray900.computedValue} />
          ),
          onClick: () => {
            if (bridge.environment === 'Web') {
              alert('bridge close event trigger')
              return
            }

            bridge.router.close()
          },
        },
      },
    }),
    historySyncPlugin({
      routes: mapValues(get('path'))(routes),
      fallbackActivity: () => JobsActivity.NOT_FOUND,
      urlPatternOptions: { segmentValueCharset: 'a-zA-Z0-9-_~ %=' },
    }),
    depthChangePlugin(),
    jobsLayoutPlugin(),
    jobsVisitedPlugin(),
    referrerPlugin(),
    karrotAnalyticsPlugin({
      bridge: karrotBridge,
      serviceName: 'jobs',
      // TODO: karrotAnalyticsPlugin BaseActivities Type 맞춰주기
      itemId: {
        [JobsActivity.JOB_POST]: (activityParams) => {
          return (activityParams as (typeof activityParamsSchemas)[typeof JobsActivity.JOB_POST]).jobPostId
        },
        [JobsActivity.JOB_POST_EXTERNAL]: (activityParams) => {
          return (activityParams as (typeof activityParamsSchemas)[typeof JobsActivity.JOB_POST]).jobPostId
        },
        [JobsActivity.JOB_POST_AD]: (activityParams) => {
          return (activityParams as (typeof activityParamsSchemas)[typeof JobsActivity.JOB_POST]).jobPostId
        },
      },
    }),
    eventManagerPlugin(),
  ],
})

export const useFlow = () => {
  return useActions<TypeActivities>()
}
