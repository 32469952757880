import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/zoom'

import SwiperCore from 'swiper'
import { Pagination, Autoplay, Zoom } from 'swiper/modules'

// eslint-disable-next-line no-restricted-imports
export * from 'swiper/react'

SwiperCore.use([Autoplay, Pagination, Zoom])
