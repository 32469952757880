import React from 'react'

import { useGetRouteInfo } from '@src/hocs/withRouteInfo'

const PageCodeInfoContext = React.createContext<{ pageSectionCode?: string; pageContext?: string } | undefined>(
  undefined
)

export const useGetPageCodeInfo = () => {
  const { pageCode } = useGetRouteInfo()
  const pageCodeInfo = React.useContext(PageCodeInfoContext)

  return React.useMemo(
    () => ({
      pageCode, // 페이지 고유 코드
      pageSectionCode: pageCodeInfo?.pageSectionCode, // 페이지의 특정 섹션을 나타내는 코드
      pageContext: pageCodeInfo?.pageContext,
    }),
    [pageCode, pageCodeInfo]
  )
}

type Props = { pageSectionCode: string; pageContext?: string }
export const PageCodeInfoProvider: React.FCC<Props> = ({ children, pageSectionCode, pageContext }) => {
  const pageCodeInfo = React.useMemo(() => {
    return {
      pageSectionCode,
      pageContext,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSectionCode, pageContext])

  return <PageCodeInfoContext.Provider value={pageCodeInfo}>{children}</PageCodeInfoContext.Provider>
}
