/**
 * @generated SignedSource<<5a35782710e87646088e5f8053e10c4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertisementExposureType = "STANDARD" | "TOP_PREMIUM" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobPostCardContainer_feedCard$data = {
  readonly advertisement: {
    readonly callback: {
      readonly click: string | null | undefined;
      readonly hide: string | null | undefined;
      readonly impression: string | null | undefined;
    } | null | undefined;
    readonly exposureType: AdvertisementExposureType | null | undefined;
  } | null | undefined;
  readonly jobPost: {
    readonly _id: string;
    readonly author: {
      readonly badge?: {
        readonly items: ReadonlyArray<{
          readonly __typename: "FastResponseBadge";
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        }>;
      } | null | undefined;
    };
    readonly bringUpCount: number;
  };
  readonly " $fragmentType": "JobPostCardContainer_feedCard";
};
export type JobPostCardContainer_feedCard$key = {
  readonly " $data"?: JobPostCardContainer_feedCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostCardContainer_feedCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostCardContainer_feedCard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FeedAdvertisementInfo",
      "kind": "LinkedField",
      "name": "advertisement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exposureType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdvertisementCallback",
          "kind": "LinkedField",
          "name": "callback",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hide",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "impression",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "click",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobPost",
      "kind": "LinkedField",
      "name": "jobPost",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bringUpCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "author",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AuthorBadge",
                  "kind": "LinkedField",
                  "name": "badge",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "items",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            }
                          ],
                          "type": "FastResponseBadge",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "HoianUserAuthor",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FeedCard",
  "abstractKey": null
};

(node as any).hash = "9110842a6d794c7beaa828c0a216fbf4";

export default node;
