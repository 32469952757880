export const JobsActivity = {
  // ------------------------------- Common ------------------------------------
  ROOT: 'common.root',
  MAIN: 'common.main',
  MAIN_EXTERNAL: 'common.main_external',
  RESTRICTION_DETAIL: 'common.restriction_detail',
  SEARCH: 'common.main_nested_search',
  SEARCH_RESULT: 'common.main_nested_search_result',
  NEARBY_ONBOARDING: 'common.nearby_onboarding',
  REVIEW_WRITE: 'common.review_write',
  REVIEW_WRITTEN: 'common.review_written',
  REVIEW_RECEIVED: 'common.review_received',
  REVIEWS_RECEIVED_GREAT: 'common.reviews_received_great',
  SHARED_SERVICE: 'common.shared_service',
  INVITE_EVENT_SHARE: 'common.invite_event_share',
  INVITE_EVENT_INVITED: 'common.invite_event_invited',
  INVITE_EVENT_PRIZE_CHECK: 'common.invite_event_prize_check',
  INTRODUCE_JOBS: 'common.introduce_jobs',
  ADVERTISEMENT_ACCOUNT_CREATE: 'common.advertisement_account_create',
  TERMS_KARROT_PRIVACY: 'common.terms_karrot_privacy',
  CAMPAIGN: 'common.campaign',
  GWANGSANGU_CITIZEN_SUPPORT: 'common.gwangsangu_citizen_support',

  // ------------------------------- Applicant ---------------------------------
  JOB_POST: 'applicant.job_post',
  JOB_POST_AD: 'applicant.job_post_ad',
  JOB_POST_EXTERNAL: 'applicant.job_post_external',
  JOB_POST_APPLY: 'applicant.job_post_apply',
  JOB_POST_APPLY_CONFIRM: 'applicant.job_post_apply_confirm',
  JOB_POSTS_DIGEST: 'applicant.job_posts_digest',
  JOB_POST_BUSINESS_CERTIFICATE: 'applicant.job_post_business_certificate',
  AUTHOR_JOB_POSTS: 'applicant.author_job_posts',
  AUTHOR_REVIEWS_COMMENT: 'applicant.author_reviews_comment',
  ME: 'applicant.me',
  RESUME_MANAGE: 'applicant.resume_manage',
  RESUME_PREVIEW: 'applicant.resume_preview',
  RESUME_UPDATE: 'applicant.resume_update',
  RESUME_CREATE_AND_APPLY: 'applicant.resume_create_and_apply',
  REGION_INFO: 'applicant.region_info',
  RESUME_EXPERIENCE_ADD: 'applicant.resume_experience_add',
  EXPERIENCE_ADD: 'applicant.experience_add',
  EXPERIENCE_UPDATE: 'applicant.experience_update',
  REVIEW_LIST_TO_WRITE: 'applicant.review_list_to_write',
  APPLICATION_CONFIRM_HIRED: 'applicant.application_confirm_hired',
  REVIEWS_SELECTED: 'applicant.reviews_selected',
  NEW_JOB_POST_FILTER_SUBSCRIPTION: 'applicant.new_job_post_filter_subscription',
  COLLABORATION_RECRUITMENT: 'applicant.collaboration_recruitment',
  FEED_EVENT_GROUP: 'applicant.feed_event_group',
  CURATION_FEED: 'applicant.curation_feed',
  CURATION_FEED_EXTERNAL: 'applicant.curation_feed_external',
  // ------------------------------- Employer ----------------------------------
  BIZ_MAIN: 'employer.biz_main',
  BIZ_JOB_POST_MANAGE: 'employer.biz_job_post_manage',
  BIZ_JOB_POST_PREVIEW: 'employer.biz_job_post_preview',
  BIZ_JOB_POST_CREATE: 'employer.biz_job_post_create',
  BIZ_JOB_POST_CREATE_CONFIRM_REGISTER_FEE: 'employer.biz_job_post_create_confirm_register_fee',
  BIZ_JOB_POST_CREATE_CONFIRM_CAMPAIGN: 'employer.biz_job_post_create_confirm_campaign',
  BIZ_JOB_POST_CREATE_PURCHASE: 'employer.biz_job_post_create_purchase',
  BIZ_JOB_POST_MIGRATE: 'employer.biz_job_post_migrate',
  BIZ_JOB_POST_UPDATE: 'employer.biz_job_post_update',
  BIZ_JOB_POST_UPDATE_EXTEND: 'employer.biz_job_post_update_extend',
  BIZ_JOB_POST_BRING_UP: 'employer.biz_job_post_bring_up',
  BIZ_JOB_POST_ADVERTISEMENT: 'employer.biz_job_post_advertisement',
  BIZ_JOB_POST_ADVERTISEMENT_CREATE: 'employer.biz_job_post_advertisement_create',
  BIZ_JOB_POST_ADVERTISEMENT_UPDATE: 'employer.biz_job_post_advertisement_update',
  BIZ_JOB_POST_ADVERTISEMENT_RESERVE: 'employer.biz_job_post_advertisement_reserve',
  BIZ_JOB_POST_ADVERTISEMENT_RESERVE_REQUEST_DONE: 'employer.biz_job_post_advertisement_reserve_request_done',
  BIZ_JOB_POST_ADVERTISEMENT_TYPE_DETAIL: 'employer.biz_job_post_advertisement_type_detail',
  BIZ_JOB_POST_LARGE_SCALE_BUSINESS_GUIDE: 'employer.biz_job_post_large_scale_business_guide',
  BIZ_JOB_POST_SLOT_OVERFLOW: 'employer.biz_job_post_slot_overflow',
  BIZ_JOB_POST_COPY: 'employer.biz_job_post_copy',
  BIZ_JOB_POST_SEARCH_ADDRESS: 'employer.biz_job_post_search_address',
  BIZ_APPLICATIONS: 'employer.biz_applications',
  BIZ_APPLICATION_DETAIL: 'employer.biz_application_detail',
  BIZ_APPLICATION_PURCHASE: 'employer.biz_application_purchase',
  BIZ_APPLICATION_REVIEWS_SELECTED: 'employer.biz_application_reviews_selected',
  BIZ_APPLICATION_REJECT: 'employer.biz_application_reject',
  BIZ_APPLICATIONS_REJECT: 'employer.biz_applications_reject',
  BIZ_APPLICATIONS_SELECT: 'employer.biz_applications_select',
  BIZ_APPLICATIONS_SELECT_CONFIRM: 'employer.biz_applications_select_confirm',
  BIZ_BUSINESS_CERTIFICATE: 'employer.biz_business_certificate',
  BIZ_IDENTIFICATION_CERTIFICATE: 'employer.biz_identification_certificate',
  BIZ_BUSINESS_CERTIFICATE_EXTERNAL: 'employer.biz_business_certificate_external',
  BIZ_BUSINESS_CERTIFICATE_ASK: 'employer.biz_business_certificate_ask',
  BIZ_BUSINESS_CERTIFICATE_MANAGE: 'employer.biz_business_certificate_manage',
  BIZ_BUSINESS_CERTIFICATE_ADD_COMPANY_NAME: 'employer.biz_business_certificate_add_company_name',
  BIZ_ADVERTISEMENT_CREATE: 'employer.biz_advertisement_create',
  BIZ_AUTHOR_UPDATE: 'employer.biz_author_update',
  BIZ_AUTHOR_INTRODUCTION_EXAMPLE: 'employer.biz_author_introduction_example',
  BIZ_AUTHOR_BADGE: 'employer.biz_author_badge',
  BIZ_JOB_POSTS: 'employer.biz_job_posts',
  BIZ_AUTHOR_REVIEWS: 'employer.biz_author_reviews',
  // ------------------------------- External Halfview -------------------------
  BIZ_JOB_POST_STATUS_CHANGE: 'external_halfview.biz_job_post_status_change',
  NEED_LOGIN: 'service.need_login',
  NOT_FOUND: 'service.not_found',
  // ------------------------------- Legacy Handling ---------------------------
  APPLICATIONS_MANAGE: 'legacy_handling.applications_manage',
  FAVORITES_MANAGE: 'legacy_handling.favorites_manage',
  APPLICATION_VIEW: 'legacy_handling.application_view',
  FEED_EVENT: 'legacy_handling.feed_event',
  // ------------------------------- Test --------------------------------------
  ERROR_TEST: 'test.error_test',
  EVENT_TEST: 'test.event_test',
} as const

export type ActivityName = (typeof JobsActivity)[keyof typeof JobsActivity]
