import { CURRENT_YEAR } from './date'

export const CURRENT_MIN_SALARY = { 2022: 9160, 2023: 9620, 2024: 9860 }[CURRENT_YEAR] ?? 9860

// 법률 상 취직 인허증 필요한 연령 (해당 연도 생일이 지나지 않은 경우로 계산)
// 만 14세 출생연도
export const YOUTH_YEAR_MIN = new Date().getFullYear() - 15
// 만 13세 출생연도
export const YOUTH_YEAR_MAX = new Date().getFullYear() - 14

// 걸어서 10분 미터
export const WALKING_DISTANCE_METER = 700

// 광고 고객센터
export const AD_ASK_TEL_NUMBER = '16449736'
