export const MAIN_PATH = '/main'

export const SLOT_OVERFLOW_PATH = '/biz/job-post/slot-overflow'

export enum JobPostApplyPageNestedPage {
  TERMS = 'terms',
  DIRECT_APPLY = 'direct-apply',
  UPDATE_CONTENT = 'update-content',
  UPDATE_NAME = 'update-name',
  PREVIEW = 'preview',
}
