import React from 'react'

import { JsonViewerOptions, JsonViewerType, initJsonViewer } from '@src/sdks/jsonViewer'

type DevToolLibrary = {
  JsonViewer?: React.FCC<JsonViewerOptions>
}

const DevToolLibraryContext = React.createContext<DevToolLibrary>({})

export const useDevToolLibrary = () => {
  const devToolLibrary = React.useContext(DevToolLibraryContext)
  return devToolLibrary
}

export const DevToolLibraryProvider: React.FCC = ({ children }) => {
  const [devToolLibrary, setDevToolLibrary] = React.useState<DevToolLibrary | null>(null)

  React.useEffect(() => {
    initJsonViewer().then((comp) => {
      setDevToolLibrary({ JsonViewer: withJsonViewer(comp) })
    })
  }, [])

  if (!devToolLibrary) return null
  return <DevToolLibraryContext.Provider value={devToolLibrary}>{children}</DevToolLibraryContext.Provider>
}

const withJsonViewer = (JsonViewer: JsonViewerType) => {
  const WithJsonViewer: React.FC<JsonViewerOptions> = (props) => {
    const uid = React.useId()
    const domId = `json-viewer-${uid.replaceAll(':', '')}`

    React.useEffect(() => {
      new JsonViewer(props).render(`#${domId}`)
    }, [props, domId])

    return <div id={domId} />
  }

  return WithJsonViewer
}
