import { ARTICLE_PATH } from '@src/constants/api'
import { hashCode } from '@src/utils/hash'

import Request from './Request'

const REQUEST_UPLOAD_URL = '/api/v2/request_upload_url'

export default class BigPicture extends Request {
  constructor(baseURL: string) {
    super(baseURL)

    this.setPlantaeInterceptor()
    this.setTokenHeader()
    this.setUserAgentHeader()
    this.setRequestIdHeader()
  }

  public async requestUploadUrl({
    blob,
    file,
    image,
    userId,
  }: {
    blob: Blob
    file: File
    image: HTMLCanvasElement
    userId: number
  }): Promise<{
    data: {
      image: {
        id: string
        old_image_id: number
        filepath: string
        type: string
        service: string
        width: number
        height: number
        url: string
        thumbnail: string
        medium: string
        __typename: string
        region: string
        user_id: number
        upload_url: string
        content_type: string
      }
    }
  }> {
    const { data } = await this.post({
      url: REQUEST_UPLOAD_URL,
      data: {
        // Todo region 대응
        body: blob,
        region: 'kr',
        service: 'jobs',
        type: 'article',
        width: image.width,
        height: image.height,
        content_type: file.type,
        user_id: userId,
        filepath: `${ARTICLE_PATH}/${userId}/${Date.now()}/job-post-${hashCode(file.name)}.${file.type.replace(
          'image/',
          ''
        )}`,
      },
    })

    return data
  }
}
