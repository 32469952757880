import React from 'react'

type Props = {
  width?: number
  height?: number
} & JSX.IntrinsicElements['svg']

const Manner02: React.FCC<Props> = ({ width = 24, height = 24, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FADD65"
      />
      <path
        opacity="0.85"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.02382 7.16886C8.04102 7.37525 7.88765 7.55651 7.68126 7.57371L5.88126 7.72371C5.67487 7.74091 5.49361 7.58754 5.47641 7.38114C5.45921 7.17475 5.61259 6.9935 5.81898 6.9763L7.61898 6.8263C7.82537 6.8091 8.00662 6.96247 8.02382 7.16886Z"
        fill="#D49D3A"
      />
      <path
        opacity="0.85"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.826 7.16886C15.8432 6.96247 16.0245 6.8091 16.2309 6.8263L18.0309 6.9763C18.2373 6.9935 18.3906 7.17475 18.3734 7.38114C18.3562 7.58754 18.175 7.74091 17.9686 7.72371L16.1686 7.57371C15.9622 7.55651 15.8088 7.37525 15.826 7.16886Z"
        fill="#D49D3A"
      />
      <path
        d="M8.84981 11.4C8.84981 12.6 8.24981 13.5 7.64981 13.5C7.04981 13.5 6.2998 12.6 6.2998 11.4"
        fill="#895F00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5874 11.4C5.5874 11.0893 5.83924 10.8375 6.1499 10.8375H8.9999C9.31056 10.8375 9.5624 11.0893 9.5624 11.4C9.5624 11.7107 9.31056 11.9625 8.9999 11.9625H6.1499C5.83924 11.9625 5.5874 11.7107 5.5874 11.4Z"
        fill="#895F00"
      />
      <path d="M17.7 11.4C17.7 12.6 17.1 13.5 16.35 13.5C15.6 13.5 15 12.6 15 11.4" fill="#895F00" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2876 11.4C14.2876 11.0893 14.5394 10.8375 14.8501 10.8375H17.8501C18.1608 10.8375 18.4126 11.0893 18.4126 11.4C18.4126 11.7107 18.1608 11.9625 17.8501 11.9625H14.8501C14.5394 11.9625 14.2876 11.7107 14.2876 11.4Z"
        fill="#895F00"
      />
      <path
        d="M9.4799 17.1C10.0386 16.8876 10.8666 16.6875 11.9983 16.6875C13.13 16.6875 13.958 16.8876 14.5167 17.1C14.7953 17.2059 15.005 17.3142 15.1505 17.4005C15.2232 17.4436 15.2797 17.4811 15.3206 17.5101C15.599 17.7066 15.7059 18.0806 15.4703 18.3601C15.2706 18.597 14.9155 18.6305 14.6742 18.4364C14.6742 18.4364 14.6674 18.4314 14.6591 18.4255C14.642 18.4134 14.6108 18.3924 14.5649 18.3651C14.4733 18.3108 14.3226 18.2316 14.1081 18.15C13.6805 17.9874 12.991 17.8125 11.9983 17.8125C11.3151 17.8125 10.7755 17.8953 10.3661 18C10.1806 18.0474 10.0218 18.0993 9.88844 18.15C9.67393 18.2316 9.52326 18.3108 9.43159 18.3652C9.38571 18.3924 9.3545 18.4134 9.33738 18.4255C9.32908 18.4314 9.32233 18.4365 9.32233 18.4365C9.08097 18.6305 8.72592 18.597 8.52621 18.3601C8.29521 18.0861 8.40226 17.7032 8.6759 17.51C8.71687 17.4811 8.77339 17.4436 8.84606 17.4005C8.99151 17.3142 9.20123 17.2059 9.4799 17.1Z"
        fill="#895F00"
      />
    </svg>
  )
}

export default Manner02
