import { IconMoreVertRegular } from '@seed-design/icon'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFragment, graphql } from 'react-relay'

import { ApplicantReviewCard_jobReview$key } from '@src/__generated__/ApplicantReviewCard_jobReview.graphql'
import Avatar from '@src/components/base/avatar/Avatar'
import NonBlockingGlobalLoader from '@src/components/base/loader/NonBlockingGlobalLoader'
import LoadMoreContent from '@src/components/base/LoadMoreContent'
import ListModal from '@src/components/base/modal/ListModal'
import TagGroup from '@src/components/base/tag/TagGroup'
import { SCHEME_PREFIX } from '@src/config'
import { IMAGE_RESUME_DEFAULT } from '@src/constants/images'
import useModalState from '@src/hooks/useModalState'
import { useReportJobReviewMutation } from '@src/mutations/useReportJobReviewMutation'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { userIdSelector } from '@src/selectors/user'
import { styled } from '@src/stitches/stitches.config'
import { calcPassedDate } from '@src/utils/date'

type Props = {
  jobReviewRef: ApplicantReviewCard_jobReview$key
} & Omit<JSX.IntrinsicElements['div'], 'ref'>

const ApplicantReviewCard: React.FCC<Props> = ({ jobReviewRef, ...props }) => {
  const { reportJobReview, loading } = useReportJobReviewMutation()
  const { logClickEvent } = useLogAnalyticsEvent()
  const userId = useSelector(userIdSelector)
  const jobReview = useFragment(
    graphql`
      fragment ApplicantReviewCard_jobReview on JobReview {
        id
        _id
        application {
          status
          jobPost {
            author {
              ... on HoianUserAuthor {
                hoianUser {
                  id
                }
              }
            }
            title
            id
          }
        }
        reviewer {
          _id
          image {
            url
          }
          nickname
          region {
            name3
          }
          displayRegionCheckinsCount
        }
        reviewee {
          _id
        }
        workingConfirmed
        rating
        comment
        createdAt
      }
    `,
    jobReviewRef
  )
  const [isModalOpen, setModalOpen, setModalClose] = useModalState()

  if (!jobReview) return null

  const isActorReviewee = userId === jobReview.reviewee._id
  const imageUrl = jobReview.reviewer.image?.url

  const handleProfileClick = () => {
    // TODO: generateScheme 등으로 개선 필요 (SCHEME_PREFIX일 때 `://` 붙이는 로직)
    const url = `${SCHEME_PREFIX}://users/${jobReview.reviewer._id} `
    window.location.href = url
  }

  const handleReportClick = () => {
    if (loading) return

    logClickEvent({
      name: 'report_applicant',
      params: {
        application_status: jobReview.application.status,
      },
    })

    reportJobReview({
      variables: { where: { jobReview: jobReview._id } },
      configs: [
        {
          type: 'RANGE_DELETE',
          parentID: jobReview.application.jobPost?.author.hoianUser?.id,
          connectionKeys: [
            {
              key: 'ReviewCommentByApplicantListPagination_receivedJobReviews',
              filters: {
                filter: {
                  hasComment: true,
                  evaluator: 'APPLICANT',
                },
              },
            },
          ],
          pathToConnection: ['jobPostAuthorById', 'hoianUser', 'receivedJobReviews'],
          deletedIDFieldName: ['jobReview'],
        },
      ],
      updater: (store) => {
        store.delete(jobReview.id)
      },
    })
    setModalClose()
  }

  const badgeItems: { type: 'primary' | 'secondary'; title: string }[] = [
    {
      type: jobReview.workingConfirmed === 'CONFIRMED' ? 'primary' : 'secondary',
      title: jobReview.workingConfirmed === 'CONFIRMED' ? '실제 근무' : '대화만 했어요',
    },
    {
      type: 'secondary',
      title: jobReview.rating === 'GREAT' ? '추천해요' : '좋아요',
    },
  ]

  return (
    <>
      <NonBlockingGlobalLoader isOpen={loading} />

      <Base {...props}>
        <BadgeContainer>
          {badgeItems.map((badge) => {
            return (
              <Badge key={badge.title} type={badge.type}>
                {badge.title}
              </Badge>
            )
          })}
        </BadgeContainer>

        <CommentContainer content={jobReview.comment} length={70} />

        <BottomContainer>
          <ProfileContainer onClick={handleProfileClick}>
            <Avatar size="small" border={true} source={imageUrl ?? IMAGE_RESUME_DEFAULT} />
            <TextContainer>
              <ReviewerNameContainer>
                <ReviewerName>{jobReview.reviewer.nickname ?? '삭제된 계정'}</ReviewerName>
              </ReviewerNameContainer>
              <TagGroup
                size="medium"
                items={[
                  {
                    visible: !!jobReview.reviewer.region?.name3,
                    value: `${jobReview.reviewer.region?.name3}${
                      jobReview.reviewer.displayRegionCheckinsCount > 0
                        ? ` 인증 ${jobReview.reviewer.displayRegionCheckinsCount}회`
                        : ''
                    }`,
                  },
                  `${calcPassedDate(jobReview.createdAt)} 전`,
                ]}
              />
            </TextContainer>
          </ProfileContainer>

          {isActorReviewee && (
            <MoreIconWrapper onClick={setModalOpen}>
              <IconMoreVertRegular width={24} height={24} />
            </MoreIconWrapper>
          )}
        </BottomContainer>
      </Base>

      {isModalOpen && (
        <ListModal
          open={isModalOpen}
          onClose={setModalClose}
          items={[{ label: '신고하기', onClick: handleReportClick, type: 'warning' }]}
        />
      )}
    </>
  )
}

export default ApplicantReviewCard

const Base = styled('div', {
  padding: '20px 0',

  '& + &': {
    borderTop: '1px solid $divider1-semantic',
  },
})

const BadgeContainer = styled('div', {
  margin: '0 0 8px',
})

const Badge = styled('div', {
  display: 'inline-block',
  padding: '2px 4px',
  borderRadius: '2px',

  variants: {
    type: {
      primary: {
        $text: 'label6Bold',
        color: '$white-static',
        background: '$primary-semantic',
      },
      secondary: {
        $text: 'label6Regular',
        color: '$gray700',
        background: '$gray100',
      },
    },
  },

  '& + &': {
    margin: '0 0 0 6px',
  },
})

const BottomContainer = styled('div', {
  margin: '8px 0 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const ProfileContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const TextContainer = styled('div', {
  margin: '0 0 0 12px',
})

const ReviewerNameContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 4px',
})

const ReviewerName = styled('div', {
  $text: 'subtitle2Bold',
  color: '$gray900',
})

const MoreIconWrapper = styled('div', {
  color: '$gray900',
})

const CommentContainer = styled(LoadMoreContent, {
  $text: 'bodyM1Regular',
  color: '$gray900',
})
