import React from 'react'

import { styled } from '@src/stitches/stitches.config'

interface Props {
  masking: string
  unMasking: string
}

const Highlighter: React.FCC<Props> = ({ masking, unMasking }) => {
  const html: React.ReactNode[] = []
  const maskingLines = masking.split('\n')
  const unMaskingLines = unMasking.split('\n')

  const loop = maskingLines?.length ?? 0

  for (let i = 0; i < loop; i++) {
    if (maskingLines[i].includes('*')) {
      const maskingLineSplitWord = maskingLines[i].split('')
      const unMaskingLineSplitWord = unMaskingLines[i].split('')

      for (let j = 0; j < maskingLineSplitWord.length; j++) {
        if (maskingLineSplitWord[j] !== unMaskingLineSplitWord[j] && maskingLineSplitWord[j] === '*') {
          html.push(<Highlight>*</Highlight>)
        } else {
          html.push(maskingLineSplitWord[j])
        }
      }
    } else {
      html.push(maskingLines[i])
    }
    html.push(<br />)
  }

  return html
}

export default React.memo(Highlighter)

const Highlight = styled('span', {
  background: '$carrot300',
})
