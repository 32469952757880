import { ApplicationStatus, ApplicationStatusType } from '@src/types/application'
import { MS } from '@src/utils/date'

export const calcApplicationStatus = (status?: Nullable<ApplicationStatusType>) => {
  return {
    isWaiting: status === ApplicationStatus.WAITING,
    isHired: status === ApplicationStatus.HIRED,
    isRejected: status === ApplicationStatus.REJECTED,
    isInProgress: status === ApplicationStatus.IN_PROGRESS,
  }
}

export const calcApplicationCountByStatus = (
  countByStatus?: DeepReadonly<
    {
      status: ApplicationStatusType
      count: number
    }[]
  >
) => {
  const calcCountByStatus = (status: ApplicationStatusType) =>
    countByStatus?.find((item) => item.status === status)?.count ?? 0

  return {
    waitingCount: calcCountByStatus(ApplicationStatus.WAITING),
    inProgressCount: calcCountByStatus(ApplicationStatus.IN_PROGRESS),
    hiredCount: calcCountByStatus(ApplicationStatus.HIRED),
    rejectedCount: calcCountByStatus(ApplicationStatus.REJECTED),
  }
}

export const stringifyApplicationCount = (
  countInfo: DeepReadonly<{ status: ApplicationStatusType; count: number }[]>,
  status: ApplicationStatus
) => {
  const MAX_APPLICATION_COUNT_BY_SERVER_LIMIT = 20
  const count = countInfo.find((item) => item.status === status)?.count ?? 0

  if (count <= MAX_APPLICATION_COUNT_BY_SERVER_LIMIT) {
    return count
  }

  return `${count}+`
}

export const isBMApplicationOpenable = (jobPostCreatedAt: Dateable) => {
  return new Date(jobPostCreatedAt).getTime() + MS.day * 3 <= Date.now()
}
