import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@src/ducks/rootReducer'

export const filterSelector = createSelector(
  (state: RootState) => {
    return state.filter
  },
  (d) => d
)
