import { ChipToggleButton, type SeedChipToggleButtonProps } from '@daangn/sprout-components-chips'
import { IconAddRegular, IconCloseRegular } from '@seed-design/icon'
import React from 'react'
import { match } from 'ts-pattern'

interface Props extends Omit<SeedChipToggleButtonProps, 'isSelected'> {
  action?: 'normal' | 'add' | 'close'
  label: string | React.ReactNode
  isDisabled?: boolean
  className?: string
  isActive?: boolean
  style?: React.CSSProperties
}

const ActionChip: React.FCC<Props> = ({
  label,
  size = 'medium',
  action = 'normal',
  isActive = false,
  prefix,
  suffix,
  isDisabled,
  className,
  style,
  ...props
}) => {
  const ActionIcon = match(action)
    .with('add', () => <IconAddRegular width="100%" height="100%" />)
    .with('close', () => <IconCloseRegular width="100%" height="100%" />)
    .otherwise(() => null)

  return (
    <ChipToggleButton
      isSelected={isActive}
      isDisabled={isDisabled}
      size={size}
      prefix={prefix}
      suffix={suffix ? suffix : ActionIcon}
      UNSAFE_className={className}
      UNSAFE_style={style}
      {...props}>
      {label}
    </ChipToggleButton>
  )
}

export default ActionChip
