import { getDay, startOfDay, toDate } from 'date-fns'
import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { InfoWorkDatesCalendar_jobPost$key } from '@src/__generated__/InfoWorkDatesCalendar_jobPost.graphql'
import Calendar from '@src/components/base/calendar/Calendar'
import { styled } from '@src/stitches/stitches.config'
import { MS, sortDates } from '@src/utils/date'
import { calcShortWorkPeriod } from '@src/utils/jobPost'

interface Props {
  isOpen: boolean
  jobPostRef: InfoWorkDatesCalendar_jobPost$key
}

const InfoWorkDatesCalendar: React.FCC<Props> = ({ isOpen, jobPostRef }) => {
  const jobPost = useFragment(
    graphql`
      fragment InfoWorkDatesCalendar_jobPost on JobPost {
        workPeriod
        workDates
      }
    `,
    jobPostRef
  )
  const isShortWorkPeriod = calcShortWorkPeriod(jobPost.workPeriod)

  const options = React.useMemo(() => {
    if (!isShortWorkPeriod || !jobPost.workDates || !jobPost.workDates.length) return null

    const LAST_DAY_AT_WEEK = 6
    const sortedDates = sortDates(jobPost.workDates.map((d) => startOfDay(new Date(d))))
    const startDateTime = Math.min(startOfDay(new Date()).getTime(), sortedDates[0].getTime())
    const lastDateTime = sortedDates[sortedDates.length - 1].getTime()

    const displayStartDate = toDate(startDateTime - getDay(toDate(startDateTime)) * MS.day)
    const displayEndDate = toDate(lastDateTime + (LAST_DAY_AT_WEEK - getDay(lastDateTime)) * MS.day)
    const enableStartDate = displayStartDate
    const enableEndDate = displayEndDate

    const value = jobPost.workDates.map((d) => startOfDay(new Date(d)))

    return { displayStartDate, displayEndDate, enableStartDate, enableEndDate, value }
  }, [isShortWorkPeriod, jobPost.workDates])

  if (!isOpen || !jobPost.workDates || !options) return null

  return (
    <Base>
      <Calendar {...options} />
    </Base>
  )
}

export default InfoWorkDatesCalendar

const Base = styled('div', {
  margin: '8px -16px 0',
  padding: '12px 6px 6px',
  background: '$paperDefault-semantic',
})
