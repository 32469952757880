import React from 'react'

type Props = {
  width?: number
  height?: number
} & JSX.IntrinsicElements['svg']

const Manner06: React.FCC<Props> = ({ width = 24, height = 24, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FADD65"
      />
      <mask
        id="mask0_1769_31446"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#FAD84A"
        />
      </mask>
      <g mask="url(#mask0_1769_31446)">
        <path
          opacity="0.5"
          d="M4.05 20.1C7.52939 20.1 10.35 17.2794 10.35 13.8C10.35 10.3206 7.52939 7.5 4.05 7.5C0.570606 7.5 -2.25 10.3206 -2.25 13.8C-2.25 17.2794 0.570606 20.1 4.05 20.1Z"
          fill="url(#paint0_radial_1769_31446)"
        />
        <path
          opacity="0.5"
          d="M20.2502 20.1C23.7296 20.1 26.5502 17.2794 26.5502 13.8C26.5502 10.3206 23.7296 7.5 20.2502 7.5C16.7708 7.5 13.9502 10.3206 13.9502 13.8C13.9502 17.2794 16.7708 20.1 20.2502 20.1Z"
          fill="url(#paint1_radial_1769_31446)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.77152 7.75775C5.87768 7.4658 6.20043 7.31518 6.49238 7.42135L9.79238 8.62135C10.0173 8.70313 10.1658 8.91828 10.1626 9.15758C10.1594 9.39688 10.0051 9.60793 9.77803 9.68362L6.17803 10.8836C5.88331 10.9819 5.56476 10.8226 5.46652 10.5279C5.36828 10.2331 5.52756 9.91459 5.82227 9.81635L7.89066 9.12688L6.10792 8.47862C5.81597 8.37245 5.66535 8.04971 5.77152 7.75775Z"
        fill="#895F00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3784 7.75775C18.4846 8.04971 18.334 8.37245 18.042 8.47862L16.2593 9.12688L18.3277 9.81635C18.6224 9.91459 18.7817 10.2331 18.6834 10.5279C18.5852 10.8226 18.2666 10.9819 17.9719 10.8836L14.3719 9.68362C14.1449 9.60793 13.9906 9.39688 13.9874 9.15758C13.9841 8.91828 14.1327 8.70313 14.3576 8.62135L17.6576 7.42135C17.9495 7.31518 18.2723 7.4658 18.3784 7.75775Z"
        fill="#895F00"
      />
      <path
        d="M11.9998 12C11.3027 12 9.73007 12.0382 8.31833 12.1735C7.61989 12.2405 6.91341 12.3355 6.36266 12.4752C6.09593 12.5429 5.79651 12.6377 5.54098 12.7825C5.35231 12.8894 4.7998 13.2436 4.7998 13.95C4.7998 13.9791 4.80101 14.0082 4.80343 14.0372C4.99829 16.3755 7.39777 19.65 11.9998 19.65C16.7021 19.65 19.1998 16.4045 19.1998 13.95C19.1998 13.2436 18.6473 12.8894 18.4586 12.7825C18.2031 12.6377 17.9037 12.5429 17.6369 12.4752C17.0862 12.3355 16.3797 12.2405 15.6813 12.1735C14.2695 12.0382 12.6969 12 11.9998 12Z"
        fill="#895F00"
      />
      <path
        opacity="0.85"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03501 4.51805L6.03336 4.5213C6.03443 4.51936 6.03736 4.51413 6.04164 4.5071C6.05025 4.49299 6.06516 4.47004 6.08674 4.44182C6.1303 4.38484 6.19818 4.31016 6.29275 4.24261C6.47027 4.11582 6.77059 3.9917 7.25884 4.11377C7.45976 4.164 7.66336 4.04184 7.71359 3.84091C7.76382 3.63999 7.64166 3.43639 7.44074 3.38616C6.72899 3.20822 6.20431 3.38411 5.85682 3.63231C5.6889 3.75226 5.56927 3.88383 5.49097 3.98623L5.36438 4.18226C5.27176 4.3675 5.34684 4.59275 5.53208 4.68537C5.71611 4.77738 5.93962 4.70388 6.03336 4.5213"
        fill="#D49D3A"
      />
      <path
        opacity="0.85"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1149 4.51805L18.1165 4.5213C18.1155 4.51936 18.1125 4.51413 18.1083 4.5071C18.0997 4.49299 18.0847 4.47004 18.0632 4.44182C18.0196 4.38484 17.9517 4.31016 17.8571 4.24261C17.6796 4.11582 17.3793 3.9917 16.8911 4.11377C16.6901 4.164 16.4865 4.04184 16.4363 3.84091C16.3861 3.63999 16.5082 3.43639 16.7092 3.38616C17.4209 3.20822 17.9456 3.38411 18.2931 3.63231C18.461 3.75226 18.5806 3.88383 18.6589 3.98623L18.7855 4.18226C18.8781 4.3675 18.8031 4.59275 18.6178 4.68537C18.4338 4.77738 18.2103 4.70388 18.1165 4.5213"
        fill="#D49D3A"
      />
      <path
        d="M17.0884 14.089C17.1646 14.0249 17.2405 13.9353 17.2491 13.8216C17.257 13.7822 17.2534 13.7417 17.2383 13.7037C17.2211 13.6605 17.1925 13.6288 17.1661 13.6065C17.1153 13.5633 17.0468 13.533 16.9791 13.5096C16.8396 13.4616 16.6407 13.4232 16.4103 13.3913C15.9461 13.327 15.3169 13.2846 14.6879 13.2564C14.0577 13.2282 13.4233 13.2141 12.9467 13.2071C12.4147 13.1992 11.8828 13.1992 11.3508 13.2071C10.8719 13.2141 10.2328 13.2282 9.59329 13.2564C8.95478 13.2846 8.31195 13.327 7.8271 13.3911C7.58585 13.4231 7.37657 13.4613 7.22478 13.5081C7.14995 13.5313 7.07933 13.5594 7.0244 13.5957C6.97452 13.6287 6.8999 13.6943 6.8999 13.8C6.8999 13.8161 6.9025 13.8321 6.9076 13.8474C6.942 13.9507 7.0292 14.0326 7.1215 14.0972C7.21859 14.1652 7.34534 14.2303 7.49361 14.2923C7.79085 14.4166 8.19407 14.5361 8.66767 14.6403C9.61589 14.8489 10.8642 15 12.1499 15C13.4355 15 14.684 14.8489 15.614 14.6401C16.0781 14.5359 16.4688 14.4161 16.7468 14.2906C16.885 14.2282 17.0028 14.1612 17.0884 14.089Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1769_31446"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.0326 13.7608) scale(6.2826)">
          <stop stopColor="#F15A24" stopOpacity="0.7" />
          <stop offset="0.1984" stopColor="#F57F29" stopOpacity="0.7595" />
          <stop offset="0.4381" stopColor="#F9A62F" stopOpacity="0.8314" />
          <stop offset="0.6596" stopColor="#FCC133" stopOpacity="0.8979" />
          <stop offset="0.8539" stopColor="#FED235" stopOpacity="0.9562" />
          <stop offset="1" stopColor="#FFD836" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1769_31446"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.1917 13.7608) scale(6.2826)">
          <stop stopColor="#F15A24" stopOpacity="0.7" />
          <stop offset="0.1984" stopColor="#F57F29" stopOpacity="0.7595" />
          <stop offset="0.4381" stopColor="#F9A62F" stopOpacity="0.8314" />
          <stop offset="0.6596" stopColor="#FCC133" stopOpacity="0.8979" />
          <stop offset="0.8539" stopColor="#FED235" stopOpacity="0.9562" />
          <stop offset="1" stopColor="#FFD836" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default Manner06
