import { Activity } from '@stackflow/core'

import { JobsActivity } from '@src/router/activityNames'

export const getLiveAcitivites = (activities: Activity[]) => {
  return activities.filter((activity) => ['enter-done', 'enter-active'].includes(activity.transitionState))
}

export const isTopActivityMain = (activities: Activity[]) => {
  const liveActivities = getLiveAcitivites(activities)
  return liveActivities[0].name === JobsActivity.MAIN
}
