/**
 * @generated SignedSource<<d1ade783361d3d86327c372affbde789>>
 * @relayHash 1c7acab67f4028a9011ff9689230bfbc
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1c7acab67f4028a9011ff9689230bfbc

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvertisementExposureType = "STANDARD" | "TOP_PREMIUM" | "%future added value";
export type RecommendedPostsQuery$variables = {
  advertisement?: boolean | null | undefined;
  jobPostId: string;
  regionId: number;
  size: number;
};
export type RecommendedPostsQuery$data = {
  readonly recommendedFeed: ReadonlyArray<{
    readonly advertisement: {
      readonly exposureType: AdvertisementExposureType | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"JobPostFeedCardRecommend_feedCard">;
  }>;
};
export type RecommendedPostsQuery = {
  response: RecommendedPostsQuery$data;
  variables: RecommendedPostsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "advertisement"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobPostId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "size"
},
v4 = [
  {
    "kind": "Variable",
    "name": "advertisement",
    "variableName": "advertisement"
  },
  {
    "kind": "Variable",
    "name": "jobPostId",
    "variableName": "jobPostId"
  },
  {
    "kind": "Variable",
    "name": "regionId",
    "variableName": "regionId"
  },
  {
    "kind": "Variable",
    "name": "size",
    "variableName": "size"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exposureType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecommendedPostsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "FeedCard",
        "kind": "LinkedField",
        "name": "recommendedFeed",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedAdvertisementInfo",
            "kind": "LinkedField",
            "name": "advertisement",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JobPostFeedCardRecommend_feedCard"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecommendedPostsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "FeedCard",
        "kind": "LinkedField",
        "name": "recommendedFeed",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedAdvertisementInfo",
            "kind": "LinkedField",
            "name": "advertisement",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertisementCallback",
                "kind": "LinkedField",
                "name": "callback",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hide",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "impression",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "click",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobPost",
            "kind": "LinkedField",
            "name": "jobPost",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "workplaceRegion",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name3",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "images",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnail",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Point",
                "kind": "LinkedField",
                "name": "workplaceLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lng",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuthorBadge",
                        "kind": "LinkedField",
                        "name": "badge",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "type": "FastResponseBadge",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "hoianUser",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "receivedHasCommentJobReviewsCount",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "filter",
                                "value": {
                                  "evaluator": "APPLICANT",
                                  "hasComment": true
                                }
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "receivedJobReviewsCount",
                            "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"hasComment\":true})"
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "HoianUserAuthor",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bringUpCount",
                "storageKey": null
              },
              {
                "name": "stringifiedSalary",
                "args": null,
                "fragment": {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "salary",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "salaryType",
                      "storageKey": null
                    }
                  ],
                  "type": "JobPost",
                  "abstractKey": null
                },
                "kind": "RelayResolver",
                "storageKey": null,
                "isOutputType": true
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "1c7acab67f4028a9011ff9689230bfbc",
    "metadata": {},
    "name": "RecommendedPostsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7156f6e181dabfbce0fe428424f41ad6";

export default node;
