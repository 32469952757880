import { createSlice } from '@reduxjs/toolkit'

import { AppInfo } from '@src/types/common'

import { initApplication } from './common'

type RegionState = { [key in 'current' | 'other']: AppInfo['region'] }

const INITIAL_STATE: RegionState = { current: null, other: null }

const regionSlice = createSlice({
  name: 'region',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initApplication, (_, { payload }) => {
      const userExtend = payload.user?.userExtend
      const other = userExtend?.[userExtend.region2?.id !== payload.region?.id ? 'region2' : 'region1'] ?? null
      const handleUnspportRegion = (regionId: number) => {
        return (
          {
            // 오포읍 -> 오포1동
            1792: 11835,
          }[regionId] ?? regionId
        )
      }

      return {
        current: payload.region && {
          ...payload.region,
          // 하위버전에서는 값이 0으로 들어옴, 하위 호완성을 위해 0인 케이스도 체크함
          id: handleUnspportRegion(Number(payload.region.name3Id) || Number(payload.region.id)),
          name: payload.region.name3 || payload.region.name,
        },
        other: other && {
          ...other,
          // 하위버전에서는 값이 0으로 들어옴, 하위 호완성을 위해 0인 케이스도 체크함
          id: handleUnspportRegion(Number(other.name3Id) || Number(other.id)),
          name: other.name3 || other.name,
        },
      }
    })
  },
})

export default regionSlice.reducer
