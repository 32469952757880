import { useMutation, graphql } from 'react-relay'

import { useNewJobPostFilterSubscriptionMutationSubscribeMutation } from '@src/__generated__/useNewJobPostFilterSubscriptionMutationSubscribeMutation.graphql'
import { useNewJobPostFilterSubscriptionMutationUnsubscribeMutation } from '@src/__generated__/useNewJobPostFilterSubscriptionMutationUnsubscribeMutation.graphql'
import { WorkPeriod } from '@src/types/jobPost'

export function useNewJobPostFilterSubscriptionMutation() {
  const [mutateSubscribe, isSubscribeMutationLoading] =
    useMutation<useNewJobPostFilterSubscriptionMutationSubscribeMutation>(graphql`
      mutation useNewJobPostFilterSubscriptionMutationSubscribeMutation(
        $where: SubscribeNewJobPostFilterWhereInput!
        $data: SubscribeNewJobPostFilterDataInput!
      ) {
        subscribeNewJobPostFilter(where: $where, data: $data) {
          user {
            newJobPostFilterSubscription {
              isHolding
              regions {
                _id
                name3
              }
              workDays
              workPeriods
              jobTasks
            }
          }
        }
      }
    `)

  const [mutateUnsubscribe, isUnsubscribeMutationLoading] =
    useMutation<useNewJobPostFilterSubscriptionMutationUnsubscribeMutation>(graphql`
      mutation useNewJobPostFilterSubscriptionMutationUnsubscribeMutation(
        $option: UnsubscribeNewJobPostFilterOptionInput!
      ) {
        unsubscribeNewJobPostFilter(option: $option) {
          user {
            newJobPostFilterSubscription {
              isHolding
              regions {
                _id
                name3
              }
              workDays
              workPeriods
              jobTasks
            }
          }
        }
      }
    `)

  const handleMutateSubscribe = (config: Parameters<typeof mutateSubscribe>[0]) => {
    const data = config.variables.data
    data.workPeriods = data.workPeriods as WorkPeriod[]
    mutateSubscribe(config)
  }

  return {
    isLoading: isSubscribeMutationLoading || isUnsubscribeMutationLoading,
    mutateSubscribe: handleMutateSubscribe,
    mutateUnsubscribe,
  }
}
