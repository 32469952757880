/**
 * @generated SignedSource<<64938d2a2e4bc2b98f19710228000837>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApplicationStatus = "HIRED" | "IN_PROGRESS" | "REJECTED" | "WAITING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useJobPostReportMutation_jobPost$data = {
  readonly id: string;
  readonly myApplication: {
    readonly status: ApplicationStatus;
  } | null | undefined;
  readonly " $fragmentType": "useJobPostReportMutation_jobPost";
};
export type useJobPostReportMutation_jobPost$key = {
  readonly " $data"?: useJobPostReportMutation_jobPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"useJobPostReportMutation_jobPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useJobPostReportMutation_jobPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Application",
      "kind": "LinkedField",
      "name": "myApplication",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "da03fd76c546baf90552d522a18a51f9";

export default node;
