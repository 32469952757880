/**
 * @generated SignedSource<<0ee2cbd461989169b9f0098dce3bc7e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostStringifiedWorkTimeResolver$data = {
  readonly isWorkTimeNegotiable: boolean | null | undefined;
  readonly workTimeEnd: string | null | undefined;
  readonly workTimeStart: string | null | undefined;
  readonly " $fragmentType": "JobPostStringifiedWorkTimeResolver";
};
export type JobPostStringifiedWorkTimeResolver$key = {
  readonly " $data"?: JobPostStringifiedWorkTimeResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostStringifiedWorkTimeResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostStringifiedWorkTimeResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workTimeStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workTimeEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWorkTimeNegotiable",
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "5f632869506e6fb3d2c39c7f4814b26d";

export default node;
