import React from 'react'

import { useImpression } from '@src/hooks/useObserveShowElement'
import { useLogAnalyticsEvent } from '@src/packages/logAnalyticsEvent'
import { AnalyticsShowEventKey } from '@src/packages/logAnalyticsEvent/eventSchema'
import { LogShowEventParams } from '@src/packages/logAnalyticsEvent/LogAnalyticsEventContext'

type Props<T extends AnalyticsShowEventKey> = {
  logParams?: {
    name: T
  } & LogShowEventParams<T>
  onImpression?: () => void
}

const ImpressionChecker = <T extends AnalyticsShowEventKey>({
  onImpression,
  logParams,
}: Props<T>): React.ReactElement => {
  const targetRef = React.useRef<HTMLDivElement>(null)
  const [shown, setShown] = React.useState(false)
  const { logShowEvent } = useLogAnalyticsEvent()

  useImpression({
    onImpression: () => {
      if (logParams) {
        logShowEvent({
          name: logParams.name,
          ...{ params: (logParams as any).params ?? {} },
        })
      }
      setShown(true)
    },
    ref: targetRef,
  })

  React.useEffect(() => {
    if (logParams && typeof IntersectionObserver === 'undefined') {
      logShowEvent({
        name: logParams.name,
        ...{ params: (logParams as any).params ?? {} },
      })
    }
    setShown(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (shown) {
      onImpression?.()
    }
  }, [shown])

  return <div ref={targetRef} />
}

export default ImpressionChecker
