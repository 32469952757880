import { RegionRange } from './jobPost'

export enum AdvertisementType {
  TOP_PREMIUM = 'TOP_PREMIUM',
  STANDARD = 'STANDARD',
}

export enum AdvertisementAction {
  START = 'ADVERTISEMENT_START',
  WRITE_CASH_CHARGE = 'WRITE_CASH_CHARGE',
  CAMPAIN_START = 'ADVERTISEMENT_CAMPAIN_START',
}

export type BusinessType =
  | 'LARGE_SCALE_BUSINESS'
  | 'SMALL_BUSINESS'
  | 'FRANCHISE'
  | 'INDIVIDUAL'
  | '%future added value'

export type BMProductOption = {
  value: any
  label: string
  originPrice: number
  discountPrice: number
  warrantyDays: number
  description?: string
  purchasable?: boolean
  benefits?: string[]
}

export type AdvertisementConfig = {
  regionName: string
  regionIds: number[]
  excludeRegionIds?: number[]
  regionRange: RegionRange
  duration: number
  dailyBudget: number
  isAgreed: boolean
}

export type JobPostAdvertisementStatus =
  | 'READY'
  | 'DENIED'
  | 'ONGOING'
  | 'OUT_DATED'
  | 'PAUSED'
  | 'STOPPED'
  | 'DELETED'
  | 'START_OUT_DATED'
  | 'DAILY_BUDGET_EXCEEDED'
  | 'TOTAL_BUDGET_EXCEEDED'
  | '%future added value'
