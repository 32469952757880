import React from 'react'

import { calcWalkingDistance } from '@src/utils/jobPost'

export const useCalcWalkingDistance = (props: Parameters<typeof calcWalkingDistance>[0]) => {
  return React.useMemo(() => {
    return calcWalkingDistance(props)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
