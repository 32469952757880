import React from 'react'
import { useFragment, graphql } from 'react-relay'

import { JobPostFeedCardPersonal_feedCard$key } from '@src/__generated__/JobPostFeedCardPersonal_feedCard.graphql'

import JobPostCardContainer from './JobPostCardContainer'
import JobPostCardPersonal from './JobPostCardPersonal'

type Props = {
  feedCardRef: JobPostFeedCardPersonal_feedCard$key
} & Omit<React.ComponentProps<typeof JobPostCardContainer>, 'feedCardRef' | 'children'>

const JobPostFeedCardPersonal: React.FCC<Props> = ({
  advertisement,
  feedCardRef,
  cardPage,
  cardIndex,
  searchKeyword,
}) => {
  const feedCard = useFragment(
    graphql`
      fragment JobPostFeedCardPersonal_feedCard on FeedCard {
        jobPost {
          ...JobPostCardPersonal_jobPost
        }
        ...JobPostCardContainer_feedCard
      }
    `,
    feedCardRef
  )

  return (
    <JobPostCardContainer
      feedCardRef={feedCard}
      advertisement={advertisement}
      cardPage={cardPage}
      cardIndex={cardIndex}
      searchKeyword={searchKeyword}>
      {(props) => {
        return <JobPostCardPersonal jobPostRef={feedCard.jobPost} {...props} />
      }}
    </JobPostCardContainer>
  )
}

export default JobPostFeedCardPersonal
