import { useActivity } from '@stackflow/react'
import React from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay'

import { RecommendedPostsQuery } from '@src/__generated__/RecommendedPostsQuery.graphql'
import JobPostFeedCardRecommend from '@src/components/common/jobPostCard/JobPostFeedCardRecommend'
import Empty from '@src/components/Empty'
import withSuspense from '@src/hocs/withSuspense'
import use202307CampaignMarketing from '@src/hooks/use202307CampaignMarketing'
import { useJobsActivityParams } from '@src/hooks/useJobsActivityParams'
import { useRefetchableQuery } from '@src/hooks/useRefetchableQuery'
import { useUserRole } from '@src/hooks/useUserRole'
import { JobsActivity } from '@src/router/activityNames'
import { styled } from '@src/stitches/stitches.config'

type Props = {
  excludeAdvertisement?: boolean
  regionId: number
}

const RecommendedPosts: React.FCC<Props> = ({ regionId, excludeAdvertisement = false }) => {
  const { jobPostId } = useJobsActivityParams<typeof JobsActivity.JOB_POST | typeof JobsActivity.JOB_POST_AD>()
  const { isAuthor } = useUserRole()
  const { id } = useActivity()
  const { queryOptions } = useRefetchableQuery({ fetchPolicy: 'network-only', fetchKey: id })
  const is202307Campaign = use202307CampaignMarketing()
  const query = useLazyLoadQuery<RecommendedPostsQuery>(
    graphql`
      query RecommendedPostsQuery($jobPostId: ID!, $regionId: Int!, $size: Int!, $advertisement: Boolean) {
        recommendedFeed(jobPostId: $jobPostId, regionId: $regionId, size: $size, advertisement: $advertisement) {
          id
          advertisement {
            exposureType
          }
          ...JobPostFeedCardRecommend_feedCard
        }
      }
    `,
    {
      jobPostId,
      advertisement: !excludeAdvertisement,
      regionId,
      size: 5,
    },
    queryOptions
  )

  const filteredJobPosts = React.useMemo(() => {
    if (is202307Campaign) {
      return query.recommendedFeed.filter(({ advertisement }) => !advertisement?.exposureType)
    }
    return query.recommendedFeed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const firstAdvertisementIndex = React.useMemo(() => {
    return filteredJobPosts.findIndex(({ advertisement }) => advertisement?.exposureType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <div>
      {filteredJobPosts.map((feedCard, i) => {
        const isAdvertisement = !!feedCard.advertisement?.exposureType

        return (
          <React.Fragment key={feedCard.id}>
            <CardContainer>
              <JobPostFeedCardRecommend
                feedCardRef={feedCard}
                cardIndex={i}
                advertisement={{
                  isAdvertisement,
                  showAdvertisementLabel: isAdvertisement,
                  showAdvertiseNudge: isAuthor && i === firstAdvertisementIndex,
                }}
              />
            </CardContainer>
            <Divider />
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default withSuspense(RecommendedPosts, Empty)

const CardContainer = styled('div', {
  padding: '16px 0',

  '&:first-of-type': {
    padding: '24px 0 16px',
  },
})

const Divider = styled('div', {
  height: '1px',
  background: '$gray100',
  '&:last-of-type': {
    display: 'none',
  },
})
