import React from 'react'

import { scrollHiddenStyle } from '@src/stitches/common'
import { styled } from '@src/stitches/stitches.config'

import { PaginationScrollContext } from './context'

const PaginationScroll: React.FCC = ({ children }) => {
  const scrollElement = React.useRef<HTMLDivElement>(null)

  const paginationScrollContextValue = React.useMemo(() => ({ scrollElement }), [scrollElement])

  return (
    <Base ref={scrollElement}>
      <PaginationScrollContext.Provider value={paginationScrollContextValue}>
        {children}
      </PaginationScrollContext.Provider>
    </Base>
  )
}

export default PaginationScroll

const Base = styled('div', {
  position: 'relative',
  ...scrollHiddenStyle,
  width: '100%',
  whiteSpace: 'nowrap',
  overflowX: 'scroll',
  WebkitOverflowScrolling: 'touch',
  scrollBehavior: 'smooth',
})
