/**
 * @generated SignedSource<<e9bdc5cd51fb706aa239de1dcab60e9b>>
 * @relayHash 9c910d08b34aa8e29a25cdb5a28b8cf4
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9c910d08b34aa8e29a25cdb5a28b8cf4

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Day = "FRI" | "MON" | "SAT" | "SUN" | "THU" | "TUE" | "WED" | "%future added value";
export type JobTaskType = "BAKING" | "BEVERAGE_MAKING" | "CLEANING" | "CONVENIENCE_STORE" | "ETC" | "HOUSEWORK" | "KIDS_PICK_UP" | "KITCHEN_ASSISTANCE" | "KITCHEN_COOK" | "LEAFLET_DISTRIBUTION" | "LIGHT_WORK" | "NURSING" | "SERVING" | "SHOP_MANAGEMENT" | "TUTORING" | "%future added value";
export type WorkPeriod = "LESS_THAN_A_MONTH" | "MORE_THAN_A_MONTH" | "%future added value";
export type UnsubscribeNewJobPostFilterOptionInput = {
  holdingHours?: number | null | undefined;
};
export type useNewJobPostFilterSubscriptionMutationUnsubscribeMutation$variables = {
  option: UnsubscribeNewJobPostFilterOptionInput;
};
export type useNewJobPostFilterSubscriptionMutationUnsubscribeMutation$data = {
  readonly unsubscribeNewJobPostFilter: {
    readonly user: {
      readonly newJobPostFilterSubscription: {
        readonly isHolding: boolean;
        readonly jobTasks: ReadonlyArray<JobTaskType>;
        readonly regions: ReadonlyArray<{
          readonly _id: number;
          readonly name3: string | null | undefined;
        }>;
        readonly workDays: ReadonlyArray<Day>;
        readonly workPeriods: ReadonlyArray<WorkPeriod>;
      } | null | undefined;
    };
  };
};
export type useNewJobPostFilterSubscriptionMutationUnsubscribeMutation = {
  response: useNewJobPostFilterSubscriptionMutationUnsubscribeMutation$data;
  variables: useNewJobPostFilterSubscriptionMutationUnsubscribeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "option"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "option",
    "variableName": "option"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHolding",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name3",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workDays",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workPeriods",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTasks",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useNewJobPostFilterSubscriptionMutationUnsubscribeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnsubscribeNewJobPostFilterResult",
        "kind": "LinkedField",
        "name": "unsubscribeNewJobPostFilter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewJobPostSubscription",
                "kind": "LinkedField",
                "name": "newJobPostFilterSubscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "regions",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useNewJobPostFilterSubscriptionMutationUnsubscribeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnsubscribeNewJobPostFilterResult",
        "kind": "LinkedField",
        "name": "unsubscribeNewJobPostFilter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewJobPostSubscription",
                "kind": "LinkedField",
                "name": "newJobPostFilterSubscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "regions",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "9c910d08b34aa8e29a25cdb5a28b8cf4",
    "metadata": {},
    "name": "useNewJobPostFilterSubscriptionMutationUnsubscribeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "62fb5220940e0f5d89faee659daf780e";

export default node;
